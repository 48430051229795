<template>
  <div class="allScreem">
    <!-- pc端 -->
    <div class="pc-digital" v-if="!global.isPhone">
      <Header class="digitalHeader" @headerListClickFn="scrollMoveFn" />
      <div class="banner">
        <!-- <img :src="global.AssetsURL + '/picture/digtal/banner.png'" class="banner-img"> -->
        <Swiper :imgList="swiperList" />
      </div>
      <middle />
      <div class="videoArea">
        <div class="videoInfo">
          <div class="video-title">专注特色专科医学</div>
          <video controls :src="data.video.url" class="marketing-video"></video>
        </div>
      </div>
      <div class="separate-img-container">
        <img :src="global.AssetsURL + '/picture/digtal/1.png'" class="separate-img">
      </div>
      <div class="dataArea">
        <div class="dataItemBlock" :style="{ flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }"
          v-for="(item, index) in data.content" :key="index">
          <div class="dataInfo" :style="{ marginLeft: index % 2 == 1 ? '0.64rem' : '0px' }">
            <div class="dataItemTitle">{{ item.title }}</div>
            <div class="dataItemTxt">{{ item.txt }}</div>
          </div>
          <div class="imgBlock">
            <video preload="auto" loop muted :id="'video' + index" v-if="blobVideoSet[index]"
              :src="blobVideoSet[index]"></video>
            <img class="videoImg" @mouseenter="enter(index)" @mouseleave="leave(index)"
              :style="{ 'opacity': mouseIndex == index ? '0' : '1' }" :src="global.AssetsURL + item.img">
          </div>
        </div>
      </div>
      <div class="separate-img-container" style="margin-bottom:2rem">
        <img :src="global.AssetsURL + '/picture/digtal/2.png'" class="separate-img">
      </div>
      <Footer @scrollMoveFn="scrollMoveFn" />
    </div>
    <!-- phone端 -->
    <div class="phone-digital" v-if="global.isPhone">
      <Header @phoneScrollMoveFn="phoneScrollMoveFn" @phoneVideoSwitch="phoneVideoSwitch"></Header>
      <div class="phone-banner">
        <Swiper :imgList="swiperList" />
      </div>
      <div class="phone-video-area">
        <div class="phone-video-info">
          <div class="phone-maintitle">数字营销</div>
          <div class="phone-video-title">专注特色专科医学</div>
          <video controls :src="data.video.url" class="phone-marketing-video"></video>
        </div>
      </div>
      <div class="phone-separate-img-container">
        <img :src="global.AssetsURL + '/picture/digtal/1.png'" class="phone-separate-img">
      </div>
      <div class="phone-data-area">
        <div class="phone-data" v-for="(item, index) in data.content" :key="index">
          <img class="phone-data-poster" :src="global.AssetsURL + item.img" />
          <div class="phone-data-title">{{ item.title }}</div>
          <div class="phone-data-info">{{ item.txt }}</div>
        </div>
      </div>
      <div class="phone-separate-img-container" style="margin-bottom:1.6rem">
        <img :src="global.AssetsURL + '/picture/digtal/2.png'" class="phone-separate-img">
      </div>
      <Footer @phoneScrollMoveFn="phoneScrollMoveFn"></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Middle from "../components/Middle.vue";
import Swiper from '../components/Swiper.vue';

import { DigtalData } from "../../public/data/Digtal";

export default {
  name: "Digital",
  components: {
    Header,
    Footer,
    Middle,
    Swiper
  },
  data() {
    return {
      isPhone: false,
      data: DigtalData,
      mouseIndex: -1,
      blobVideoSet: new Array(DigtalData.content.length),
      showVideo: true,
      swiperList: DigtalData.swiperList
    };
  },
  created() {
    this.global.titleIndex = 3;
    //如果有跳转index，屏幕滚动
    if (!isNaN(this.$route.params.y)) {
      setTimeout(() => {
        if (this.global.isPhone) {
          const correction = this.$route.params.correction ? this.$route.params.correction : 0;
          this.phoneScrollMoveFn(this.$route.params.y, correction);
        } else {
          this.scrollMoveFn(this.$route.params.y);
        }
      }, 100);
    }
    this.isPhone = this.global.isPhone;

  },
  mounted() {
    console.log("页面加载完毕，加载视频");
    this.blobLoadVideoFn();

    // this.videoTestFn();
  },
  methods: {
    scrollMoveFn(index) {
      let dy = 0;
      let block = document.getElementsByClassName("dataItemBlock")[index - 1];
      dy = block.getBoundingClientRect().top;
      let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      window.scrollTo(0, dy + scrollTop - 250);
    },
    phoneScrollMoveFn(index, correction = 0) {
      const ratio = window.innerWidth / 375;
      const headHeight = 79 * ratio;
      const block = document.getElementsByClassName("phone-data")[index];
      const dy = block.getBoundingClientRect().top;
      const scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      let dis = dy + scrollTop - headHeight;
      if (correction != 0) dis += correction;
      window.scrollTo(0, dis);

      // let block = document.getElementsByClassName("phone-data")[index];
      // let dy = block.getBoundingClientRect().top;
      // let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      // window.scrollTo(0, dy + scrollTop - 50);
    },
    blobLoadVideoFn() {
      let data = this.data.content;
      data.forEach((item, index) => {
        if (item.video) {
          const req = new XMLHttpRequest();
          let _this = this;
          req.open("get", item.video);
          req.responseType = "blob";
          req.onload = function () {
            if (this.status === 200) {
              const videoBlob = this.response;
              let blobSrc = URL.createObjectURL(videoBlob);
              _this.blobVideoSet[index] = blobSrc;
              // setTimeout(() => {
              //   URL.revokeObjectURL(blobSrc);
              // }, 0);
              _this.$forceUpdate();
            }
          };
          req.onerror = err => {
            console.log(err);
          };
          req.send();
        }
      });
    },
    videoTestFn() {
      // console.log(this.data.video.url);
      let blobVideo = document.getElementById("blobVideoTag");
      this.global
        .getVideo(
          "http://resourcedev.smaxserver.com/assets/frag_bunny.mp4",
          blobVideo
        )
        .then(res => {
          console.log("get completed:", res);
          blobVideo.play();
        });
    },

    // 鼠标进入图片范围
    enter(index) {
      let video = document.getElementById("video" + index);
      if (video) {
        video.play();
        this.mouseIndex = index;
      }
    },
    leave(index) {
      let video = document.getElementById("video" + index);

      if (video) video.pause();

      this.mouseIndex = -1;
    },
    phoneVideoSwitch(show) {
      this.showVideo = !show;
    }
  }
};
</script>

<style scoped>
@import "../common/font/font.css";

.allScreem {
  position: relative;
  width: 100%;
}

.allScreem::-webkit-scrollbar {
  display: none;
}

.banner {
  width: 100%;
  height: 8.62rem;
  /* overflow: hidden; */
}

.banner-img {
  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.videoArea {
  position: relative;
  /* height: 6rem; */
  height: 12.42rem;
  width: 100%;
  overflow: hidden;
}

.videoAreaBG {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.videoInfo {
  position: relative;
  /* width: 60%; */
  width: 14.4rem;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1440px;
  padding: 1.54rem 0 1.9rem 0;
}

.video-title {
  font-size: .48rem;
  font-weight: 100;
  margin-bottom: .74rem;
}

.marketing-video {
  width: 100%;
  height: 7.5rem;
  object-fit: none;
}

.videoInfoTitle {
  position: relative;
  font-size: 0.36rem;
  color: #333333;
  margin-top: 1.63rem;
  margin-bottom: 0.22rem;
  font-family: "Bold";
}

.videoInfoContent {
  position: relative;
  font-size: 0.16rem;
  color: rgba(68, 68, 68, 0.8);
  width: 28%;
  font-family: "Regular";
}

/* .videoInfo video {
  position: absolute;
  width: 64%;
  max-height: 6rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
} */
.separate-img-container {
  width: 100%;
  height: 5.6rem;
  position: relative;
}

.separate-img {
  /* width: 2560px; */
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.dataArea {
  position: relative;
  /* width: 60%;
  padding: 0 20%; */
  background-color: #ffffff;
  max-width: 1440px;
  width: 14.4rem;
  margin: 0 auto;
}

.dataItemBlock {
  position: relative;
  display: flex;
  width: 100%;
  height: 4.06rem;
  margin-bottom: 1.9rem;
  justify-content: space-between;
}

.dataItemBlock:first-child {
  margin-top: 1rem;
}

.dataItemBlock:last-child {
  margin-bottom: 1.2rem;
}

.dataInfo {
  position: relative;
  height: 100%;
  width: 6.56rem;
}

.dataItemTitle {
  position: relative;
  font-size: 0.48rem;
  width: 5.33rem;
  line-height: .6rem;
  font-family: "Bold";
  margin-bottom: .3rem;
}

.dataItemTxt {
  font-size: 0.18rem;
  color: #777A81;
  width: 100%;
  font-family: "Regular";
  font-weight: 200;
  line-height: 0.32rem;
}

.imgBlock {
  position: relative;
  margin-top: .1rem;
  width: 7.2rem;
  flex-shrink: 0;
  height: calc(100% - .1rem);
  overflow: hidden;
}

.imgBlock video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.videoImg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  /* border-radius: 16px; */
  cursor: pointer;
}

.videoLogo {
  position: absolute;
  height: 7.14%;
  width: fit-content;
  top: 0.3rem;
  right: 0.3rem;
}

.phone-digital {
  width: 100%;
}

.phone-banner {
  width: 100%;
}

.phone-video-area {
  padding: 0.46rem 0.18rem 0.8rem 0.18rem;
  box-sizing: border-box;
  width: 100%;
}

.phone-video-info {
  width: 100%;
}

.phone-maintitle {
  width: 100%;
  font-weight: bold;
  font-size: 0.28rem;
  margin-bottom: 0.12rem;
}

.phone-video-title {
  width: 100%;
  font-size: 0.28rem;
  margin-bottom: 0.09rem;
  font-weight: 200;
}

.phone-marketing-video {
  width: 100%;
  height: 1.91rem;
}

.phone-separate-img-container {
  width: 100%;
  height: 1.28rem;
  position: relative;
  margin-bottom: 0.8rem;
}

.phone-separate-img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.phone-data-area {
  width: 100%;
  padding: 0 0.18rem;
  box-sizing: border-box;
  margin-bottom: 0.75rem;
}

.phone-data {
  width: 100%;
}

.phone-data:not(:last-child) {
  margin-bottom: 0.6rem;
}

.phone-data-poster {
  display: block;
  width: 100%;
  height: 2.11rem;
  margin-bottom: 0.14rem;
}

.phone-data-title {
  width: 100%;
  font-size: 0.21rem;
  font-weight: bold;
  line-height: 0.24rem;
  margin-bottom: 0.11rem;
}

.phone-data-info {
  width: 100%;
  font-size: 0.16rem;
  font-weight: 300;
  color: #777A81;
  line-height: 0.27rem;
}
</style>