<template>
  <div>
    <div v-if="middleStyleChange" class="middleFixSpace"></div>
    <!-- <div v-if="!isPhone" :class="middleStyleChange ? scrollUp ? 'middleTopChange' : 'middleChange' : 'middle'"> -->
    <div v-if="!isPhone" class="middle">
      <div class="middleContent">
        <div class="middleItem">
          姓名
          <div style="color: red;marginRight: 3px;">*</div>
          ：
        </div>
        <input
          id="name"
          type="text"
          placeholder="请输入姓名"
          v-model="pushInfo.name"
          :disabled="showSubmitedBtn"
          :style="{
            backgroundColor: showSubmitedBtn ? 'rgba(204,204,204,0.5)' : '',
          }"
        />
        <div class="middleItem">
          单位
          <div style="color: red;marginRight: 3px;">*</div>
          ：
        </div>
        <input
          type="text"
          placeholder="请输入公司名称"
          v-model="pushInfo.email"
          :disabled="showSubmitedBtn"
          :style="{
            backgroundColor: showSubmitedBtn ? 'rgba(204,204,204,0.5)' : '',
          }"
        />
        <div class="middleItem">
          <!-- 手机号 -->
          联系方式
          <div style="color: red;marginRight: 3px;">*</div>
          ：
        </div>
        <input
          type="text"
          placeholder="请输入联系方式"
          v-model="pushInfo.phoneNumber"
          :disabled="showSubmitedBtn"
          :style="{
            backgroundColor: showSubmitedBtn ? 'rgba(204,204,204,0.5)' : '',
          }"
        />
        <div class="middleItem">
          留言
          <div style="color: red;marginRight: 3px;">*</div>
          ：
        </div>
        <input
          type="text"
          placeholder="请输入您宝贵的建议"
          v-model="pushInfo.message"
          :disabled="showSubmitedBtn"
          :style="{
            backgroundColor: showSubmitedBtn ? 'rgba(204,204,204,0.5)' : '',
          }"
        />
        <button class="pushBtn" @click="pushInfoFn" v-if="!showSubmitedBtn">
          联系我们
        </button>
        <button class="submited-btn" v-if="showSubmitedBtn">
          <img src="../assets/w-bg-tick.png" class="w-bg-tick" />
          已提交
        </button>
        <transition name="fade">
          <div class="prompt" v-show="showPrompt">
            <div class="prompt-content">
              <img src="../assets/prompt-icon.png" class="prompt-icon" />
              提交成功!
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- 手机端 -->
    <div
      v-if="isPhone"
      :class="
        phoneMidFix && !phoneMidPush
          ? 'phoneMidPushInfoFix'
          : 'phoneMidPushInfo'
      "
    >
      <div class="phone-mid-title" v-if="!phoneMidPush" @click="phoneMidPushFn">
        <div class="phoneMidPushTxt">联系我们</div>
        <img
          class="phoneMidPushBtn"
          :src="require('../assets/picture/home/down.png')"
        />
      </div>
      <div class="phoneMidInfoArea" v-show="phoneMidPush">
        <input
          type="text"
          v-if="!infoCommitSuccess"
          v-model="pushInfo.name"
          placeholder="请输入姓名"
        />
        <input
          type="text"
          v-if="!infoCommitSuccess"
          v-model="pushInfo.email"
          placeholder="请输入邮箱"
        />
        <input
          type="text"
          v-if="!infoCommitSuccess"
          v-model="pushInfo.phoneNumber"
          placeholder="请输入11位手机号"
        />
        <textarea
          type="text"
          v-if="!infoCommitSuccess"
          v-model="pushInfo.message"
          placeholder="留言"
        />
        <div class="commited" v-if="infoCommitSuccess">
          <img src="../assets/success.png" class="success" />
          <div class="commited-text">提交成功！<br />感谢您的支持！</div>
        </div>
        <button
          class="phonepushBtn"
          v-if="!infoCommitSuccess"
          @click="pushInfoFn"
        >
          提交
        </button>
        <button
          class="phonepushBtn"
          v-if="infoCommitSuccess"
          @click="closePhoneTable"
        >
          关闭
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Middle",
  data() {
    return {
      pushInfo: {
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      },
      homeImgHeight: 880,
      scrollIndex: 0,
      middleStyleChange: false,
      scrollUp: true,
      scrollY: undefined,
      isPhone: false,
      phoneMidPush: false,
      phoneMidFix: false,
      showPrompt: false,
      infoCommitSuccess: false,
      showSubmitedBtn: false,
    };
  },
  methods: {
    async pushInfoFn() {
      // this.pushInfo.email.length < 1 ||
      if (
        this.pushInfo.name.length < 1 ||
        this.pushInfo.phoneNumber.length < 1 ||
        this.pushInfo.message.length < 1
      ) {
        alert("请完整填写信息");
        return;
      }
      let res = await this.global.contactUsFn(
        JSON.parse(JSON.stringify(this.pushInfo))
      );

      this.onInfoCommited(res);

      this.pushInfo.name = "";
      this.pushInfo.email = "";
      this.pushInfo.phoneNumber = "";
      this.pushInfo.message = "";
    },
    scrollFn() {
      this.scrollIndex =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 下滑
      if (this.scrollIndex > this.homeImgHeight) {
        this.middleStyleChange = true;
      } else {
        this.middleStyleChange = false;
      }
      //获取滚动方向
      if (this.scrollY == undefined) {
        this.scrollY = window.pageYOffset;
      }
      if (this.scrollY - window.pageYOffset < 0) {
        this.scrollUp = false;
      } else {
        this.scrollUp = true;
      }
      this.scrollY = window.pageYOffset;
      if (this.isPhone) {
        this.phoneMidMoveFn();
      }
    },
    resizeFn() {
      this.homeImgHeight = window.innerWidth / 2.1818 - 8;
    },
    phoneMidPushFn() {
      this.phoneMidPush = true;
      if (this.phoneMidFix) {
        setTimeout(() => {
          let block = document.querySelector(".phoneMidPushInfo");
          let dy = block.getBoundingClientRect().top;
          let top = document.documentElement.scrollTop;
          window.scrollTo(0, dy + top - 50);

          this.phoneMidFix = false;
        }, 10);
      }
    },
    phoneMidMoveFn() {
      let middle = document.getElementsByClassName("phoneSwiperMask");
      //获取元素下边到视图上边的距离
      let x = middle[0].getBoundingClientRect().bottom + 20;
      if (x < 0 && !this.scrollUp) {
        this.phoneMidFix = true;
      } else {
        this.phoneMidFix = false;
      }
    },
    hidePrompt() {
      this.showPrompt = true;
      setTimeout(() => {
        this.showPrompt = false;
      }, 3000);
    },
    closePhoneTable() {
      this.phoneMidPush = false;
      this.infoCommitSuccess = false;
    },
    onInfoCommited(result) {
      if (result && !this.global.isPhone) {
        this.hidePrompt();
        this.showSubmitedBtn = true;
      } else if (result && this.global.isPhone) {
        this.infoCommitSuccess = true;
      }
    },
  },
  created() {
    this.homeImgWidth = window.innerWidth - 6;
    if (this.global.isPhone) {
      this.isPhone = true;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollFn);
    window.addEventListener("resize", this.resizeFn);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFn);
    window.removeEventListener("resize", this.resizeFn);
  },
};
</script>

<style>
.middleFixSpace {
  position: relative;
  left: 0;
  height: 1.2rem;
  width: 100%;
  background-color: #ffffff;
}

.middle {
  position: relative;
  left: 0;
  height: 1.2rem;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: #f7f7f7;
  /* border-bottom: 1px solid #cccccc; */
}

.middleChange {
  position: fixed;
  top: 0;
  left: 0;
  /* height: 1.02rem; */
  height: 1.2rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  border-bottom: 1px solid #cccccc;
}

.middleTopChange {
  display: none;
}

.middleContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  /* width: 13.88rem; */
  width: 14.4rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  max-width: 1920px;
}

.middleContent span {
  position: relative;
  text-align: left;
  width: fit-content;
  font-size: 0.16rem;
  color: #333333;
  margin-right: 0.64rem;
  white-space: nowrap;
}

.middleItem {
  position: relative;
  font-size: 0.16rem;
  color: #32373e;
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-family: "Regular";
  flex-shrink: 0;
}

.middleContent input {
  position: relative;
  width: 2.2rem;
  height: 0.4rem;
  font-family: "Regular";
  font-size: 0.14rem;
  color: #32373e;
  border: 1px #c3cbdc solid;
  background-color: #ffffff;
  padding-left: 0.2rem;
  margin-left: 0.06rem;
  margin-right: 0.42rem;
  box-sizing: border-box;
}

.middleContent input::placeholder {
  font-family: "Regular";
  font-size: 0.14rem;
  color: #777a81;
}

.middleContent input:focus {
  outline: 0;
}

.pushBtn {
  width: 1.24rem;
  height: 0.4rem;
  background-color: #0073e6;
  color: #ffffff;
  font-size: 0.18rem;
  font-family: "Medium";
  border: none;
}

.pushBtn:focus {
  outline: 0;
}

.pushBtn:hover {
  cursor: pointer;
  background-color: #1a8dff;
}

.pushBtn:active {
  background-color: #0067ce;
}

.submited-btn {
  width: 1.24rem;
  height: 0.4rem;
  font-size: 0.18rem;
  font-family: "Medium";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a578;
  border: none;
}

.w-bg-tick {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.08rem;
}

.prompt {
  width: 3rem;
  height: 0.71rem;
  background-image: url("../assets/prompt-bg.png");
  background-position: 50% 36%;
  background-repeat: no-repeat;
  font-size: 0.2rem;
  color: #fff;
  font-weight: 400;
  position: absolute;
  right: -1.05rem;
  top: -0.91rem;
}

.fade-enter {
  transform: translateY(0.71rem);
  transition: all 1s;
}

.fade-enter-to {
  transition: all 1s;
}

.fade-leave-to {
  transform: translateY(0.71rem);
  transition: all 1s;
}

.fade-leave {
  transition: all 1s;
}

.prompt-content {
  width: 3rem;
  height: 0.64rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prompt-icon {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.12rem;
}

/* phone css */
.phoneMidPushInfoFix {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8f8f9;
  overflow: hidden;
  z-index: 1;
}

.phoneMidPushInfo {
  width: 100%;
  background-color: #f8f8f9;
  overflow: hidden;
}

.phone-mid-title {
  width: 100%;
  height: 0.56rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2rem;
  box-sizing: border-box;
}

.phoneMidPushTxt {
  font-size: 0.14rem;
  font-family: "Bold";
  color: #2f4257;
}

.phoneMidPushBtn {
  height: 0.16rem;
  width: 0.16rem;
  z-index: 2;
  transform: rotate(270deg);
}

.phoneMidInfoArea {
  width: 100%;
  background-color: #ffffff;
  padding: 0.64rem 0.7rem 0 0.7rem;
  box-sizing: border-box;
}

.phoneMidInfoArea input {
  display: block;
  width: 100%;
  height: 0.44rem;
  font-family: "Regular";
  font-size: 0.14rem;
  color: #333333;
  border: none;
  background-color: #f2f3f5;
  border-radius: 0.04rem;
  padding-left: 0.2rem;
  box-sizing: border-box;
  margin-bottom: 0.24rem;
  outline: none;
}

.phoneMidInfoArea textarea {
  display: block;
  width: 100%;
  height: 1.02rem;
  font-family: "Regular";
  font-size: 0.14rem;
  line-height: 0.19rem;
  border: none;
  color: #333333;
  background-color: #f2f3f5;
  border-radius: 0.04rem;
  padding-left: 0.2rem;
  padding-top: 0.12rem;
  box-sizing: border-box;
  margin-bottom: 0.24rem;
  resize: none;
  outline: none;
}

.phoneMidInfoArea input::placeholder,
.phoneMidInfoArea textarea::placeholder {
  color: #aaa;
}

.phoneMidInfoArea input:focus,
.phoneMidInfoArea textarea:focus {
  border: 1px solid #005dff;
  background-color: rgba(255, 255, 255);
}

.commited {
  width: 100%;
  height: 1.87rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.98rem;
}

.success {
  width: 1.4rem;
  height: 1.25rem;
  margin-bottom: 0.14rem;
}

.commited-text {
  font-size: 0.18rem;
  font-weight: 700;
  line-height: 0.24rem;
  color: #333333;
  text-align: center;
}

.phonepushBtn {
  margin-top: 0.12rem;
  margin-bottom: 0.64rem;
  width: 100%;
  height: 0.44rem;
  background: linear-gradient(to right, #00a8ff, #005dff);
  color: #ffffff;
  font-size: 0.16rem;
  font-family: "MeDium";
  border-radius: 0.04rem;
  border: none;
}

.fontMedium {
  font-family: "Medium";
}
</style>
