<script>
import Header from "../components/Header.vue";
import Middle from "../components/Middle.vue";
import { RobotData } from "../../public/data/Robot";
import Footer from "../components/Footer.vue";
import Swiper from "../components/Swiper.vue";
export default {
  components: {
    Header,
    Middle,
    Footer,
    Swiper,
  },
  data() {
    return {
      data: RobotData,
      swiperList: RobotData.swiperList,
    };
  },
  created() {
    this.global.titleIndex = 1;
    //如果有跳转index，屏幕滚动
    if (!isNaN(this.$route.params.y)) {
      setTimeout(() => {
        if (this.global.isPhone) {
          const correction = this.$route.params.correction
            ? this.$route.params.correction
            : 0;
          this.phoneScrollMoveFn(this.$route.params.y, correction);
        } else {
          this.scrollMoveFn(this.$route.params.y);
        }
      }, 100);
    }
  },
  methods: {
    scrollMoveFn(index) {
      let dy = 0;
      let block = document.getElementsByClassName("mainItems")[index - 1];
      dy = block.getBoundingClientRect().top;
      let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      window.scrollTo(0, dy + scrollTop - 280);
    },
    phoneScrollMoveFn(index, correction = 0) {
      const ratio = window.innerWidth / 375;
      const headHeight = 79 * ratio;
      const block = document.getElementsByClassName("phone-data")[index];
      const dy = block.getBoundingClientRect().top;
      const scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      let dis = dy + scrollTop - headHeight;
      if (correction != 0) dis += correction;
      window.scrollTo(0, dis);

      // let block = document.getElementsByClassName("phone-data")[index];
      // let dy = block.getBoundingClientRect().top;
      // let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      // window.scrollTo(0, dy + scrollTop - 50);
    },
  },
};
</script>

<template>
  <div id="robot">
    <Header></Header>
    <div class="robot-pc" v-if="!global.isPhone">
      <!-- <div>1111</div> -->
      <div class="banner">
        <div class="imgBlock">
          <img :src="global.AssetsURL + '/picture/robot/bannerBg.jpg'" />
          <div class="bannerTitle">
            开发国产模拟器替代进口产品<br />帮助血管介入术式向各级医院普及
          </div>
        </div>
      </div>
      <Middle></Middle>
      <div class="main">
        <div
          class="mainItems"
          v-for="(item, index) in data.content"
          :key="index"
          :style="{ flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }"
        >
          <div class="leftMianDesc">
            <div
              class="descItemTitle"
              :style="{ marginLeft: index % 2 == 1 ? '0.64rem' : '0px' }"
            >
              {{ item.title }}
            </div>
            <div
              class="dexcItemTxt"
              :style="{ marginLeft: index % 2 == 1 ? '0.64rem' : '0px' }"
              v-html="item.txt"
            ></div>
          </div>
          <div class="rightImgBlock">
            <img :src="global.AssetsURL + item.img" alt="" />
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="middleImgBlock">
          <img :src="global.AssetsURL + '/picture/robot/middleBg.png'" />
        </div>
      </div>
      <Footer @scrollMoveFn="scrollMoveFn"></Footer>
    </div>
    <div class="robot-mobile" v-if="global.isPhone">
      <Swiper :imgList="swiperList" />
      <div class="phone-data-area">
        <div
          class="phone-data"
          v-for="(item, index) in data.content"
          :key="index"
        >
          <img class="phone-data-poster" :src="global.AssetsURL + item.img" />
          <div class="phone-data-title">{{ item.title }}</div>
          <div class="phone-data-info">{{ item.txt }}</div>
        </div>
      </div>
      <div class="phone-separate-img-container" style="margin-bottom:1.6rem">
        <img :src="global.AssetsURL + '/picture/robot/middleBg.png'" />
      </div>
      <Footer @phoneScrollMoveFn="phoneScrollMoveFn"></Footer>
    </div>
  </div>
</template>

<style scoped>
#robot {
  width: 100vw;
}
.banner {
  width: 100%;
  height: 8.48rem;
}
.banner .imgBlock {
  width: 14.4rem;
  height: 100%;
  position: relative;
  margin: 0 auto;
}
.imgBlock img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.bannerTitle {
  width: 9.14rem;
  height: 1.7rem;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.48rem;
  font-family: "Bold";
  padding-top: 0.36rem;
  padding-left: 0.4rem;
}
.main {
  width: 14.4rem;
  margin: 0 auto;
}
.mainItems {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1.9rem;
}
.mainItems:first-child {
  margin-top: 1rem;
}

.mainItems:last-child {
  margin-bottom: 1.2rem;
}

.leftMianDesc {
  width: 7.2rem;
  /* position: relative; */
}
.descItemTitle {
  position: relative;
  font-size: 0.48rem;
  line-height: 0.6rem;
  font-family: "Bold";
  margin-bottom: 0.3rem;
  margin-top: -0.05rem;
}

.leftMianDesc .dexcItemTxt {
  width: 6.56rem;
  font-size: 0.18rem;
  color: #777a81;
  font-family: "Regular";
  line-height: 0.32rem;
  text-align: justify;
}
.rightImgBlock {
  width: 7.2rem;
  position: relative;
}
.rightImgBlock img {
  width: 100%;
}
.middle {
  width: 100%;
  height: 5.6rem;
}
.middleImgBlock {
  width: 14.4rem;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.middleImgBlock img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.phone-data-area {
  width: 100%;
  padding: 0 0.18rem;
  box-sizing: border-box;
  margin-bottom: 0.75rem;
}

.phone-data {
  width: 100%;
}

.phone-data:not(:last-child) {
  margin-bottom: 0.6rem;
}

.phone-data-poster {
  display: block;
  width: 100%;
  height: 2.11rem;
  margin-bottom: 0.14rem;
}

.phone-data-title {
  width: 100%;
  font-size: 0.21rem;
  font-weight: bold;
  line-height: 0.24rem;
  margin-bottom: 0.11rem;
}

.phone-data-info {
  width: 100%;
  font-size: 0.16rem;
  font-weight: 300;
  color: #777a81;
  line-height: 0.27rem;
}
.phone-data:first-child {
  margin-top: 0.5rem;
}
.phone-separate-img-container {
  width: 100%;
  height: 1.28rem;
  position: relative;
  margin-bottom: 0.8rem;
}

.phone-separate-img-container img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
