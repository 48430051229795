<template>
  <div id="app" :style="{ 'min-width': getMinWidth() }">
    <router-view @showIntentionForm="showForm" />
    <IntentionForm v-if="showIntentionForm" @closeForm="closeForm"></IntentionForm>
    <div class="to-top" v-if="global.isPhone" @click="backToTop"></div>
  </div>
</template>

<script>
import IntentionForm from './components/IntentionForm.vue';
export default {
  created() {
    let htmlWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    // console.log(htmlWidth);
    if (htmlWidth < 750) {
      this.global.isPhone = true;
    } else {
      this.global.isPhone = false;
    }

    // this.global.userAction(0);

    this.global.setURLFrom();
  },
  methods: {
    getMinWidth() {
      let htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (htmlWidth < 750) {
        return "none";
      } else {
        return `1200px`;
      }
    },
    showForm() {
      this.showIntentionForm = true;
    },
    closeForm() {
      this.showIntentionForm = false;
    },
    backToTop() {
      window.scrollTo(0, 0);
    }
  },
  components: {
    IntentionForm
  },
  data() {
    return {
      showIntentionForm: false,
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  margin: 0 auto;
  padding: 0;
  overflow-x: auto;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
}

html::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

div {
  overflow: -moz-scrollbars-none;
}

.to-top {
  position: fixed;
  bottom: 0.41rem;
  right: 0.2rem;
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 50%;
  border: 1px solid #005DFF;
  background-color: #fff;
  background-image: url('./assets/phone/up-blue-arrow.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0.16rem 0.16rem;
  z-index: 10;
}
</style>
