<template>
  <div class="rewardInfoAreaRoot" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"
    @mouseleave="onMouseLeave" :style="{transform:'translateX('+swiperTransform+'px)'}">
    <div class="reward" v-for="(reward, index) in data" :key="index">
      <div class="reward-year">{{ reward.time }}</div>
      <div class="reward-item" v-for="(item, index) in reward.content" :key="index">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiper",
  props: {
    data: {
      type: Array
    }
  },
  data() {
    return {
      isPhone: false,
      swiperPointIndex: 0,
      imgDx: 0,
      swiperTransform: 0,
      moving: false,
      startPosition: 0
    };
  },
  created: function () {
    if (this.global.isPhone) {
      this.isPhone = true;
    }
    // console.log(this.data)
  },
  methods: {
    swiperDxChange() {
      this.imgDx = document
        .querySelector(".rewardInfoArea")
        .getBoundingClientRect().width;
    },
    swiperIndexClick(index) {
      if (
        index > 0 &&
        this.swiperPointIndex < Math.floor(this.data.length / 2)
      ) {
        this.swiperPointIndex++;
      } else if (index < 0 && this.swiperPointIndex > 0) {
        this.swiperPointIndex--;
      }
    },
    swiperIndexPointClick(index) {
      this.swiperPointIndex = index;
    },
    onMouseDown(event) {
      this.moving = true;
      this.startPosition = event.clientX;
    },
    getSwiperMoveSpace() {
      const width = window.innerWidth;
      const blocks = this.data.length; //奖项块数量
      const blockWidth = 600; //奖项块宽度
      const containetWidth = 1602; //奖项容器宽度
      const space = ((blocks * blockWidth) - containetWidth) * width / 1920;
      return -space;
    },
    onMouseMove(event) {
      if (this.moving) {
        const pos = event.clientX;
        const movement = pos - this.startPosition;
        const maxMovement = this.getSwiperMoveSpace();
        if (this.swiperTransform + movement > 0) {
          this.swiperTransform = 0;
        } else if (this.swiperTransform + movement < maxMovement) {
          this.swiperTransform = maxMovement;
        } else {
          this.swiperTransform += movement;
        }
        this.startPosition = pos;
      }
    },
    onMouseUp() {
      this.moving = false;
      this.startPosition = 0;
    },
    onMouseLeave() {
      this.moving = false;
      this.startPosition = 0;
    }
  },
  computed: {
    swiperPoinstDx() {
      let x = -this.swiperPointIndex * this.imgDx;
      return {
        transform: `translateX(${x}px)`,
        transition: "1s"
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.imgDx = document
      //   .querySelector(".rewardInfoArea")
      //   .getBoundingClientRect().width;
    });
    window.addEventListener("resize", this.swiperDxChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.swiperDxChange);
  }
};
</script>

<style scoped>
.rewardInfoAreaRoot {
  /* position: relative;
  height: calc(100% - 1.31rem);
  width: 100%; */
  /* width: 100%; */
  height: 100%;
  padding: .62rem 0;
  box-sizing: border-box;
  display: flex;
  background-color: #F7F7F7;
  user-select: none;
}

.rewardInfoArea {
  position: relative;
  margin: 0 auto;
  width: 60%;
  height: 100%;
  overflow: hidden;
}

.rewardInfoAreaLine {
  position: relative;
  top: 4rem;
  border-top: 1px dashed #aaaaaa;
  display: flex;
  margin: 0 auto;
}

.rewardInfoAreaBlock {
  position: relative;
  display: flex;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
}

.rewardItemPoint {
  position: relative;
  height: 0.16rem;
  width: 0.16rem;
  transform: translateY(-0.08rem);
  border-radius: 50%;
  background-color: #005dff;
  margin-left: 1rem;
}

.rewardItemTop {
  position: relative;
  height: fit-content;
  width: fit-content;
  transform: translateY(-100%);
  padding-bottom: 0.4rem;
}

.rewardItemBottom {
  position: relative;
  width: fit-content;
  margin-top: 0.4rem;
}

.rewardItemTime {
  position: relative;
  font-size: 0.36rem;
  color: #005dff;
  margin-bottom: 0.2rem;
  font-weight: 600;
  font-family: "Bold";
  white-space: nowrap;
}

.rewardItemTxt {
  position: relative;
  font-size: 0.2rem;
  margin-bottom: 0.14rem;
  line-height: 0.24rem;
  color: #333333;
  font-family: "Regular";
  white-space: nowrap;
}

.rewardInfoFlag {
  position: absolute;
  width: 62%;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -64%);
}

.rewardInfoFlag img {
  position: absolute;
  height: 12px;
}

.rewardInfoFlagLeft {
  left: 0;
}

.rewardInfoFlagRight {
  right: 0;
}

.rewardInfoAreaBtn {
  position: absolute;
  width: 75%;
  height: 0.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -64%);
}

.rewardInfoAreaLeftBtn {
  position: absolute;
  cursor: pointer;
  background-image: url("../assets/left_no.png");
  background-size: 0.6rem 0.6rem;
  height: 0.6rem;
  width: 0.6rem;
  left: 0;
}

.rewardInfoAreaLeftBtn:hover {
  background-image: url("../assets/left_normal.png");
}

.rewardInfoAreaLeftBtn:active {
  background-image: url("../assets/left_click.png");
}

.rewardInfoAreaRightBtn {
  position: absolute;
  cursor: pointer;
  background-image: url("../assets/right_no.png");
  background-size: 0.6rem 0.6rem;
  height: 0.6rem;
  width: 0.6rem;
  right: 0;
}

.rewardInfoAreaRightBtn:hover {
  background-image: url("../assets/right_normal.png");
}

.rewardInfoAreaRightBtn:active {
  background-image: url("../assets/right_click.png");
}

.rewardIndexPoint {
  position: absolute;
  height: 16px;
  width: 100%;
  bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewardIndexPointItem {
  position: relative;
  height: 0.1rem;
  width: 0.1rem;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.rewardIndexPointItemIndex {
  background-image: linear-gradient(to right, #00a8ff, #005dff);
  width: 0.16rem;
  height: 0.16rem;
}

/* 20230222 */
.reward {
  flex-shrink: 0;
  height: 100%;
  width: 6rem;
  padding: .13rem .89rem;
  box-sizing: border-box;
}

.reward:not(:last-child) {
  border-right: 1px solid #000000;
}

.reward-year {
  font-size: .36rem;
  font-weight: 600;
  margin-bottom: .55rem;
}

.reward-item {
  font-size: .24rem;
  font-weight: 200;
  color: #32373E;
  line-height: .32rem;
}

.reward-item:not(:last-child) {
  margin-bottom: .36rem;
}
</style>