<template>
  <div class="footerBg">
    <div class="footer" v-if="!global.isPhone">
      <div class="footerHeader">
        <img src="../assets/wechatCode.png" class="wechat-code" />
        <div class="address">
          <!-- <p>
            公司地址： <br /><span style="color:#000"
              >上海市长宁区延安西路1590号增泽世贸大楼9KL室</span
            >
          </p> -->

           <p>
              公司地址： <br /><span style="color:#000"
                >上海市徐汇区钦州北路1122号新汇园90号楼10楼</span
              >
            </p>
          <p>联系电话：<br /><span style="color:#000">021-52358005</span></p>
        </div>
      </div>
      <div class="footerLine"></div>
      <div class="footerInfo">
        <div
          class="footerItem2"
          v-for="(item, index) in data.data2"
          :key="index"
        >
          <div class="footerItemTitle" @click="footerItemClickFn(index)">
            {{ item.title }}
          </div>
          <div
            class="footerItemContent"
            @click="footerItemClickFn(index, index2 + 1)"
            v-for="(item2, index2) in item.item"
            :key="index2"
          >
            {{ item2 }}
          </div>
        </div>
      </div>
      <div class="footerLine1"></div>
      <div class="companyCoyeInfo" @click="toGovWebsite">
        SpcaeMax©Copyright2016-2023沪ICP备16026985号
      </div>
    </div>
    <div v-if="global.isPhone">
      <div class="firstLine"></div>
      <div class="phone-footer">
        <div class="qr-codes">
          <div class="public" style="margin-right:0.26rem">
            <img src="../assets/phone/public.png" class="code" />
            <div class="code-title">公众号</div>
          </div>
          <div class="video">
            <img src="../assets/phone/video.png" class="code" />
            <div class="code-title">视频号</div>
          </div>
        </div>
        <p class="phoneAddress">
          公司地址： <br />
          <span style="color:#000"
            >上海市徐汇区钦州北路1122号新汇园90号楼10楼</span
          >
          <!-- <span style="color:#000"
            >上海市长宁区延安西路1590号增泽世贸大楼9KL室</span
          > -->
        </p>
        <p class="phoneAddress">
          联系电话：<br /><span style="color:#000">021-52358005</span>
        </p>
        <div class="secondLine"></div>
        <div class="phone-options">
          <div
            class="phone-option"
            v-for="(item, index) in phoneData"
            :key="index"
          >
            <div class="phone-option-title">
              <div
                class="name"
                @click="onOptionTouch(item, index)"
                :style="{
                  // color: global.titleIndex == index - 1 ? '#005DFF' : '',
                }"
              >
                {{ item.title }}
              </div>
              <img
                v-if="item.details || item.secOptions"
                :src="
                  item.detail
                    ? require('../assets/phone/up.png')
                    : require('../assets/phone/down.png')
                "
                class="icon"
                @click="switchDetails(item)"
              />
            </div>
            <!-- <div
            class="phone-footer-underline"
            v-if="item.detail"
            :style="{
              backgroundColor: global.titleIndex == index - 1 ? '#005DFF' : '',
            }"
          ></div> -->
            <!-- <div
              class="phone-option-detail"
              v-if="item.detail && item.details"
              v-html="item.details"
            ></div> -->
            <div
              class="sec-options"
              id="secOption"
              v-if="item.detail && item.secOptions"
            >
              <div
                class="sec-option"
                v-for="(opt, idx) in item.secOptions"
                :key="idx"
                @click="onChildMenuOptionTocuh(index, idx)"
              >
                {{ opt }}
              </div>
            </div>
          </div>
        </div>
        <div class="threeLine"></div>
        <div class="phone-provider">
          SpcaeMax©Copyright2016-2023沪ICP备16026985号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCode: false,
      idx: 0,
      phoneIndex: 0,
      data: {
        height: 0,
        data1: [],
        data2: [
          {
            title: " 机器人手术模拟器",
            item: [
              "国产血管介入手术模拟器",
              "手术定位导航系统",
              "术前规划",
              "结合人工智能的虚拟医生",
              "结合5G的虚拟手术云平台",
            ],
          },
          {
            title: "高端医疗器械培训",
            item: [
              "医疗器械原理动画",
              "模拟医学培训",
              "在线培训工具",
              "全景术野融合临床手术直录播平台",
              "严肃游戏与互动",
            ],
          },
          {
            title: "数字营销",
            item: [
              "3D医学原理动画",
              "医疗器械产品发布",
              "元宇宙虚拟人",
              "VR/AR/MR多人异地协同互动",
              "多媒体展项/互动触摸屏",
              "互联网营销传播工具",
              "企业销售道具（Sales Tool）",
              "VR手术直录播平台",
              "严肃游戏互动",
              "企业元宇宙（线上/线下）展厅",
            ],
          },
          {
            title: "临床专科",
            item: [
              "专科综合教学平台",
              "专科单病种教学内容平台",
              "3D医学打印服务",
              "远程手术/查房直播系统",
              "全景/高清/立体手术直录播服务",
              "临床与教学科研支持",
            ],
          },
          {
            title: "医学院校",
            item: [
              "执业/助理医师规范化培训虚拟仿真教学平台",
              "基本手术虚拟仿真和VR教学平台",
              "最新MR虚拟融合教学平台",
              "养老护理康复与健康管理平台",
              "3D医学打印服务",
              "公共卫生教学平台",
              "人体生命科学馆",
              "远程教学直播系统",
            ],
          },
          {
            title: "合作伙伴",
            item: [],
          },
          {
            title: "其他行业",
            item: [],
          },
        ],
      },
      routerBox: [
        "Robot",
        "DeviceTraining",
        "Digital",
        "Medical",
        "Simulation",
        "Partner",
      ],
      phoneData: [
        {
          title: "机器人手术模拟器",
          detail: false,
          secOptions: [
            "国产血管介入手术模拟器",
            "手术定位导航系统",
            "术前规划",
            "结合人工智能的虚拟医生",
            "结合5G的虚拟手术云平台",
          ],
        },
        {
          title: "高端医疗器械培训",
          detail: false,
          secOptions: [
            "医疗器械原理动画",
            "模拟医学培训",
            "在线培训工具",
            "全景术野融合临床手术直录播平台",
            "严肃游戏与互动",
          ],
        },
        {
          title: "数字营销",
          detail: false,
          secOptions: [
            // "App/Web端3D开发",
            // "VR定制化开发",
            // "3D打印服务",
            // "混合现实",
            "3D医学原理动画",
            "医疗器械产品发布",
            "元宇宙虚拟人",
            "VR/AR/MR多人异地协同互动",
            "多媒体展项/互动触摸屏",
            "互联网营销传播工具",
            "企业销售道具（Sales Tool）",
            "VR手术直录播平台",
            "严肃游戏互动",
            "企业元宇宙（线上/线下）展厅",
          ],
        },
        {
          title: "临床专科",
          detail: false,
          secOptions: [
            "专科综合教学平台",
            "专科单病种教学内容平台",
            "3D医学打印服务",
            "远程手术/查房直播系统",
            "全景/高清/立体手术直录播服务",
            "临床与教学科研支持",
          ],
        },
        {
          title: "医学院校",
          detail: false,
          secOptions: [
            // "执业/助理医师规范化培训虚拟仿真教学平台",
            // "基础手术虚拟仿真和VR教学平台",
            // "3D医学打印服务",
            // "护理/养老康复教学平台",
            // "远程教学直播系统",
            "执业/助理医师规范化培训虚拟仿真教学平台",
            "基本手术虚拟仿真和VR教学平台",
            "最新MR虚拟融合教学平台",
            "养老护理康复与健康管理平台",
            "3D医学打印服务",
            "公共卫生教学平台",
            "人体生命科学馆",
            "远程教学直播系统",
          ],
        },
        {
          title: "合作伙伴",
          detail: false,
        },
        {
          title: "其他行业",
          detail: false,
          QRCodes: true,
        },
      ],
    };
  },
  created() {},
  methods: {
    footerItemClickFn(index, index2) {
      // if (index == 0) {
      //   this.idx = 13;
      // } else if (index == 1) {
      //   this.idx = 12;
      // } else {
      //   this.idx = index;
      // }
      this.idx = index + 1;
      // console.log(index, index2);
      if (this.idx === 7) {
        window.location.href = "https://other.spacemax.com.cn";
      } else if (this.idx === 6) {
        this.global.titleIndex = 6;
        this.$router.push({
          name: "Partner",
        });
      } else {
        // console.log(this.idx, this.global.titleIndex, index2);
        if (
          this.global.titleIndex == this.idx &&
          typeof index2 != "undefined"
        ) {
          console.log("bbb");
          this.$emit("scrollMoveFn", index2);
        } else {
          console.log("aaaa");
          this.global.titleIndex = this.idx;
          // console.log(this.idx, this.global.titleIndex, index2);
          this.$router.push({
            name: this.routerBox[index],
            params: {
              y: index2,
            },
          });
        }
      }
    },
    switchDetails(opt, index) {
      if (!opt.detail) {
        for (let i = 0; i < this.phoneData.length; i++) {
          if (i !== index) {
            this.phoneData[i].detail = false;
          }
        }
      }

      opt.detail = !opt.detail;
    },
    onOptionTouch(item, index) {
      console.log(item, index);
      // if (item.details || item.secOptions) {
      //   if (item.detail && index > 0) {
      //     // this.switchPage(this.routerBox[index - 1]);
      //   } else {
      //     this.switchDetails(item, index);
      //   }
      // } else if (item.title == "合作伙伴") {
      //   this.switchPage("Partner");
      // }
      if (item.title == "合作伙伴") {
        this.switchPage("Partner");
      } else if (item.title == "其他行业") {
        window.location.href = "https://other.spacemax.com.cn";
      } else {
        this.switchDetails(item, index);
      }
    },
    switchPage(name) {
      this.$router.push({ name: name });
    },
    onChildMenuOptionTocuh(index, idx) {
      console.log(index, idx);
      // if (index == 0) {
      //   this.phoneIndex = 13;
      // } else if (index == 1) {
      //   this.phoneIndex = 12;
      // } else {
      //   this.phoneIndex = index;
      // }
      this.phoneIndex = index + 1;
      let radio = window.innerWidth / 375;
      if (this.global.titleIndex == this.phoneIndex) {
        console.log(this.phoneIndex, this.global.titleIndex);
        console.log("aaa");
        this.$emit("phoneScrollMoveFn", idx);
      } else {
        console.log("bbb");
        if (index == 2) {
          console.log("ccc");
          this.height = 160 * radio;
        } else {
          this.height = -120 * radio;
        }
        // console.log(height);
        this.global.titleIndex = this.phoneIndex;
        this.$router.push({
          name: this.routerBox[index],
          params: {
            y: idx,
            correction: this.height,
          },
        });
      }
    },
    toGovWebsite() {
      window.location.href = "https://beian.miit.gov.cn/";
    },
  },
};
</script>

<style scoped>
.footerBg {
  position: relative;
  width: 100%;
}

.footer {
  position: relative;
  padding: 0.8rem 0;
  width: 14.4rem;
  margin: 0 auto;
  /* font-size: 0.2rem; */
  font-family: "Regular";
  font-weight: 400;
}
.footerHeader {
  position: relative;
  width: 100%;
  height: 1.3rem;
  display: flex;
  justify-content: space-between;
}
.address {
  width: 6rem;
  padding-right: 4.8rem;
  font-size: 0.16rem;
  color: #777a81;
  font-family: "Regular";
  padding-top: 0.2rem;
  display: flex;
  justify-content: space-between;
}
.footerInfo {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.16rem;
}

.footerItem {
  position: relative;
}

.footerItem2 {
  position: relative;
  /* padding-right: 0.37rem; */
  width: fit-content;
  flex-shrink: 0;
}

.footerItemTitle {
  position: relative;
  margin-bottom: 0.3rem;
  color: #000000;
}

.footerItemContent {
  font-size: 0.14rem;
  margin-bottom: 0.15rem;
  color: #777a81;
}

/* .footerInfo:last-child .footerItemContent:hover {
  color: #005dff;
} */

.footerItem2 .footerItemContent {
  cursor: pointer;
}

.footerItem2 .footerItemContent:hover {
  color: #005dff !important;
}

.footerItem2 .footerItemTitle:hover {
  color: #005dff !important;
}

.footerItem2 .footerItemTitle {
  cursor: pointer;
}

.footerLine {
  position: relative;
  width: 100%;
  border-top: 1px solid #dfe4ee;
  margin-top: 0.25rem;
  margin-bottom: 0.45rem;
}
.footerLine1 {
  position: relative;
  width: 100%;
  border-top: 1px solid #dfe4ee;
  margin-top: 0.28rem;
  margin-bottom: 0.25rem;
}
.footerWechatIcon {
  position: relative;
  /* cursor: pointer; */
  width: 0.4rem;
  left: 0.2rem;
}

.footerWechatCode {
  position: absolute;
  width: 1.65rem;
  top: 0.8rem;
  left: -0.42rem;
  z-index: 10;
}

/* .wechat-code {
  width: 2.28rem;
  position: absolute;
  top: 0;
  left: 0;
} */

.companyCoyeInfo {
  width: 4.21rem;
  font-size: 0.16rem;
  color: #777a81;
  cursor: pointer;
  border-bottom: 1px solid #707070;
  text-align: center;
}

@media screen and (max-width: 650px) {
}

.phone-footer {
  width: 100%;
  padding: 0.36rem 0.2rem 1.18rem 0.2rem;
  box-sizing: border-box;
  /* background-color: #dadcdf; */
}

.phone-options {
  width: 100%;
}

.phone-option {
  width: 100%;
}

.phone-option:not(:last-child) {
  margin-bottom: 0.37rem;
}

.phone-option-title {
  width: 100%;
  font-size: 0.16rem;
  font-weight: 500;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  width: calc(100% - 0.16rem);
}
.icon {
  width: 0.16rem;
}

.phone-footer-underline {
  width: 0.36rem;
  height: 1px;
  background-color: #333333;
  /* margin-bottom: 0.32rem; */
  margin-top: 0.05rem;
}

.phone-option-detail {
  margin-top: 0.17rem;
  font-size: 0.12rem;
  line-height: 0.24rem;
  color: #333333;
  font-weight: 400;
}

.sec-options {
  width: 100%;
  margin-top: 0.17rem;
  color: #777a81;
}

.sec-option {
  margin-bottom: 0.24rem;
  font-size: 0.16rem;
}

.qr-codes {
  width: 100%;
  display: flex;
  font-size: 0.12rem;
  font-weight: 400;
  color: #777777;
  margin-bottom: 0.31333rem;
}
.phoneAddress {
  font-size: 0.16rem;
  color: #777a81;
  font-family: "Regular";
  line-height: 0.32rem;
  margin-bottom: 0.1rem;
}
.public,
.video {
  width: 0.64rem;
  height: 0.94rem;
}

.code-title {
  width: 100%;
  text-align: center;
  margin-bottom: 0.14rem;
}

.code {
  width: 0.64rem;
  height: 0.64rem;
  margin-bottom: 8px;
}

.phone-provider {
  font-size: 0.12rem;
  font-weight: 400;
  color: #777777;
  margin-top: 0.21rem;
}
.firstLine {
  width: 100%;
  height: 1px;
  background: #dfe4ee;
  /* margin-bottom: 0.3333rem; */
}
.secondLine {
  width: 100%;
  height: 1px;
  background: #dfe4ee;
  margin-bottom: 0.33333rem;
  margin-top: 0.28rem;
}
.threeLine {
  width: 100%;
  height: 1px;
  background: #dfe4ee;
  margin-top: 0.313333rem;
  margin-bottom: 0.306667rem;
}
</style>
