const DigtalData = {
    video: {
        url: "http://resourcedev.smaxserver.com/CMEF2021.5b52a468.mp4",
        title: "用XR<br>关爱生命温度",
        content: "提供最新医疗器械医学可视化、培训系统与临床教学工具。"
    },
    content: [
    {
        img:'/picture/digtal/001.jpg',
        title:'3D医学原理动画',
        txt:'通过3D医学原理建模、高品质贴图与渲染、通过3D医学动画，打造高品质医疗动画影片，结合短视频的营销方式，通过企业媒体流量平台，赋能营销流量，提高品牌科技力。'
    },
    {
        img:'/picture/digtal/002.jpg',
        title:'医疗器械产品发布',
        txt:'通过高品质3D医学动画，介绍新品外观造型，内构展示，医学原理，操作步骤，让医生快速了解认知产品整体结构和临床设备使用。'
    },
    {
        img:'/picture/digtal/003.jpg',
        title:'元宇宙虚拟人',
        txt:'通过XR技术，企业可以创造元宇宙虚拟人物，打破时间和空间限制，沉浸式赋能未来医疗场景，结合人工智能和语音识别，通过人机互动形式，服务医患群体，患者互动，未来可以提供初诊或分诊服务，支持临床售前服务工作。'
    },
    {
        img:'/picture/digtal/004.jpg',
        title:'VR/AR/MR多人异地协同互动',
        txt:'通过最新VR/MR（虚拟现实/混合现实）设备，模拟临床器械产品操作步骤和设备使用流程，通过多人异地联网形式，创建在线互动手术室,系统支持在线语音通话，可以快速开展远程手术演示和产品交流，为医疗企业降本增效，提高产品认知与临床操作协同能力。'
    },
    {
        img:'/picture/digtal/005.jpg',
        title:'多媒体展项/互动触摸屏',
        txt:' 医学多媒体互动展项可以结合企业产品定位，通过3D可视化与触摸大屏，展示医学原理，人体结构，患者教育，疗法说明。通过轻交互，多屏互动，动态捕捉等技术，能够快速让现场参会人员了解产品特性，医学原理，疗法功能，提高品牌科技调性。'
    },
    {
        img:'/picture/digtal/006.jpg',
        title:'互联网营销传播工具',
        txt:'通过最新微信小程序，结合AR特性，结合医疗企业宣传产品，能够快速在线展示高品质内容。其中不仅能展示医疗器械设备结构、设备原理介绍、临床模拟展示等功能，学术会现场也能快速演示和讲解。通过关联微信企业公众号，进行产品快速传播与推广，短时间内可以内覆盖大量用户。'
    },
    {
        img:'/picture/digtal/007.jpg',
        title:'企业销售道具（Sales Tool）',
        txt:'通过企业官方APP，为医疗企业提供远程设备展示或医学原理和售后技术支持，结合客户CRM销售管理系统，演示携带便捷，无需携带产品。尤其针对大型手术机器人与大型医疗设备，商务拜访与交流。'
    },
    {
        img:'/picture/digtal/008.jpg',
        title:'VR手术直录播平台',
        txt:'通过8K VR全景相机与多机位高清相机融合，将临床手术8KVR全景信号和主刀手部局部画面、4K高清多路术野画面、患者生理数据画面采集并部署于手机微信端，用户无需佩戴VR设备,拥有沉浸式第一人称视角,在线进行现场手术观摩和学习和回看。在学术会现场，应对与服务不同临床医生视角需求，为医生提供自主画面视角切换。医疗企业长期打造一批高水准、多病理、有深度的手术视频素材库，长期为临床医生提供学术交流与学术转化支持。'
    },
    {
        img:'/picture/digtal/009.jpg',
        title:'严肃游戏互动',
        txt:'抓住年轻医生碎片时间，进行兴趣培养，注意力与记忆提升训练。结合企业不同产品为不同患者选择正确的诊疗方案，包括用药，是否需要手术等关卡；企业定制化游戏内容结合时间压力的介入手术游戏，游戏过程中包括使用不同类型器械，操作步骤是否正确；游戏设置榜单和联机模块，提升用户长期使用意愿，通过移动端完成诊疗的记忆训练。'
    },
    {
        img:'/picture/digtal/010.jpg',
        title:'企业元宇宙（线上/线下）展厅',
        txt:'通过最新Digital Web3D与XR技术，医疗企业可以打造专业的（线上/线下）数字化展厅。能够快速开展学术会议、线上手术室、举办线上沙龙、演讲与视频分享、销售产品线上/线下实时演示。'
    },

],
    swiperList: [{
        url: '/picture/digtal/banner.png',
        preUrl:'/picture/digtal/banner.png',
        show:false,
        title: '用XR关爱生命的温度',
        subtitle: '提供最新医疗厂商医学可视化、培训系统与临床教学工具'
    }]
}

export {
    DigtalData
}