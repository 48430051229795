<script>
export default {
    data() {
        return {
            showOptions: false,
            fixedComponent: false
        }
    },

    props: {
        data: Array
    },

    methods: {
        setOptionsVisiblity() {
            this.showOptions = !this.showOptions;
        },

        inPageNavigation(index) {
            let correction = 0;
            if (!this.fixedComponent) correction = -(((51 * this.data.length) + 53) * (window.innerWidth / 375));
            this.$emit('inPageNavigation', { index, correction });
            this.showOptions = false;
        },

        onWindowScroll() {
            if (!this.global.isPhone) return
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            const width = window.innerWidth;
            const ratio = width / 375;

            const top = scrollTop / ratio;

            if (top > 506) {
                this.fixedComponent = true;
            } else {
                this.fixedComponent = false;
            }
        }
    },

    mounted() {
        window.addEventListener('scroll', this.onWindowScroll.bind(this));
    }

}
</script>

<template>
    <div id="in-page-navigator" :class="fixedComponent ? 'fixed-navigator' : 'in-page-navigator'"
        :style="{ 'height': showOptions ? `${0.51 * data.length + 0.53}rem` : '0.53rem' }">
        <div class="title" @click="setOptionsVisiblity()">
            Overview
            <img src="../assets/arrow-down.png" class="stat" :style="{ 'transform': showOptions ? 'rotate(180deg)' : '' }">
        </div>
        <div class="options" v-if="showOptions">
            <div class="option" v-for="(opt, index) in data" :key="index" @click="inPageNavigation(index)">{{ opt }}</div>
        </div>
    </div>
</template>

<style scoped>
.in-page-navigator {
    width: 100%;
    padding: 0 0.18rem;
    box-sizing: border-box;
    border-top: 1px solid #DFE4EE;
    border-bottom: 1px solid #DFE4EE;
    overflow: hidden;
    transition: all 0.3s linear;
    background-color: #fff;
}

.fixed-navigator {
    width: 100%;
    padding: 0 0.18rem;
    box-sizing: border-box;
    border-top: 1px solid #DFE4EE;
    border-bottom: 1px solid #DFE4EE;
    overflow: hidden;
    transition: all 0.3s linear;
    position: fixed;
    top: 0.79rem;
    z-index: 10;
    background-color: #fff;
}

.title {
    width: 100%;
    height: 0.51rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.16rem;
    color: #32373E;
    font-weight: 500;
}

.stat {
    width: 0.16rem;
    height: 0.08rem;
    transition: all 0.3s linear;
}

.options {
    width: 100%;
    transition: all 0.5s linear;
}

.option {
    width: 100%;
    height: 0.51rem;
    padding: 0 0.12rem;
    box-sizing: border-box;
    line-height: 0.51rem;
    font-size: 0.16rem;
    color: #32373E;
    border-top: 1px solid #DFE4EE;
}
</style>