<script>
import Header from '../components/Header.vue';
import Swiper from '../components/Swiper.vue';
import Middle from '../components/Middle.vue';
import Footer from '../components/Footer.vue';

import { DeviceTrainingData } from '../../public/data/DeviceTraining';

export default {
    components: {
        Header,
        Swiper,
        Middle,
        Footer
    },
    data() {
        return {
            data: DeviceTrainingData,
        }
    },
    created() {
        this.global.titleIndex = 2;

        if (!isNaN(this.$route.params.y)) {
            setTimeout(() => {
                if (!this.global.isPhone) {
                    this.scrollMoveFn(this.$route.params.y);
                } else {
                    const crt = this.$route.params.correction ? this.$route.params.correction : 0;
                    this.phoneScrollMoveFn(this.$route.params.y, crt);
                }
            }, 100);
        }
    },
    methods: {
        scrollMoveFn(index) {
            let block = document.getElementsByClassName("dataItemBlock")[index - 1];
            let dy = block.getBoundingClientRect().top;
            let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
            window.scrollTo(0, dy + scrollTop - 250);
        },

        phoneScrollMoveFn(index, correction = 0) {
            const ratio = window.innerWidth / 375;
            const headHeight = 79 * ratio;
            const block = document.getElementsByClassName("phone-data")[index];
            const dy = block.getBoundingClientRect().top;
            const scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
            let dis = dy + scrollTop - headHeight;
            if (correction != 0) dis += correction;
            window.scrollTo(0, dis);
        },
    },

}
</script>

<template>
    <div id="device-training">
        <div class="device-training-pc" v-if="!global.isPhone">
            <Header></Header>
            <div class="banner">
                <Swiper :imgList="data.swiperList" />
            </div>
            <Middle />
            <div class="dataArea">
                <div class="dataItemBlock" :style="{ flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }"
                    v-for="(item, index) in data.contents" :key="index">
                    <div class="dataInfo" :style="{ marginLeft: index % 2 == 1 ? '0.64rem' : '0px' }">
                        <div class="dataItemTitle">{{ item.title }}</div>
                        <div class="dataItemTxt">{{ item.desc }}</div>
                    </div>
                    <div class="imgBlock">
                        <img class="videoImg" :src="global.AssetsURL + item.picture">
                    </div>
                </div>
            </div>
            <div class="separate-img-container" style="margin-bottom:2rem">
                <img :src="global.AssetsURL + '/picture/device/sp.jpg'" class="separate-img">
            </div>
            <Footer @scrollMoveFn="scrollMoveFn" />
        </div>
        <div class="device-training-mobile" v-if="global.isPhone">
            <Header @phoneScrollMoveFn="phoneScrollMoveFn"></Header>
            <div class="phone-banner">
                <Swiper :imgList="data.swiperList" />
            </div>
            <div class="phone-data-area">
                <div class="phone-data" v-for="(item, index) in data.contents" :key="index">
                    <img class="phone-data-poster" :src="global.AssetsURL + item.picture" />
                    <div class="phone-data-title">{{ item.title }}</div>
                    <div class="phone-data-info">{{ item.desc }}</div>
                </div>
            </div>
            <div class="phone-separate-img-container" style="margin-bottom:1.6rem">
                <img :src="global.AssetsURL + '/picture/device/sp.jpg'" class="phone-separate-img">
            </div>
            <Footer @phoneScrollMoveFn="phoneScrollMoveFn"></Footer>
        </div>
    </div>
</template>

<style scoped>
#device-training {
    width: 100vw;
}

.device-training-pc {
    width: 100%;
}

.banner {
    width: 100%;
    height: 8.48rem;
}

.dataArea {
    position: relative;
    background-color: #ffffff;
    max-width: 1440px;
    width: 14.4rem;
    margin: 0 auto;
}

.dataItemBlock {
    position: relative;
    display: flex;
    width: 100%;
    height: 4.06rem;
    margin-bottom: 1.9rem;
    justify-content: space-between;
}

.dataItemBlock:first-child {
    margin-top: 1rem;
}

.dataItemBlock:last-child {
    margin-bottom: 1.2rem;
}

.dataInfo {
    position: relative;
    height: 100%;
    width: 6.56rem;
}

.dataItemTitle {
    position: relative;
    font-size: 0.48rem;
    width: 5.33rem;
    line-height: .6rem;
    font-family: "Bold";
    margin-bottom: .3rem;
}

.dataItemTxt {
    font-size: 0.18rem;
    color: #777A81;
    width: 100%;
    font-family: "Regular";
    font-weight: 200;
    line-height: 0.32rem;
}

.imgBlock {
    position: relative;
    margin-top: .1rem;
    width: 7.2rem;
    flex-shrink: 0;
    height: calc(100% - .1rem);
    overflow: hidden;
}

.imgBlock video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.videoImg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.separate-img-container {
    width: 100%;
    height: 5.6rem;
    position: relative;
}

.separate-img {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.device-training-mobile {
    width: 100%;
}

.phone-banner {
    width: 100%;
}

.phone-data-area {
    width: 100%;
    padding: 0 0.18rem;
    box-sizing: border-box;
    margin-bottom: 0.75rem;
}

.phone-data {
    width: 100%;
}

.phone-data:not(:last-child) {
    margin-bottom: 0.6rem;
}

.phone-data-poster {
    display: block;
    width: 100%;
    height: 2.11rem;
    margin-bottom: 0.14rem;
}

.phone-data-title {
    width: 100%;
    font-size: 0.21rem;
    font-weight: bold;
    line-height: 0.24rem;
    margin-bottom: 0.11rem;
}

.phone-data-info {
    width: 100%;
    font-size: 0.16rem;
    font-weight: 300;
    color: #777A81;
    line-height: 0.27rem;
}

.phone-separate-img-container {
    width: 100%;
    height: 1.28rem;
    position: relative;
    margin-bottom: 0.8rem;
}

.phone-separate-img {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>