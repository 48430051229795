<template>
    <!-- 用户点击医学院校和临床专科中各项目的了解更多后，弹出此意向表单，收集用户的了解意向 -->
    <div id="intention">
        <div class="content" :class="{ 'phone-content': global.isPhone }">
            <div class="close-btn" :class="{ 'phone-close-btn': global.isPhone }" @click="closeForm"></div>
            <div class="user-info" v-if="step == 0">
                <div class="title" :class="{ 'phone-title': global.isPhone }">联系我们</div>
                <div class="name" :class="{ 'phone-name': global.isPhone }"
                    :style="{ 'border': focusIpt == 1 ? '1px solid #005DFF' : '' }">
                    <input class="name-ipt" :class="{ 'phone-name-ipt': global.isPhone }" type="text"
                        placeholder="请输入姓名(必填)" @focus="onInputFocus(1)" @keyup="onInputKeyUp">
                </div>
                <div class="email" :class="{ 'phone-email': global.isPhone }"
                    :style="{ 'border': focusIpt == 2 ? '1px solid #005DFF' : '' }">
                    <input class="email-ipt" :class="{ 'phone-email-ipt': global.isPhone }" type="text"
                        placeholder="请输入邮箱" @focus="onInputFocus(2)" @keyup="onInputKeyUp">
                </div>
                <div class="phone" :class="{ 'phone-phone': global.isPhone }"
                    :style="{ 'border': focusIpt == 3 ? '1px solid #005DFF' : '' }">
                    <input class="phone-ipt" :class="{ 'phone-phone-ipt': global.isPhone }" type="text"
                        placeholder="请输入11位手机号(必填)" @focus="onInputFocus(3)" @keyup="onInputKeyUp">
                </div>
            </div>
            <div class="select-table" v-if="step == 1">
                <div class="title" :class="{ 'phone-title': global.isPhone }">选择您想要了解的产品</div>
                <div class="options" :class="{ 'phone-options': global.isPhone }">
                    <div class="main-cate" :class="{ 'phone-main-cate': global.isPhone }" v-for="(item, index) in data"
                        :key="index">
                        <div class="cate-content" v-if="item.title">
                            <div class="stat-icon" :class="{ 'phone-stat-icon': global.isPhone }"
                                :style="{ 'backgroundImage': getStatIconBackGroundImage(item, index), 'backgroundColor': form.intentions[index].length > 0 ? '#005DFF' : '', 'border': form.intentions[index].length > 0 ? '1px solid #005DFF' : '' }"
                                @click="showSecCate(item)">
                            </div>
                            <div class="cate-title">{{ item.title }}</div>
                        </div>
                        <div class="sec-cates" v-if="item.optUnfold"
                            :style="{ 'padding': item.title ? (global.isPhone ? '0.18rem 0 0 0.36rem' : '0.2rem 0 0 0.3rem') : '' }">
                            <div class="sec-cate" :class="{ 'phone-sec-cate': global.isPhone }"
                                v-for="(cate, idx) in item.options" :key="idx">
                                <div class="cate-content">
                                    <div class="stat-icon" :class="{ 'phone-stat-icon': global.isPhone }"
                                        :style="{ 'backgroundImage': form.intentions[index].includes(idx) ? 'url(' + require('../assets/selected.png') + ')' : '', 'backgroundColor': form.intentions[index].includes(idx) ? '#005DFF' : '', 'border': form.intentions[index].includes(idx) ? '1px solid #005DFF' : '' }"
                                        @click="selectSecCate(index, idx)"></div>
                                    <div class="cate-title">{{ cate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="commited" :class="{ 'phone-commited': global.isPhone }" v-if="step == 2">
                <img src="../assets/success.png">
                <div class="text" :class="{ 'phone-text': global.isPhone }">提交成功!<br />感谢您的支持!</div>
            </div>
            <div class="button" :class="{ 'phone-button': global.isPhone }"
                :style="{ 'bottom': global.isPhone && step == 1 ? '0.48rem' : '' }" @click="buttonClick">{{
                        buttonTexts[step]
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { Intentions } from '../../public/data/IntentionCategory';
export default {
    name: 'IntentionForm',
    data() {
        return {
            step: 0,
            buttonTexts: [`下一步`, `提交`, `关闭`],
            focusIpt: 0,
            form: {
                name: null,
                email: null,
                phone: null,
                intentions: [[], [], [], [], [], [], [], [], []]
            },
            data: []
        }
    },
    methods: {
        buttonClick() {
            switch (this.step) {
                case 0:
                    this.userInfoCheck();
                    break;
                case 1:
                    this.commitIntentionForm();
                    break;
                case 2:
                    this.closeForm();
                    break;
            }
        },
        async commitIntentionForm() {
            // 保存至localStorage
            localStorage.setItem('intentionForm', JSON.stringify(this.form));
            // 向数据库提交
            let res = await this.global.commitIntentionForm(JSON.parse(JSON.stringify(this.form)));

            if (res) {
                this.step++;
            } else {
                alert(`提交失败`);
            }
            // this.step++;
        },
        userInfoCheck() {
            let name = document.querySelector('.name-ipt').value;
            let email = document.querySelector('.email-ipt').value;
            let phone = document.querySelector('.phone-ipt').value;
            // 非空校验
            if (!name || !phone) {
                alert(`请完整填写信息`);
                return;
            }
            // 邮箱校验
            // const emailReg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
            // if (!emailReg.test(email)) {
            //     alert(`请正确填写邮箱地址`);
            //     return;
            // }
            // 11位手机号校验
            const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (!phoneReg.test(phone)) {
                alert(`请正确填写11位手机号`);
                return;
            }
            // 缓存用户填写数据
            this.form.name = name;
            this.form.email = email;
            this.form.phone = phone;
            console.log(this.form);
            // 校验通过，进入下一步
            this.step++;
        },
        onInputFocus(index) {
            this.focusIpt = index;
        },
        onInputKeyUp(event) {
            event.target.value = event.target.value.replace(/\s+/g, '');
        },
        initData() {
            let data = JSON.parse(JSON.stringify(Intentions));

            for (let i = 0; i < data.length; i++) {
                Object.defineProperty(data[i], 'optUnfold', { value: data[i].title ? false : true, enumerable: true, writable: true, configurable: true });
            }

            this.data = data;
        },
        getStatIconBackGroundImage(item, index) {
            if (item.optUnfold && this.form.intentions[index].length > 0) {
                return 'url(' + require('../assets/-_click.png') + ')';
            } else if (item.optUnfold && this.form.intentions[index].length == 0) {
                return 'url(' + require('../assets/-_normal.png') + ')';
            } else if (!item.optUnfold && this.form.intentions[index].length == 0) {
                return 'url(' + require('../assets/+_normal.png') + ')';
            } else {
                return 'url(' + require('../assets/+_click.png') + ')';
            }
        },
        showSecCate(item) {
            item.optUnfold = !item.optUnfold;
        },
        selectSecCate(index, idx) {
            if (this.form.intentions[index].includes(idx)) {
                const i = this.form.intentions[index].indexOf(idx);
                this.form.intentions[index].splice(i, 1);
            } else {
                this.form.intentions[index].push(idx);
            }
        },
        readForm() {
            let form = localStorage.getItem('intentionForm');

            if (form !== null) {
                this.form = JSON.parse(form);
                this.step = 1;
            }
        },
        closeForm() {
            this.$emit('closeForm');
        }
    },
    created() {
        this.readForm();
        this.initData();
        console.log(this.step)
    },
}

</script>

<style scoped>
#intention {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
}

.content {
    width: 8rem;
    height: 6.8rem;
    position: absolute;
    top: calc(50% - 3.4rem);
    left: calc(50% - 4rem);
    background-color: #fff;
    border-radius: 0.16rem;
    position: relative;
}

.close-btn {
    width: 1.2rem;
    height: 1.4rem;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('../assets/close.png');
    background-repeat: no-repeat;
    background-size: 0.27rem 0.27rem;
    background-position: 50% 50%;
    cursor: pointer;
}

.close-btn:hover {
    background-image: url('../assets/close-hover.png');
}

.phone-close-btn {
    width: 0.64rem;
    height: 0.64rem;
    background-size: 0.16rem 0.16rem;
}

.phone-content {
    width: 3.35rem;
    height: 4.6rem;
    top: calc(50% - 2.3rem);
    left: calc(50% - 1.675rem);
    position: relative;
}

.title {
    font-size: 0.36rem;
    font-weight: 700;
    color: #333333;
    position: absolute;
    top: 0.73rem;
    left: 1.27rem;
}

.phone-title {
    font-size: 0.18rem;
    top: 0.48rem;
    left: 0.32rem;
}

.name,
.email,
.phone {
    width: 5.46rem;
    height: 0.64rem;
    background-color: #F2F3F5;
    border-radius: 0.06rem;
    box-sizing: border-box;
    color: #333333;
}

.phone-name,
.phone-email,
.phone-phone {
    width: 2.35rem;
    height: 0.44rem;
    border-radius: 0.04rem;
}

.name {
    position: absolute;
    top: 1.88rem;
    left: 1.27rem;
}

.phone-name {
    top: 1.09rem;
    left: 0.5rem;
}

.email {
    position: absolute;
    top: 2.88rem;
    left: 1.27rem;
}

.phone-email {
    top: 1.77rem;
    left: 0.5rem;
}

.phone {
    position: absolute;
    top: 3.88rem;
    left: 1.27rem;
}

.phone-phone {
    top: 2.45rem;
    left: 0.5rem;
}

.options {
    width: 5.46rem;
    height: 3.06rem;
    position: absolute;
    top: 1.62rem;
    left: 1.27rem;
    padding: 0.2rem 0 0 0.16rem;
    box-sizing: border-box;
    overflow-y: scroll;
}

.options::-webkit-scrollbar {
    display: none;
}

.phone-options {
    width: 2.71rem;
    height: 2.29rem;
    top: 1.05rem;
    left: 0.32rem;
    padding: 0.08rem 0 0 0.18rem;
    box-sizing: border-box;
}

.main-cate {
    width: 100%;
    font-size: 0.18rem;
    color: #333333;
    font-weight: 400;
    margin-bottom: 0.41rem;
}

.phone-main-cate {
    font-size: 0.14rem;
    line-height: 0.19rem;
    margin-bottom: 0.19rem;
}

.cate-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.2rem;
}

.stat-icon {
    width: 0.2rem;
    height: 0.2rem;
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 0.04rem;
    margin-right: 0.1rem;
    background-position: 50% 50%;
    cursor: pointer;
}

.phone-stat-icon {
    width: 0.24rem;
    height: 0.24rem;
    flex-shrink: 0;
    margin-right: 0.12rem;
}

.name-ipt,
.email-ipt,
.phone-ipt {
    position: absolute;
    left: 0.36rem;
    top: calc(50% - 0.12rem);
    outline: none;
    border: none;
    width: calc(100% - 0.36rem);
    height: 0.24rem;
    background-color: #F2F3F5;
    font-size: 0.18rem;
}

.phone-name-ipt,
.phone-email-ipt,
.phone-phone-ipt {
    top: 0.12rem;
    left: 0.2rem;
    width: calc(100% - 0.2rem);
    height: 0.19rem;
    font-size: 0.14rem;
}

input::placeholder {
    color: #AAAAAA;
}

.sec-cates {
    width: 100%;
    box-sizing: border-box;
}

.sec-cate {
    margin-bottom: 0.19rem;
}

.phone-sec-cate {
    margin-bottom: 0.13rem;
}

.commited {
    width: 100%;
}

.commited>img {
    width: 2.79rem;
    height: 2.48rem;
    position: absolute;
    top: 0.91rem;
    left: 2.61rem;
}

.phone-commited>img {
    width: 1.4rem;
    height: 1.25rem;
    top: 0.9rem;
    left: 0.975rem;
}

.text {
    width: 100%;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.48rem;
    position: absolute;
    top: 3.61rem;
    left: 0;
}

.phone-text {
    font-size: 0.18rem;
    line-height: 0.24rem;
    top: 2.29rem;
    left: 0;
}

.button {
    width: 5.46rem;
    height: 0.64rem;
    position: absolute;
    bottom: 0.99rem;
    left: calc(50% - 2.73rem);
    background: linear-gradient(to right, #00A8FF, #005DFF);
    border-radius: 0.06rem;
    font-size: 0.24rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.64rem;
    color: #FFFFFF;
}

.phone-button {
    font-size: 0.16rem;
    width: 2.35rem;
    height: 0.44rem;
    line-height: 0.44rem;
    bottom: 0.64rem;
    left: 0.5rem;
    border-radius: 0.04rem;
}
</style>