<script>
const titleIndex = 0;
const isPhone = false;
let setFrom = false;

// const AssetsURL = "https://www.spacemax.com.cn/preview/data";
const AssetsURL = "https://www.spacemax.com.cn/data";

function blobLoadVideoFn(url) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("get", url);
    req.responseType = "blob";
    req.onload = function () {
      if (this.status === 200) {
        const videoBlob = this.response;
        let blobSrc = URL.createObjectURL(videoBlob);
        // setTimeout(() => {
        //   URL.revokeObjectURL(blobSrc);
        // }, 1000);
        resolve(blobSrc);
      }
    };
    req.onerror = (err) => {
      console.log(err);
      reject(err);
    };
    req.send();
  });
}

// mediaSource load video
// 视频格式要求为 fmp4
function getVideo(url, videoELe) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("get", url);
    req.responseType = "arraybuffer";
    req.onload = function () {
      if (this.status === 200) {
        videoToMediaSourceFn(this.response, videoELe, (res) => {
          resolve(res);
        });
      }
    };
    req.onerror = (err) => {
      reject(err);
    };
    req.send();
  });
}


function videoToMediaSourceFn(videoBuffer, videoELe, callback) {
  let mediaSource = new MediaSource();
  videoELe.src = URL.createObjectURL(mediaSource);

  mediaSource.addEventListener("sourceopen", sourceOpen);

  function sourceOpen() {
    let mediaSource = this;
    let sourceBuffer = mediaSource.addSourceBuffer(
      'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
    );

    sourceBuffer.appendBuffer(videoBuffer);

    sourceBuffer.addEventListener("updateend", function () {
      // 读取完成
      // videoEle.play()
      callback(videoELe.src);

      URL.revokeObjectURL(videoELe.src);
      mediaSource.endOfStream();
    });
  }
}

function userAction(page) {
  let message = window.navigator.userAgent;
  let type = page;
  asyncPostFn("access", { type: type, msg: message });
}

function setURLFrom() {
  if (setFrom) return
  const from = document.referrer ? document.referrer : '';
  const param = { from };

  asyncPostFn('urlFrom', param);

  setFrom = true;
}

function contactUsFn(data) {
  return new Promise((resolve, reject) => {
    let _data = {
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
      msg: data.message,
    };
    asyncPostFn("leaveMessage", _data)
      .then(() => {
        // alert("提交成功！");
        resolve(true);
      }).catch(() => {
        reject(false);
        // alert("提交失败");
      });
  });
}

function commitIntentionForm(data) {
  return new Promise((resolve, reject) => {
    let _data = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      intentions: JSON.stringify(data.intentions)
    };

    asyncPostFn('commitIntention', _data)
      .then(() => {
        resolve(true);
      }).catch(() => {
        reject(false);
      });
  });
}

function asyncPostFn(url, data) {
  return new Promise((res, rej) => {
    // if (data) console.log(data);

    var xhr = new XMLHttpRequest();
    xhr.open(
      "post",
      // "http://www.smaxserver.com/apps/SpaceMaxWebSiteServer/interface/" +
      "https://www.spacemax.com.cn/SpaceMaxWebSiteServer/interface/" +
      url +
      ".php"
    );
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    xhr.send(JSON.stringify(data));

    xhr.onreadystatechange = function () {
      // console.log(xhr);
      if (xhr.readyState == 4 && xhr.status == 200) {
        // console.log(xhr.responseText);
        let data = JSON.parse(xhr.responseText);
        // console.log(data);
        if (data.status === "success") {
          res(data);
        } else {
          console.log("获取数据失败");
          console.log(data);
          rej(data);
        }
      }
    };
  });
}

export default {
  titleIndex,
  isPhone,
  AssetsURL,
  blobLoadVideoFn,
  getVideo,
  userAction,
  contactUsFn,
  commitIntentionForm,
  setURLFrom
};
</script>
