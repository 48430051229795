import {
  ServerTypographical
} from "./define";
const SchoolData = {
  // 轮播图待替换
  swiperImgData: [{
      url: "/picture/school/swiper/s1.jpg",
    },
    {
      url: "/picture/school/2/1/资源 1.png",
    },
  ],
  // swiperImgData: [
  //   {
  //     url: "/picture/school/1/1/资源 21.png",
  //   },
  //   {
  //     url: "/picture/school/2/1/资源 1.png",
  //   },
  //   {
  //     url: "/picture/school/3/1/资源 15.png",
  //   },
  //   {
  //     url: "/picture/school/4/1/资源 21.png",
  //   },
  //   {
  //     url: "/picture/medical/5-1.png",
  //   },
  // ],
  siwperContentList: [{
      title: "执业/助理医师<br>规范化培训虚拟仿真教学平台",
      info: "",
      url: "true",
    },
    {
      title: "基础手术虚拟仿真和VR<br>教学平台",
      info: "",
      url: "true",
    },
    {
      title: "3D医学打印服务",
      info: "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术...",
      url: "true",
    },
    {
      // title: "护理/养老康复教学平台",
      title: "养老护理康复与健康管理平台",
      info: "",
      url: "true",
    },
    {
      title: "远程教学直播系统",
      info: "",
      url: "true",
    },
  ],
  items: [{
      title: "执业/助理医师规范化培训虚拟仿真教学平台",
      class: [
        "缝合术",
        "三腔二囊管",
        "导尿术",
        "胸腔穿刺术",
        "腰椎穿刺术",
        "心脉复苏",
        "气管插管",
      ],
      info: [
        "缝合技术作为临床基础操作之一，是广大医学生必须要掌握的专业知识之一。传统缝合技术训练方式一般为视频学习，然后通过仿真缝合模型进行实操训练。我司根据临床实际教学需求，将9大缝合技术进行标准化的三维动画还原，同时结合AR技术进行虚实融合，学生可以在进行缝合训练的同时观看标准缝合技法，为临床学习提供帮助。",
        "三腔二囊管常用于曲张食管-胃底静脉破裂出血的局部压迫止血。程序通过三维技术对手术场景、手术器械等进行建模，使操作者能够沉浸式体验三腔二囊管的操作。系统严格按照临床真实流程进行模拟",
        "导尿术是临床上常见的一种操作技术，在严格无菌操作下，使用导尿管经尿道插入膀胱引流出尿液，广泛应用于临床诊断和治疗。系统包含男性导尿术和女性导尿术，基于导尿术真实临床路径，借助VR技术，在虚拟空间内直观地展示导尿术完整流程步骤",
        "虚拟仿真胸腔穿刺术模拟，使用vr技术让用户在高度仿真的模拟手术场景中，模拟胸腔穿刺术。引导用户使用消毒过的针刺经皮肤、肋间组织、壁层胸膜穿刺进入胸膜腔，吸出积出的液体。让用户对胸腔穿刺术的流程以及操作方式进行学习，模拟中有操作提示与答题，能够让用户进行全面的学习",
        "腰椎穿刺术是临床上常用的有创检查/治疗手段，广泛应用于检查脑脊液性质，测定颅内压，了解蛛网膜下隙是否阻塞或是否需要鞘内注射药物。传统腰椎穿刺术学习停留在文字描述或医学模拟人模拟穿刺操作阶段。腰椎穿刺术教学培训系统，根据临床需求，使用三维引擎对腰椎穿刺术临床路径在三维空间进行还原，供学者进行腰椎穿刺术的模拟练习，对医学生的临床教学任务及临床医师的规范化培训工作有很大帮助",
        "心肺复苏（CPR）是指采用徒手或辅助设备来维持呼吸、心脏骤停（CA）患者人工循环的最基本的抢救方法，包括开放气道、人工通气、胸外按压、电除颤及药物治疗等。目的是尽快恢复自主循环，最终达到脑神经功能良好的存活",
        "气管插管是指通过口腔或鼻腔将一特制的气管导管，经声门置入气管或支气管内的技术。这一技术能为呼吸道通畅、通气供氧、呼吸道吸引和防止误吸等提供最佳条件，是抢救呼吸功能障碍及高级心肺脑复苏的重要措施。操作者全方位沉浸式体验气管插管流程，同时结合第三人称视角的气管插管动画，对气管插管的操作流程进行全方位的教学指导。用户每一步操作，会有系统进行引导与提示，会有相应的操作反馈",
      ],

      video: [
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E7%BC%9D%E5%90%88%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E4%B8%89%E8%85%94%E4%BA%8C%E5%9B%8A%E7%AE%A1.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%AF%BC%E5%B0%BF%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E8%83%B8%E8%85%94%E7%A9%BF%E5%88%BA%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E8%85%B0%E6%A4%8E%E7%A9%BF%E5%88%BA%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%BF%83%E8%82%BA%E5%A4%8D%E8%8B%8F.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E6%B0%94%E7%AE%A1%E6%8F%92%E7%AE%A1.mp4",
      ],
      data: [{
          name: "缝合术",
          icon: [{
              title: "标准化开发",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/缝合术/SVG/标准化开发.svg",
            },
            {
              title: "高精度还原",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/缝合术/SVG/高精度还原.svg",
            },
            {
              title: "虚实融合",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/缝合术/SVG/虚实融合.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "缝合技术作为临床基础操作之一，是广大医学生必须要掌握的专业知识之一。传统缝合技术训练方式一般为视频学习，然后通过仿真缝合模型进行实操训练。我司根据临床实际教学需求，将9大缝合技术进行标准化的三维动画还原，同时结合AR技术进行虚实融合，学生可以在进行缝合训练的同时观看标准缝合技法，为临床学习提供帮助。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "9大缝合技术",
                  txt: "缝合课程内容涵盖9大基础缝合技术，包括：单纯间断缝合法；单纯连续缝合法；“8”字缝合法；连续锁边缝合法；间断垂直褥式外翻缝合；间断水平褥式外翻缝合；连续水平褥式外翻缝合；间断垂直褥式内翻缝合；间断水平内翻缝合法",
                },
                {
                  titleB: "虚拟仿真缝合模块",
                  txt: "使用三维引擎进行开发，运用全三维模型进行各种缝合技术的模拟训练。学生可以通过系统提示在移动端进行操作交互，通过三维模型动画进行演示缝合步骤",
                },
                {
                  titleB: "AR缝合模块",
                  txt: "在实物操作台上放置AR识别标识。通过移动端AR识别，生成三维伤口模型，然后用户可以在三维模型进行交互，通过三维模型动画进行缝合演示",
                  img: [
                    "/picture/school/1/1/资源 18.png",
                    "/picture/school/1/1/资源 19.png",
                    "/picture/school/1/1/资源 20.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "高精度",
                  txt: "制作高精度高还原的缝合三维动画，更清晰便捷的学习缝合技术的各种细节操作",
                },
                {
                  titleB: "标准化",
                  txt: "在专业老师的指导下进行开发，符合临床操作习惯及教学需求",
                },
                {
                  titleB: "虚实融合",
                  txt: "将AR技术、虚拟仿真技术及仿真缝合模型相结合，打造缝合操作台，利于临床教学实际使用",
                  img: [
                    "/picture/school/1/1/资源 21.png",
                    "/picture/school/1/1/资源 22.png",
                    "/picture/school/1/1/资源 23.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "三腔二囊管",
          icon: [{
              title: "手术模拟",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/三腔二囊管/SVG/手术模拟.svg",
            },
            {
              title: "真实流程模拟",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/三腔二囊管/SVG/真实流程模拟.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "三腔二囊管常用于曲张食管-胃底静脉破裂出血的局部压迫止血。程序通过三维技术对手术场景、手术器械等进行建模，使操作者能够沉浸式体验三腔二囊管的操作。系统严格按照临床真实流程进行模拟",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "临床标准流程",
                  txt: "通过虚拟现实在线学习平台对VR教学课程、手术教学视频、病例资源等进行统一管理，通过教学管理平台进行人员、资源、题库、训练记录等方面的管理<br>使用全景8K+4K超高清拍摄模式，采集术中的全手术室场景、主刀特写画面、DSA术中影像、术中B超影像等各种数据信号，经后期处理后上云分类储存，使用者可通过在线学习平台登陆进行在线手术学习<br>VR教学课件模拟临床病理的情况，还原临床介入手术的操作流程，结合临床病理影像，操作者虚拟的操作与真实DSA影像对应匹配，最大限度的还原临床实际操作场景。课件经评分考核系统及临床考试题库与教学管理平台相连通，通过教学管理平台管理记录和统计操作者的操作结果，根据需要随时更新临床考试题库。尽可能满足临床教学需求<br>课件内容包括：桡动脉穿刺、器械准备、冠脉造影、经皮冠状动脉球囊血管成形术、血管内超声、冠状动脉支架植入术等",
                },
                {
                  titleB: "沉浸式操作体验",
                  txt: "利用3D技术搭建虚拟情景，模拟临床常用心血管介入技术及对应解剖结构。系统采用轻量化的设计，操作者可在移动端及PC端登陆系统，通过点击、拖拽等轻交互的方式利用碎片时间随时随地进行学习体验。课件内容包括：桡动脉穿刺、股动脉穿刺、股静脉穿刺、心包穿刺、冠脉造影、经皮冠脉内血管成形术等",
                },
                {
                  titleB: "多步骤提示",
                  txt: "建立教学管理体系，通过统一的平台对账号数据、教学资源、教学课件等内容进行体系化管理",
                  img: [
                    "/picture/school/1/2/资源 1.png",
                    "/picture/school/1/2/资源 2.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  txt: "软件可用于基础手术教学，培养医生应对临床突发事件的临床思维及反应能力。系统采用高精度人体模型进行模拟操作，具有很强的真实感。系统还原了三腔二囊管的使用方法，能起到很好的教学效果。",
                },
                {
                  titleB: "系统严格按照临床真实流程进行模拟，保证医学正确性和权威性",
                },
                {
                  titleB: "软件模拟真实手术室场景，使用高精度仿真男性人体模型进行手术模拟",
                  img: [
                    "/picture/school/1/2/资源 3.png",
                    "/picture/school/1/2/资源 4.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "导尿术",
          icon: [{
              title: "标准化设计",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/导尿术/SVG/标准化设计.svg",
            },
            {
              title: "多模式操作",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/导尿术/SVG/多模式操作.svg",
            },
            {
              title: "多形式教学",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/导尿术/SVG/多形式教学.svg",
            },
            {
              title: "直观显示",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/导尿术/SVG/直观显示.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "导尿术是临床上常见的一种操作技术，在严格无菌操作下，使用导尿管经尿道插入膀胱引流出尿液，广泛应用于临床诊断和治疗。系统包含男性导尿术和女性导尿术，基于导尿术真实临床路径，借助VR技术，在虚拟空间内直观地展示导尿术完整流程步骤",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "标准临床导尿路径",
                  txt: "系统严格按照临床真实导尿路径设计，采用三维引擎进行制作，真实还原导尿术的临床操作。操作者根据语音提示进行器械准备、初步消毒、再次消毒、铺巾、插导尿管、连接尿袋等操作",
                },
                {
                  titleB: "VR沉浸式体验",
                  txt: "系统Unity 3D引擎进行制作，使用者可以佩戴VR设备在虚拟空间内进行导尿术的手法模拟训练，模拟训练过程中，操作者手握器械，根据系统提示在高精度模拟人身上进行导尿操作",
                },
                {
                  titleB: "器械模型库",
                  txt: "系统配备有丰富的临床器械模型，用户在导尿操作过程中使用步骤对应的器械进行操作",
                  img: [
                    "/picture/school/1/3/资源 2.png",
                    "/picture/school/1/3/资源 3.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  txt: "系统为医学教学产品，支持医学生临床教学和考核，医师规范化培训内容；系统采用高新技术，形式新颖接受度高，能快速达成技能的掌握；系统可以对操作者行为进行记录，多方面评估操作者技能掌握程度",
                },
                {
                  titleB: "标准化",
                  txt: "系统严格按照临床真实导尿路径设计，真实还原导尿术的临床操作",
                },
                {
                  titleB: "多模式",
                  txt: "系统有教学及考核两种模式，教学模式下操作者在语音提示下进行每一步操作；考核模式下会记录操作者的行为数据，操作结束后进行多方位考核评价",
                },
                {
                  titleB: "多场景",
                  txt: "系统配备有导尿术（男）和导尿术（女）",
                },
                {
                  titleB: "多器械选择",
                  txt: "操作者在进行导尿术操作过程中，使用不同的器械进行对应步骤的操作",
                },
                {
                  titleB: "多形式",
                  txt: "系统在操作过程中配备有理论考核题目，对操作者进行理论知识的教学",
                },
                {
                  titleB: "直观显示",
                  txt: "在导尿管插入过程中，操作者能够直观看到导尿管插入深度",
                },
              ],
            },
          ],
        },
        {
          name: "胸腔穿刺术",
          icon: [{
              title: "仿真人体模型",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/胸膛穿刺术/SVG/仿真人体模型.svg",
            },
            {
              title: "高精度还原",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/胸膛穿刺术/SVG/高精度还原.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "虚拟仿真胸腔穿刺术模拟，使用vr技术让用户在高度仿真的模拟手术场景中，模拟胸腔穿刺术。引导用户使用消毒过的针刺经皮肤、肋间组织、壁层胸膜穿刺进入胸膜腔，吸出积出的液体。让用户对胸腔穿刺术的流程以及操作方式进行学习，模拟中有操作提示与答题，能够让用户进行全面的学习",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "医学标准设计",
                  txt: "系统按照医学标准设计，操作者可以进行如下操作：叩诊、消毒、铺巾、局部浸润麻醉、对比进针长度、穿刺、抽吸、摘下注射器、留置标本、拔针",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "用户使用VR设备在虚拟仿真手术室中进行胸腔穿刺模拟，让用户身临其境，给用户提供沉浸式体验",
                },
                {
                  titleB: "多终端支持",
                  txt: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "全程步骤提示面板",
                  txt: "用户在操作时，通过步骤面板了解具体操作步骤提示，通过点选步骤面板，进行步骤切换，术中出现专业知识考题",
                },
                {
                  titleB: "全程语音提示",
                  txt: "在操作过程中，每个步骤会有语言提示，穿刺操作时有半透明手势提示",
                  img: [
                    "/picture/school/1/4/资源 7.png",
                    "/picture/school/1/4/资源 6.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  txt: "该软件用于基础手术教学，用于给医生以及医学生进行基础训练，使其熟悉胸腔穿刺手术步骤。软件采用仿真人体模型，还原胸腔穿刺的完整步骤，保证临床操作真实感与教学效率。",
                },
                {
                  titleB: "系统真实还原临床操作场景，使用男性人体模型进行操作模拟",
                  img: [
                    "/picture/school/1/4/资源 4.png",
                    "/picture/school/1/4/资源 5.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "腰椎穿刺术",
          icon: [{
              title: "多终端适配",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/腰椎穿刺术/SVG/多终端适配.svg",
            },
            {
              title: "教学场景适配",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/腰椎穿刺术/SVG/教学场景适配.svg",
            },
            {
              title: "内容形式多样",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/腰椎穿刺术/SVG/内容形式多样.svg",
            },
            {
              title: "全程系统提示",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/腰椎穿刺术/SVG/全程系统提示.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "腰椎穿刺术是临床上常用的有创检查/治疗手段，广泛应用于检查脑脊液性质，测定颅内压，了解蛛网膜下隙是否阻塞或是否需要鞘内注射药物。传统腰椎穿刺术学习停留在文字描述或医学模拟人模拟穿刺操作阶段。腰椎穿刺术教学培训系统，根据临床需求，使用三维引擎对腰椎穿刺术临床路径在三维空间进行还原，供学者进行腰椎穿刺术的模拟练习，对医学生的临床教学任务及临床医师的规范化培训工作有很大帮助",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "腰椎穿刺术标准临床路径",
                  txt: "系统按照临床操作标准进行设计腰椎穿刺术内容，操作者可按照设计步骤模拟腰椎穿刺操作。包括：术前检查、术前麻醉评估及准备、麻醉、穿刺",
                },
                {
                  titleB: "腰椎解剖",
                  txt: "系统配备有腰椎局部解剖知识内容，供操作者术前进行学习",
                },
                {
                  titleB: "腰椎穿刺术动画演示",
                  txt: "系统配备有腰椎穿刺术动画演示内容，使用三维动画形式展示腰椎穿刺内容供操作者穿刺前进行学习",
                  img: [
                    "/picture/school/1/5/资源 8.png",
                    "/picture/school/1/5/资源 9.png",
                    "/picture/school/1/5/资源 10.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  txt: "腰椎穿刺术虚拟仿真系统为医学教学产品，用于医学生临床教学和考核，用于医师规范化培训内容。系统会对操作者行为进行记录，对操作者技能掌握程度进行多方面考核评价；系统完整还原腰椎穿刺术，高效完成教学任务：系统适配多终端，可适应不同场地要求",
                },
                {
                  titleB: "标准化流程",
                  txt: "系统按照临床操作标准进行设计腰椎穿刺术内容，符合医学操作要求和临床医生习惯",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "系统采用三维引擎制作，所有操作均在高精度人物模型之上进行，支持高品质真实感",
                },
                {
                  titleB: "多教学场景适配",
                  txt: "系统具备教学和考核两种功能，适配教学场景",
                },
                {
                  titleB: "内容形式多样",
                  txt: "系统包含有多种内容形式，包括医学理论知识、临床实践操作、医学人文关怀以及医师个人素养等方面进行全方面的教学与考核",
                },
                {
                  titleB: "全程系统提示",
                  txt: "系统中每一步关键操作均有语音、文字等提示内容，指导操作者进行模拟训练",
                },
                {
                  titleB: "多终端适配",
                  txt: "针对临床教学的多种场景需求，系统配备有多终端适配内容。针对PC及Pad端，系统采用虚拟仿真的形式进行腰椎穿刺术的三维还原，用户可以在PC及Pad端模拟进行腰椎穿刺术模拟操作",
                },
              ],
            },
          ],
        },
        {
          name: "心脉复苏",
          icon: [{
              title: "多人协同",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/心肺复苏/SVG/多人协同.svg",
            },
            {
              title: "空间微定位技术",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/心肺复苏/SVG/空间微定位技术.svg",
            },
            {
              title: "权威性",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/心肺复苏/SVG/权威性.svg",
            },
            {
              title: "虚拟现实融合",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/心肺复苏/SVG/虚拟现实融合.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "心肺复苏（CPR）是指采用徒手或辅助设备来维持呼吸、心脏骤停（CA）患者人工循环的最基本的抢救方法，包括开放气道、人工通气、胸外按压、电除颤及药物治疗等。目的是尽快恢复自主循环，最终达到脑神经功能良好的存活。<br>心肺复苏虚实融合教学培训系统可以进行OCHA后的单人徒手CPR模拟训练，帮助完成医学院学生及实习医师的CPR训练及考核工作。系统遵循CPR路径进行模拟训练，配备有医学模拟人实体模具，帮助操作者模拟单人徒手CPR流程及手法训练，能够提供真实的操作体验",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "标准化流程",
                  txt: "系统按照真实临床路径设计，真实还原CPR路径，操作者根据系统提示进行开放气道、人工通气、胸外按压、电除颤等操作",
                },
                {
                  titleB: "多人协同",
                  txt: "系统针对单人及多人CPR场景，配备了单人CPR和多人协同CPR模式，支持单人或多人分工合作进行CPR操作",
                },
                {
                  titleB: "系统在关键步骤都配备有语音及文字提示，引导操作者进行操作",
                },
                {
                  titleB: "系统在关键步骤配备有专业知识内容，对操作者理论知识进行教学与考核",
                  img: [
                    "/picture/school/1/6/资源 9.png",
                    "/picture/school/1/6/资源 10.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "正确性与权威性",
                  txt: "系统按照临床路径设计并参考国际标准，具有医学正确性和权威性",
                },
                {
                  titleB: "多人协同",
                  txt: "系统配备有多人协同模式，支持多人协同合作进行CPR",
                },
                {
                  titleB: "虚拟现实融合",
                  txt: "系统采用三维引擎制作，操作者可在虚拟空间内对高精度人体模型进行CPR模拟学习，同时系统还配套有医学模拟人，借助虚拟现实融合技术，将VR虚拟空间内高精度人体模型与真实世界内医学模拟人进行叠加，使得操作者沉浸式学习CPR的同时能够有真实操作手感",
                },
                {
                  titleB: "空间微定位技术",
                  txt: "系统拥有毫米级定位技术，实时记录到操作者胸外按压深度及频次等CPR效果关键指标，对于教学及考核都具备很重要意义",
                },
              ],
            },
          ],
        },
        {
          name: "气管插管",
          icon: [{
              title: "操作面板显示",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/气管插管/SVG/操作面板显示.svg",
            },
            {
              title: "沉浸式操作",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/气管插管/SVG/沉浸式操作.svg",
            },
            {
              title: "仿真人体模型",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/气管插管/SVG/仿真人体模型.svg",
            },
            {
              title: "权威性",
              img: "/icon/执业助理医师 规范化培训虚拟仿真教学平台/气管插管/SVG/权威性.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "气管插管是指通过口腔或鼻腔将一特制的气管导管，经声门置入气管或支气管内的技术。这一技术能为呼吸道通畅、通气供氧、呼吸道吸引和防止误吸等提供最佳条件，是抢救呼吸功能障碍及高级心肺脑复苏的重要措施。操作者全方位沉浸式体验气管插管流程，同时结合第三人称视角的气管插管动画，对气管插管的操作流程进行全方位的教学指导。用户每一步操作，会有系统进行引导与提示，会有相应的操作反馈",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "标准临床路径",
                  txt: "系统按照临床标准制作，操作者可模拟气管插管操作的全过程，内容包括：体位摆放、器械准备、麻醉、显露声门、插入气管导管、固定气管导管等内容",
                },
                {
                  titleB: "直观插管深度",
                  txt: "用户在气管插管过程中可以直观看到导管插入深度",
                },
                {
                  titleB: "临床思维训练",
                  txt: "用户需要在气管插管过程中进行气道紧急情况处理",
                  img: [
                    "/picture/school/1/7/资源 10.png",
                    "/picture/school/1/7/资源 11.png",
                  ],
                },
                {
                  img: [
                    "/picture/school/1/7/资源 12.png",
                    "/picture/school/1/7/资源 13.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "系统按照临床实际流程设计，具有医学正确性和权威性",
                },
                {
                  titleB: "系统使用三维引擎进行制作，还原真实手术室环境，使用高精度人体模型进行操作模拟",
                },
                {
                  titleB: "用户使用VR设备在虚拟仿真手术室中进行沉浸式操作",
                },
                {
                  titleB: "在操作时有操作面板显示当前步骤，每个步骤，都有多种器械供用户选择。操控面板还会记录用户的操作时间",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "基础手术虚拟仿真和VR教学平台",
      class: [
        "包皮环切术",
        "剖宫产",
        "甲状腺次切全术",
        "阑尾切除术",
        "体表肿物切除",
        "围术期压疮管理",
      ],
      info: [
        "采用最新虚拟现实（VR）技术，模拟真实的手术室环境，提供全方位沉浸式的包皮环切手术流程学习体验。程序采用高精度的男性模型，从阴茎表皮到血管内部，完全仿真人体结构，能够让用户清晰地观察到人体解剖结构。在操作时，有实时交互提供反馈，软件完全模拟包皮环切术的操作步骤，所学即所得。能够让用户快速学习到相关的知识与技能。软件包含教学模式与考核模式，教学模式下，系统会对用户进行提示，包含语音、文字、操作提示。",
        "VR剖腹产术手术模拟软件，是采用最新VR虚拟现实技术，佩戴VR头盔，在虚拟场景内进行手术仿真模拟。通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验剖腹产手术流程。软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练。术中还有专业性问答与突发状况，培训用户的专业性知识与临场应对能力。操作时每一步都会有实时反馈，让用户学习效率大大提升",
        "VR甲状腺次全切术模拟软件，是采用最新的VR虚拟现实技术，在虚拟场景内进行甲状腺次全切术手术仿真模拟。软件使用高精度人体模型，从颈部表皮到内部甲状腺，完全仿真人体结构。通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验甲状腺次全切术手术流程。软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练。操作时每一步都会有实时反馈，让用户学习效率大大提升。术中还有专业性问答，培训用户的专业性知识与临场应对能力",
        "阑尾切除术手术模拟软件，采用最新虚拟现实(VR)技术，通过模拟真实的手术室环境，有护士协助，全方位沉浸式体验阑尾切除术的流程。用户在操作过程中，会有实时的交互提供反馈，术中还有理论知识答题，提升用户的理论知识水平。同时还有难易度选择，不同水平用户，选择不同难度进行训练，逐级增加难度。包含教学模式与考核模式，让用户在培训完成后，通过考核模型对自身水平进行测试",
        "体表肿物切除手术模拟软件，采用最新虚拟现实(VR)技术，通过模拟真实的手术室环境，有护士协助，全方位沉浸式体验体表肿物切除的流程。并且用户在操作过程中，会有实时的交互提供反馈，术中还有理论知识答题，提升用户的理论知识水平。同时还有难易度选择，不同水平用户，可以选择不同的难度进行训练，并逐级增加难度。包含教学模式与考核模式，让用户在培训完成后，通过考核模型对自身水平进行测试",
        "手术围术期压疮管理模拟软件，使用VR进行手术围术期仿真模拟。软件通过模拟真实的手术室环境，全方位沉浸式体验患者在术时采用不同体位所对应的不同压疮管理，以便术后进行适当护理。软件分为体位摆放与体位观看，用户可以根据需要选择内容进行学习。软件使用高精度人体模型与手术台器械，完全模拟患者在手术时的体位。同时在操作过程中，实时提供反馈，让用户快速学习，真实模拟",
      ],
      video: [
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%8C%85%E7%9A%AE%E7%8E%AF%E5%88%87%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%88%A8%E5%AE%AB%E4%BA%A7.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E7%94%B2%E7%8A%B6%E8%85%BA%E6%AC%A1%E5%85%A8%E5%88%87%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E9%98%91%E5%B0%BE%E5%88%87%E9%99%A4%E6%9C%AF.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E4%BD%93%E8%A1%A8%E8%82%BF%E7%89%A9%E5%88%87%E9%99%A4.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%9B%B4%E6%9C%AF%E6%9C%9F%E5%8E%8B%E7%96%AE%E7%AE%A1%E7%90%86.mp4",
      ],
      data: [{
          name: "包皮环切术",
          icon: [{
              title: "沉浸式交互",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/包皮环切术/SVG/沉浸式交互.svg",
            },
            {
              title: "仿真模型",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/包皮环切术/SVG/仿真模型.svg",
            },
            {
              title: "全流程学习",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/包皮环切术/SVG/全流程学习.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "VR包皮环切术，采用最新虚拟现实（VR）技术，模拟真实的手术室环境，提供全方位沉浸式的包皮环切术手术流程学习体验。程序采用高精度的男性模型，从阴茎表皮到内部血管，完全仿真人体结构，能够让用户清晰地观察到人体解剖结构。在操作时，有实时交互提供反馈，软件完全模拟包皮环切术的操作步骤，所学即所得。能够让用户快速学习到相关的知识与技能。软件包含教学模式与考核模式，教学模式下，系统会对用户进行提示，包含语音、文字、操作提示。在考核模式下，系统会对用户的操作进行记录并计分，从而更好地掌控用户的学习进度",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "标准化流程",
                  txt: "联合一线医学专家共同开发，制定标准化步骤流程",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "软件模拟临床手术场景，并采用高精度的男性仿真模型",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "使用者佩戴VR设备在虚拟仿真手术室中进行包皮环切手术模拟，能够让用户身临其境，提供沉浸式体验",
                },
                {
                  titleB: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "多教学场景适配",
                  txt: "软件拥有练习和考核两个模式，练习模式中具备文字和语言提示操作步骤，考核模式无提示，并根据操作者的操作对错进行评分，操作结束会给出评分",
                  img: [
                    "/picture/school/2/1/资源 1.png",
                    "/picture/school/2/1/资源 2.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "软件通过沉浸式交互方式，高效率的培养医生以及医学生进行手术教学",
                },
                {
                  titleB: "软件使用仿真人物模型加上仿真的操作环境，让使用者有着强烈的真实感",
                },
                {
                  titleB: "软件还原了包皮环切手术的全部步骤，缩短培训时间",
                  img: [
                    "/picture/school/2/1/资源 3.png",
                    "/picture/school/2/1/资源 4.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "剖宫产",
          icon: [{
              title: "双语模式",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/剖宫产/SVG/双语模式.svg",
            },
            {
              title: "系统评分",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/剖宫产/SVG/系统评分.svg",
            },
            {
              title: "虚拟医生协助",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/剖宫产/SVG/虚拟医生协助.svg",
            },
            {
              title: "训练临场能力",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/剖宫产/SVG/训练临场能力.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "VR剖腹产术手术模拟软件，是采用最新VR虚拟现实技术，佩戴VR头盔，在虚拟场景内进行手术仿真模拟。通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验剖腹产手术流程。软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练。术中还有专业性问答与突发状况，培训用户的专业性知识与临场应对能力。操作时每一步都会有实时反馈，让用户学习效率大大提升",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "标准化流程",
                  txt: "手术操作步骤从消毒开始，到最后缝合完毕全过程都与专业医师合作制作，按照标准步骤制定",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "软件模拟临床手术场景，使用高精度孕妇模型，从表皮到内部脏器到子宫内婴儿，完全仿真孕妇人体结构",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "用户使用VR设备在虚拟仿真手术室中进行手术模拟，在用户旁边有手术助手递器械给用户，让用户身临其境，给用户提供沉浸式体验",
                },
                {
                  titleB: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "多教学模式适配",
                  txt: "拥有练习模式和考核模式，每个步骤拥有多种器械供用户选择；练习模式中拥有文字和语言提示操作步骤，考核模式无提示，并根据操作者的操作对错进行评分，操作结束会给出评分",
                  img: [
                    "/picture/school/2/2/资源 1.png",
                    "/picture/school/2/2/资源 2.png",
                    "/picture/school/2/2/资源 3.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "软件分为中/英文两种语言模式，分别提供练习模式与考核模式，针对不同用户进行训练或者考核",
                },
                {
                  titleB: "软件在操作过程中，会有虚拟医生与护士进行手术协助，实时提供反馈，让用户快速学习，真实模拟",
                },
                {
                  titleB: "软件在术中还有突发状况，能够训练用户临场应对能力",
                },
                {
                  titleB: "软件在考核模式下，系统会对用户的各项操作进行评分，在操作结束后给出得分，显示错误操作的步骤",
                  img: [
                    "/picture/school/2/2/资源 4.png",
                    "/picture/school/2/2/资源 5.png",
                    "/picture/school/2/2/资源 6.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "甲状腺次切全术",
          icon: [{
              title: "多人协同",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/甲状腺次切全术/SVG/多人协同.svg",
            },
            {
              title: "神经探头探查 ",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/甲状腺次切全术/SVG/神经探头探查 .svg",
            },
            {
              title: "显示术野视角",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/甲状腺次切全术/SVG/显示术野视角.svg",
            },
            {
              title: "专业手术教学",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/甲状腺次切全术/SVG/专业手术教学.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "VR甲状腺次全切术模拟软件，是采用最新的VR虚拟现实技术，在虚拟场景内进行甲状腺次全切术手术仿真模拟。软件使用高精度人体模型，从颈部表皮到内部甲状腺，完全仿真人体结构。通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验甲状腺次全切术手术流程。软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练。操作时每一步都会有实时反馈，让用户学习效率大大提升。术中还有专业性问答，培训用户的专业性知识与临场应对能力",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "标准化流程",
                  txt: "手术操作步骤从消毒开始，到最后缝合完毕全过程都与专业医师合作制作，按照标准步骤制定",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "软件模拟临床手术场景，软件使用高精度人体模型，从颈部表皮到内部甲状腺，完全仿真人体结构",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "用户使用VR设备在虚拟仿真手术室中进行手术模拟，由手术助手递器械给用户，模拟临床手术氛围与环境",
                },
                {
                  titleB: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "多教学模式选择",
                  txt: "拥有练习模式和考核模式，每个步骤拥有多种器械供用户选择；练习模式中拥有文字和语言提示操作步骤，考核模式无提示，并根据操作者的操作对错进行评分，操作结束会给出评分",
                },
                {
                  titleB: "双人协同",
                  txt: "软件可进行双人协同完成手术，旁观者可以进行跟台体验",
                  img: [
                    "/picture/school/2/3/资源 7.png",
                    "/picture/school/2/3/资源 8.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "软件用于专业手术教学",
                },
                {
                  titleB: "软件针对用户不同需求设置了练习模式和考核模式",
                },
                {
                  titleB: "软件可以进行多人协同操作，多人协助完成甲状腺次全切手术，并且实时提供反馈，提高学习效率",
                },
                {
                  titleB: "软件在操作过程中，会在用户前方显示术野视角，详细展示用户在操作时的细节操作",
                },
                {
                  titleB: "用户在使用神经探头进行探查时，还会显示神经探头探查到的神经信号",
                },
                {
                  titleB: "软件通过高度仿真模拟的形式，让用户对甲状腺次全切术的全流程进行沉浸式体验 ",
                },
              ],
            },
          ],
        },
        {
          name: "阑尾切除术",
          icon: [{
              title: "仿真人体模型",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/阑尾切除术/SVG/仿真人体模型.svg",
            },
            {
              title: "模拟操作步骤 ",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/阑尾切除术/SVG/模拟操作步骤 .svg",
            },
            {
              title: "语音提示",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/阑尾切除术/SVG/语音提示.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "阑尾切除术手术模拟软件，采用最新虚拟现实(VR)技术，通过模拟真实的手术室环境，有护士协助，全方位沉浸式体验阑尾切除术的流程。用户在操作过程中，会有实时的交互提供反馈，术中还有理论知识答题，提升用户的理论知识水平。同时还有难易度选择，不同水平用户，选择不同难度进行训练，逐级增加难度。包含教学模式与考核模式，让用户在培训完成后，通过考核模型对自身水平进行测试",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "软件使用三维引擎进行制作",
                },
                {
                  titleB: "标准化流程",
                  txt: "手术操作步骤从消毒开始，到最后缝合完毕全过程都与专业医师合作制作，按照标准步骤制定",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "软件模拟临床手术场景，使用男性人体模型进行手术模拟",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "用户使用VR设备在虚拟仿真手术室中进行手术模拟，用户旁边有手术助手递器械给用户，让用户身临其境，给用户提供沉浸式体验",
                },
                {
                  titleB: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "多教学模式选择",
                  txt: "有培训和考核2个模式，软件会记录用户的操作时间和在术中会出现专业知识题目来进行培训和考核，培训模式操作全程有语音提示，考核模式中会记录用户答题错误次数和用户可以点击提示，来获得操作提示，操作结束后，会显示操作错误次数、准确率、答题正确率等信息",
                  img: [
                    "/picture/school/2/4/资源 8.png",
                    "/picture/school/2/4/资源 9.png",
                    "/picture/school/2/4/资源 10.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "软件用于手术流程教学，培养医生和医学生对阑尾切除术步骤流程了解",
                },
                {
                  titleB: "软件使用高精度人体模型，从表皮到内部脏器，完全仿真人体结构，真实模拟",
                },
                {
                  titleB: "程序完全模拟阑尾切除术的操作步骤，学有所得，加快了教学效率",
                },
                {
                  titleB: "软件在练习操作过程中，全程有语音对用户进行提示。而在考核模式中，用户可以在遗忘步骤时，使用提示功能，更快得回忆起操作步骤",
                  img: [
                    "/picture/school/2/4/资源 11.png",
                    "/picture/school/2/4/资源 12.png",
                    "/picture/school/2/4/资源 13.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "体表肿物切除",
          icon: [{
              title: "仿真人体模型",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/体表肿物切除/SVG/仿真人体模型.svg",
            },
            {
              title: "模拟操作步骤 ",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/体表肿物切除/SVG/模拟操作步骤 .svg",
            },
            {
              title: "语音提示",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/体表肿物切除/SVG/语音提示.svg",
            },
            {
              title: "专业手术教学",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/体表肿物切除/SVG/专业手术教学.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "体表肿物切除手术模拟软件，采用最新虚拟现实(VR)技术，通过模拟真实的手术室环境，有护士协助，全方位沉浸式体验体表肿物切除的流程。并且用户在操作过程中，会有实时的交互提供反馈，术中还有理论知识答题，提升用户的理论知识水平。同时还有难易度选择，不同水平用户，可以选择不同的难度进行训练，并逐级增加难度。包含教学模式与考核模式，让用户在培训完成后，通过考核模型对自身水平进行测试",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "软件使用三维引擎进行制作",
                },
                {
                  titleB: "标准化流程",
                  txt: "手术操作步骤从消毒开始，到最后缝合完毕全过程都与专业医师合作制作，按照标准步骤制定",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "软件模拟临床手术场景，并使用高精度人体仿真模型进行手术模拟",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "用户使用VR设备在虚拟仿真手术室中进行手术模拟，并且在用户旁边有手术助手递器械给用户，让用户身临其境，给用户提供沉浸式体验",
                },
                {
                  titleB: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "多教学模式选择",
                  txt: "有培训和考核两种模式，软件会记录用户的操作时间和在术中会出现专业知识题目来进行培训和考核，培训模式操作全程有语音提示，考核模式中会记录用户答题错误次数和用户可以通过点击进行提示；操作结束后，会显示操作错误次数、准确率、答题正确率等信息",
                  img: [
                    "/picture/school/2/5/资源 14.png",
                    "/picture/school/2/5/资源 15.png",
                    "/picture/school/2/5/资源 16.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "软件用于手术流程教学，培养医生和医学生对体表肿物切除步骤流程的了解",
                },
                {
                  titleB: "软件使用高精度人体仿真模型，完全仿真人体结构，真实模拟",
                },
                {
                  titleB: "软件完全模拟体表肿物切除的操作步骤，学有所得，加快了教学效率",
                },
                {
                  titleB: "软件在练习操作过程中，全程有语音对用户进行提示，软件会模拟体表肿物受到手术器械夹取时真实的变形反馈，让用户体验到真实的手术操作感受。而在考核模式中，用户可以在遗忘步骤时，使用提示功能，更快得回忆起操作步骤",
                  img: [
                    "/picture/school/2/5/资源 17.png",
                    "/picture/school/2/5/资源 18.png",
                    "/picture/school/2/5/资源 19.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "围术期压疮管理",
          icon: [{
              title: "交互方式",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/围术期压疮管理/SVG/交互方式.svg",
            },
            {
              title: "术后护理练习 ",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/围术期压疮管理/SVG/术后护理练习 .svg",
            },
            {
              title: "体位展示",
              img: "/icon/基本手术 虚拟仿真和VR教学平台/围术期压疮管理/SVG/体位展示.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "手术围术期压疮管理模拟软件，使用VR进行手术围术期仿真模拟。软件通过模拟真实的手术室环境，全方位沉浸式体验患者在术时采用不同体位所对应的不同压疮管理，以便术后进行适当护理。软件分为体位摆放与体位观看，用户可以根据需要选择内容进行学习。软件使用高精度人体模型与手术台器械，完全模拟患者在手术时的体位。同时在操作过程中，实时提供反馈，让用户快速学习，真实模拟",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "使用三维引擎进行开发",
                },
                {
                  titleB: "标准化流程",
                  txt: "联合三甲医院医护专家共同开发，进行压疮护理操作全步骤制作",
                },
                {
                  titleB: "高精度三维模型",
                  txt: "软件模拟真实手术室场景，使用高精度仿真器械",
                },
                {
                  titleB: "沉浸式体验",
                  txt: "软件支持在头盔中观看和交互，支持显示空间中用户的移动和旋转与虚拟空间保持一致，能够让使用者身临其境",
                },
                {
                  titleB: "支持硬件包括：HTC Vive/Pro、Pico、Oculus Quest 2",
                },
                {
                  titleB: "多教学模式适配",
                  txt: "用户可以任意选择想要学习的护理体位，软件从手术时的各个体位，都有对应的压疮护理内容",
                  img: [
                    "/picture/school/2/6/资源 20.png",
                    "/picture/school/2/6/资源 21.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "软件在操作上采用简洁的交互方式，针对患者以及医护人员进行设计",
                },
                {
                  titleB: "患者可第一时间查看术后护理流程",
                },
                {
                  titleB: "医护人员能够根据软件教学进行对术后护理的练习以及体位摆放",
                },
                {
                  titleB: "软件提供4种常见的体位，每个体位上都标注具体每个该保护的位置。让用户更加明确的了解围术期压疮护理的细节保护位置",
                  img: [
                    "/picture/school/2/6/资源 22.png",
                    "/picture/school/2/6/资源 23.png",
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "3D医学打印服务",
      class: ["定制化医学临床教具", "骨科正骨", "齿科"],
      info: [
        "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术。3D打印运用于医疗领域常与三维重建技术相结合，通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印。",
        "骨科正骨3D打印以临床影像数据为基础，根据实际教学需求对模型进行针对性优化，同时结合食品级硅胶材料，打造符合临床病理症状的，具有表皮、肌肉及骨骼层的仿真上肢模型",
        "取代传统模具，通过3D打印机设备，学生可在教师指导下自主操作模型打印，打印出的模型可供学生在仿真教学区域内进行高还原度操作训练",
      ],
      // img: [
      //     ["/picture/school/3/1/资源 15.png",
      //         "/picture/school/3/1/资源 16.png",
      //         "/picture/school/3/1/资源 18.png",
      //         "/picture/school/3/1/资源 19.png"],
      //     ["/picture/school/3/2/资源 19.png",
      //         "/picture/school/3/2/资源 20.png"],
      //     ["/picture/school/3/3/1.png",
      //         "/picture/school/3/3/2.png",
      //         "/picture/school/3/3/3.png"]
      // ],
      video: [
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%AE%9A%E5%88%B6%E5%8C%96%E5%8C%BB%E5%AD%A6%E4%B8%B4%E5%BA%8A%E6%95%99%E5%85%B7.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E9%AA%A8%E7%A7%91.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E9%BD%BF%E7%A7%91.mp4",
      ],
      data: [{
          name: "定制化医学临床教具",
          icon: [{
              title: "多元化材料",
              img: "/icon/3D医学打印服务2/定制化医学临床教具/SVG/多元化材料.svg",
            },
            {
              title: "一体化彩色打印 ",
              img: "/icon/3D医学打印服务2/定制化医学临床教具/SVG/一体化彩色打印.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "近年，医学教学越来越强调贴近临床，统一的批量化模型逐渐无法满足教学需求，于是定制化临床教具应运而生。定制化医学临床教具是指根据医学院或临床科室的教学需求，以疾病及病例为导向，以实际患者的影像数据为基础，利用三维重建技术及3D打印技术定制开发医学临床教具",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "病理解剖教学",
                  txt: "教具根据病理影像数据进行重建，可清晰展现病理解剖特征",
                },
                {
                  titleB: "手术操作模拟",
                  txt: "教具根据病理影像重建，可用于手术操作的教学，例如肿瘤手术的切除范围及血管情况，介入手术的入路及操作方法等",
                },
                {
                  titleB: "术前规划及患者教育",
                  txt: "可用于临床科室复杂手术前的术前规划，在术前用完全匹配患者的教具进行模拟操作演练，并可用其对解释手术操作方法",
                  img: [
                    "/picture/school/3/1/资源 13.png",
                    "/picture/school/3/1/资源 14.png",
                    "/picture/school/3/1/资源 15.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "一体化彩色打印",
                  txt: "可在一次打印中完成将不同的打印材料根据解剖结构，运用不同颜色及特性的材料进行区分，并一次性打印成型，可制作复杂解剖结构的临床教具",
                },
                {
                  titleB: "多元化材料",
                  txt: "可根据教学及模拟需要，灵活运用不同的3D打印材料，实现最大化的结构及手感模拟",
                  img: [
                    "/picture/school/3/1/资源 16.png",
                    "/picture/school/3/1/资源 17.png",
                    "/picture/school/3/1/资源 18.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "骨科正骨",
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "骨科正骨3D打印以临床影像数据为基础，根据实际教学需求对模型进行针对性优化，同时结合食品级硅胶材料，打造符合临床病理症状的，具有表皮、肌肉及骨骼层的仿真上肢模型",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "3D打印",
                  txt: "通过临床影像数据进行三维重建，根据教学需求进行针对性修改后，进行3D打印及后处理，3D打印骨科正骨模型具有表皮、骨骼及肌肉填充材料",
                },
                {
                  titleB: "虚实融合",
                  txt: "通过3D打印技术重建骨折骨骼，打造骨科正骨模拟器。结合虚实融合技术，操作者佩戴MR头显设备对整骨模拟器进行整复操作时，骨折端的虚拟影像叠加在模拟器上，操作者能通过MR头显实时观察操作时骨骼的位置变化，从而更好的理解骨折整复操作的原理。同时，系统通过检测骨折断端对合的位置、角度对骨折整复操作进行评分，有效提高操作者的学习效率",
                },
                {
                  titleB: "配套虚拟仿真教学课件",
                  txt: "根据教学需要，配套正骨虚拟仿真教学课件，内容以具体骨折部位为病例单元进行教学，每个骨折单元都包括局部解剖、病例读片、体格检查、临床诊断、骨折处理等几个部分，通过虚拟病例将骨折临床处理的全流程进行拆解，使用者可以通过课件学习正骨相关的理论知识，了解临床骨骼复位的操作流程",
                  img: [
                    "/picture/school/3/2/资源 19.png",
                    "/picture/school/3/2/资源 20.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "多维度教学",
                  txt: "教学内容横跨理论及实操，具体包含局部解剖、骨折诊断、临床读片、骨骼整复、整复后处理等各个步骤，同时满足临床专科教学及医学院教学需求",
                },
                {
                  titleB: "三维重建",
                  txt: "3D打印的模型根据临床实际影像数据进行重建，符合临床教学需求",
                },
                {
                  titleB: "虚实融合",
                  txt: "通过3D打印结合虚实融合打造整骨模拟人，更好的满足学生对于实操训练的教学需求",
                },
              ],
            },
          ],
        },
        {
          name: "齿科",
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "临床上，常规口腔疾病和诊断和治疗虽然原理简单，但因口腔空间狭小等原因，操作较为困难。将虚拟现实成像技术和3D打印技术结合后，通过虚拟现实成像技术可以还原患者口腔病变部位的场景。成像后，无需患者的配合和干预，医生就可以很好的诊断患者的口腔情况。在此基础上，通过3D打印牙齿等假体，并将假体换原至虚拟场景中进行比对，能够改善和提高假体的制作效果。<br>而在口腔医学教学中，传统的仿头模、离体牙的实践，无法使学习者在操作过程中及时发现错误并纠正，更缺乏完善的临床操作环节。利用虚拟现实成像技术和3D打印技术，可以为学生提供依据真实临床案例打印的实体模型进行实操练习，在提升学习效率的同时，帮助学生在学习阶段即可掌握最新临床技术。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                txt: "口腔科的很多疾病都与牙齿相关，如镶牙和牙齿的修复等。将3D打印技术与虚拟现实技术融合后，首先根据虚拟现实成像技术的结果，通过计算机可以较为准确的计算出假体具体的形状和大小等数据。以此为基础，将数据输入3D打印机后，电脑自动打印假体。打印完成后，将打印的模型假体放置在虚拟成像系统构造的场景中进行模拟的还原比对，如比较大小、位置、 角度和形状等，若并不是十分理想，重新调整相关参数，实现极小的精度，充分保证修改的质量后重新打印即可。同时由于参数都记录在3D打印系统中，若未来假体出现损坏或丢失等问题，也无需再次测量，医生根据假体在3D打印机的数据记录，直接再次打印即可。当3D打印模型与虚拟现实成像技术的场景基本一致后，再将假体置入患者对应的病变部位，患者感受理想后，再制作真实的假体",
                img: [
                  "/picture/school/3/3/1.png",
                  "/picture/school/3/3/2.png",
                  "/picture/school/3/3/3.png",
                ],
              }, ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "口腔医学教学：口腔修复体制作练习、口腔科病种诊疗实践",
                },
                {
                  titleB: "临床口腔修复：牙体缺损修复中的应用、固定修复、可摘义齿、种植义齿，3D打印个性化人工种植牙、3D 打印手术导板",
                },
                {
                  titleB: "临床口腔正畸治疗",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "护理/养老康复教学平台",
      class: ["护理", "养老"],
      info: [
        "压疮管理虚拟仿真软件基于护理人员对于压疮护理的知识缺乏而设计。我们与专业院校及专业学术平台互相合作开发了护理这款仿真软件，具体内容包括压疮理论、压疮预防知识以及压疮的治疗与护理操作教学，保证医学正确性。",
        "老年人群由于在行为及精神症状具有鲜明的独特性，如记忆、理解、判断、计算等认知功能减退，部分患有认知症的老人人群还伴有幻觉、妄想、行为紊乱和人格改变，大大增加了照护的复杂性。",
      ],

      video: [
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E6%8A%A4%E7%90%86.mp4",
        "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E5%85%BB%E8%80%81.mp4",
      ],
      data: [{
          name: "护理",
          icon: [{
              title: "全流程学习",
              img: "/icon/护理养老康复教学平台/护理/SVG/全流程学习.svg",
            },
            {
              title: "提高学习效率 ",
              img: "/icon/护理养老康复教学平台/护理/SVG/提高学习效率.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "压疮管理虚拟仿真软件基于护理人员对于压疮护理的知识缺乏而设计。我们与专业院校及专业学术平台互相合作开发了护理这款仿真软件，具体内容包含压疮理论、压疮预防知识以及压疮的治疗与护理操作教学，保证医学正确性",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "软件通过全方位沉浸式体验老年患者压疮进行护理管理",
                  img: [
                    "/picture/school/4/1/资源 21.png",
                    "/picture/school/4/1/资源 22.png",
                    "/picture/school/4/1/资源 23.png",
                  ],
                },
                {
                  titleB: "软件使用高精度人体模型与手术台器械，在操作过程中，实时提供反馈，让用户快速学习，真实模拟",
                },
                {
                  titleB: "联合一线医护专家共同开发，能够让患者身临其境，进行压疮护理操作",
                },
                {
                  titleB: "软件从手术时的各个体位，都有对应的压疮护理内容",
                },
                {
                  titleB: "用户可以任意选择想要学习的护理体位",
                },
                {
                  titleB: "软件支持在头盔中观看和交互，支持显示空间中用户的移动和旋转与虚拟空间保持一致",
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                titleB: "该软件用于压疮护理的教学，培养护理人员对压疮的了解以及压疮护理操作，软件参考专业平台相关文献，并还原了压疮护理的全部步骤，加快了护理人员对护理知识的学习效率",
                img: [
                  "/picture/school/4/1/资源 24.png",
                  "/picture/school/4/1/资源 25.png",
                  "/picture/school/4/1/资源 26.png",
                ],
              }, ],
            },
          ],
        },
        {
          name: "养老",
          icon: [{
              title: "病例数据汇总",
              img: "/icon/护理养老康复教学平台/养老/SVG/病例数据汇总.svg",
            },
            {
              title: "可视化呈现 ",
              img: "/icon/护理养老康复教学平台/养老/SVG/可视化呈现.svg",
            },
            {
              title: "系统录入 ",
              img: "/icon/护理养老康复教学平台/养老/SVG/系统录入.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "老年人群由于在行为及精神症状具有鲜明的独特性，如记忆、理解、判断、计算等认知功能减退，部分患有认知症的老人人群还伴有幻觉、妄想、行为紊乱和人格改变，大大增加了照护的复杂性。<br>长护险即长期护理保险，是国家为失能人群提供的护理服务。我司与专业医学院校合作，在进行信息脱敏的前提下，通过实地采集老人的真实数据，经过科学的统计学统计，能够得到一个老人的基准线，供医护人员进行参考。收集真实长护险病例，进行数据统计汇总及可视化呈现。未来在新录入一位老年人的数据后，系统能够自动得出该老人与基准线的偏离，给出建议，对老人的日常生活进行干预。从而达到改善老年人生活质量与健康水平的目的",
                img: [
                  "/picture/school/4/2/资源 27.png",
                  "/picture/school/4/2/资源 28.png",
                ],
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "系统可以使用图表的形式呈现老人们的身体健康数据",
                },
                {
                  titleB: "系统包含统计功能，能够统计老人们的身体数据并进行呈现",
                },
                {
                  titleB: "系统可以选择不同的老人数据进行查看",
                },
                {
                  titleB: "基于PC端windows10系统",
                },
                {
                  titleB: "系统能够录入老人采集到的老人数据",
                },
                {
                  titleB: "在系统中能够看到虚拟的三维老人形象",
                },
                {
                  titleB: "将真实长护险病例数据进行汇总统计，根据一定的规律进行分类整理，提取信息特征进行可视化呈现",
                  img: [
                    "/picture/school/4/2/资源 29.png",
                    "/picture/school/4/2/资源 30.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                titleB: "医护人员可使用该系统给出的基准线进行判断老年人身体状况，通过数据对老人进行相应照护。将真实数据可视化呈现，为临床科研及护理教学提供一定的帮助。本系统在认知症及老年照护专家专业指导下进行开发，搜集整理临床资料，对认知症患者及认知症照护从业人员等进行调研，搭建在线认知症患者调研系统",
              }, ],
            },
          ],
        },
      ],
    },
    {
      title: "远程教学直播系统",
      info: "在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。5G+VR探视可以有效避免医生和家属进入病房时遇到交叉感染的情况，增加感染隐患，减少医生和家属探视时所需使用防护用品和衣物…",
      // img: ["/picture/school/5/资源 16.png",
      //     "/picture/school/5/资源 14.png",
      //     "/picture/school/5/资源 15.png"],
      video: "http://resourcedev.smaxserver.com/assets%2FYXYX%2F%E8%BF%9C%E7%A8%8B%E6%95%99%E5%AD%A6%E7%9B%B4%E6%92%AD%E7%B3%BB%E7%BB%9F.mp4",
      data: {
        name: "远程教学直播系统",
        data: [{
            titleA: "内容及介绍",
            content: [{
              txt: "在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。5G+VR探视可以有效避免医生和家属进入病房时遇到交叉感染的情况，增加感染隐患，减少医生和家属探视时所需使用防护用品和衣物，减少不必要资源损耗，减少医生和家属每次穿防护服或防护衣物时间，减少无效时间",
              img: [require("./picture/school/5/资源 16.png")],
            }, ],
          },
          {
            titleA: "功能描述",
            content: [{
                titleB: "采用5G+VR技术，支持8k VR+4k超高清视频信号",
              },
              {
                titleB: "可以通过VR头盔，看到全景相机的画面，能够清晰看到患者所处的病房环境与患者本人，犹如身临其境探视患者一般",
              },
              {
                titleB: "系统实时传输现场场景，让探视患者第一时间看到病房内场景",
              },
              {
                titleB: "支持硬件包括：大屏显示设备、手机、VR头盔",
              },
              {
                titleB: "系统包含远程查房系统、直播系统，系统在每个病房内架设4台移动推车，其中3台推车上有一台高视频清晰度的PAD，1台推车上架有1台PAD和1台全景相机，pad与全景相机通过5G网络，进行推流",
              },
            ],
          },
          {
            titleA: "用途与特色",
            content: [{
              titleB: "通过5G+VR技术呈现，在保护隐私安全的前提下，为家属开放远程预约和探视服务，尽可能减少医护干扰、交叉感染等可能性。隔离探视的高清直播及双向沟通能力还可以承载远程诊疗场景、医疗操作示范教学、养老探视、母婴探视、医疗教学、会议活动直播、监狱探视、康复训练等场景。同时远程探视还能让家属实时“看”到病房的患者高清动态，平复他们焦虑的内心",
              img: [
                require("./picture/school/5/资源 14.png"),
                require("./picture/school/5/资源 15.png"),
              ],
            }, ],
          },
        ],
      },
    },
  ],
  itemList: [{
      title: '执业/助理医师</br>规范化培训虚拟仿真教学平台',
      phoneTitle:'执业/助理医师规范化</br>培训虚拟仿真教学平台',
      blocks: [{
          typographical: ServerTypographical.MXFLEX,
          data: [{
              picture: '/picture/school/1.png',
              prePicture: '/picture/school/1.jpg',
              showImg: false,
              title: '缝合术',
              subtitle: '制作高精度高还原的缝合三维动画',
              desc: '缝合技术作为临床基础操作之一，是广大医学生必须要掌握的专业知识之一。传统缝合技术训练方式一般为视频学习，然后通过仿真缝合模型进行实操训练。我司根据临床实际教学需求，将9大缝合技术进行标准化的三维动画还原，同时结合AR技术进行虚实融合，学生可以在进行缝合训练的同时观看标准缝合技法，为临床学习提供帮助',
            },
            {
              picture: '/picture/school/2.png',
              prePicture: '/picture/school/2.jpg',
              showImg: false,
              title: '三腔二囊管',
              subtitle: '对曲张食管-胃底静脉破裂出血的局部压迫止血',
              desc: '程序通过三维技术对手术场景、手术器械等进行建模，使操作者能够沉浸式体验三腔二囊管的操作。系统严格按照临床真实流程进行模拟'
            }
          ]
        },
        {
          typographical: ServerTypographical.MXFLEX,
          data: [{
            picture: '/picture/school/3.png',
            prePicture: '/picture/school/3.jpg',
            showImg: false,
            title: '导尿术',
            subtitle: '无菌操作下，使用导尿管经尿道插入膀胱引流尿液',
            desc: '系统包含男性导尿和女性导尿术，基于导尿术真实临床路径，借助VR技术，在虚拟空间内直观地展示导尿术完成流程步骤'
          }, {
            picture: '/picture/school/4.png',
            prePicture: '/picture/school/4.jpg',
            showImg: false,
            title: '胸腔穿刺术',
            subtitle: '引导用户在高度仿真的模拟手术场景中进行全面的学习',
            desc: '虚拟仿真胸腔穿刺术模拟，使用VR技术引导用户使用消毒过的针刺经皮肤、肋间组织、叠层胸膜穿刺进入胸膜腔，吸出积出的液体'
          }, {
            picture: '/picture/school/5.png',
            prePicture: '/picture/school/5.jpg',
            showImg: false,
            title: '腰椎穿刺术',
            subtitle: '在三维空间中还原腰椎穿刺术临床路径',
            desc: '腰椎穿刺术是临床上常用的有创检查/治疗手段，广泛应用于检查脑脊椎液性质，测定颅内压，了解蛛网膜下腺是否阻塞或是否需要鞘内注射药物'
          }, ]
        },
        {
          typographical: ServerTypographical.MXFLEX,
          data: [{
              picture: '/picture/school/6.png',
              prePicture: '/picture/school/6.jpg',
              showImg: false,
              title: '心肺复苏',
              subtitle: '配备医学模拟人，提供真实的操作体验',
              desc: '心肺复苏是采用徒手或辅助设备来维持呼吸、心脏骤停患者人工循环的最基本的抢救方法，包括开放气道、人工通气、胸外按压、电除颤及药物治疗等。目的是尽快恢复自主循环，最终达到脑神经功能良好的存活'
            },
            {
              picture: '/picture/school/7.png',
              prePicture: '/picture/school/7.jpg',
              showImg: false,
              title: '气管插管',
              subtitle: '结合第三人称视角的气管插管动画，全方位指导教学',
              desc: '气管插管是指通过口腔或鼻腔将一特制的气管导管，经声门置入气管或支气管内的技术。这一技术能为呼吸道通畅、通气供氧、呼吸道吸引和防止误吸等提供最佳条件，是抢救呼吸功能障碍及高级心肺脑复苏的重要措施'
            }
          ]
        }
      ]
    },
    {
      title: '基本手术</br>虚拟仿真和VR教学平台',
      blocks: [{
          typographical: ServerTypographical.SIGFLEX,
          data: [{
            picture: '/picture/school/8.png',
            prePicture: '/picture/school/8.jpg',
            showImg: false,
            title: '包皮环切术',
            subtitle: '最新虚拟现实（VR）技术，高精度模型',
            desc: '采用最新虚拟现实（VR）技术，模拟真实的手术室环境，提供全方位沉浸式的包皮环切手术流程学习体验。程序采用高精度的男性模型，从阴茎表皮到血管内部，完全仿真人体结构，能够让用户清晰地观察到人体解剖结构。在操作时，有实时交互提供反馈，软件完全模拟包皮环切术的操作步骤，所学即所得。能够让用户快速学习到相关的知识与技能。软件包含教学模式与考核模式，教学模式下，系统会对用户进行提示，包含语音、文字、操作提示。'
          }]

        },
        {
          typographical: ServerTypographical.MXFLEX,
          data: [{
              picture: '/picture/school/9.png',
              prePicture: '/picture/school/9.jpg',
              showImg: false,
              title: '剖宫产',
              subtitle: '仿真手术环境，沉浸式剖腹体验',
              desc: 'VR剖腹产术手术模拟软件，是采用最新VR虚拟现实技术，佩戴VR头盔，在虚拟场景内进行手术仿真模拟。通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验剖腹产手术流程'
            },
            {
              picture: '/picture/school/10.png',
              prePicture: '/picture/school/10.jpg',
              showImg: false,
              title: '甲状腺次切全术',
              subtitle: '仿真手术环境，专业性问答训练',
              desc: 'VR甲状腺次全切术模拟软件，是采用最新的VR虚拟现实技术，在虚拟场景内进行甲状腺次全切术手术仿真模拟。软件使用高精度人体模型，从颈部表皮到内部甲状腺，完全仿真人体结构'
            },
            {
              picture: '/picture/school/11.png',
              prePicture: '/picture/school/11.jpg',
              showImg: false,
              title: '阑尾切除术',
              subtitle: '仿真手术环境，全方位沉浸式体验',
              desc: '阑尾切除术模拟软件，采用最新虚拟现实（VR）技术，通过模拟真实的手术室环境，有护士协助，全方位沉浸式体验阑尾切除术的流程。'
            }
          ]
        },
        {
          typographical: ServerTypographical.MXFLEX,
          data: [{
              picture: '/picture/school/12.png',
              prePicture: '/picture/school/12.jpg',
              showImg: false,
              title: '体表肿物切除',
              subtitle: '仿真手术环境，多种考核模式',
              desc: '体表肿物切除术手术模拟软件，采用最新虚拟现实（VR）技术，通过模拟真实的手术室环境，有护士协助，全方位沉浸式体验体表切除的流程'
            },
            {
              picture: '/picture/school/13.png',
              prePicture: '/picture/school/13.jpg',
              showImg: false,
              title: '围术期压疮管理',
              subtitle: '仿真手术环境，高精度人体模型',
              desc: '手术围术期压疮管理模拟软件，使用VR进行手术围术期仿真模拟。软件通过模拟真实的手术室环境，全方位沉浸式体验患者在术时采用不同体位所对应的不同压疮管理，以便术后进行适当护理'
            }
          ]
        }
      ]
    },
    {
      title: '最新MR虚拟融合教学平台',
      blocks: [{
        typographical: ServerTypographical.MXFLEX,
        data: [{
            picture: '/picture/school/14.png',
            prePicture: '/picture/school/14.jpg',
            showImg: false,
            title: '导尿术虚实融合训练系统',
            subtitle: '虚实融合实训，仿真教学',
            desc: '本项目采用3D技术构建情景化虚拟场景、标准化虚拟病人以及人体标准解剖结构，采用MR技术和毫米级空间微定位系统相结合实现虚实融合教学'
          }, {
            picture: '/picture/school/15.png',
            prePicture: '/picture/school/15.jpg',
            showImg: false,
            title: '硬膜外麻醉虚实融合训练系统',
            subtitle: '虚实融合实训，仿真教学',
            desc: '系统将线上教学和线下实训相结合，教学内容包含在线虚拟仿真互动教学内容及虚实融合线下实训内容两个部分'
          },
          {
            picture: '/picture/school/16.png',
            prePicture: '/picture/school/16.jpg',
            showImg: false,
            title: '多人协同正骨实训系统',
            subtitle: '在线虚拟课堂，移动式教学',
            desc: '本次项目是国内首个虚实融合正骨实习系统，解决正骨教学手法单一，缺少手感训练，引导标准化操作，实训考核准确性。'
          }
        ]
      }]
    },
    {
      title: '养老护理康复与健康管理平台',
      phoneTitle: '养老护理</br>康复与健康管理平台',
      blocks: [{
          typographical: ServerTypographical.SIGFLEX,
          data: [{
            picture: '/picture/school/17.png',
            prePicture: '/picture/school/17.jpg',
            mode:'height-fix',
            showImg: false,
            title: '健康管理平台',
            subtitle: '结合专业特色、学校定位、人才培养',
            desc: '健康服务与管理虚拟仿真实验管理平台是与广东药科大学关向东教授团队及杭州师范大学健康管理学院合作。健康服务与管理虚拟仿真实验管理平台，是建立以专业主干课程为核心开发的若干虚拟仿真实验资源内容，本平台包含了六大模块，有健康服务与管理虚拟仿真实验教学及开发软件、慢病管理虚拟仿真实验教学软件、基于大数据技术的区域性健康风险评估虚拟仿真实验、健康风险评估管理虚拟仿真实验软件、饮食综合评估及干预虚拟仿真实验软件、运动综合评估及训练虚拟仿真实验软件。'
          }]
        },
        {
          typographical: ServerTypographical.MXFLEX,
          data: [{
              picture: '/picture/school/18.png',
              prePicture: '/picture/school/18.jpg',
              showImg: false,
              title: '护理',
              subtitle: '仿真手术环境，多种考核模式',
              desc: '压疮管理虚拟仿真软件基于护理人员对于压疮护理的知识缺乏而设计。我们与专业院校及专业学术平台互相合作开发了护理这款仿真软件，具体内容包括压疮理论、压疮预防知识以及压疮的治疗与护理操作教学，保证医学正确性。'
            },
            {
              picture: '/picture/school/19.png',
              prePicture: '/picture/school/19.jpg',
              showImg: false,
              title: '养老',
              subtitle: '仿真手术环境，多种考核模式',
              desc: '老年人群由于在行为及精神症状具有鲜明的独特性，如记忆、理解、判断、计算等认知功能减退，部分患有认知症的老人人群还伴有幻觉、妄想、行为紊乱和人格改变，大大增加了照护的复杂性。'
            }
          ]
        }
      ]
    },
    {
      title: '3D医学打印服务',
      blocks: [{
        typographical: ServerTypographical.MXFLEX,
        data: [{
            picture: '/picture/school/20.png',
            prePicture: '/picture/school/20.jpg',
            showImg: false,
            title: '定制化医学临床教具',
            subtitle: '仿真手术环境，多种考核模式',
            desc: '通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印'
          },
          {
            picture: '/picture/school/21.png',
            prePicture: '/picture/school/21.jpg',
            showImg: false,
            title: '骨科正骨',
            subtitle: '仿真手术环境，多种考核模式',
            desc: '根据实际教学需求对模型进行针对性优化，打造符合临床病理症状的，具有表皮、肌肉及骨骼层的仿真上肢模型'
          },
          {
            picture: '/picture/school/22.png',
            prePicture: '/picture/school/22.jpg',
            showImg: false,
            title: '齿科',
            subtitle: '仿真手术环境，多种考核模式',
            desc: '取代传统模具，通过3D打印机设备打印出的模型可提供学生在仿真教学区域内进行高还原度操作训练'
          },
        ]
      }]
    },
    {
      title: '公共卫生教学平台',
      blocks: [{
        typographical: ServerTypographical.MXFLEX,
        data: [{
            picture: '/picture/school/23.png',
            prePicture: '/picture/school/23.jpg',
            showImg: false,
            title: '疫情防护仿真培训平台',
            subtitle: '综合实事，适用广泛',
            desc: '响应国家号召，以目前新冠肺炎疫情为出发点，为加强普通群众和医护人员的防护意识、知识，增 强防护培训效果，快速地应用到现实工作中，减少感染率的发生，为目前打赢抗“疫”攻坚战、常态化 防疫提供帮助'
          },
          {
            picture: '/picture/school/24.png',
            prePicture: '/picture/school/24.jpg',
            showImg: false,
            title: '公共卫生事件模拟教学系统',
            subtitle: '智能语音系统，多模式选择',
            desc: '本系统根据预设的情景进行合理演绎，医护人员需作为专业人员对流调、卫生检测、消毒、临床诊疗等各角色进行模拟训练。考评系统根据整个过程中医护人员操作的正确性、时长等诸多因素进行评分，从而提高训练效率。'
          }
        ]
      }]
    },
    {
      title: '人体生命科学馆',
      blocks: [{
        typographical: ServerTypographical.VERT,
        data: [{
          picture: '/picture/school/25.png',
          prePicture: '/picture/school/25.jpg',
          showImg: false,
          title: '',
          subtitle: '',
          desc: '人体生命科学馆可以展示针对医学发展史、解剖学、药学等不同学科及科普设定的专业展馆，通过专题人体馆项可以体现众多药学、解剖学馆藏、医学发展史的学术价值以及从中体现出来的人性关怀。结合最新5G +XR技术，结合最新人工智能、大数据分析、元宇宙技术让更多观众了解生命与健康、医学学史，人文关怀，关爱生命、关爱他人和息息相关生态体系。'
        }]
      }]
    },
    {
      title: '远程教学直播系统',
      blocks: [{
        typographical: ServerTypographical.VERT,
        data: [{
          picture: '/picture/school/26.png',
          prePicture: '/picture/school/26.jpg',
          showImg: false,
          title: '',
          subtitle: '',
          desc: '在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。5G+VR探视可以有效避免医生和家属进入病房时遇到交叉感染的情况，增加感染隐患，减少医生和家属探视时所需使用防护用品和衣物，减少不必要资源损耗，减少医生和家属每次穿防护服或防护衣物时间，减少无效时间。'
        }]
      }]
    },
  ],
  swiperList: [{
    url: '/picture/school/banner.png',
    preUrl: '/picture/school/banner.jpg',
    show: false,
    title: '用科技打造特色医学教学',
    subtitle: '赋能本科、中高职特色医学教学'
  }],
  inPageNavigations: ['执业/助理医师规范化培训虚拟仿真教学平台', '基本手术虚拟仿真和VR教学平台', '最新MR虚拟融合教学平台', '养老护理康复与健康管理平台', '3D医学打印服务', '公共卫生教学平台', '人体生命科学馆', '远程教学直播系统']
};

export {
  SchoolData
};