<template>
  <div class="dataList">
    <!-- pc -->
    <div class="pc-datalist" v-if="!global.isPhone">
      <div class="dataItemBlock" v-for="(s, i) in data.itemList" :key="i">
        <div class="main-title" v-if="s.title" v-html="s.title"></div>
        <div class="block" v-for="(block, idx) in s.blocks" :key="idx" :ref="'block'">
          <div class="single-flex" v-if="block.typographical == ServerTypographical.SIGFLEX">
            <div class="server-item" v-for="(item, index) in block.data" :key="index"
              style="display:flex;justify-content: space-between;">
              <div class="img-container">
                <!-- <img :src="global.AssetsURL + item.prePicture" style="z-index: 1;"
                  :style="{ display: item.showImg ? 'none' : '' }">
                <img :src="global.AssetsURL + item.picture" style="z-index: 2;"
                  :style="{ display: item.showImg ? '' : 'none' }" @load="onDataListImgLoad(i, idx, index)"> -->
                <img :src="global.AssetsURL + item.prePicture" style="z-index: 2;">
              </div>
              <div class="text-area">
                <div class="title"
                  :style="{ fontSize: s.title ? '0.36rem' : '', marginBottom: item.subtitle ? '0.07rem' : '' }">{{
                    item.title
                  }}</div>
                <div class="subtitle" v-if="item.subtitle">{{ item.subtitle }}</div>
                <div class="desc">{{ item.desc }}</div>
                <div class="more" @click="showMoreInfo(i, idx, index)">
                  <img src="../assets/right-blue-arrow.png" class="ico">
                  了解更多
                </div>
              </div>
            </div>
          </div>
          <div class="multiple-flex" v-if="block.typographical == ServerTypographical.MXFLEX">
            <div class="server-item" v-for="(item, index) in block.data" :key="index"
              :style="{ 'width': block.data.length == 3 ? '4.52rem' : '6.99rem' }">
              <div class="img-container" :style="{ 'height': block.data.length == 3 ? '' : '4.2rem' }">
                <!-- <img :src="global.AssetsURL + item.prePicture" style="z-index: 1;"
                  :style="{ display: item.showImg ? 'none' : '' }">
                <img :src="global.AssetsURL + item.picture" style="z-index: 2;"
                  :style="{ display: item.showImg ? '' : 'none' }" @load="onDataListImgLoad(i, idx, index)"> -->
                <img :src="global.AssetsURL + item.prePicture" style="z-index: 2;">
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="subtitle">{{ item.subtitle }}</div>
              <div class="desc">{{ item.desc }}</div>
              <div class="more" @click="showMoreInfo(i, idx, index)">
                <img src="../assets/right-blue-arrow.png" class="ico">
                了解更多
              </div>
            </div>
          </div>
          <div class="vertical" v-if="block.typographical == ServerTypographical.VERT">
            <div class="server-item" v-for="(item, index) in block.data" :key="index">
              <div class="text-area">
                <div class="desc">{{ item.desc }}</div>
                <div class="more" @click="showMoreInfo(i, idx, index)">
                  <img src="../assets/right-blue-arrow.png" class="ico">
                  了解更多
                </div>
              </div>
              <div class="img-container">
                <!-- <img :src="global.AssetsURL + item.prePicture" style="z-index: 1;"
                  :style="{ display: item.showImg ? 'none' : '' }">
                <img :src="global.AssetsURL + item.picture" style="z-index: 2;"
                  :style="{ display: item.showImg ? '' : 'none' }" @load="onDataListImgLoad(i, idx, index)"> -->
                <img :src="global.AssetsURL + item.prePicture" style="z-index: 2;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="phone-datalist" v-if="global.isPhone">
      <div class="phone-data-item" v-for="(s, i) in data.itemList" :key="i">
        <div class="phone-data-title" v-if="s.title || s.phoneTitle" v-html="s.phoneTitle ? s.phoneTitle : s.title"></div>
        <div class="phone-data-block" v-for="(block, idx) in s.blocks" :key="idx">
          <div class="phone-block" v-for="(item, index) in block.data" :key="index" :ref="'phoneBlock'">
            <div class="phone-img-container">
              <img :src="global.AssetsURL + item.prePicture"
                :class="item.mode == 'height-fix' ? 'height-fix-img' : 'width-fix-img'" style="z-index: 2;">
            </div>
            <div class="phone-block-title" v-if="!(!s.title && s.phoneTitle)">{{ item.title }}</div>
            <div class="phone-block-subtitle">{{ item.subtitle }}</div>
            <div class="phone-block-desc">{{ item.desc }}</div>
            <div class="phone-show-more" @click="showMoreInfo(0, 0, 0)">
              <img src="../assets/right-blue-arrow.png" style="width:0.05rem ;height: 0.09rem;margin-right: 0.08rem;">
              了解更多
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ServerTypographical } from '../../public/data/define.js';

export default {
  name: "DataList",
  props: {
    data: {
      type: Object,
    }
  },
  data() {
    return {
      dataIndexMap: new Map(),
      phoneDataIndexMap: new Map(),
      ServerTypographical: ServerTypographical
    };
  },
  created() {
    // 平台分类选择标签
    if (this.global.isPhone) {
      // 移动端
      for (let i = 0; i < this.data.items.length; i++) {
        if (this.data.items[i].class) {
          this.phoneDataIndexMap.set(i, 0);
        }
      }
    } else {
      // pc端
      for (let i = 0; i < this.data.items.length; i++) {
        if (this.data.items[i].class) {
          this.dataIndexMap.set(i, 0);
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.gotoTargetBlock();
    })
  },
  methods: {
    getDataIndex(i) {
      return this.dataIndexMap.get(i);
    },
    getPhoneDataIndex(i) {
      return this.phoneDataIndexMap.get(i);
    },
    // 标题类别点击
    itemClassClickFn(index, tar) {
      this.dataIndexMap.set(index, tar);
      this.$forceUpdate();
    },
    phoneClassTouch(index, tar) {
      console.log(this.data.items[index].data[this.getPhoneDataIndex(index)].icon);
      this.phoneDataIndexMap.set(index, tar);
      this.$forceUpdate();
    },
    // 详情页
    // itemMoreClickFn(data, title) {
    //   if (!data) return;
    //   window.scrollTo(0, 0);
    //   this.$emit("showDataItemInfo", data, title);
    // },
    // 详情页暂时不开放，改为显示信息收集页
    itemMoreClickFn(data) {
      if (!data) return
      this.$emit('showIntentionForm');
    },
    showDataInfo(data, title) {
      if (!data) return;
      console.log(data, title);
      //  显示意向收集表
      this.$emit('showIntentionForm');
      // 显示二级页面
      // window.scrollTo(0, 0);
      // this.$emit("showDataItemInfo", data, title);
    },
    showMoreInfo(i, idx, index) {
      console.log(i, idx, index);
      // this.global.titleIndex = 6;
      this.global.titleIndex = 8;
      this.$router.push({
        name: 'Contact'
      });
    },
    gotoTargetBlock() {
      const index = this.$route.params.block;
      if (index > -1) {
        const block = this.global.isPhone ? this.$refs['phoneBlock'][index] : this.$refs['block'][index];
        const top = block.getBoundingClientRect().top;
        window.scrollTo(0, top - 250);
      }
    },

    onDataListImgLoad(i, idx, index) {
      const param = { i, idx, index };
      this.$emit('dataListImageLoad', param);
    }
  },
};
</script>

<style scoped>
.dataList {
  width: 100%;
  background-color: #ffffff;
}

.pc-datalist {
  position: relative;
  width: 14.4rem;
  max-width: 1440px;
  margin: 0 auto;
}

.dataItemBlock {
  width: 100%;
  margin-top: 0.9rem;
  margin-bottom: 1.9rem;
}

.block {
  width: 100%;
  margin-bottom: .97rem;
}

.single-flex,
.multiple-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vertical {
  width: 100%;
}

.server-item {
  flex-shrink: 0;
}

.img-container {
  position: relative;
}

.img-container img {
  width: 100%;
  height: 100%;
}

.main-title {
  font-size: .48rem;
  font-weight: bold;
  margin-bottom: .74rem;
  line-height: .6rem;
}

.multiple-flex .img-container {
  width: 100%;
  height: 2.64rem;
  margin-bottom: .15rem;
}

.multiple-flex .title {
  font-size: .24rem;
  font-weight: 600;
  margin-bottom: .07rem;
}

.multiple-flex .subtitle {
  font-size: .24rem;
  font-weight: 200;
  color: #32373E;
  margin-bottom: .24rem;
}

.multiple-flex .desc {
  font-size: .18rem;
  font-weight: 100;
  color: #626E7E;
  margin-bottom: .33rem;
  line-height: .28rem;
}

.more {
  display: flex;
  align-items: center;
  font-size: .18rem;
  font-weight: 200;
  color: #0073E6;
  cursor: pointer;
  user-select: none;
}

.ico {
  width: .06rem !important;
  height: .11rem !important;
  margin: 0 !important;
  margin-right: .08rem !important;
}

.single-flex .img-container {
  width: 8.32rem;
  height: 5.34rem;
  margin-right: .42rem;
}

.single-flex .text-area {
  width: calc(14.4rem - 8.74rem);
  height: 100%;
}

.single-flex .title {
  font-size: .48rem;
  font-weight: 600;
  margin-bottom: .61rem;
}

.single-flex .subtitle {
  font-size: .24rem;
  font-weight: 200;
  color: #32373E;
  margin-bottom: .24rem;
}

.single-flex .desc {
  width: 100%;
  font-size: .18rem;
  font-weight: 100;
  color: #626E7E;
  line-height: .28rem;
  margin-bottom: .33rem;
}

.vertical .text-area {
  display: flex;
  align-items: flex-start;
  margin-bottom: .34rem;
}

.vertical .desc {
  width: 10.6rem;
  font-size: .18rem;
  font-weight: 100;
  color: #626E7E;
  line-height: .32rem;
  margin-right: .42rem;
}

.vertical .img-container {
  width: 100%;
  height: 7.82rem;
}

.itemTitle {
  position: relative;
  font-size: 0.36rem;
  color: #333333;
  font-weight: 600;
}

.itemClassBlock {
  position: relative;
  display: flex;
  font-size: 0.2rem;
  color: #666666;
  margin-top: 0.15rem;
}

.itemClassTxt {
  padding: 0.1rem 0.2rem;
  margin-right: 0.16rem;
  cursor: pointer;
  font-family: "Regular";
}

.itemClassTxt:hover {
  background-color: #f0f4f8;
}

.itemClassBlueTxt {
  padding: 0.1rem 0.2rem;
  margin-right: 0.16rem;
  cursor: pointer;
  color: #005dff;
  background-color: #eff4ff;
  font-family: "Medium";
}

.itemContent {
  position: relative;
  height: fit-content;
  margin-top: 0.3rem;
  height: 3.36rem;
}

.itemImg {
  position: relative;
  height: 100%;
  width: 40%;
  border-radius: 12px;
  box-shadow: 0px 12px 24px rgba(0, 43, 87, 0.08);
  background-color: #000000;
  overflow: hidden;
}

.itemInfoBlock {
  position: absolute;
  height: 100%;
  width: 55%;
  /* bottom: 0; */
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  font-family: "Regular";
  font-weight: 400;
}

.itemTxt {
  position: relative;
  font-size: 0.2rem;
  color: #333333;
  width: 100%;
  display: -webkit-box;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
  line-height: 0.36rem;
}

.aboutMore {
  position: relative;
  text-align: right;
  width: 100%;
  font-size: 0.2rem;
  color: #005dff;
  cursor: pointer;
  /* margin-top: 1rem; */
  padding-right: 0.11rem;
  box-sizing: border-box;
}

.right-arrow {
  position: absolute;
  top: 0.085rem;
  right: 0;
  width: 0.05rem;
  height: 0.1rem;
}

.itemVideo {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.iconArea {
  position: absolute;
  font-size: 0.15rem;
  bottom: 0;
  display: flex;
  color: #333333;
}

.iconItem {
  position: relative;
  margin-right: 0.64rem;
  flex-shrink: 0;
}

.iconItem img {
  position: relative;
  height: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
}

.phone-datalist {
  margin-top: 0.74rem;
  width: 100%;
  padding: 0 0.18rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.phone-data-item {
  width: 100%;
  margin-bottom: 0.14rem;
}

.phone-data-title {
  font-size: 0.28rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

.phone-data-block {
  width: 100%;
}

.phone-block {
  width: 100%;
  margin-bottom: 0.58rem;
}

.phone-img-container {
  width: 100%;
  height: 2.04rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.14rem;
}

.width-fix-img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.height-fix-img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.phone-block-title {
  font-size: 0.21rem;
  font-weight: bold;
  margin-bottom: 0.1rem;

}

.phone-block-subtitle {
  width: 100%;
  font-size: 0.21rem;
  color: #32373E;
  line-height: 0.24rem;
  margin-bottom: 0.12rem;
}

.phone-block-desc {
  width: 100%;
  color: #777A81;
  font-size: 0.16rem;
  line-height: 0.27rem;
  margin-bottom: 0.25rem;
}

.phone-show-more {
  display: flex;
  color: #0073E6;
  font-size: 0.16rem;
  align-items: center;
}
</style>
