import Vue from 'vue'
import App from './App.vue'
import router from './router'
import global from './common/Global'
import AmapVue from 'vue-amap'

Vue.use(AmapVue)
AmapVue.initAMapApiLoader({
    key: '',
    plugin: ['AMap.Marker'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
});

Vue.prototype.global = global
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')


const widthLimit = document.documentElement.clientWidth < 750 ? 350 : 1200;
const htmlMaxWidth = document.documentElement.clientWidth < 750 ? 750 : 1920;

const setHtmlFontSize = () => { // PC web:1rem = 100px
    const htmlDom = document.getElementsByTagName('html')[0];
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;

    if (htmlWidth <= widthLimit) {
        htmlWidth = widthLimit;
    } else if (htmlWidth >= htmlMaxWidth) {
        htmlWidth = htmlMaxWidth;
    }
    // htmlDom.style.fontSize = `${htmlWidth / 19.2}px`;
    
    if(htmlWidth > 750){
        htmlDom.style.fontSize = `${htmlWidth / 19.2}px`;
    }else{
        htmlDom.style.fontSize = `${htmlWidth / 3.75}px`;
    }
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();