<template>
    <div id="reward">
        <div class="head-menu">
            <img class="page-setter-minus" src="../assets/arrow-right-grey.png" @click="setCurrentPage(-1)" />
            <div class="years">
                <div class="container">
                    <div class="year" v-for="(reward, index) in rewardData" :key="index" :style="{
                        'borderBottom': index == activeContentIndex ? '4px solid #141E8C' : '', 'transform': `translateX(${getTranslate()})`
                    }" @click="setActiveContentIndex(index)">{{ reward.time }}</div>
                </div>
            </div>
            <img class="page-setter-add" src="../assets/arrow-right-grey.png" @click="setCurrentPage(1)" />
        </div>
        <div class="rewards">
            <div class="reward-item" v-for="(c, i) in rewardData[activeContentIndex].content" :key="i">{{ c }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PhoneSwiperReaward',
    props: {
        rewardData: {
            type: Array
        }
    },
    data() {
        return {
            currentPage: 0,
            activeContentIndex: 0,
        }
    },
    methods: {
        setActiveContentIndex(index) {
            this.activeContentIndex = index;
        },

        setCurrentPage(increment) {
            if (this.currentPage + increment < 0 || this.currentPage + increment > ((Math.ceil(this.rewardData.length / 4)) - 1)) return
            this.currentPage += increment;
        },

        getTranslate() {
            if (this.currentPage == 0) {
                return `0`;
            } else {
                return `calc(-${5 * this.currentPage}00% - 0.19rem)`;
            }
        }
    }
}
</script>

<style scoped>
#reward {
    width: 100%;
    height: 6.12rem;
    padding: 0 0.18rem;
    box-sizing: border-box;
}

.head-menu {
    width: 100%;
    height: 0.54rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #fff 0%, #707070 25%, #707070 75%, #fff 100%) 1;
    box-sizing: border-box;
    margin-bottom: 0.99rem;
}

.page-setter-minus {
    width: 0.08rem;
    height: 0.16rem;
    transform: rotate(180deg);
}

.page-setter-add {
    width: 0.08rem;
    height: 0.16rem;
}

.years {
    width: calc(100% - 0.74rem);
    overflow-x: hidden;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-y: visible;
}

.year {
    box-sizing: border-box;
    flex-shrink: 0;
    height: calc(0.53rem + 4px);
    line-height: 0.53rem;
    width: 0.5rem;
    margin-right: 0.19rem;
    font-size: 0.16rem;
    font-weight: 400;
    white-space: nowrap;
    transition: transform 0.3s linear;
}

.rewards {
    width: 100%;
    height: 5.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reward-item {
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    margin-bottom: 0.24rem;
    line-height: 0.24rem;
    color: #32373E;
}
</style>