var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PartnerAllScreem"},[(!_vm.global.isPhone)?_c('div',{staticClass:"pc-partner"},[_c('Header',{on:{"headerListClickFn":_vm.scrollMoveFn}}),_vm._m(0),_c('Middle'),_c('div',{staticClass:"partnerArea",staticStyle:{"margin-top":".93rem"}},[_c('div',{staticClass:"title"},[_vm._v("公司合作伙伴")]),_c('div',{staticClass:"viceTitle"},[_vm._v("排名不分先后")]),_c('div',{staticClass:"grid"},_vm._l((_vm.data.partner),function(item,index){return _c('div',{key:index,class:{
            item6: index == 2,
            item4: index == 4,
            item5: index == 10,
          }},[_c('img',{staticClass:"partnerIrem",attrs:{"src":_vm.global.AssetsURL + item.url}})])}),0)]),_c('div',{staticClass:"partnerArea",staticStyle:{"margin-top":"0.74rem"}},[_c('div',{staticClass:"title"},[_vm._v("公司客户")]),_c('div',{staticClass:"viceTitle"},[_vm._v("排名不分先后")]),_c('div',{staticClass:"grid"},_vm._l((_vm.data.custmer[0]),function(item,index){return _c('div',{key:index,class:{
            item5: index == 0,
            item4: index == 8,
            item6: index == 16,
          }},[_c('img',{staticClass:"partnerIrem",attrs:{"src":_vm.global.AssetsURL + item.url}})])}),0),_c('div',{staticClass:"grid"},_vm._l((_vm.data.custmer[1]),function(item,index){return _c('div',{key:index,class:{
            item5: index == 0,
          }},[_c('img',{staticClass:"partnerIrem",attrs:{"src":_vm.global.AssetsURL + item.url}})])}),0),_c('div',{staticClass:"grid"},_vm._l((_vm.data.custmer[2]),function(item,index){return _c('div',{key:index,class:{
            item5: index == 0 || index == 10,
            item6: index == 5 || index == 15,
          }},[_c('img',{staticClass:"partnerIrem",attrs:{"src":_vm.global.AssetsURL + item.url}})])}),0)]),_c('Footer')],1):_vm._e(),(_vm.global.isPhone)?_c('div',{staticClass:"phone-partner"},[_c('Header'),_c('img',{staticClass:"phone-partner-banner",attrs:{"src":require("../../public/data/picture/partner/ps1.jpg")}}),_vm._m(1),_vm._m(2),_c('Footer')],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiperArea"},[_c('img',{staticClass:"banner",attrs:{"src":require("../../public/data/picture/partner/s1.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phone-partner-area"},[_c('div',{staticClass:"phone-partner-title"},[_c('div',{staticClass:"phone-title"},[_vm._v("公司合作伙伴")]),_c('div',{staticClass:"phone-title2"},[_vm._v("排名不分先后")])]),_c('img',{attrs:{"src":require("../assets/partner/1.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phone-partner-area"},[_c('div',{staticClass:"phone-partner-title"},[_c('div',{staticClass:"phone-title"},[_vm._v("公司客户")]),_c('div',{staticClass:"phone-title2"},[_vm._v("排名不分先后")])]),_c('img',{attrs:{"src":require("../assets/partner/2.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/partner/3.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/partner/4.jpg"),"alt":""}})])}]

export { render, staticRenderFns }