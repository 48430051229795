<template>
  <div>
    <Header />
  </div>
</template>

<script>
  import Header from "../components/Header.vue";

  export default {
    name: "Digital",
    components: {
      Header
    },
    data() {
      return {};
    },
    created: function () {
      this.global.titleIndex = 4;
    }
  };
</script>

<style scoped>
</style>