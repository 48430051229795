<template>
    <div id="contact">
        <Header />
        <div class="input-panel" v-if="!global.isPhone">
            <img :src="global.AssetsURL + '/picture/contact/bg.png'" class="bg">
            <div class="panel" v-if="!submited">
                <div class="panel-title">联系我们</div>
                <div class="panel-subtitle">为了更好的了解到您的需求，请您认真填写以下问题</div>
                <div class="input-comp" style="height:.52rem;top:2.97rem;align-items: center;">
                    <div class="label">姓名<span>*</span>：</div>
                    <input type="text" placeholder="请输入姓名" v-model.trim="pushInfo.name">
                </div>
                <div class="input-comp" style="height:.52rem;top:3.69rem;align-items: center;">
                    <div class="label">手机<span>*</span>：</div>
                    <input type="text" placeholder="请输入11位手机号" v-model.trim="pushInfo.phoneNumber">
                </div>
                <div class="input-comp" style="height:1.2rem;top:4.33rem;">
                    <div class="label" style="margin-top:.14rem">需求<span>*</span>：</div>
                    <textarea placeholder="请告知我们您的需求" v-model.trim="pushInfo.message"></textarea>
                </div>
                <div class="confirm" @click="submit">确定</div>
            </div>
            <div class="submited" v-if="submited">
                <img src="../assets/commited.png" class="success-ico">
                <div class="main-title">感谢您的填写</div>
                <div class="subtitle">请您静候我们的联系~</div>
                <div class="continue" @click="back">继续浏览</div>
            </div>
        </div>
        <div class="phone-input-panel" v-if="global.isPhone">
            <img :src="global.AssetsURL + '/picture/contact/phone-contact-bg.jpg'" class="phone-bg">
            <div class="phone-panel" v-if="!submited">
                <div class="phone-panel-title">联系我们</div>
                <div class="phone-panel-subtitle">为了更好的了解到您的需求，请您认真填写以下问题</div>
                <div class="phone-input-comp" style="margin-bottom: 0.16rem;">
                    <div class="phone-label">姓名<span>*</span>：</div>
                    <input type="text" placeholder="请输入姓名" v-model.trim="pushInfo.name">
                </div>
                <div class="phone-input-comp" style="margin-bottom: 0.16rem;">
                    <div class="phone-label">手机<span>*</span>：</div>
                    <input type="text" placeholder="请输入11位手机号" v-model.trim="pushInfo.phoneNumber">
                </div>
                <div class="phone-input-comp">
                    <div class="phone-label">需求<span>*</span>：</div>
                    <textarea placeholder="请告知我们您的需求" v-model.trim="pushInfo.message"></textarea>
                </div>
                <div class="phone-submit" @click="submit">确定</div>
            </div>
            <div class="phone-submited" v-if="submited">
                <img src="../assets/commited.png" class="phone-success-ico">
                <div class="phone-main-title">感谢您的填写</div>
                <div class="phone-subtitle">请您静候我们的联系~</div>
                <div class="phone-continue" @click="back">继续浏览</div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            submited: false,
            pushInfo: {
                name: '',
                phoneNumber: '',
                message: '',
                email: ''
            }
        }
    },
    methods: {
        async submit() {
            if (this.formCheck()) {
                const result = await this.global.contactUsFn(JSON.parse(JSON.stringify(this.pushInfo)));
                if (result && !this.global.isPhone) {
                    this.submited = true;
                }
            } else {
                alert('请按要求填写要提交的数据');
            }

        },

        formCheck() {
            if (this.pushInfo.name == '' || this.pushInfo.phoneNumber == '' || this.pushInfo.message == '') {
                return false;
            }

            if (this.pushInfo.phoneNumber.length != 11) {
                return false;
            }

            return true;
        },

        back() {
            this.$router.back();
        }
    }
}

</script>
<style scoped>
#contact {
    width: 100%;
}

.input-panel {
    width: 100%;
    height: 7.38rem;
    position: relative;
    margin-top: .8rem;
}

.bg {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.panel {
    margin: 0 auto;
    width: 14.4rem;
    max-width: 1440px;
    height: 100%;
    position: relative;
}

.panel-title {
    font-size: .48rem;
    font-weight: 600;
    position: absolute;
    top: .55rem;
    right: 4.32rem;
}

.panel-subtitle {
    position: absolute;
    top: 1.29rem;
    right: 0;
    width: 6.24rem;
    font-size: .48rem;
    font-weight: 300;
    color: #32373E;
    line-height: .6rem;
}

.input-comp {
    display: flex;
    font-size: .18rem;
    color: #32373E;
    font-weight: 300;
    right: 1.63rem;
    position: absolute;
    width: 4.61rem;
}

.input-comp input {
    width: 4rem;
    border: 1px solid #C3CBDC;
    height: .52rem;
    line-height: .52rem;
    padding-left: .16rem;
    box-sizing: border-box;
}

.input-comp input::placeholder,
.input-comp textarea::placeholder {
    color: #DFE4EE;
}

.input-comp textarea {
    width: 4rem;
    border: 1px solid #C3CBDC;
    padding: .14rem 0 0 .16rem;
    box-sizing: border-box;
    line-height: .24rem;
    resize: none;
}

.input-comp input:focus,
.input-comp textarea:focus {
    outline: none;
}

.label {
    display: flex;
    white-space: nowrap;
}

.label span {
    color: #FF0000;
}

.confirm {
    width: 1.6rem;
    height: .6rem;
    text-align: center;
    line-height: .6rem;
    color: #fff;
    background-color: #0073E6;
    font-size: .24rem;
    position: absolute;
    bottom: .65rem;
    right: 4.03rem;
    cursor: pointer;
    user-select: none;
}

.submited {
    margin: 0 auto;
    width: 14.4rem;
    max-width: 1440px;
    height: 100%;
    position: relative;
}

.success-ico {
    width: .8rem;
    height: .8rem;
    position: absolute;
    top: 1.85rem;
    right: 5.44rem;
}

.main-title {
    font-size: .48rem;
    font-weight: 600;
    position: absolute;
    top: 2.91rem;
    left: 8.16rem;
}

.subtitle {
    font-size: .48rem;
    font-weight: 300;
    position: absolute;
    top: 3.65rem;
    left: 8.16rem;
}

.continue {
    width: 1.6rem;
    height: .6rem;
    text-align: center;
    line-height: .6rem;
    color: #fff;
    background-color: #0073E6;
    font-size: .24rem;
    font-weight: 300;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 5.08rem;
    left: 8.24rem;
}

.phone-input-panel {
    width: 100%;
    height: 6.28rem;
    position: relative;
    margin-bottom: 1.6rem;
}

.phone-bg {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.phone-panel {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0.8rem 0.18rem;
    box-sizing: border-box;
}

.phone-panel-title {
    font-size: 0.28rem;
    font-weight: bold;
    margin-bottom: 0.07rem;
}

.phone-panel-subtitle {
    font-size: 0.28rem;
    margin-bottom: 0.37rem;
    line-height: 0.37rem;
}

.phone-input-comp {
    display: flex;
    justify-content: space-between;
}

.phone-input-comp input {
    width: 2.83rem;
    height: 0.38rem;
    line-height: 0.38rem;
    border: 1px solid #C3CBDC;
    box-sizing: border-box;
    padding-left: 0.13rem;
}

.phone-input-comp textarea {
    width: 2.83rem;
    height: 1.16rem;
    line-height: 0.21rem;
    border: 1px solid #C3CBDC;
    box-sizing: border-box;
    padding: 0.08rem 0.13rem;
    resize: none;
    margin-bottom: 0.47rem;
}

.phone-input-comp input::placeholder,
.phone-input-comp textarea::placeholder {
    color: #777A81;
}

.phone-input-comp input:focus,
.phone-input-comp textarea:focus {
    outline: none;
}

.phone-label {
    display: flex;
    align-items: center;
    height: 0.38rem;
    font-size: 0.16rem;
    white-space: nowrap;
}

.phone-label span {
    color: #FF0000;
}

.phone-submit {
    width: 1.57rem;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    font-size: 0.16rem;
    color: #fff;
    background-color: #0073E6;
    margin: 0 auto;
}

.phone-submited {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem 0.18rem;
    box-sizing: border-box;
}

.phone-success-ico {
    width: 0.87rem;
    height: 0.87rem;
    margin-bottom: 0.33rem;
}

.phone-main-title {
    font-weight: bold;
    font-size: 0.28rem;
    margin-bottom: 0.07rem;
}

.phone-subtitle {
    font-size: 0.28rem;
    margin-bottom: 0.51rem;
}

.phone-continue {
    width: 1.57rem;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    font-size: 0.16rem;
    color: #fff;
    background-color: #0073E6;
}
</style>

