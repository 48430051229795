const Intentions = [
    {
        title: '执业/助理医师规范化培训虚拟仿真教学平台',
        options: ['缝合术', '三腔二囊管', '导尿术', '胸腔穿刺术', '腰椎穿刺术', '心肺复苏', '气管插管']
    }, 
    {
        title: '基础手术虚拟仿真和VR教学平台',
        options: ['包皮环切术', '剖宫产', '甲状腺次全切术', '阑尾切除术', '体表肿物切除', '围术期压疮管理']
    },
    {
        title: '专科综合教学平台',
        options: ['心血管综合教学平台', '超声综合教学平台', '神经外科综合教学平台']
    },
    {
        title: '专科单病种教学内容平台',
        options: ['甲状腺', '乳腺癌']
    },
    {
        title: '3D医学打印服务',
        options: ['医学教学模拟器', '临床病理打印', '定制化医学临床教具', '骨科正骨', '齿科']
    },
    {
        title: '护理/养老康复教学平台',
        options: ['护理', '养老']
    },
    {
        title: '',
        options: ['远程/手术/查房直播教学系统']
    },
    {
        title: '',
        options: ['全景/高清/立体手术直录播服务']
    },
    {
        title: '',
        options: ['临床与教学科研支持']
    },
];

export {
    Intentions
};