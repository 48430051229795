<template>
  <div class="SimulationAllScreem">
    <!-- pc端 -->
    <div class="pc-sim" v-if="!global.isPhone">
      <Header :styleChangeIndex="headerStyle" @headerListClickFn="scrollMoveFn" />
      <div v-if="!dataItemInfo.title" class="medivalSwiper">
        <!-- <img src="../assets/picture/Medical/banner.png" class="swiperBG" />
        <img src="../assets/picture/04Simulation/banner.png" class="banner"> -->
        <!-- <Swiper :swiperStyle="false" :imgList="data.swiperImgData" :contentList="data.siwperContentList"
          @swiperIndexFn="swiperIndexFn" @checkMoreFn="scrollMoveFn" /> -->
        <Swiper :imgList="data.swiperList" />
      </div>

      <Middle v-if="!dataItemInfo.title" />

      <div class="pageItemList">
        <img class="previous" @click="scrollPageItemList(-1)" src="../assets/to-left-hover.png" />
        <div class="page-item-container" :style="{ 'transform': `translateX(-${pageItemIndex * 14.4}rem)` }">
          <div @click="scrollMoveFn(1)">
            执业/助理医师规范化培训虚拟仿真教学平台
          </div>
          <div @click="scrollMoveFn(2)">基本手术虚拟仿真和VR教学平台</div>
          <div @click="scrollMoveFn(5)">最新MR虚拟融合教学平台</div>
          <div @click="scrollMoveFn(4)">养老护理康复与健康管理平台</div>
          <div @click="scrollMoveFn(3)">3D医学打印服务</div>
          <div @click="scrollMoveFn(6)" style="margin-right: 0.6rem;">公共卫生教学平台</div>
          <div @click="scrollMoveFn(7)">人体生命科学馆</div>
          <div @click="scrollMoveFn(8)">远程教学直播系统</div>
        </div>
        <img class="next" @click="scrollPageItemList(1)" src="../assets/to-right-hover.png" v-if="pageItemIndex == 0" />
      </div>

      <DataList v-show="!dataItemInfo.title" :data="data" @showDataItemInfo="showDataItemInfo"
        @showIntentionForm="showIntentionForm" @dataListImageLoad="onDataListImgLoad($event)" />

      <DataInfo v-if="dataItemInfo.title" :data="dataItemInfo" @clearDataItemInfo="clearDataItemInfo" />

      <Footer style="margin-top:120px" @scrollMoveFn="scrollMoveFn" />
    </div>
    <!-- 移动端 -->
    <div class="phone-sim" v-if="global.isPhone">
      <div class="phone-sim-body" v-if="!dataItemInfo.title">
        <Header></Header>
        <div class="phone-banner">
          <Swiper :imgList="data.swiperList" />
        </div>
        <InPageNavigator :data="inPageNavigations" @inPageNavigation="inPageNavigation" />
        <DataList :data="data" @showDataItemInfo="showDataItemInfo" @showIntentionForm="showIntentionForm" />
      </div>
      <PhoneDataInfo v-if="dataItemInfo.title" :data="dataItemInfo" @clearDataItemInfo="clearDataItemInfo">
      </PhoneDataInfo>
      <Footer @phoneScrollMoveFn="phoneScrollMoveFn" />
    </div>

  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Middle from "../components/Middle.vue";
import Footer from "../components/Footer.vue";
import Swiper from "../components/Swiper.vue";

import DataList from "../components/DataList.vue";
import DataInfo from "../components/DataInfo.vue";
import PhoneDataInfo from "./PhoneDataInfo.vue";
import InPageNavigator from "../components/InPageNavigator.vue";

import { SchoolData } from "../../public/data/School";

export default {
  name: "SimulationAllScreem",
  components: {
    Header,
    Footer,
    Middle,
    DataList,
    DataInfo,
    PhoneDataInfo,
    Swiper,
    InPageNavigator
  },
  data() {
    return {
      headerStyle: false,
      isPhone: false,
      data: SchoolData,
      dataItemInfo: {
        info: null,
        title: null,
      },
      showVideo: true,
      pageItemIndex: 0,
      inPageNavigations: SchoolData.inPageNavigations
    };
  },
  created: function () {
    this.global.titleIndex = 5;

    //如果有跳转index，屏幕滚动
    if (!isNaN(this.$route.params.y)) {
      setTimeout(() => {
        if (this.global.isPhone) {
          const correction = this.$route.params.correction ? this.$route.params.correction : 0;
          this.phoneScrollMoveFn(this.$route.params.y, correction);
        } else {
          this.scrollMoveFn(this.$route.params.y);
        }
      }, 100);
    }
    this.isPhone = this.global.isPhone;
  },
  methods: {
    swiperIndexFn() {
      // console.log(index);
    },
    scrollMoveFn(index) {
      // console.log(index);
      let dy = 0;
      let block = document.getElementsByClassName("dataItemBlock")[index - 1];
      if (index > 0) {
        dy = block.getBoundingClientRect().top;
      }
      let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      window.scrollTo(0, dy + scrollTop - 250);
    },
    phoneScrollMoveFn(index, correction = 0) {
      const ratio = window.innerWidth / 375;
      const headHeight = 130 * ratio;
      const block = document.getElementsByClassName("phone-data-item")[index];
      const dy = block.getBoundingClientRect().top;
      const scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      let dis = dy + scrollTop - headHeight;
      if (correction != 0) dis += correction;
      window.scrollTo(0, dis);
    },
    showDataItemInfo(data, title) {
      [this.dataItemInfo.info, this.dataItemInfo.title] = [data, title];
    },
    clearDataItemInfo() {
      this.dataItemInfo.info = null;
      this.dataItemInfo.title = null;
    },
    showIntentionForm() {
      this.$emit('showIntentionForm');
    },

    onDataListImgLoad(event) {
      const { i, idx, index } = event;
      this.data.itemList[i].blocks[idx].data[index].showImg = true;
    },

    scrollPageItemList(increment) {
      if (increment == 1 && this.pageItemIndex == 1) return
      if (increment == -1 && this.pageItemIndex == 0) {
        this.pageItemIndex -= increment;
      } else {
        this.pageItemIndex += increment;
      }
    },

    inPageNavigation(event) {
      const { index, correction } = event;

      this.phoneScrollMoveFn(index, correction);
    },
  },
};
</script>

<style scoped>
@import "../common/font/font.css";

.MedicalALlScreem {
  position: relative;
  width: 100%;
}

.medivalSwiper {
  height: 8.62rem;
  width: 100%;
}

.swiperBG {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.banner {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.pageItemList {
  position: relative;
  height: .36rem;
  font-size: .16rem;
  font-family: "regular";
  color: #32373E;
  background-color: #ffffff;
  width: 14.4rem;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: .44rem;
  padding-left: .2rem;
  box-sizing: border-box;
  border-bottom: 1px solid #DFE4EE;
  overflow: auto;
}

.page-item-container {
  display: flex;
  height: 100%;
  transition: all 0.5s linear;
}

.page-item-container div {
  margin-right: .4rem;
  cursor: pointer;
  box-sizing: border-box;
  flex-shrink: 0;
}

.page-item-container div:hover {
  color: #005dff;
  border-bottom: 4px solid #005dff;
}

.previous {
  position: absolute;
  left: 0;
  top: .02rem;
  cursor: pointer;
}

.next {
  position: absolute;
  right: 0;
  top: .02rem;
  cursor: pointer;
}

/* 移动端 */
.phone-sim {
  width: 100%;
}

.phone-sim-body {
  width: 100%;
}

.phone-banner {
  width: 100%;
}
</style>
