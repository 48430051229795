const info = [{
        title: "内容为心血管，标题和介绍待定",
        content: "运用虚拟现实技术和计算机多媒体技术模拟CTO介入手术的有影像数据，从而辅助临床医师进行CTO手术的术前规划。操作者通过头显设备观看临床病例的影像数据重建的心脏及心血管模型，结合手势和辅助手套，完成对虚拟模型的观察、测量及标记，还能再预置的器械列表中选用虚拟的导丝导管等介入设备，在虚拟心脏中进行模拟介入操作，在实际手术操作前做好充分的准备。",
        select: [{ title: "临床病例", content: "" },
            { title: "针对性提取", content: "" },
            { title: "模拟功能", content: "" },
            { title: "预置器械", content: "" },
            { title: "沉浸式体验", content: "" },
            { title: "云端病例库", content: "" }
        ],
        imgPositon: "right",
        imgSize: [950, 746],
        imgUrl: require("../assets/picture/Medical/心血管.png")
    },
    {
        title: "VR/MR临床超声仿真模拟系统",
        content: "三维体数据、VR/MR穿戴设备，超声探头等物联网技术，结合临床医用假人及定位模拟器，进行超声检查模拟操作，导入真实临床病例数据，帮助临床年轻医师和医学院学生，进行不同临床病例、不同体位的超声模拟检查及读片的练习。有便携版和专业版，满足不同场合的教学需求。",
        select: [{ title: "真实病例", content: "" },
            { title: "多器官数据", content: "" },
            { title: "真实感", content: "" },
            { title: "专业背书", content: "" },
            { title: "多场合学习", content: "" },
            { title: "保障数据安全", content: "" }
        ],
        imgPositon: "left",
        imgSize: [950, 746],
        imgUrl: require("../assets/picture/Medical/VRMR.png")
    },
    {
        title: "PC/VR神经外科医学图像处理平台",
        content: "神经外科医学图像处理平台将患者影像数据进行三维重建，医生通过PC、VR及移动设备更加立体、直观地看到人体结构，准确规划手术路径。在三维重建模型中查看器官组织关系；在VR端对模型进行简单操作。",
        select: [{ title: "平台化管理", content: "" },
            { title: "数据灵活", content: "" },
            { title: "操作便携", content: "" }
        ],
        imgPositon: "right",
        imgSize: [950, 746],
        imgUrl: require("../assets/picture/Medical/神经外科.png")
    },
    {
        title: "5G+8K临床手术术野融合直播系统",
        content: "5G+8K临床手术术野融合直播系统，是通过5G网络，使用全景相机/普通摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面，形成画中画。让观看直播的用户佩戴上VR头显后，能够身临其境观看手术现场，又能同时看到医生的手术术野画面或者医学影像画面。",
        select: [{ title: "低延迟", content: "" },
            { title: "高沉浸", content: "" },
            { title: "高画质", content: "" }
        ],
        imgPositon: "left",
        imgSize: [950, 746],
        imgUrl: require("../assets/picture/Medical/5G+8K.png")
    },
    {
        title: "5G+模拟医学临床专科教学系统",
        content: "运用虚拟现实技术进行临床操作技能及临床手术的模拟训练，涵盖<p class='bold'>妇产、普外、护理、急诊、胸外、泌尿、消化内、精神等众多专科</p>，满足临床技能中心医学院校对于虚拟仿真教学的需求，教学课程根据临床专科进行分类管理，训练内容和临床接轨，在医学院与临床之间架起桥梁，通过模拟临床实际场景，消除学生对于临床的陌生感，在临床实际操作之前，强化医学生对于操作流程的记忆。",
        select: [{ title: "系统化整合", content: "——将不同专业的训练内容整合在一个平台系统中，根据临床专科进行分类，教师通过平台自由选择，满足不同专业的教学需求。" },
            { title: "多学科融合", content: "——学生可通过平台学习其他学科的操作技能，丰富自身的知识面，从而更好的面对临床工作中会遇到的复杂情景。" },
            { title: "沉浸式学习", content: "——模拟实际临床操作场景、将临床操作中使用的器械、人体解剖结构等真实还原，操作者可多角度的进行观察，在反复训练操作流程的同时，强化了立体感和空间感。" },
            { title: "专业背书", content: "——所有的训练课程均在专业教师的指导下进行制作开发。" },
            { title: "平台化管理", content: "——程序分为训练模式和考核模式，程序后台记录操作者的个人信息及训练痕迹，并进行统计分析，从而优化训练效果，方便教师掌握学生的学习进度，以保证培训效果。" }
        ],
        imgPositon: "right",
        imgSize: [950, 746],
        imgUrl: require("../assets/picture/Medical/5G+模拟.png"),
        courseList: [{
            courseName: "妇产科",
            course: [{
                courseTitle: "腹经子宫下段剖宫产术模拟",
                courseContent: `VR剖宫产术，采用最新虚拟现实技术，通过模拟真实的手术室环境，全方位沉浸式体验剖腹产术的流程。软件使用高精度孕妇模型，从表皮到内部脏器，到子宫内婴儿，完全仿真人体结构。并且用户在操作过程中，会有实时的交互提供反馈，让用户快速学习，真实模拟。<br/>程序完全年模拟剖宫产术的操作步骤，学有所得。除了交互操作外，还有理论知识考核，将交互实操与理论相结合。另外术中还有突发状况，能够训练用户的临场应对能力。包含教学模式和考核模式，更好地掌握用户的学习进度。`,
                courseImgList: [
                    { url: require("../assets/picture/Medical/level2/妇产科1.jpg") },
                    { url: require("../assets/picture/Medical/level2/妇产科2.jpg") }
                ]
            }]
        }, {
            courseName: "普外科",
            course: [{
                courseTitle: "阑尾切除术模拟",
                courseContent: `阑尾切除术模拟，是指通过vr技术，在模拟的手术场景中，模拟阑尾切除术。手术大体步骤：①仰卧位。②右下腹麦氏切口。③寻找阑尾。④处理系膜。⑤保护阑尾及盲肠。⑥荷包缝合。⑦结扎烂尾根部。⑧切断阑尾。⑨阑尾残端处理。⑩包埋阑尾残端。⑪覆盖系膜。⑫关膜。让用户对阑尾切除术的流程以及操作方式进行学习，模拟中有操作提示与答题，能够让用户进行全面的学习。`,
                courseImgList: [
                    { url: require("../assets/picture/Medical/level2/阑尾3.png") },
                    { url: require("../assets/picture/Medical/level2/阑尾4.png") }
                ]
            }, {
                courseTitle: "阑尾切除术模拟小程序",
                courseContent: `阑尾切除术模拟小程序，在小程序上实现Web 3D交互。软件采用逼真的人体模型，用户通过与3D人体模型的实时交互，实现阑尾切除术培训。`,
                courseImgList: [{ url: require("../assets/picture/Medical/level2/阑尾小程序.png") }]
            }]

        }, {
            courseName: "护理",
            course: [{
                courseTitle: "导尿术",
                courseContent: `VR导尿术模拟，是对导尿术的模拟，是将导尿管经尿道插入膀胱引出尿液。目的是解除尿潴留，采取不污染的尿液标本作检查，测定残余尿，测定膀胱冷热感、容量、压力、注入造影剂或药物帮助诊断或治疗等。使用者经过模拟训练，掌握相应的导尿术流程思维。`,
                courseImgList: [{ url: require("../assets/picture/Medical/level2/导尿术.png") }]
            }, {
                courseTitle: "乳腺癌术后护理",
                courseContent: `乳腺癌术后护理是提供给乳腺癌患者，在术后康复时使用的VR全景模拟体验。能够让患者声临其境，跟随场景中的护士，进行术后恢复操，从而达到促进患肢痊愈的效果。<br/>软件考虑到使用人群为术后患者，在操作上采用简洁的交互方式，针对患者用户进行设计。使得患者在体验的同时，能够跟随进行术后护理。`,
                courseImgList: [{ url: require("../assets/picture/Medical/level2/乳腺癌术后护理.png") }]
            }, {
                courseTitle: "围术期压疮管理",
                courseContent: `围术期压疮管理VR模拟，是通过VR的形式，普及手术围术期知识。该程序在一个模拟的手术室场景中，演示不同的手术体位，以及如果对该体位进行压疮管理。让患者及其家属了解患者在手术过程采用何种体位，以及应该注意哪些部位容易得压疮。`,
                courseImgList: [{ url: require("../assets/picture/Medical/level2/围术期压疮管理.png") }]
            }]
        }, {
            courseName: "急诊",
        }, {
            courseName: "胸外科",
        }, {
            courseName: "泌尿外科",
        }, {
            courseName: "消化内科",
        }, {
            courseName: "精神科",
        }]
    },
    {
        title: "疫情防护仿真培训系统",
        content: `相应国家号召，以目前新冠肺炎疫情为出发点，为加强普遍群众和医护人员的防护意识、知识，增强防护培训效果，快速地应用到现实工作中，减少感染率的发生，为目前打赢抗“疫”攻坚战、常态化防疫提供帮助。当前仿真培训系统涵盖<p class='bold'>新冠肺炎、登革热、流行性肝炎、SARS、手足口病、艾滋病等内容</p>。`,
        select: [{ title: "结合时事", content: "" },
            { title: "使用灵活", content: "" },
            { title: "专业背书", content: "" },
            { title: "使用广泛", content: "" },
            { title: "游戏化教学", content: "" },
            { title: "平台化管理", content: "" }
        ],
        imgPositon: "left",
        imgSize: [950, 746],
        imgUrl: require("../assets/picture/Medical/公共卫生.png")
    }
]

export { info }