<template>
  <div>
    <!-- pc端 -->
    <div class="homeAllScreem" v-if="!isPhone">
      <Header @headerListClickFn="scrollMoveFn" />
      <div class="home">
        <Swiper :renderBG="true" :imgList="swiperList" :contentList="siwperContentList" @swiperIndexFn="swiperIndexFn" />
      </div>
      <middle />
      <!-- 页面中部导航栏 -->
      <div class="pageItemList">
        <div @click="scrollMoveFn(1)">
          公司介绍</div>
        <div @click="scrollMoveFn(2)">
          数字营销</div>
        <div @click="scrollMoveFn(3)">
          最新消息</div>
        <div @click="scrollMoveFn(4)">
          获奖情况</div>
      </div>
      <!-- 中部成就栏 -->
      <div class="achievement">
        <div class="achieve-container">
          <div class="achieve-title">{{ achievement.title }}</div>
          <div class="achieve-subtitle">{{ achievement.subtitle }}</div>
          <div class="achievements">
            <div class="achieve" v-for="(item, index) in achievement.details" :key="index">
              <div class="achieve-name">{{ item.name }}</div>
              <!-- <div class="achieve-content">{{ item.content }}</div> -->
              <NumberWheel :data="item"></NumberWheel>
            </div>
          </div>
        </div>
      </div>
      <!-- 公司介绍 -->
      <div class="companyInfoBLock">
        <div class="companyInfo">
          <div class="companyName">{{ companyInfo.title }}</div>
          <div class="comp-info-subtitle">{{ companyInfo.subtitle }}</div>
          <div class="comp-info-desc" v-html="companyInfo.desc"></div>
          <div class="comp-info-blocks">
            <div class="comp-info-block" v-for="(block, i) in companyInfo.blocks" :key="i">
              <!-- <img :src="global.AssetsURL + block.prePicture" class="block-picture" style="z-index: 1;" :style="{ display: block.showImg ? 'none' : '' }"> -->
              <!-- <img :src="global.AssetsURL + block.picture" class="block-picture" style="z-index: 2;" :style="{ display: block.showImg ? '' : 'none' }" @load="onCompanyInfoImageLoad(i)"> -->
              <img :src="global.AssetsURL + block.prePicture" class="block-picture" style="z-index: 2;">
              <div class="block-title">{{ block.blockTitle }}</div>
              <div class="block-subtitle">{{ block.blockSubtitle }}</div>
              <div class="block-desc">{{ block.blockDesc }}</div>
              <div class="block-categories" v-if="block.categories">
                <div class="block-cate" v-for="(cate, index) in block.categories" :key="index"
                  @click="gotoCompInfoBlock(cate.page, cate.block)">
                  <!-- <img src="../assets/arrow-right.png" class="block-ico"> -->
                  <div class="block-ico">></div>
                  <div class="block-span">{{ cate.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 数字营销 -->
      <Marketing />
      <!-- 最新消息 -->
      <div class="newsAreaBG">
        <div class="newsArea">
          <p class="companyName">{{ newsList.title }}</p>
          <p class="subtitle">{{ newsList.subtitle }}</p>
          <div class="news-area">
            <div class="news" v-for="(news, index) in newsList.news" :key="index" @click="gotoNews(index)">
              <div class="img-container">
                <img :src="global.AssetsURL + news.picture" class="news-picture"
                  :style="{ width: news.pictureType == 'vertical' ? '100%' : '', height: news.pictureType == 'vertical' ? '' : '100%', transform: news.pictureType == 'vertical' ? 'translateY(-50%)' : 'translateX(-50%)', left: news.pictureType == 'vertical' ? '' : '50%', top: news.pictureType == 'vertical' ? '50%' : '' }">
              </div>
              <div class="news-date">{{ news.date }}</div>
              <div class="news-desc">{{ news.desc }}</div>
            </div>
          </div>
          <div class="news-more" @click="shownewsInfo()">阅读更多内容</div>
        </div>
      </div>
      <!-- 获奖情况 -->
      <div class="reward">
        <div class="reward-title">
          <div class="companyName reward-main-title">获奖情况</div>
          <p>每一份荣誉</p>
          <p>都有您</p>
          <p>背后的</p>
          <p>支持与付出</p>
        </div>
        <div class="box-shadow"></div>
        <div class="swiper-container">
          <SwiperReward :data="rewardList" />
        </div>
      </div>
      <Footer @scrollMoveFn="scrollMoveFn" />
    </div>

    <!-- 移动端 -->
    <div class="phoneHome" v-if="isPhone">
      <Header @phoneScrollMoveFn="phoneScrollMoveFn" @phoneVideoSwitch="phoneVideoSwitch" />
      <div class="phone-home">
        <Swiper :imgList="phoneSwiperList" :contentList="siwperContentList" @swiperIndexFn="swiperIndexFn" />
      </div>
      <InPageNavigator :data="inPageNavigations" @inPageNavigation="inPageNavigation" />
      <div class="phone-achivement">
        <div class="phone-achiv-title">{{ achievement.title }}</div>
        <div class="phone-achiv-subtitle">{{ achievement.subtitle }}</div>
        <div class="phone-achivements">
          <div class="phone-achiv" v-for="(item, index) in achievement.details" :key="index">
            <NumberWheel :data="item" :range="[200, 1200]" />
            <div class="phone-achiv-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="phoneCompanyInfo">
        <div class="phoneCompanyName">
          <div class="phone-company-title">{{ companyInfo.title }}</div>
          <div class="phone-company-subtitle">{{ companyInfo.subtitle }}</div>
        </div>
        <div class="phoneCompanyContent" v-html="companyInfo.desc"></div>
        <div class="phone-comp-info-blocks">
          <div class="phone-comp-info-block" v-for="(block, i) in companyInfo.blocks" :key="i">
            <img :src="global.AssetsURL + block.prePicture" class="phone-comp-info-poster" />
            <div class="phone-comp-info-title">{{ block.blockTitle }}</div>
            <div class="phone-comp-info-subtitle">{{ block.blockSubtitle }}</div>
            <div class="phone-comp-info-desc">{{ block.blockDesc }}</div>
            <div class="phone-comp-info-block-cates" v-if="block.categories">
              <div class="phone-comp-info-block-cate" v-for="(cate, index) in block.categories" :key="index"
                @click="gotoCompInfoBlock(cate.page, cate.blockIndexInPhone)">
                <img src="../assets/pcib-ico.jpg" class="pcib-ico">
                <div class="phone-block-span">{{ cate.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Marketing />
      <div class="phoneNewsArea">
        <div class="phoneCompanyName">
          <div class="phone-news-title">{{ newsList.title }}</div>
          <div class="phone-news-subtitle">{{ newsList.subtitle }}</div>
        </div>
        <div class="phone-news-list">
          <div class="phone-news" v-for="(news, index) in newsList.news" :key="index" @click="gotoNews(index)">
            <div class="phone-news-picture">
              <img :src="global.AssetsURL + news.picture"
                :style="{ width: news.pictureType == 'vertical' ? '100%' : '', height: news.pictureType == 'vertical' ? '' : '100%', transform: news.pictureType == 'vertical' ? 'translateY(-50%)' : 'translateX(-50%)', left: news.pictureType == 'vertical' ? '' : '50%', top: news.pictureType == 'vertical' ? '50%' : '' }">
            </div>
            <div class="phone-news-date">{{ news.date }}</div>
            <div class="phone-news-desc">{{ news.desc }}</div>
          </div>
          <div class="phone-more-btn" @click="showMorePhoneNews">阅读更多内容</div>
        </div>
      </div>
      <div class="phone-reward">
        <div class="phone-reward-label">
          <div class="phoneCompanyName"
            style="width: 0;height: 0; background-color: rgba(0,0,0,0);position: absolute; top:0;">
          </div>
          <div class="phone-reward-maintitle">获奖情况</div>
          <div class="phone-reward-subtitle" v-html="'每一份荣誉都有您背后的</br>支持与付出'"></div>
        </div>
        <PhoneSwiperReward :rewardData="rewardList" />
      </div>
      <!-- 移动端的滚动事件需要调整 -->
      <Footer @phoneScrollMoveFn="phoneScrollMoveFn" />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Middle from "../components/Middle.vue";
import Footer from "../components/Footer.vue";
import Swiper from "../components/Swiper.vue";
import SwiperReward from "../components/SwiperReward.vue";
import PhoneSwiperReward from "../components/PhoneSwiperReward.vue";
import Marketing from "../components/Marketing.vue";
import NumberWheel from "../components/NumberWheel.vue";
import InPageNavigator from "../components/InPageNavigator.vue";

import { homeData } from "../../public/data/Home";
import { newsData } from "../../public/data/Allnews";

export default {
  name: "Home",
  components: {
    Header,
    Middle,
    Footer,
    Swiper,
    SwiperReward,
    Marketing,
    NumberWheel,
    InPageNavigator,
    PhoneSwiperReward
  },
  data() {
    return {
      swiperList: homeData.swiperList,
      phoneSwiperList: homeData.swiperList,
      PhoneIntroduceSwiperList: ['/picture/home/phone/ps1.png', '/picture/home/phone/ps2.png', '/picture/home/phone/ps3.png', '/picture/home/phone/ps4.png', '/picture/home/phone/ps5.png', '/picture/home/phone/ps6.png', '/picture/home/phone/ps7.png', '/picture/home/phone/ps8.png', '/picture/home/phone/ps9.png', '/picture/home/phone/ps10.png'],
      siwperContentList: homeData.siwperContentList,
      swiperTimer: "",
      swiperIndex: 0,
      companyInfoSwiperData: homeData.companyInfoSwiperData,
      maskUrl: [
        {
          url: require("../assets/picture/大图蒙版.png"),
        },
        {
          url: require("../assets/picture/banner图头部蒙版.png"),
        },
      ],
      lists: [
        "心血管",
        "超声",
        "神经外科",
        "5G+模拟医学临床专科教学",
        `公共卫生`,
        "查看更多",
      ],
      itemImg: "",
      itemImgList: [
        {
          url: require("../assets/picture/home/心血管.png"),
        },
        {
          url: require("../assets/picture/home/超声.png"),
        },
        {
          url: require("../assets/picture/home/神经外科.png"),
        },
        {
          url: require("../assets/picture/home/模拟医学临床专科教学.png"),
        },
        {
          url: require("../assets/picture/home/公共介绍.png"),
        },
      ],
      listClick: 0,
      listContent: "",
      listContentBox: [
        "通过Wechat轻量AR虚拟增强现实交互技术、微软Hololens2混合现实眼镜、VR虚拟现实眼镜，全方位数字化展示心脏模型正常心脏、临床病理心脏、相关疗法，介入手术模拟等流程，属于行业内首个基于VR/AR /MR多端融合的体验形式。<br>该系统面向医学专业人士,零距离接触心脏，一览无余地学习心脏解剖基础知识，了解病理具体原理，进行临床手术模拟训练，为年轻医学院学生和医师普及心血管疗法，有助于临床教学。",
        "超声模拟器分便携版和专业版，系统通过定位器和医用假人等实物，结合临床超声探头实时操作，涵盖人体6种以上体位，超过100种临床病理数据，辅助医学院学生及年轻医师进行临床超声训练。",
        "神经外科医学图像处理平台将患者影像数据进行三维重建，医生通过PC 、VR及移动设备更加立体、直观地看到人体结构，准确规划手术路径。在三维重建模型中查看器官组织关系；系统分PC端和VR端，VR端将临床CT影像数据导入后，临床医生快速进行病理数据展示、术前规划，提高临床医生临床经验，提高术前规划效率。",
        "通过便捷VR设备，将传统全科医师规培学习经验，进行仿真实训步骤操作，通过在线答题，考核训练等形式，进行高效临床思维训练和实操步骤模拟，临床教学系统涵盖内科、外科、妇产科、基础护理学、急救护理学、养老护理学、为广大医学院学生提供高效、自助的学习体验，提高课内课外学生学习效率，结合临床相关经验，为学生后续临床医师规培提供高效学习工具。",
        "公共卫生实训平台包含登革热疫情筛查和防护和新冠疫情。登革热疫情按照疫情防护的步骤进行疫情防护检查，通过标准流程达到登革热疫情筛查目的。针对最新2020新冠肺炎疫情，加强普通群众和医护人员的防护意识、知识，增强防护培训效果，系统为临床医护人员和普通民众提供专业防护指导。",
      ],
      newsImgList: newsData,
      rewardList: homeData.rewardList,
      mapinfoList: [
        {
          url: require("../assets/picture/地址.png"),
          // txt: "长宁区延安西路1590号曾泽世贸大楼713室",
          txt: "上海市徐汇区钦州北路1122号新汇园90号楼10楼",
        },
        {
          url: require("../assets/picture/工作时间.png"),
          txt: "周一至周五 10:00-19:30",
        },
        {
          url: require("../assets/picture/联系方式.png"),
          txt: `Tel:021-52358005</br>M:13817071286`,
        },
        {
          url: require("../assets/picture/邮箱.png"),
          txt: "bd@spacemax.com.cn",
        },
      ],
      newsImgIndex: 0,
      newsImgSelect: -1,
      newsimgSite: 0,
      pageZoomRatio: 1,
      headerScrollIndex: [0, 700, 1800, 2700, 3150],
      rewardMove: 0,
      newsImgEnter: false,
      listContentColorChange: false,
      videoPlay: false,
      icon: require("../assets/picture/定位.png"),
      isPhone: false,
      phoneAwardMoveIndex: 0,
      phoneAwardMoveX: 0,
      phoneAwardMoveWidth: 0,
      phoneNewsMoveIndex: 0,
      phoneNewsMoveX: 0,
      phoneNewsMoveWidth: 0,
      phoneClassMoveIndex: 0,
      phoneClassMoveX: 0,
      phoneRewardList: homeData.PhoneRewardList,
      showVideo: true,
      currentBrowseBlock: 0,
      newsList: homeData.newsList,
      achievement: homeData.achievement,
      companyInfo: homeData.companyInfo,
      inPageNavigations: homeData.inPageNavigations
    };
  },
  created: function () {
    this.global.titleIndex = 0;
    this.listContent = this.listContentBox[this.listClick];
    this.resizeFn();
    //如果有跳转index，屏幕滚动
    if (!isNaN(this.$route.params.y)) {
      setTimeout(() => {
        if (this.global.isPhone) {
          const correction = this.$route.params.correction ? this.$route.params.correction : 0;
          this.phoneScrollMoveFn(this.$route.params.y, correction);
        } else {
          this.scrollMoveFn(this.$route.params.y);
        }
      }, 100);
    }
    if (this.global.isPhone) {
      this.isPhone = true;
    }
    this.itemImg = this.itemImgList[0];
  },
  methods: {
    listClickFn(index) {
      this.listClick = index;
      this.listContent = this.listContentBox[this.listClick];
      if (index === 5) {
        this.$router.push(`/Home/AboutMore`);
      }
      this.itemImg = this.itemImgList[index];
    },
    newsArrowClickFn(index) {
      let x = 3.55;
      if (index === 0) {
        if (this.newsImgIndex > 0) {
          this.newsImgIndex--;
          this.newsimgSite = this.newsimgSite + x;
        }
      } else {
        if (this.newsImgIndex < this.newsImgList.length - 4) {
          this.newsImgIndex++;
          this.newsimgSite = this.newsimgSite - x;
        }
      }
    },
    scrollMoveFn(index) {
      let dy = 0;
      if (index == 1) {
        let block = document.getElementsByClassName("companyName")[0];
        dy = block.getBoundingClientRect().top;
      } else if (index == 2) {
        let block = document.getElementsByClassName("companyName")[1];
        dy = block.getBoundingClientRect().top;
      } else if (index == 3) {
        let block = document.getElementsByClassName("companyName")[2];
        dy = block.getBoundingClientRect().top;
      } else if (index == 4) {
        let block = document.getElementsByClassName("companyName")[3];
        dy = block.getBoundingClientRect().top;
      }
      let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      // window.scrollTo(0, dy + scrollTop - 250);
      window.scrollTo(0, dy + scrollTop - 150);
      this.currentBrowseBlock = index;
    },
    phoneScrollMoveFn(index, correction = 0) {
      // 计算定位偏移量
      const ratio = window.innerWidth / 375;
      const headHeight = 130 * ratio;
      const block = document.getElementsByClassName("phoneCompanyName")[index];
      const dy = block.getBoundingClientRect().top;
      const scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      let dis = dy + scrollTop - headHeight;
      if (correction != 0) dis += correction;
      window.scrollTo(0, dis);
    },
    resizeFn() {
      this.pageZoomRatio = 1920 / window.innerWidth;
    },
    swiperIndexFn(n) {
      this.swiperIndex = n;
    },
    newsImgEnterFn() {
      this.newsImgEnter = true;
    },
    newsImgLeaveFn() {
      this.newsImgEnter = false;
    },
    listContentEnterFn() {
      this.listContentColorChange = true;
    },
    listContentLeaveFn() {
      this.listContentColorChange = false;
    },
    // 视频自动播放
    scrollFn() {
      if (this.$refs.homeVideo) {
        let bottom = this.$refs.homeVideo.getBoundingClientRect().bottom;
        let h = document.documentElement.clientHeight;
        if (!this.videoPlay && bottom >= 0 && bottom <= h) {
          this.videoPlay = true;
          // this.$refs.homeVideo.play();
        } else if (this.videoPlay && (bottom < 0 || bottom > h)) {
          this.videoPlay = false;
          this.$refs.homeVideo.pause();
        }
      }
    },
    // 最新消息详情页
    showMoreNewsFn() {
      this.$router.push({
        name: "AllNews",
      });
    },
    //移动端事件
    awardTouchStart(e) {
      this.phoneAwardMoveX = e.touches[0].clientX;
    },
    awardTouchEnd(e) {
      let nx = e.changedTouches[0].clientX;
      if (nx > this.phoneAwardMoveX) {
        this.phoneAwardMoveIndex--;
      }
      if (nx < this.phoneAwardMoveX) {
        this.phoneAwardMoveIndex++;
      }
      if (this.phoneAwardMoveIndex < 0) {
        this.phoneAwardMoveIndex = 0;
      }
      if (this.phoneAwardMoveIndex > this.rewardList.length - 1) {
        this.phoneAwardMoveIndex = this.rewardList.length - 1;
      }
    },
    newsTouchStart(e) {
      this.phoneNewsMoveX = e.touches[0].clientX;
    },
    newsTouchEnd(e) {
      let nx = e.changedTouches[0].clientX;
      if (nx > this.phoneNewsMoveX) {
        this.phoneNewsMoveIndex--;
      }
      if (nx < this.phoneNewsMoveX) {
        this.phoneNewsMoveIndex++;
      }
      if (this.phoneNewsMoveIndex < 0) {
        this.phoneNewsMoveIndex = 0;
      }
      if (this.phoneNewsMoveIndex > this.newsImgList.length - 1) {
        this.phoneNewsMoveIndex = this.newsImgList.length - 1;
      }
    },
    classTouchStart(e) {
      this.phoneClassMoveX = e.touches[0].clientX;
    },
    classTouchEnd(e) {
      let nx = e.changedTouches[0].clientX;
      if (nx > this.phoneClassMoveX) {
        this.phoneClassMoveIndex--;
      }
      if (nx < this.phoneClassMoveX) {
        this.phoneClassMoveIndex++;
      }
      if (this.phoneClassMoveIndex < 0) {
        this.phoneClassMoveIndex = 0;
      }
      if (this.phoneClassMoveIndex > this.lists.length - 3) {
        this.phoneClassMoveIndex = this.lists.length - 3;
      }
    },
    shownewsInfo(index) {
      this.$router.push({
        name: "AllNews",
        params: {
          index: index,
        },
      });
    },
    showMorePhoneNews() {
      this.$router.push({ name: 'PhoneNewsList' });
    },
    showNewsInfo(index) {
      this.$router.push({
        name: 'PhoneNewsList', params: {
          index: index
        }
      });
    },
    phoneVideoSwitch(show) {
      this.showVideo = !show;
    },
    toDigital() {
      this.$router.push({ name: 'Digital' });
    },
    gotoCompInfoBlock(page, block) {
      if (page && block > -1) {
        this.$router.push({ name: page, params: { block } });
      }
    },

    gotoNews(index) {
      const id = this.newsList.news[index].id;
      this.$router.push({
        name: "NewsInfo",
        query: {
          data: JSON.stringify(newsData),
          index: id - 1,
        },
      });
    },

    onCompanyInfoImageLoad(index) {
      this.companyInfo.blocks[index].showImg = true;
    },

    inPageNavigation(event) {
      const { index, correction } = event;

      this.phoneScrollMoveFn(index, correction);
    },


  },
  directives: {
    drag: {
      inserted: function (el) {
        let x = 0;
        let rewardMove = 0;
        let isDown = false;
        let pos = {};
        el.onmousedown = function (e) {
          pos = el.parentElement.getBoundingClientRect();
          x = e.clientX;
          isDown = true;
          el.style.cursor = "w-resize";
        };
        window.onmousemove = function (e) {
          if (isDown == false) {
            return;
          }
          el.style.userSelect = "none";
          //当鼠标移出区域时
          if (e.clientX < pos.left + 1 || e.clientX > pos.right) {
            isDown = false;
            el.style.cursor = "default";
            return;
          }
          let nx = e.clientX;
          //移动距离
          if (x > nx) {
            rewardMove -= 1;
          } else {
            rewardMove += 1;
          }
          //判断获奖列表边界
          if (rewardMove > 0) {
            rewardMove = 0;
          } else if (rewardMove < -(el.clientWidth / 46)) {
            rewardMove = -(el.clientWidth / 46);
          }
          el.style.transform = `translateX(${rewardMove}%)`;
          x = e.clientX;
        };
        el.onmouseup = function () {
          isDown = false;
          el.style.cursor = "default";
        };
      },
    },
  },
  computed: {
    newsImgMove() {
      let x = this.newsimgSite;
      return {
        transform: `translateX(${x}rem)`,
        transition: `0.7s`,
      };
    },
    swiperChange() {
      let x = -this.swiperIndex * 19.2;
      return {
        transform: `translateX(${x}rem)`,
        transition: "1s",
      };
    },
    newsImgBlockMove() {
      if (this.newsImgEnter) {
        return {
          transform: `translateX(-0.32rem)`,
          width: `14.6rem`,
        };
      } else {
        return {
          transform: `translateX(-0rem)`,
          width: `14.2rem`,
        };
      }
    },
    listTriangleColorChange() {
      if (this.listContentColorChange) {
        return {
          borderColor: `transparent transparent #eff0f5 transparent`,
        };
      } else {
        return {
          borderColor: `transparent transparent #f8f8f9 transparent`,
        };
      }
    },
    listContColorChange() {
      if (this.listContentColorChange) {
        return {
          backgroundColor: `#eff0f5`,
        };
      } else {
        return {
          backgroundColor: `#f8f8f9`,
        };
      }
    },
    phoneAwardMove() {
      let x = this.phoneAwardMoveIndex * -12;
      return {
        transform: `translateX(${x}rem)`,
        transition: `0.5s`,
      };
    },
    phoneAwardBarMove() {
      let x = this.phoneAwardMoveIndex * (this.phoneAwardMoveWidth + 1);
      return {
        transform: `translateX(${x}px)`,
        transition: `0.5s`,
      };
    },
    phoneNewsMove() {
      let x = this.phoneNewsMoveIndex * -10;
      return {
        transform: `translateX(${x}rem)`,
        transition: `0.5s`,
      };
    },
    phoneNewsBarMove() {
      let x = this.phoneNewsMoveIndex * (this.phoneNewsMoveWidth + 1);
      return {
        transform: `translateX(${x}px)`,
        transition: `0.5s`,
      };
    },
    phoneClassMove() {
      let x = this.phoneClassMoveIndex * -2.5;
      return {
        transform: `translateX(${x}rem)`,
        transition: `0.3s`,
      };
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeFn);
    window.addEventListener("scroll", this.scrollFn);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeFn);
    window.removeEventListener("scroll", this.scrollFn);
  },
};
</script>

<style scoped>
@import "../common/font/font.css";

.homeAllScreem {
  position: relative;
  left: 0;
  width: 100%;
}

.homeAllScreem::-webkit-scrollbar {
  display: none;
}

.home {
  position: relative;
  margin: 0 auto;
  height: 8.62rem;
  width: 100%;

}

.achievement {
  width: 100%;
  height: 5.74rem;
  background-color: #F7F7F7;
}

.achieve-container {
  width: 14.4rem;
  height: 100%;
  margin: 0 auto;
  max-width: 1440px;
  padding-top: .9rem;
  box-sizing: border-box;
}

.achieve-title {
  width: 100%;
  font-size: .48rem;
  font-weight: 700;
  margin-bottom: .1rem;
}

.achieve-subtitle {
  width: 100%;
  font-size: .48rem;
  font-weight: 200;
  margin-bottom: .6rem;
}

.achievements {
  width: 100%;
  height: 2.45rem;
  display: flex;
  justify-content: space-between;
}

.achieve-name {
  font-size: .24rem;
  font-weight: 500;
  margin-bottom: .37rem;
}

.achieve-content {
  font-size: 1.2rem;
  color: #141E8C;
}

.line {
  position: relative;
  top: -0.24rem;
  width: 3.48rem;
  height: 1px;
  background-color: rgba(202, 212, 224, 1);
  margin: 0 auto;
}

.pageItemList {
  position: relative;
  height: .36rem;
  font-size: .16rem;
  font-family: "regular";
  color: #32373E;
  display: flex;
  background-color: #ffffff;
  width: 14.4rem;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: .44rem;
  padding-left: .2rem;
  box-sizing: border-box;
  border-bottom: 1px solid #DFE4EE;
}

.pageItemList div {
  margin-right: .4rem;
  cursor: pointer;
  box-sizing: border-box;
}

.pageItemList div:hover {
  color: #005dff;
  border-bottom: 4px solid #005dff;
}

.companyInfoBLock {
  width: 100%;
}

.companyInfo {
  margin: 0 auto;
  margin-top: 0.46rem;
  width: 14.4rem;
  max-width: 1440px;
  margin-top: .9rem;
}

.companyName {
  font-size: 0.48rem;
  font-family: "Bold";
  font-weight: Bold;
  line-height: 0.64rem;
  width: fit-content;
}

.comp-info-subtitle {
  font-size: .48rem;
  font-weight: 100;
  margin-bottom: .79rem;
}

.comp-info-desc {
  width: 100%;
  font-size: .18rem;
  font-weight: 100;
  line-height: .32rem;
  margin-bottom: .48rem;
  /* text-indent: 2em; */
}

.comp-info-blocks {
  width: 100%;
  height: 36.88rem;
  display: grid;
  grid-template-rows: 8.31rem 5.69rem 6.59rem 6.65rem 5.68rem;
  grid-template-columns: 4.52rem .42rem 2.05rem .42rem 2.05rem .42rem 4.52rem;
  justify-content: space-between;
  row-gap: .97rem;
  font-size: .16rem;
}

.comp-info-block {
  position: relative;
}

.comp-info-block:nth-child(1),
.comp-info-block:nth-child(2),
.comp-info-block:nth-child(6),
.comp-info-block:nth-child(7),
.comp-info-block:nth-child(11),
.comp-info-block:nth-child(12) {
  width: 6.99rem;
}

.comp-info-block:nth-child(1),
.comp-info-block:nth-child(6),
.comp-info-block:nth-child(11) {
  grid-column-start: 1;
  grid-column-end: 4;
}

.comp-info-block:nth-child(2),
.comp-info-block:nth-child(7),
.comp-info-block:nth-child(12) {
  grid-column-start: 5;
  grid-column-end: 9;
}


.comp-info-block:nth-child(3),
.comp-info-block:nth-child(4),
.comp-info-block:nth-child(5),
.comp-info-block:nth-child(8),
.comp-info-block:nth-child(9),
.comp-info-block:nth-child(10) {
  width: 4.52rem;
}

.comp-info-block:nth-child(3),
.comp-info-block:nth-child(8) {
  grid-column-start: 1;
  grid-column-end: 2;
}

.comp-info-block:nth-child(4),
.comp-info-block:nth-child(9) {
  grid-column-start: 3;
  grid-column-end: 6;
}

.comp-info-block:nth-child(5),
.comp-info-block:nth-child(10) {
  grid-column-start: 7;
  grid-column-end: 8;
}

.block-picture {
  position: absolute;
  width: 100%;
  display: block;
  margin-bottom: .15rem;
}

.comp-info-block:nth-child(1) .block-picture,
.comp-info-block:nth-child(2) .block-picture,
.comp-info-block:nth-child(6) .block-picture,
.comp-info-block:nth-child(7) .block-picture,
.comp-info-block:nth-child(11) .block-picture,
.comp-info-block:nth-child(12) .block-picture {
  height: 4.2rem;
}

.comp-info-block:nth-child(3) .block-picture,
.comp-info-block:nth-child(4) .block-picture,
.comp-info-block:nth-child(5) .block-picture,
.comp-info-block:nth-child(8) .block-picture,
.comp-info-block:nth-child(9) .block-picture,
.comp-info-block:nth-child(10) .block-picture {
  height: 2.64rem;
}

.block-title {
  width: 100%;
  font-size: .24rem;
  font-weight: Bold;
  margin-bottom: .02rem;
}

.comp-info-block:nth-child(1) .block-title,
.comp-info-block:nth-child(2) .block-title,
.comp-info-block:nth-child(6) .block-title,
.comp-info-block:nth-child(7) .block-title,
.comp-info-block:nth-child(11) .block-title,
.comp-info-block:nth-child(12) .block-title {
  margin-top: 4.35rem;
}


.comp-info-block:nth-child(3) .block-title,
.comp-info-block:nth-child(4) .block-title,
.comp-info-block:nth-child(5) .block-title,
.comp-info-block:nth-child(8) .block-title,
.comp-info-block:nth-child(9) .block-title,
.comp-info-block:nth-child(10) .block-title {
  margin-top: 2.79rem;
}

.block-subtitle {
  width: 100%;
  font-size: .24rem;
  font-weight: 300;
  color: #32373E;
  margin-bottom: .21rem;
}

.block-desc {
  width: 100%;
  font-size: .18rem;
  font-weight: 300;
  color: #626E7E;
}

.block-categories {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.block-cate {
  width: 100%;
  display: flex;
  /* align-items: center; */
  color: #0073E6;
  user-select: none;
  cursor: pointer;
}

.block-cate:hover {
  color: #141E8C;
}

.block-cate:not(:last-child) {
  margin-bottom: .11rem;
}

.block-ico {
  font-size: .1rem;
  margin-right: .08rem;
  line-height: .24rem;
}

.block-span {
  width: calc(100% - .19rem);
  font-size: .18rem;
  font-weight: 300;
  line-height: .24rem;
}

.subtitle {
  font-size: .48rem;
  line-height: .64rem;
  margin-bottom: .74rem;
  font-weight: 100;
}

.news-area {
  width: 100%;
  height: 9.12rem;
  display: grid;
  grid-template-rows: repeat(2, 4.06rem);
  grid-template-columns: repeat(3, 4.52rem);
  row-gap: 1rem;
  column-gap: .42rem;
  margin-bottom: .68rem;
}

.news {
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: pointer;
}

.img-container {
  width: 100%;
  height: 2.64rem;
  margin-bottom: .15rem;
  overflow: hidden;
  position: relative;
}

.news-picture {
  position: absolute;
  display: block;
}

.news-date {
  width: 100%;
  font-size: .24rem;
  font-weight: Bold;
  margin-bottom: .07rem;
}

.news-desc {
  width: 100%;
  font-size: .24rem;
  font-weight: 300;
  line-height: .32rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news-more {
  width: 2rem;
  height: .6rem;
  border: 1px solid #0073E6;
  box-sizing: border-box;
  text-align: center;
  line-height: .6rem;
  color: #0073E6;
  cursor: pointer;
  font-size: .24rem;
  font-weight: 400;
}

.reward {
  width: 100%;
  height: 7.38rem;
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.reward-title {
  width: 3.18rem;
  height: 100%;
  background-color: #141E8C;
  color: #E4EDFF;
  font-size: .48rem;
  line-height: .6rem;
  padding: .7rem .39rem;
  box-sizing: border-box;
  font-weight: 200;
}

.box-shadow {
  width: 3.18rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9;
  box-shadow: 10px 0 36px rgba(8, 13, 64, 0.36);
}

.reward-main-title {
  font-size: .48rem;
  font-weight: Bold;
  color: #FFFFFF;
}

.swiper-container {
  width: calc(100% - 3.18rem);
  height: 100%;
  background-color: #F7F7F7;
  overflow: hidden;
}

.listContent {
  position: absolute;
  width: 13.43rem;
  background-color: #eff0f5;
  margin-top: 0.34rem;
  height: 0.9rem;
  font-size: 0.16rem;
  font-family: "Regular";
  color: #647588;
  padding: 0.2rem 0.25rem 0 0.25rem;
}


.newsAreaBG {
  padding-top: 0.93rem;
  padding-bottom: 1.1rem;
  background-color: #ffffff;
}

.newsArea {
  position: relative;
  font-size: 0;
  width: 14.4rem;
  max-width: 1440px;
  margin: 0 auto;
}


.rewardList {
  position: relative;
  margin-left: 0.26rem;
  margin-right: 0.3rem;
  margin-bottom: 0.32rem;
  font-size: 0.16rem;
  font-family: "Regular";
  color: #cad4e0;
}

.rewardList:nth-child(5) {
  color: #ffcc00;
}

.mapIndex {
  width: 20px;
}

/* phone css */
.phoneHome {
  width: 100%;
  overflow: scroll;
}

.phone-home {
  width: 100%;
}

.phoneMidContent {
  position: absolute;
  margin-top: -12.85rem;
  width: 100%;
  text-align: center;
}

@keyframes phoneMidContentMove {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.phoneP1 {
  font-size: 1.2rem;
  font-family: "Regular";
  color: #ffffff;
  animation: phoneMidContentMove 1s;
}

.phoneMidLine {
  border: 1px solid #cad4e0;
  width: 9.6rem;
  margin: 0.25rem auto;
}

.phoneP2 {
  font-size: 0.6rem;
  font-family: "Regular";
  color: #cad4e0;
  animation: phoneMidContentMove 1.5s;
}

.phoneP3 {
  font-size: 0.4rem;
  font-family: "Regular";
  color: #cad4e0;
  transform: scale(0.65);
  animation: phoneMidContentMove 1.5s;
}

.phoneCompanyInfo {
  /* position: relative; */
  width: 100%;
  padding-top: 0.35rem;
  box-sizing: border-box;
}

.phoneCompanyName {
  font-size: 0.28rem;
  font-family: "MeDium";
  margin-bottom: 0.24rem;
  padding: 0 0.18rem;
  box-sizing: border-box;
}

.phone-company-title {
  font-weight: bold;
  margin-bottom: 0.12rem;
}

.phone-icon-left {
  width: 0.06rem;
  height: 0.24rem;
  margin-right: 0.12rem;
  background: linear-gradient(to bottom, #00A8FF, #005DFF);
}

.phoneCompanyContent {
  font-family: "Regular";
  font-size: 0.16rem;
  line-height: 0.27rem;
  word-wrap: normal;
  padding: 0 0.18rem;
  box-sizing: border-box;
  color: #777A81;
  font-weight: 200;
}

.phone-comp-info-blocks {
  width: 100%;
  padding: 0 0.18rem;
  box-sizing: border-box;
}

.phone-comp-info-block {
  margin-top: 0.55rem;
  width: 100%;
}

.phone-comp-info-poster {
  width: 100%;
  height: 2.04rem;
  z-index: 2;
  margin-bottom: 0.14rem;
  display: block;
}

.phone-comp-info-title {
  font-size: 0.21rem;
  font-weight: bold;
  line-height: 0.24rem;
  margin-bottom: 0.09rem;
}

.phone-comp-info-subtitle {
  font-size: 0.21rem;
  line-height: 0.24rem;
  margin-bottom: 0.11rem;
}

.phone-comp-info-desc {
  font-size: 0.16rem;
  font-weight: 200;
  color: #777A81;
  line-height: 0.27rem;
  margin-bottom: 0.25rem;
}

.phone-comp-info-block-cates {
  width: 100%;
}

.phone-comp-info-block-cate {
  display: flex;
  margin-bottom: 0.13rem;
}

.pcib-ico {
  width: 0.05rem;
  height: 0.09rem;
  margin-top: 0.07rem;
  margin-right: 0.08rem;
}

.phone-block-span {
  font-size: 0.16rem;
  color: #0073E6;
  line-height: 0.24rem;
}

.phoneClassItem {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.phoneClassItemImg {
  position: relative;
  width: 17.6rem;
  height: 8.1rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
}

.phoneClassItemArea {
  position: relative;
  display: flex;
  margin-left: 0.6rem;
  width: 18.5rem;
  /* overflow: hidden; */
}

.phoneClassItemTitle {
  font-size: 0.7rem;
  color: #2f4257;
  font-family: "Regular";
  flex-shrink: 0;
  border-top: 2px #eff0f5 solid;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.phoneClassItemTitleIndex {
  border-top: 2px #00a2ff solid;
  font-size: 0.7rem;
  color: #2f4257;
  font-family: "Regular";
  flex-shrink: 0;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.phoneTriangle {
  position: absolute;
  top: 1.5rem;
  border: 0.5rem solid;
  border-color: transparent transparent #f8f8f9 transparent;
  width: 0px;
}

.phoneListContent {
  background-color: #f8f8f9;
  font-size: 0.6rem;
  font-family: "Regular";
  color: #647588;
  margin: 1rem 0.6rem 0 0.6rem;
  padding: 1rem;
  text-align: left;
}

.phoneSuziyinxiao {
  position: relative;
  margin-top: 0.35rem;
  overflow: hidden;
  background-image: url('../assets/phone/phone-yingxiao-bg.png');
  background-size: 100% 100%;
  padding: 0.35rem 0.2rem 0.41rem 0.2rem;
  box-sizing: border-box;
}

.phone-yingxiao-header {
  width: 100%;
  height: 0.32rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.32rem;
}

.phoneSuziyinxiaoTitle {
  font-size: 0.24rem;
  color: #333333;
  font-family: "MeDium";
  margin-right: 1.65rem;
}

.phoneSuziyinxiaoBtn {
  font-size: 0.14rem;
  font-family: "Regular";
  color: #005DFF;
  position: absolute;
  right: 0.2rem;
  top: 0.43rem;
}

.phoneSuziyinxiao video {
  width: 100%;
}

.video-shadow {
  width: 100%;
  height: 1.89rem;
}

.phoneNewsArea {
  width: 100%;
  margin-top: 0.74rem;
}

.phone-news-title {
  font-size: 0.28rem;
  font-weight: bold;
  margin-bottom: 0.12rem;
}

.phone-news-subtitle {
  font-size: 0.28rem;
  margin-bottom: 0.24rem;
}

.phoneNewsArea p {
  font-size: 1.2rem;
  font-family: "MeDium";
  color: #2f4257;
  margin-top: 2rem;
}

.phone-news-list {
  width: 100%;
  padding: 0 0.18rem;
  box-sizing: border-box;
}

.phone-news {
  width: 100%;
  margin-bottom: 0.46rem;
}

.phone-news-picture {
  width: 100%;
  height: 2.04rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.09rem;
}

.phone-news-picture img {
  position: absolute;
  display: block;
}

.phone-news-date {
  width: 100%;
  color: #777A81;
  font-size: 0.16rem;
  font-weight: 200;
  margin-bottom: 0.18rem;
}

.phone-news-desc {
  width: 100%;
  font-size: 0.21rem;
  line-height: 0.27rem;
  text-decoration: underline;
}

.phone-more-btn {
  width: 1.57rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  color: #fff;
  background-color: #0073E6;
  font-size: 0.16rem;
  margin-bottom: 0.8rem;
}

.phone-reward {
  width: 100%;
}

.phone-reward-label {
  width: 100%;
  height: 2.15rem;
  background-color: #141E8C;
  color: #fff;
  padding: 0.74rem 0.18rem 0 0.18rem;
  box-sizing: border-box;
  position: relative;
}

.phone-reward-maintitle {
  font-size: 0.28rem;
  font-weight: bold;
  margin-bottom: 0.12rem;
}

.phone-reward-subtitle {
  font-size: 0.28rem;
  line-height: 0.36rem;
}

.phoneMap {
  position: relative;
  text-align: center;
}

.phoneMapTitle {
  margin-top: 3.5rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  font-family: "MeDium";
  color: #2f4257;
}

.phoneMapBlock {
  width: 17.6rem;
  height: 10.6rem;
  margin: 0 auto;
}

.phoneMapInfo {
  position: relative;
  margin-left: 2.1rem;
  margin-right: 1.25rem;
  text-align: left;
}

.phoneMapInfo img {
  position: absolute;
  transform: translateX(-1.1rem);
  height: 0.7rem;
  width: 0.7rem;
}

.phoneMapInfo div {
  margin-top: 0.5rem;
  font-size: 12px;
  font-family: "Regular";
  color: #647588;
}

.phoneNewsBar {
  position: relative;
  width: 90%;
  height: 2px;
  background-color: #eff0f5;
  margin: 0.6rem auto;
}

.phoneNewsBarIndex {
  position: relative;
  height: 2px;
  width: 100px;
  background-color: #00a2ff;
}

.suziTxt {
  width: 11.68rem;
  line-height: 34px;
  color: #666666;
  font-size: 0.18rem;
  margin-bottom: 30px;
}

.phone-achivement {
  width: 100%;
  height: 5.36rem;
  background-color: #F7F7F7;
  padding: 0.74rem 0.18rem;
  box-sizing: border-box;
}

.phone-achiv-title {
  width: 100%;
  font-weight: bold;
  margin-bottom: 0.12rem;
  font-size: 0.28rem;
}

.phone-achiv-subtitle {
  width: 100%;
  font-size: 0.28rem;
  line-height: 0.37rem;
  margin-bottom: 0.41rem;
}

.phone-achivements {
  width: 100%;
  height: 2.17rem;
  display: grid;
  grid-template-rows: repeat(2, 50%);
  grid-template-columns: repeat(2, 50%);
}

.phone-achiv {
  font-size: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

}

.phone-achiv:nth-child(1),
.phone-achiv:nth-child(3) {
  border-right: 1px solid #DFE4EE;
}

.phone-achiv:nth-child(3),
.phone-achiv:nth-child(4) {
  border-top: 1px solid #DFE4EE;
}

.phone-achiv:nth-child(3),
.phone-achiv:nth-child(4) {
  padding-top: 0.26rem;
}

.phone-achiv-name {
  width: 100%;
  text-align: center;
  font-size: 0.16rem;
  font-weight: 600;
  margin-top: 0.1rem;
}
</style>
