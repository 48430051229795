<template>
    <div class="fiveSystem">
      <div class="levelTitleBG"></div>
      <div class="header">
          <Header :styleChangeIndex="false" :page2Level="true" /> 
          <div class="levelTitle">
              <p class="homeTags" @click="backHome">模拟医学教学 </p><p> > {{infos.title}}</p>
          </div>
      </div>
      <div class="content" ref="content">
        <p class="contentTitle">{{infos.title}}</p>
        <div class="line"></div>
        <p class="basicInfo">基本介绍</p>
        <span class="basicContent" v-html="infos.content"></span>
        <p class="point">优势及特点</p>
        <div class="pointInfo" v-for="(item,index) in infos.select" :key="index">
          <img src="../../assets/picture/打勾.png" class="selectImg">
          <div>{{item.title+item.content}}</div>
        </div>
      </div>
      <div :class="courseFixed?'courseCenterFixed':'courseCenter'">
        <div class="courseTitle"> 课程列表</div>
      </div>
      <div :class="courseFixed?'coureseListFixed':'coureseList'" ref="course">
        <div class="course">
          <div class="courseName" @click="courseClickFn(index)" v-for="(item,index) in infos.courseList" :key="index">
            {{item.courseName}}
            <div class="courseLine" v-show="courseIndex === index"></div>
          </div>
        </div>
      </div>
      <div class="heigth80" v-if="courseFixed"></div>
      <div class="courseInfoArea">
        <div class="courseInfo" v-for="(item,index) in infos.courseList" :key="index" :ref="index">
          <div class="couorseInfoName">{{item.courseName}}</div>
          <div v-for="(item,index) in item.course" :key="index">
            <div class="courseInfoTitle">{{item.courseTitle}}</div>
            <div class="courseInfoContent" v-html="item.courseContent"></div>
            <div v-for="(item,index) in item.courseImgList" :key="index">
              <img :src="item.url" class="courseInfoImg">
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
</template>

<script scoped>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import { info } from "../../common/MedicalInfo.js";

export default {
  name: "fiveSystem",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      infos: [],
      courseIndex: 0,
      courseFixed: false,
      scrollY: ""
    };
  },
  methods: {
    backHome() {
      this.$router.push(`/Medical`);
    },
    courseClickFn(index) {
      this.courseIndex = index;
      let y = this.$refs[index][0].getBoundingClientRect().top; //元素头部到显示器顶部的距离
      let scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      window.scrollTo(0, y + scrollTop - 350);
    },
    scrollFn() {
      let bottom = this.$refs.content.getBoundingClientRect().bottom;
      if (bottom < 0) {
        this.courseFixed = true;
        // 获取滚动方向
        if (this.scrollY == undefined) {
          this.scrollY = window.pageYOffset;
        }
        if (this.scrollY - window.pageYOffset < 0) {
          this.courseFixed = true;
        } else {
          this.courseFixed = false;
        }
        this.scrollY = window.pageYOffset;
      } else {
        this.courseFixed = false;
      }
      //课程列表下划线跟踪
      for (let i = 0; i < this.infos.courseList.length; i++) {
        let y = this.$refs[i][0].getBoundingClientRect().bottom;
        if (y > window.innerHeight) {
          this.courseIndex = i;
          break;
        }
      }
    }
  },
  created() {
    this.global.titleIndex = 2;
    this.infos = info[this.$route.query.index];
    console.log(this.$route.query.index);

  },
  mounted() {
    window.addEventListener("scroll", this.scrollFn);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFn);
  }
};
</script>

<style scoped>
.fiveSystem {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  font-size: 0;
}
.header {
  width: 19.2rem;
  max-width: 1920px;
  margin: 0 auto;
}
.levelTitle {
  margin: 0 auto;
  margin-top: 1.37rem;
  width: 13.88rem;
  max-width: 1388px;
  height: 0.8rem;
  background-color: #F8F8F8;
  font-family: "Regular";
  color: #2f4257;
  line-height: 0.7rem;
}
.levelTitleBG {
  position: absolute;
  margin-top: 1.37rem;
  width: 100%;
  height: 0.8rem;
  background-color: #F8F8F8;
  z-index: -1;
}
.levelTitle p {
  font-size: 0.18rem;
  display: inline;
}
.homeTags:hover {
  cursor: pointer;
}
.content {
  width: 13.88rem;
  max-width: 1388px;
  margin: 0 auto;
  padding-bottom: 1.1rem;
}
.contentTitle {
  margin-top: 1.6rem;
  font-size: 0.36rem;
  font-family: "Regular";
  color: #2f4257;
  margin-bottom: 0.35rem;
}
.line {
  height: 1px;
  width: 100%;
  background-color: #2F4257;
  opacity: 0.2;
}
.basicInfo {
  margin-top: 0.75rem;
  margin-bottom: 0.3rem;
  font-size: 0.24rem;
  font-family: "Regular";
  color: #465567;
}
.basicContent {
  font-size: 0.16rem;
  font-family: "Regular";
  color: #647588;
  line-height: 0.3rem;
}
.point {
  margin-top: 1.1rem;
  margin-bottom: 0.3rem;
  font-size: 0.24rem;
  font-family: "Regular";
  color: #465567;
}
.pointInfo {
  width: 100%;
  font-size: 0.18rem;
  font-family: "Regular";
  color: #647588;
  margin-bottom: 0.2rem;
  display: flex;
}
.pointInfo img {
  transform: translateY(0.02rem);
  margin-right: 0.1rem;
  max-height: 18px;
  max-width: 18px;
}
.heigth80 {
  width: 100%;
  height: 1.6rem;
}
.courseCenter {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 0.4rem;
}
.courseCenterFixed {
  position: fixed;
  top: 0;
  height: 0.6rem;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 0.4rem;
  padding-top: 0.2rem;
  z-index: 1;
}
.courseTitle {
  width: 13.88rem;
  max-width: 1388px;
  margin: 0 auto;
  font-size: 0.24rem;
  font-family: "Regular";
  color: #465567;
}
.coureseList {
  width: 100%;
  height: 0.8rem;
  background-color: #eff0f5;
  z-index: -1;
}
.coureseListFixed {
  position: fixed;
  top: 0.8rem;
  width: 100%;
  height: 0.8rem;
  background-color: #eff0f5;
  z-index: 1;
}
.course {
  width: 13.88rem;
  max-width: 1388px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  align-items: center;
}
.courseName {
  transform: translateY(33%);
  height: 0.8rem;
  flex: 1;
  font-family: "Regular";
  color: #2f4257;
  font-size: 0.18rem;
}
.courseName:hover {
  cursor: pointer;
}
.courseLine {
  position: absolute;
  height: 3px;
  background-color: #00a2ff;
  width: 1rem;
  bottom: 34%;
  left: 50%;
  border-radius: 4px;
  transform: translateX(-0.5rem);
}
.courseInfoArea {
  width: 12.5rem;
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 1.3rem;
}
.courseInfo {
  margin-bottom: 1rem;
}
.couorseInfoName {
  text-align: center;
  font-size: 0.24rem;
  font-family: "Bold";
  color: #465567;
}
.courseInfoTitle {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  font-family: "Bold";
  color: #2f4257;
  font-size: 0.18rem;
}
.courseInfoContent {
  font-family: "Regular";
  color: #2f4257;
  font-size: 0.18rem;
  margin-bottom: 0.15rem;
}
.courseInfoImg {
  position: relative;
  width: 12rem;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-6rem);
  margin-bottom: 0.2rem;
}
</style>

<style>
.bold {
  display: inline;
  font-size: 0.16rem;
  font-family: "Bold";
  color: #2f4257;
}
</style>
