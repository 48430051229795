<template>
  <div class="container">
    <!-- 此元素使得header在下拉改变style时视觉效果不那么突然 -->
    <div
      style="height: 0.96rem;width: 100%;transition: all 0.5s linear;"
      v-if="!headerUnfold && !isPhone"
    ></div>
    <!-- <div style="height: 0.8rem;width: 100%;" v-if="scrollUp &&!isPhone"></div> -->
    <!-- <div :class="scrollUp ? 'headerFix' : 'header'" v-if="!isPhone" @mouseleave="leave"
      :style="{ width: headerWidth + 'px' }">
      <div class="headerMid">
        <img src="../assets/picture/logo.png" class="logo" @click="pageChange(0)" />
        <div class="titleArea">
          <div class="titleItem" v-for="(item, index) in titles.slice(0, 6)" :key="index" @mouseenter="enter(index)"
            @click="pageChange(index)">{{ item.name }}</div>
        </div>
        <div class="contactTitle" @click="scrolltoBottomFn()">
          <img src="../assets/picture/blue-email.png" />
          {{ titles[6].name }}
        </div>
      </div>
      <div v-show="listShow > -1" class="listArea">
        <div class="listItem" v-for="(item, index) in hoverList[mouseIndex]" :key="index"
          @click.self="listClickFn(index)">{{ item.name }}</div>
      </div>
    </div> -->
    <div :class="headerUnfold ? 'header' : 'fold-header'" v-if="!isPhone">
      <div class="header-content">
        <div
          class="header-top"
          :style="{
            height: headerUnfold ? '0.59rem' : '100%',
            marginBottom: headerUnfold ? '0.4rem' : '',
          }"
        >
          <img src="../assets/logo.png" class="logo" @click="toHomePage()" />
          <div
            class="contact"
            :style="{ width: headerUnfold ? '0.9rem' : '1.46rem' }"
          >
            <div
              class="contactTitle"
              @click="gotoContactPage()"
              :style="{ marginRight: headerUnfold ? '' : '0.36rem' }"
            >
              <img src="../assets/picture/blue-email.png" />
              联系我们
            </div>
            <img
              src="../assets/menu.png"
              class="menu"
              v-if="!headerUnfold"
              @click="showSideMenu = true"
            />
          </div>
        </div>
        <div class="header-mid" v-if="headerUnfold">
          <div
            class="page"
            v-for="(item, index) in routeNameBox"
            :key="index"
            @click="pageChange(index)"
            :style="{ fontWeight: setPageFontWeight(index) }"
          >
            {{ item }}
          </div>
        </div>
        <!-- 此元素中的内容应该绑定一个动态的当前路径，表示用户正在浏览的内容 -->
        <div class="header-bottom" v-if="headerUnfold">
          <span :style="{ cursor: getCursorStyle(1) }" @click="gotoRootPage">{{
            block.root
          }}</span>
          <div class="route-children" v-if="block.children">
            <span
              v-for="(c, i) in block.children"
              :key="i"
              :style="{ cursor: getCursorStyle(2, i) }"
              @click="gotoChildPage(i)"
              >{{ " / " + c }}</span
            >
          </div>
          <span v-if="endElement">{{ " / " + endElement }}</span>
        </div>
      </div>
      <div
        class="side-menu"
        v-if="showSideMenu"
        @click.self="showSideMenu = false"
        @mousewheel.capture="mouseWheelOnSideMenu"
      >
        <div class="menu-container">
          <div
            class="menu-option"
            v-for="(route, index) in routeNameBox"
            :key="index"
            :style="{ cursor: titleIndex == index + 1 ? '' : 'pointer' }"
            @click="pageChange(index)"
          >
            {{ route }}
            <img
              src="../assets/arrow-right-grey.png"
              class="arrow-ico"
              v-if="titleIndex !== index + 1"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 手机 -->
    <div
      style="height: 0.79rem;width: 100%;transition: all 0.5s linear;"
      v-if="!phoneHeaderUnfold && isPhone"
    ></div>
    <div
      :class="phoneHeaderUnfold ? 'phone-header' : 'phone-fold-header'"
      v-if="isPhone"
    >
      <div class="logo-and-button">
        <img src="../assets/phone-logo.jpg" class="phone-logo" />
        <div class="button-group">
          <div class="phone-contact" @click="gotoContactPage()">
            <img src="../assets/email.jpg" class="c-ico" />
            联系我们
          </div>
          <img
            src="../assets/menu.png"
            class="phone-menu"
            @click="showPhoneNavigator()"
          />
        </div>
      </div>
      <div class="path" v-if="phoneHeaderUnfold">
        <div class="gradient"></div>
        <span @click="gotoRootPage" style="margin-right:0.06rem;">{{
          block.root
        }}</span>
        <div class="route-children" v-if="block.children">
          <span
            v-for="(c, i) in block.children"
            :key="i"
            @click="gotoChildPage(i)"
            style="margin-right:0.06rem;"
            >{{ " / " + c }}</span
          >
        </div>
        <span v-if="endElement">{{ " / " + endElement }}</span>
      </div>
      <div class="phone-navigation-bar" v-if="showPhoneNavigationBar">
        <div class="grey-side" @click="hidePhoneNavigator"></div>
        <div class="bar">
          <div class="bar-title">空岛科技</div>
          <div class="bar-options">
            <div
              class="bar-option"
              v-for="(page, index) in routeNameBox"
              :key="index"
            >
              <div class="option" @click="pageChange(index)">
                {{ page }}
                <img src="../assets/arrow-right-grey.png" class="opt-ico" />
              </div>
            </div>
          </div>
          <div class="bar-current-location">
            <div>当前位置:</div>
            <div class="location">
              <div class="gradient"></div>
              <span @click="gotoRootPage" style="margin-right:0.06rem;">{{
                block.root
              }}</span>
              <div class="route-children" v-if="block.children">
                <span
                  v-for="(c, i) in block.children"
                  :key="i"
                  @click="gotoChildPage(i)"
                  style="margin-right:0.06rem;"
                  >{{ " / " + c }}</span
                >
              </div>
              <span v-if="endElement">{{ " / " + endElement }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PageBlocks } from "../../public/data/define";
export default {
  name: "Header",
  props: {
    styleChangeIndex: Boolean,
    page2Level: Boolean,
    endElement: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      titles: [
        {
          name: "关于我们",
        },
        {
          name: "数字营销",
        },
        {
          name: "临床专科",
        },
        {
          name: "医学院校",
        },
        {
          name: "合作伙伴",
        },
        {
          name: "其他行业",
        },
        {
          name: "联系我们",
        },
      ],
      routerBox: [
        "Robot",
        "DeviceTraining",
        "Digital",
        "Medical",
        "Simulation",
        "Partner",
      ],
      routeNameBox: [
        "机器人手术模拟器",
        "高端医疗器械培训",
        "数字营销",
        "临床专科",
        "医学院校",
        "合作伙伴",
        "其他行业",
      ],
      // routerBox: ["Home", "Digital", "Medical", "Simulation", "Partner"],
      // routeNameBox: ['关于我们', '数字营销', '临床专科', '医学院校', '合作伙伴', '其他行业'],
      titleIndex: this.global.titleIndex, //当前页面页标
      scrollIndex: 0,
      homeImgWidth: 880,
      scrollUp: false,
      scrollY: undefined,
      listShow: -1,
      styleChange: false,
      hoverList: [
        [
          {
            name: "公司介绍",
            site: 15,
          },
          {
            name: "数字营销",
            site: 20,
          },
          {
            name: "最新消息",
            site: 25,
          },
          {
            name: "获奖情况",
            site: 30,
          },
        ],
        [
          {
            name: "App/Web端 3D开发",
            site: 15,
          },
          {
            name: "VR系统开发",
            site: 20,
          },
          {
            name: "3D打印服务",
            site: 15,
          },
          {
            name: "混合现实",
            site: 20,
          },
        ],
        [
          {
            name: "专科综合教学平台",
            site: 15,
          },
          {
            name: "专科单病种教学内容平台",
            site: 20,
          },
          {
            name: "3D医学打印服务",
            site: 25,
          },
          {
            name: "远程手术/查房直播系统",
            site: 30,
          },
          {
            name: "全景/高清/立体手术直录播服务",
            site: 30,
          },
          {
            name: "临床与教学科研支持",
            site: 30,
          },
        ],
        [
          {
            name: "执业/助理医师规范化培训虚拟仿真教学平台",
            site: 15,
          },
          {
            name: "基础手术虚拟仿真和VR教学平台",
            site: 20,
          },
          {
            name: "3D医学打印服务",
            site: 25,
          },
          {
            // name: "护理/养老康复教学平台",
            name: "养老护理康复与健康管理平台",
            site: 25,
          },
          {
            name: "远程教学直播系统",
            site: 25,
          },
        ],
        [],
        [],
      ],
      whiteHeaderBGShow: false,
      mouseIndex: -1,
      isPhone: false,
      phoneList: false,
      phoneHeaderFix: false,
      // 头部宽度
      headerWidth: 0,
      phoneMenu: [
        {
          showOpt: false,
          title: "关于我们",
          options: ["公司介绍", "数字营销", "最新消息", "获奖情况"],
        },
        {
          showOpt: false,
          title: "数字营销",
          options: ["App/Web端 3D开发", "VR系统开发", "3D打印服务", "混合现实"],
        },
        {
          showOpt: false,
          title: "临床专科",
          options: [
            "专科综合教学平台",
            "专科单病种教学内容平台",
            "3D医学打印服务",
            "远程手术/查房直播系统",
            "全景/高清/立体手术直录播服务",
            "临床与教学科研支持",
          ],
        },
        {
          showOpt: false,
          title: "医学院校",
          options: [
            "执业/助理医师规范化培训虚拟仿真教学平台",
            "基础手术虚拟仿真和VR教学平台",
            "3D医学打印服务",
            "护理/养老康复教学平台",
            "远程教学直播系统",
          ],
        },
        {
          showOpt: false,
          title: "合作伙伴",
          options: [],
        },
      ],
      phoneListWidth: window.innerWidth,
      phoneListHeight: window.innerHeight,
      headerUnfold: true,
      block: null,
      showSideMenu: false,
      showPhoneNavigationBar: false,
      phoneHeaderUnfold: true,
    };
  },
  methods: {
    pageChange(index) {
      // console.log(index);
      // if (index == this.global.titleIndex + 1) return;
      if (index === 6) {
        this.global.userAction(7);
        window.location.href = "https://other.spacemax.com.cn";
      } else {
        this.global.titleIndex = index + 1;
        this.$router.push({
          name: this.routerBox[index],
        });
      }
    },
    scrollFn() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop)

      if (this.isPhone) {
        this.phoneHeaderUnfold = scrollTop > 20 ? false : true;
      } else {
        this.headerUnfold = scrollTop > 20 ? false : true;
      }
    },
    mouseWheelOnSideMenu(event) {
      event.preventDefault();
      event.stopPropagation();
      return;
    },
    haederStyleChangeFn() {
      if (
        this.scrollIndex > this.homeImgWidth / 2 - 85 ||
        this.styleChangeIndex
      ) {
        this.styleChange = true;
      } else {
        this.styleChange = false;
      }
    },
    setPageFontWeight(index) {
      if (index == this.routeNameBox.length - 1) {
        return "";
      } else {
        const page = this.routerBox[index];

        if (page == this.$route.name) {
          return `bold`;
        }
      }
    },
    resizeFn() {
      this.homeImgWidth = window.innerWidth - 6; //获取首页大图宽度，减去滚动条的6px宽度

      this.headerWidth = document.querySelector("#app").clientWidth;

      // 手机浏览器头部导航栏消失导致浏览器窗口大小发生变化
      if (this.global.isPhone) {
        this.phoneListWidth = window.innerWidth;
        this.phoneListHeight = window.innerHeight;
      }
    },
    enter(index) {
      if (index === 4 || index === 5) {
        this.listShow = -1;
        return;
      }
      this.listShow = index;
      this.whiteHeaderBGShow = true;
      this.styleChange = true;
      this.mouseIndex = index;
    },
    leave() {
      if (this.scrollIndex !== 0) {
        this.listShow = -1;
        this.whiteHeaderBGShow = false;
        return;
      }
      this.listShow = -1;
      this.whiteHeaderBGShow = false;
      this.styleChange = false;
    },
    listClickFn(index) {
      // console.log(this.mouseIndex, index);
      if (this.titleIndex === this.mouseIndex) {
        this.$emit("headerListClickFn", index + 1);
      } else {
        this.global.titleIndex = this.mouseIndex;
        this.$router.push({
          name: this.routerBox[this.mouseIndex],
          params: {
            y: index + 1,
          },
        });
      }
    },
    phoneListFn() {
      this.phoneList = !this.phoneList;
      // 收起次级菜单
      for (let item of this.phoneMenu) {
        item.showOpt = false;
      }
      this.$emit("phoneVideoSwitch", this.phoneList);
    },
    phoneHeaderMoveFn() {
      let middle = document.getElementsByClassName("phoneMidPushInfo");
      //获取元素下边到视图上边的距离
      if (middle[0]) {
        let x = middle[0].getBoundingClientRect().bottom;
        if (x < 0 && this.scrollUp) {
          this.phoneHeaderFix = true;
        } else {
          this.phoneHeaderFix = false;
        }
      }
    },
    scrolltoBottomFn() {
      window.scrollTo(0, document.body.clientHeight);
    },
    gotoContactPage() {
      this.global.titleIndex = 9;
      this.$router.push({
        name: "Contact",
      });
    },
    onListItemTouch(index) {
      if (
        this.phoneMenu[index].options.length > 0 &&
        !this.phoneMenu[index].showOpt
      ) {
        this.showSecMenu(index);
        return;
      }
      this.pageChange(index);
    },
    showSecMenu(index) {
      for (let i = 0; i < this.phoneMenu.length; i++) {
        if (index !== i) {
          this.phoneMenu[i].showOpt = false;
        }
      }

      this.phoneMenu[index].showOpt = true;
    },
    onSecMenuTouch(index, idx) {
      console.log(index, idx);
      if (index == this.global.titleIndex + 1) {
        this.$emit("phoneScrollMoveFn", idx);
        this.phoneListFn();
      } else {
        this.global.titleIndex = index + 1;
        this.$router.push({
          name: this.routerBox[index],
          params: {
            y: idx,
          },
        });
      }
    },

    setCurrentBlock() {
      const name = this.$route.name;
      const block = PageBlocks.find((block) => {
        return block.name == name;
      });

      if (!block) {
        this.currentBlock = "";
      } else {
        this.block = block;
      }
    },

    gotoRootPage() {
      this.$router.push({
        name: this.block.rootName,
      });
    },

    gotoChildPage(index) {
      this.$router.push({
        name: this.block.childrenNames[index],
      });
    },

    getCursorStyle(type, index = -1) {
      if (type == 1) {
        if (this.block.rootName != this.$route.name) {
          return "pointer";
        }
      } else if (type == 2) {
        if (this.block.childrenNames[index] !== this.$route.name) {
          return "pointer";
        }
      }
      return "";
    },

    showPhoneNavigator() {
      this.showPhoneNavigationBar = true;
    },

    hidePhoneNavigator() {
      this.showPhoneNavigationBar = false;
    },

    toHomePage() {
      this.global.titleIndex = 0;

      this.$router.push({
        name: "Home",
      });
    },
  },

  created() {
    this.homeImgWidth = window.innerWidth - 6;
    if (this.styleChangeIndex) {
      this.styleChange = true;
    }
    if (this.global.isPhone) {
      this.isPhone = true;
    }

    this.setCurrentBlock();
  },
  mounted() {
    this.headerWidth = document.querySelector("#app").clientWidth;
    window.addEventListener("scroll", this.scrollFn);
    window.addEventListener("resize", this.resizeFn);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFn);
    window.removeEventListener("resize", this.resizeFn);
  },
  computed: {},
  watch: {
    styleChangeIndex() {
      this.scrollUp = true;
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background-color: #ffffff;
  font-family: "Regular";
  box-sizing: border-box;
  z-index: 10;
  width: 100%;
  height: 2.28rem;
  padding: 0.46rem 0 0.19rem 0;
  transition: all 0.5s linear;
  /* border-bottom: 1px solid #cccccc; */
  overflow: hidden;
}

.fold-header {
  position: fixed;
  width: 100%;
  height: 0.96rem;
  z-index: 10;
  background-color: #fff;
  box-sizing: border-box;
  /* border-bottom: 1px solid #cccccc; */
  transition: all 0.5s linear;
  overflow: hidden;
}

.header-content {
  width: 14.4rem;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.header-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.59rem;
  margin-bottom: 0.4rem;
  transition: all 0.5s linear;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s linear;
}

.menu {
  width: 0.2rem;
  height: 0.16rem;
  cursor: pointer;
}

.header-mid {
  width: 100%;
  display: flex;
  margin-bottom: 0.24rem;
}

.page {
  margin-right: 0.4rem;
  font-size: 0.16rem;
  color: #000000;
  cursor: pointer;
  user-select: none;
}

.header-bottom {
  font-size: 0.14rem;
  color: #777a81;
  display: flex;
  align-items: center;
}

.header-bottom span {
  margin-right: 0.1rem;
}

.route-children {
  font-size: 0.14rem;
  color: #777a81;
  display: flex;
  align-items: center;
}

.side-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.16);
}

.menu-container {
  width: 5rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 12;
  background-color: #fff;
  padding: 0.25rem 0.24rem;
  box-sizing: border-box;
}

.menu-option {
  width: 100%;
  height: 1.02rem;
  border-bottom: 1px solid #dfe4ee;
  display: flex;
  padding: 0 0.16rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-size: 0.36rem;
  font-weight: 300;
  color: #32373e;
  user-select: none;
}

.arrow-ico {
  width: 0.12rem;
  height: 0.205rem;
}

.headerFix {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  /* height: 80px; */
  height: 0.8rem;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #cccccc;
  box-sizing: border-box;
  z-index: 10;
}

.headerMid {
  position: relative;
  /* width: calc(100% - 60px); */
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  /* max-width: 1440px; */
  /* padding: 0 30px; */
  width: 75%;
}

.bgChange {
  position: fixed;
  background-color: white;
  /* left: 0; */
  width: 100%;
  height: 1.37rem;
  z-index: 10;
}

.bgFixed {
  position: absolute;
  z-index: 10;
}

.level2PageStyle {
  position: absolute;
  top: 0;
  z-index: 10;
}

.level2PageStyleFixed {
  position: fixed;
  top: 0;
  z-index: 10;
}

.page2LevelBg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 1.37rem;
  background-color: white;
  z-index: 0;
}

.logo {
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* width: 18%; */
  /* width: 2.57rem; */
  /* height: 0.155rem; */
  cursor: pointer;
  width: 2.72rem;
  height: 0.59rem;
}

.titleArea {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  text-align: center;
  width: fit-content;
  height: 100%;
}

.titleItem {
  position: relative;
  padding: 0 16px;
  font-size: 16px;
  color: #333333;
  height: calc(100% - 1px);
  display: flex;
  align-items: center;
}

.contactTitle {
  /* position: absolute;
  right: 0px;
  top: 50%; */
  /* transform: translateY(-50%); */
  font-size: 16px;
  color: #005dff;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.5s linear;
}

.contactTitle img {
  /* position: relative; */
  height: 100%;
  transform: translateY(1px);
}

.titleItem:hover {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, #00a8ff, #005dff);
  border-image-slice: 10;
}

.fontWhite {
  color: #cad4e0;
}

.whiteClick {
  color: #fefefe;
}

.fontBlue {
  color: #647588;
}

.blueClick {
  color: #2f4257;
}

.line {
  position: absolute;
  width: 0.54rem;
  min-width: 37px;
  height: 2px;
  background-color: #00a2ff;
  top: 0.3rem;
}

.listArea {
  position: absolute;
  width: 100%;
  color: #ffffff;
  top: 100%;
  z-index: -1;
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
}

.listItem {
  color: #666666;
  font-size: 14px;
  font-family: "Regular";
  margin: 17px 16px;
  white-space: nowrap;
  cursor: pointer;
}

.listItem:hover {
  color: #005dff;
}

.headerWhiteBg2 {
  position: fixed;
  height: 1.37rem;
  left: 0;
  top: 0;
  background-color: #ffffff;
  width: 100%;
  z-index: -1;
}

/* phone's css */
.phoneHeaderFix {
  position: fixed;
  top: 0;
  left: 0;
  height: 0.44rem;
  width: 100%;
  background-color: #ffffff;
  z-index: 10;
}

.phoneHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.44rem;
  background-color: #ffffff;
  z-index: 10;
}

.phoneLogo {
  position: absolute;
  top: 0.16rem;
  left: 0.2rem;
  width: 1.84rem;
  height: 0.12rem;
}

.phoneListBtn {
  position: absolute;
  top: 0.16rem;
  right: 0.2rem;
  width: 0.16rem;
  z-index: 12;
}

.phoneList {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  z-index: 11;
}

.phoneListArea {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.phoneListItem {
  width: 100%;
  min-height: 1.3rem;
  font-size: 0.18rem;
  color: #2f4257;
  font-family: "Regular";
  padding: 0.53rem 0 0 0.36rem;
  box-sizing: border-box;
}

.phoneListItem:nth-child(2),
.phoneListItem:nth-child(4) {
  background-color: #f4f4f4;
}

.phoneListItem:nth-child(3) {
  background-color: #ededed;
}

.phone-list-text {
  margin-bottom: 0.05rem;
}

.phone-underline {
  width: 0.36rem;
  height: 1px;
  background-color: #333333;
  margin-bottom: 0.32rem;
}

.phone-sec-menu {
  width: 100%;
}

.phone-sec-option {
  width: 100%;
  font-size: 0.16rem;
  font-weight: 400;
  color: #333333;
  padding-bottom: 0.24rem;
  box-sizing: border-box;
}

.phone-header {
  width: 100%;
  height: 1.32rem;
  padding: 0.24rem 0.18rem 0.14rem 0.18rem;
  box-sizing: border-box;
  transition: all 0.5s linear;
  background-color: #fff;
}

.phone-fold-header {
  width: 100%;
  height: 0.79rem;
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 0.24rem 0.18rem 0.14rem 0.18rem;
  box-sizing: border-box;
  background-color: #fff;
  transition: all 0.5s linear;
}

.logo-and-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.22rem;
}

.phone-logo {
  width: 1.48rem;
  height: 0.32rem;
}

.button-group {
  height: 0.32rem;
  display: flex;
  align-items: center;
}

.phone-contact {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  color: #0073e6;
  line-height: 0.32rem;
  margin-right: 0.21rem;
}

.c-ico {
  width: 0.21rem;
  height: 0.15rem;
  margin-right: 0.05rem;
}

.phone-menu {
  width: 0.19rem;
  height: 0.16rem;
}

.path {
  width: 100%;
  display: flex;
  height: 0.18rem;
  line-height: 0.18rem;
  align-items: center;
  overflow: hidden;
  font-size: 0.13rem;
  color: #777a81;
  white-space: nowrap;
  margin-top: 0.44rem;
  position: relative;
}

.phone-navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  width: 100vw;
  height: 100%;
}

.grey-side {
  width: 0.96rem;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
}

.bar {
  width: calc(100% - 0.96rem);
  height: 100%;
  background-color: #fff;
}

.bar-title {
  width: 100%;
  height: 0.52rem;
  border-bottom: 1px solid #777a81;
  line-height: 0.52rem;
  box-sizing: border-box;
  padding: 0 0.24rem;
  font-size: 0.16rem;
}

.bar-options {
  width: 100%;
}

.bar-option {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.21rem 0 0.42rem;
  color: #32373e;
  font-size: 0.16rem;
}

.bar-option:not(:last-child) {
  border-bottom: 1px solid #dfe4ee;
}

.option {
  width: 100%;
  height: 0.52rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opt-ico {
  width: 0.08rem;
  height: 0.16rem;
}

.bar-current-location {
  width: 100%;
  padding: 0.14rem 0.24rem;
  font-size: 0.13rem;
  color: #777a81;
  border-top: 1px solid #777a81;
  line-height: 0.19rem;
  box-sizing: border-box;
}

.location {
  width: 100%;
  height: 0.19rem;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  position: relative;
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 25%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8)
  );
  z-index: 2;
}
</style>
