const homeData = {
  //swiper图片列表格式为[头,...,尾,头]
  swiperList: [
    {
      url: "/picture/home/1.png",
      preUrl: "/picture/home/1.jpg",
      show: false,
      title: "用科技打造特色医学教育",
      subtitle:
        "专注特色专科医学与模拟医学领域，通过最新前沿技术，用科技打造特色医学教育",
    },
    {
      // url: "/picture/home/2.png",
      url: "/picture/home/2.jpg",
      preUrl: "/picture/home/2.jpg",
      show: false,
      title: "开发国产模拟器替代进口产品",
      subtitle: "帮助血管介入术式向各级医院普及",
    },
    {
      // url: "/picture/home/3.png",
      url: "/picture/home/3.jpg",
      preUrl: "/picture/home/3.jpg",
      show: false,
      title: "提升培训效率，降低手术风险",
      subtitle: "构建标准，降本增效",
    },
    {
      url: "/picture/home/1.png",
      preUrl: "/picture/home/1.jpg",
      show: false,
      title: "用科技打造特色医学教育",
      subtitle:
        "专注特色专科医学与模拟医学领域，通过最新前沿技术，用科技打造特色医学教育",
    },
  ],
  siwperContentList: [
    {
      title: "用科技打造<br/>特色医学教育",
      info:
        "专注特色专科医学与模拟医学领域，<br/>通过最新前沿技术，用科技打造特色医学教育",
      url: "",
    },
    {
      title: "微软Hololens MRPP <br/>官方合作伙伴",
      info: "虚实融合医学教育入选<br/>2022微软Holoens合作伙伴精选案例",
      url: "",
    },
    {
      title: "世界VR大会虚拟现实医疗<br/>应用白皮书撰写方",
      info:
        "连续两届（2019-2020）参与工信部、赛迪研究院<br/>举办“世界VR大会（VR+医学健康）”行业应用白皮书撰写",
      url: "",
    },
  ],
  companyInfoSwiperData: [
    {
      title: `执业/助理医师规范化培训虚拟仿真教学平台
        `,
      content:
        "系统基于医师规范化培训需求，使用三维重建、虚拟现实、虚实融合等技术，对24项临床技能进行全流程，供操作者进行模拟学习、临床教学。临床基本操作技能的项目几乎涉及所有临床科室的医疗工作，帮助医生系统、全面、扎实地掌握医学基础知识临床基本技能操作是临床教学工作的核心内容。执业/助理医师规范化培训教学系统立足于医师规范化培训24项临床技能，涵盖内科类（胸腔穿刺术、腹腔穿刺术、骨髓穿刺术、桡动脉穿刺采血、胃肠减压、腰椎穿刺术）、外科类拆线、穿脱手术衣（单人）、穿脱手术衣（双人）、换药、开放性伤口的止血包扎、脓肿切开术、切开缝合、清创术、手术区消毒铺巾、洗手法以及流程类（穿脱隔离衣，导尿术、脊柱损伤的搬运、简易呼吸器的应用、四肢骨折现场急救固定、吸痰术、心肺复苏、吸氧术）等全部内容",
      url: "/picture/home/info/01.png",
    },
    {
      title: "基础手术<br/>虚拟仿真和VR教学平台",
      content:
        "基础手术教学平台，是运用虚拟仿真技术进行临床操作技能及临床基础手术的模拟训练，满足临床技能中心医学院校对于虚拟仿真教学的需求，教学课程根据临床专科进行分类管理，训练内容与临床接轨，在医学院与临床之间架起桥梁，通过模拟临床实际场景，消除学生对于临床的陌生感，在临床实际操作之前，强化医学生对于操作流程的记忆",
      url: "/picture/home/info/02.png",
    },
    {
      title: "3D医学打印服务",
      content:
        "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术。3D打印运用于医疗领域常与三维重建技术相结合，通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印。常用材料有用于骨骼打印的光敏树脂和用于内脏或血管的类橡胶材料",
      url: "/picture/home/info/03.png",
    },
    {
      title: "远程手术/查房/教学直播系统",
      content:
        "在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。5G+VR探视可以有效避免医生和家属进入病房时遇到交叉感染的情况，增加感染隐患，减少医生和家属探视时所需使用防护用品和衣物，减少不必要资源损耗，减少医生和家属每次穿防护服或防护衣物时间，减少无效时间",
      url: "/picture/home/info/04.png",
    },
    {
      title: "全景/高清/立体手术直录播服务",
      content:
        "5G+临床手术术野融合直播系统，是通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面，形成画中画。让观看直播的用户通过手机、VR头盔等多终端身临其境观看手术。全景画面融合医生手术术野画面或者医学影像画面",
      url: "/picture/home/info/05.png",
    },
    {
      title: "...",
      content:
        "公司基于XR技术在临床科研领域已经和多家医院在专科领域产生了广泛的合作，目前已涵盖心血管、超声、神经外科、泌尿科、骨科脊椎外科、精神科等科室，基于医工结合的对于领域，应用于术前规划、疼痛管理、精神治疗、专科医学教学等不同领域。公司致力于使用VR/AR/MR 、计算机图形学和三维打印技术等，支持临床科研工作，形成专利、软件著作权和内容版权等，同时提供相关技术领域的科研和学术支持",
      url: "/picture/home/info/06.png",
    },
    {
      title: "...",
      content: "",
      url: "/picture/home/info/07.png",
    },
    {
      title: "...",
      content: "",
      url: "/picture/home/info/08.png",
    },
    {
      title: "...",
      content: "",
      url: "/picture/home/info/09.png",
    },
    {
      title: "...",
      content: "",
      url: "/picture/home/info/10.png",
    },
  ],
  rewardList: [
    {
      time: "2024年",
      content: ["2024工信部《元宇宙应用场景认定导则》医疗元宇宙场景撰写方"],
    },
    {
      time: "2023年",
      content: [
        "2023年 世界VR大会年度创新奖项",
        "强生加速器中国区成员",
        "Meta医疗生态合作伙伴",
        "Intel中国研究院合作伙伴",
      ],
    },
    {
      time: "2022年",
      content: [
        "（2019-2022）连续四年“世界VR产业大会”VR/AR年度创新奖",
        "ISO 27001 计算机软件开发相关的信息安全管理活动",
        "ISO20000  向外部客户提供计算机应用软件开发服务",
        "国家专精特新企业",
        "微软Hololens MRPP 合作伙伴优秀案例",
      ],
    },
    {
      time: "2021年",
      content: [
        "首届CHITEC“英特尔杯”数字医疗健康创新服务优秀案例大赛全国二等奖",
        "（2019-2021）连续三年“世界VR产业大会”VR/AR年度创新奖",
        `微软Hololens全球合作伙伴`,
        "西门子医疗上海创新中心成员",
        "上海市高新技术企业",
        "IOS 9001 国际质量管理体系认证",
        "IOS 14001国际环境管理体系认证",
      ],
    },
    {
      time: "2020年",
      content: [
        "连续两年“世界VR产业大会”虚拟现实+医疗应用官方白皮书技术撰写方",
        "HTC VIVE行业生态大会医疗行业展示方",
        `微软Hololens混合现实MRPP官方合作伙伴`,
        "美敦力中国创新加速器成员",
      ],
    },
    {
      time: "2019年",
      content: [
        "参展SESAM欧洲模拟医学大会（首个国内XR领域企业）",
        "“世界VR产业大会”虚拟现实+医疗应用官方白皮书技术撰写方",
      ],
    },
    {
      time: "2018年",
      content: [
        "虚拟与增强现实产业生态高峰论坛暨“91913VR”奖颁奖盛典最佳VR应用",
        "网龙科技全国VR创新大赛“多人大空间解决方案”最具行业创新奖",
        "VR世界大会中国VR创新创业大赛全国优胜奖",
        "华为“CLOUD VR+2B场景白皮书”案例入围",
      ],
    },
    {
      time: "2017年",
      content: [
        "中国电子商会虚拟现实市场促进委员会第二届中国虚拟现实行业创新奖",
        "HTC VIVE全球虚拟现实开发者大会行业应用组评比优秀奖",
      ],
    },
  ],
  PhoneRewardList: [
    {
      time: "2017年",
      content: [
        "中国电子商会虚拟现实市场促进委员会<br>第二届中国虚拟现实行业创新奖<br><br>",
        "HTC VIVE全球虚拟现实开发者大会<br>行业应用组评比优秀奖",
      ],
    },
    {
      time: "2018年",
      content: [
        "虚拟与增强现实产业生态高峰论坛<br>暨“91913VR”奖颁奖盛典最佳VR应用<br><br>",
        "网龙科技全国VR创新大赛“多人大空间解决方<br>案”最具行业创新奖<br><br>",
        "VR世界大会中国VR创新创业大赛<br>全国优胜奖<br><br>",
        "华为“CLOUD VR+2B场景白皮<br>书”案例入围",
      ],
    },
    {
      time: "2019年",
      content: [
        "参展SESAM欧洲模拟医学大会<br>（首个国内XR领域企业）<br><br>",
        "“世界VR产业大会”虚拟现实+医疗应用<br>官方白皮书技术撰写方",
      ],
    },
    {
      time: "2020年",
      content: [
        "连续两年“世界VR产业大会”虚拟现实+医疗<br>应用官方白皮书技术撰写方<br><br>",
        "HTC VIVE行业生态大会<br>医疗行业展示方<br><br>",
        `微软Hololens混合现实MRPP官方合作伙伴<br><br>`,
        "美敦力中国创新加速器成员",
      ],
    },
    {
      time: "2021年",
      content: [
        "首届CHITEC“英特尔杯”数字医疗健康创新<br>服务优秀案例大赛全国二等奖<br><br>",
        "（2019-2021）连续三年“世界VR产业大<br>会”VR/AR年度创新奖<br><br>",
        "西门子医疗上海创新中心成员<br><br>",
        "上海市高新技术企业<br><br>",
        "IOS 9001 国际质量管理体系认证<br><br>",
        "IOS 14001国际环境管理体系认证",
      ],
    },
  ],
  newsList: {
    title: "最新消息",
    subtitle: "了解最新资讯",
    news: [
      {
        id: 1, //映射allnews中的news本体,作为index使用
        picture: "/picture/home/news/20230529.jpg",
        pictureType: "vertical",
        date: "2023/05/29",
        desc:
          "步入元宇宙，打开心世界--空岛科技亮相第17届东方心脏病学会议（OCC 2023）",
      },
      {
        id: 2, //映射allnews中的news本体
        picture: "/picture/home/news/20230518.jpg",
        pictureType: "vertical",
        date: "2023/05/18",
        desc: "空岛科技携模拟医学临床数字化培训亮相2023中国国际医疗器械博览会",
      },
      {
        id: 3, //映射allnews中的news本体
        picture: "/picture/home/news/20230320.jpg",
        pictureType: "vertical",
        date: "2023/03/20",
        desc: "空岛科技加入强生中国创新加速器JLABS",
      },
      {
        id: 4,
        picture: "/picture/home/news/20230316.jpg",
        pictureType: "vertical",
        date: "2023/03/16",
        desc: "空岛科技诚邀您参加第58-59届中国高等教育博览会",
      },
      {
        id: 5,
        picture: "/picture/home/news/20230305.jpg",
        pictureType: "vertical",
        date: "2023/03/05",
        desc: "空岛科技亮相第二届中国医院协会模拟医学专业委员会年会",
      },
      {
        id: 6,
        picture: "/picture/home/news/20230223.jpg",
        pictureType: "vertical",
        date: "2023/02/23",
        desc: "独家！2023医学可视化营销十大应用场景",
      },
    ],
  },
  companyInfo: {
    title: "公司介绍",
    subtitle: "欢迎您的来访",
    desc:
      "&nbsp&nbsp&nbsp&nbsp&nbsp空岛科技SpaceMax总部位于中国上海，是一家专注特色医学教育培训的国家专精特新、国家高新技术企业。公司成立至今已获得ISO9001，14001，20000，27001多项国际管理体系认证。已获得拥有发明专利和软件著作权60余项。公司从2016至今获得国内外行业专项奖项与认可超过30余项，与国内外优秀头部企业长期建立业务合作与交流，深耕国内模拟医学教育培训市场，提高医疗培训效率，降低培训成本，减少医疗事故发生。\
               <br/><br/>&nbsp&nbsp&nbsp&nbsp&nbsp公司拥有经验丰富的临床医生、系统部署与运营团队以及健全的元宇宙XR技术研发团队。长期为全国三甲医院、临床专家、医学院校、医疗机构提供多元化多模拟医学培训产品与系统。长期提供临床操作技能训练、临床思维训练、行业专家经验转化，通过结合最新元宇宙技术、医学三维可视化、多人远程交互、虚实融合操作、力反馈、空间微定位、三维影像数据重建、多模态数据整合与传输等最新前沿技术。公司目前拥有众多系统与产品：护理学、基础手术平台、执业医师24项、心脑血管专科培训系统、超声模拟器、养老护理康复与健康管理平台、全景术野融合手术直录播系统、远程查房和探视系统、多人协同虚实融合培训系统、3D医学打印等系列产品。\
               <br/><br/>&nbsp&nbsp&nbsp&nbsp&nbsp空岛科技目前服务数十家全球顶级医疗厂商、几十家全国重点三甲医院、近百家医学院校、提供系统和培训时间超过11万小时。结合创新“医工结合”研发，临床医生和专家的丰富经验，赋能广大年轻医师、医疗客户、医学院师生培训效率，提供长期专业、稳定、多模块嵌入式培训系统。根据最终培训效果与目的，适配用户操作的穿戴式设备与便携式硬件，达到量化的培训过程与培训结果。",
    blocks: [
      {
        picture: "/picture/home/info/1.png",
        prePicture: "/picture/home/info/1.jpg",
        showImg: false,
        blockTitle: "执业/助理医师规范化培训虚拟仿真教学平台",
        blockSubtitle: "规范化医师培训，涵盖24项临床技能全流程",
        blockDesc:
          "软件涉及所有临床科室的医疗工作，帮助医生系统、全面、扎实地掌握医学基础知识临床基本技能操作",
        categories: [
          {
            name: "缝合术",
            page: "Simulation",
            block: 0,
            blockIndexInPhone: 0,
          },
          {
            name: "三腔二囊管",
            page: "Simulation",
            block: 0,
            blockIndexInPhone: 1,
          },
          {
            name: "导尿术",
            page: "Simulation",
            block: 1,
            blockIndexInPhone: 2,
          },
          {
            name: "胸腔穿刺术",
            page: "Simulation",
            block: 1,
            blockIndexInPhone: 3,
          },
          {
            name: "腰椎穿刺术",
            page: "Simulation",
            block: 1,
            blockIndexInPhone: 4,
          },
          {
            name: "心肺复苏",
            page: "Simulation",
            block: 2,
            blockIndexInPhone: 5,
          },
          {
            name: "气管插管",
            page: "Simulation",
            block: 2,
            blockIndexInPhone: 6,
          },
        ],
      },
      {
        picture: "/picture/home/info/2.png",
        prePicture: "/picture/home/info/2.jpg",
        showImg: false,
        blockTitle: "基础手术虚拟仿真和VR教学平台",
        blockSubtitle: "模拟真实手术室环境，全方位沉浸式体验",
        blockDesc:
          "运用虚拟仿真技术进行临床操作技能及临床基础手术的模拟训练，满足临床技能中心医学院校对于虚拟仿真教学的需求；教学课程根据临床专科进行分类管理，通过模拟临床实际场景，消除学生对于临床的陌生感，强化医学生对操作流程的记忆",
        categories: [
          {
            name: "包皮环切术",
            page: "Simulation",
            block: 3,
            blockIndexInPhone: 7,
          },
          {
            name: "腹经子宫下段剖宫产术",
            page: "Simulation",
            block: 4,
            blockIndexInPhone: 8,
          },
          {
            name: "甲状腺次全切术",
            page: "Simulation",
            block: 4,
            blockIndexInPhone: 9,
          },
          {
            name: "阑尾切除术",
            page: "Simulation",
            block: 4,
            blockIndexInPhone: 10,
          },
          {
            name: "体表肿物切除",
            page: "Simulation",
            block: 5,
            blockIndexInPhone: 11,
          },
          {
            name: "围术期压疮管理",
            page: "Simulation",
            block: 5,
            blockIndexInPhone: 12,
          },
        ],
      },
      {
        picture: "/picture/home/info/3.png",
        prePicture: "/picture/home/info/3.jpg",
        showImg: false,
        blockTitle: "专科综合教学平台",
        blockSubtitle: "规范化医师培训，涵盖24项临床技能全流程",
        blockDesc:
          "根据医学图像进行三维成像，建立临床数据库，应用设备提高教学效率",
        categories: [
          {
            name: "心血管综合教学平台",
            page: "Medical",
            block: 0,
            blockIndexInPhone: 0,
          },
          {
            name: "超声综合教学平台",
            page: "Medical",
            block: 0,
            blockIndexInPhone: 1,
          },
          {
            name: "神经外科综合教学平台",
            page: "Medical",
            block: 0,
            blockIndexInPhone: 2,
          },
        ],
      },
      {
        picture: "/picture/home/info/4.png",
        prePicture: "/picture/home/info/4.jpg",
        showImg: false,
        blockTitle: "专科单病种教学内容平台",
        blockSubtitle: "适配多终端（PC/VR/小程序），在不同场景下都可以学习",
        blockDesc:
          "软件针对用户不同需求，多模式提高学习效率；用户可以全面学习手术操作流程",
        categories: [
          {
            name: "甲状腺（小程序/VR课件/手术拍摄）",
            page: "Medical",
            block: 1,
            blockIndexInPhone: 3,
          },
          {
            name: "乳腺癌手术及护理（测量/半径/乳腺癌围术期护理/VR术后康复）",
            page: "Medical",
            block: 1,
            blockIndexInPhone: 4,
          },
        ],
      },
      {
        picture: "/picture/home/info/5.png",
        prePicture: "/picture/home/info/5.jpg",
        showImg: false,
        blockTitle: "最新MR虚拟融合教学平台",
        blockSubtitle: "结合传统教学模具，虚实融合交互，上手实操更顺手",
        blockDesc:
          "辅助医院/医学院日常教学，利用HoloLens设备协同构建在线虚拟课堂；设备有很强的灵活性，携带方便",
        categories: [
          {
            name: "导尿术虚实融合训练系统",
            page: "Simulation",
            block: 6,
            blockIndexInPhone: 13,
          },
          {
            name: "硬膜外麻醉虚实融合训练系统",
            page: "Simulation",
            block: 6,
            blockIndexInPhone: 14,
          },
          {
            name: "多人协同正骨实训系统",
            page: "Simulation",
            block: 6,
            blockIndexInPhone: 15,
          },
        ],
      },
      {
        picture: "/picture/home/info/6.png",
        prePicture: "/picture/home/info/6.jpg",
        showImg: false,
        blockTitle: "公共卫生教学平台",
        blockSubtitle: "加强疫情防护意识，减少感染率",
        blockDesc:
          "可作为教学软件，辅助公共卫生相关专业的教师进行课堂讲学；也可做为体验课程，进行公共卫生及防疫科普",
        categories: [
          {
            name: "疫情防护仿真培训平台",
            page: "Simulation",
            block: 10,
            blockIndexInPhone: 22,
          },
          {
            name: "公共卫生事件模拟教学系统",
            page: "Simulation",
            block: 10,
            blockIndexInPhone: 23,
          },
        ],
      },
      {
        picture: "/picture/home/info/7.png",
        prePicture: "/picture/home/info/7.jpg",
        showImg: false,
        blockTitle: "养老护理康复与健康管理平台",
        blockSubtitle: "改善老年人生活质量和健康水平",
        blockDesc:
          "将真实数据可视化呈现，搜集整理临床资料，搭建患者调研系统，培养对老年人的认知",
        categories: [
          {
            name: "护理（压疮/吸氧/吸痰）",
            page: "Simulation",
            block: 8,
            blockIndexInPhone: 17,
          },
          {
            name: "养老（长护险和心理认知症）",
            page: "Simulation",
            block: 8,
            blockIndexInPhone: 18,
          },
          {
            name: "健康管理平台",
            page: "Simulation",
            block: 7,
            blockIndexInPhone: 16,
          },
        ],
      },
      {
        picture: "/picture/home/info/8.png",
        prePicture: "/picture/home/info/8.jpg",
        showImg: false,
        blockTitle: "3D医学打印服务",
        blockSubtitle: "医学影像三维数字化重建，材料定制化打印",
        blockDesc:
          "3D打印运用于医疗领域常与三维重建技术相结合，通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印。",
        categories: [
          {
            name: "医学模拟器",
            page: "Medical",
            block: 2,
            blockIndexInPhone: 5,
          },
          {
            name: "临床病理打印",
            page: "Medical",
            block: 2,
            blockIndexInPhone: 6,
          },
          {
            name: "定制化医学临床教具",
            page: "Simulation",
            block: 9,
            blockIndexInPhone: 19,
          },
          {
            name: "骨科正骨",
            page: "Simulation",
            block: 9,
            blockIndexInPhone: 20,
          },
          {
            name: "齿科",
            page: "Simulation",
            block: 9,
            blockIndexInPhone: 21,
          },
        ],
      },
      {
        picture: "/picture/home/info/9.png",
        prePicture: "/picture/home/info/9.jpg",
        showImg: false,
        blockTitle: "人体生命科学馆",
        blockSubtitle:
          "通过5G+XR技术，结合最新人工智能、大数据分析、元宇宙技术",
        blockDesc:
          "人体生命科学馆可以展示针对医学发展史、解剖学、药学等不同学科及科普设定的专业展馆，通过专题人体馆项可以体现众多药学、解剖学馆藏、医学发展史的学术价值以及从中体现出来的人性关怀。",
        categories: [],
      },
      {
        picture: "/picture/home/info/10.png",
        prePicture: "/picture/home/info/10.jpg",
        showImg: false,
        blockTitle: "远程手术/查房/教学直播系统",
        blockSubtitle: "远程查房、探视，减少医护干扰、交叉感染",
        blockDesc:
          "通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。",
        categories: [],
      },
      {
        picture: "/picture/home/info/11.png",
        prePicture: "/picture/home/info/11.jpg",
        showImg: false,
        blockTitle: "全景/高清/立体手术直录播服务",
        blockSubtitle: "通过5G网络，融合医生手术术野画面或者医学影像画面",
        blockDesc:
          "通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面，形成画中画。",
        categories: [],
      },
      {
        picture: "/picture/home/info/12.png",
        prePicture: "/picture/home/info/12.jpg",
        showImg: false,
        blockTitle: "临床与教学科研支持",
        blockSubtitle: "支持临床科研工作，提供学术支持",
        blockDesc:
          "公司致力于使用VR/AR/MR 、计算机图形学和三维打印技术等，支持临床科研工作，形成专利、软件著作权和内容版权等，同时提供相关技术领域的科研和学术支持。",
        categories: [],
      },
    ],
  },
  achievement: {
    title: "来自全球专业医疗企业",
    subtitle: "国内著名三甲医院与省级医学院校长期信任与支持",
    details: [
      {
        name: "全球顶尖医疗企业",
        content: "10+",
        numbers: [1, 0],
      },
      {
        name: "三甲医院",
        content: "50+",
        numbers: [5, 0],
      },
      {
        name: "省会级院校",
        content: "100+",
        numbers: [1, 0, 0],
      },
      {
        name: "医师体验时长（小时）",
        content: "11W+",
        numbers: [1, 1],
        unit: "W",
      },
    ],
  },
  inPageNavigations: ["关于我们", "数字营销", "最新消息", "获奖情况"],
};

export { homeData };
