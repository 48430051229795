<template>
    <div id="phone-data-info">
        <div class="back" @click="back">
            <img src="../assets/phone/back-icon.png" class="back-icon">
            <div class="back-text">返回上级</div>
        </div>
        <div class="info-content">
            <div class="main-cate">{{ data.title }}</div>
            <div class="sec-cate" v-if="data.info.name">{{ data.info.name }}</div>
            <div class="dividing-line"></div>

            <div class="contents">
                <div class="desc">
                    <div class="title">{{ data.info.data[0].titleA }}</div>
                    <div class="text">{{ data.info.data[0].content[0].txt }}</div>
                </div>

                <div class="title">{{ data.info.data[1].titleA }}</div>
                <div class="func" v-for="(item, index) in data.info.data[1].content" :key="index">
                    <div class="sec-title" v-if="item.txt">
                        <img src="../assets/phone/dot.png" class="dot">
                        {{ item.titleB }}
                    </div>
                    <div class="content">
                        <div class="text">{{ item.txt ? item.txt : item.titleB }}</div>
                        <img v-for="(img, idx) in item.img" :key="idx" :src="global.AssetsURL + img" class="img">
                    </div>
                </div>

                <div class="title">{{ data.info.data[2].titleA }}</div>
                <div class="feature" v-for="(item, index) in data.info.data[2].content" :key="index">
                    <div class="sec-title" v-if="item.txt">
                        <img src="../assets/phone/dot.png" class="dot">
                        {{ item.titleB }}
                    </div>
                    <div class="content">
                        <div class="text">{{ item.txt ? item.txt : item.titleB }}</div>
                        <img v-for="(img, idx) in item.img" :key="idx" :src="global.AssetsURL + img" class="img">
                    </div>
                </div>

                <div class="button">
                    <div class="btn">联系我们 </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhoneDataInfo',
    props: {
        data: Object
    },
    created(){
      this.global.titleIndex = 11;
    },
    methods: {
        back() {
            this.$emit("clearDataItemInfo");
        }
    }
}
</script>

<style scoped>
#phone-data-info {
    width: 100%;
    padding-top: 0.12rem;
    box-sizing: border-box;
}

.back {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.2rem;
    box-sizing: border-box;
}

.back-icon {
    width: 0.13rem;
    display: block;
    margin-right: 0.09rem;
}

.back-text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #333333;
}

.info-content {
    width: 100%;
    padding: 0.21rem 0.2rem 0.36rem 0.2rem;
    box-sizing: border-box;
}

.main-cate {
    font-size: 0.24rem;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.1rem;
}

.sec-cate {
    font-size: 0.12rem;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0.17rem;
}

.dividing-line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #AAAAAA;
    margin-bottom: 0.36rem;
}

.contents {
    width: 100%;
}

.title {
    font-size: 0.18rem;
    font-weight: 700;
    color: #005DFF;
    margin-bottom: 0.17rem;
}

.text {
    font-size: 0.13rem;
    font-weight: 400;
    line-height: 0.24rem;
    color: #666666;
}

.desc {
    width: 100%;
    margin-bottom: 0.36rem;
}

.button {
    width: 100%;
    height: 0.36rem;
    display: flex;
    justify-content: center;
    margin-top: 0.36rem;
}

.btn {
    width: 1.33rem;
    height: 0.36rem;
    text-align: center;
    line-height: 0.36rem;
    background: linear-gradient(to right, #00A8FF, #005DFF);
    font-size: 0.13rem;
    font-weight: 700;
    color: #FFFFFF;
    border-radius: 0.06rem;
}

.func {
    margin-bottom: 0.36rem;
}

.feature {
    width: 100%;
    margin-bottom: 0.2rem;
}

.content {
    width: 100%;
    padding: 0 0.14rem;
    box-sizing: border-box;
}

.sec-title {
    font-size: 0.14rem;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.13rem;
    display: flex;
    align-items: center;
}

.dot {
    width: 0.06rem;
    height: 0.06rem;
    margin-right: 0.08rem;
}

.img {
    width: 100%;
    height: 1.81rem;
    margin-top: 0.12rem;
    display: block;
}
</style>