<template>
  <div>
    <Header :endElement="showData.content" />
    <!-- PC端 -->
    <div class="news-info" v-if="!isPhone">
      <div class="info">
        <!-- <div class="title"> -->
        <div class="newInfoDate"><span>日期</span>{{ showData.time }}</div>
        <div class="newsDataTitle">{{ showData.content }}</div>
        <!-- <div class="newsDataTitle2">{{ showData.title2 }}</div> -->
        <!-- </div> -->
        <!-- <div class="title2" v-if="titleInfo">
          <div class="newInfoDate"><span>日期</span>{{ showData.time }}</div>
          <div class="newsDataTitle">{{ showData.title }}</div>
          <div class="newsDataTitle2">{{ showData.title2 }}</div>
        </div> -->
      </div>
      <!-- <Middle></Middle> -->
      <div class="newsInfo">
        <!-- <img :src="global.AssetsURL + showData.url" style="margin-top: 0.6rem;" /> -->
        <!-- <img src="../assets/2.gif" alt="" /> -->
        <div
          class="newsDataArea"
          v-for="(item, index) in showData.data"
          :key="index"
        >
          <div v-if="item.txt" class="newsDataTxt" v-html="item.txt"></div>
          <img
            v-if="item.img"
            :src="global.AssetsURL + item.img"
            class="newsDataImg"
          />
        </div>
      </div>
      <Marketing></Marketing>
      <div class="otherNewsInfo">
        <div class="otherArticles">其它文章</div>
        <div class="viceTitle">快速浏览您想知道的信息</div>
        <div class="otherNews">
          <div
            class="otherOneNew"
            v-for="(item, index) in otherData"
            :key="index"
            @click="showOtherNews(item)"
          >
            <div class="otherOneNewImg">
              <img
                :src="global.AssetsURL + item.url"
                class="img"
                v-if="!item.height"
              />
              <img
                :src="global.AssetsURL + item.url"
                class="img1"
                v-if="item.height"
              />
            </div>
            <div class="otherTitle">{{ item.content }}</div>
            <div class="otherTitleTwo">{{ item.desc }}</div>
            <div class="otherInfoDate"><span>日期</span>{{ item.time }}</div>
          </div>
        </div>
      </div>
      <!-- <Footer style="margin-top:120px" /> -->
    </div>
    <!-- 移动端 -->
    <div class="phoneNews" v-if="isPhone">
      <div class="phoneInfoTitle">
        <div></div>
        <div class="phoneNewInfoDate"><span>日期</span>{{ showData.time }}</div>
        <div class="phoneNewsDataTitle">{{ showData.content }}</div>
      </div>
      <div class="phoneNewsInfo">
        <!-- <img :src="global.AssetsURL + showData.url" style="margin-top: 0.6rem;" /> -->
        <!-- <img src="../assets/2.gif" alt="" /> -->
        <div
          class="phoneNewsDataArea"
          v-for="(item, index) in showData.data"
          :key="index"
        >
          <div v-if="item.txt" class="phoneNewsDataTxt" v-html="item.txt"></div>
          <img
            v-if="item.img"
            :src="global.AssetsURL + item.img"
            class="phoneNewsDataImg"
          />
        </div>
      </div>
      <Marketing></Marketing>

      <div class="phoneOtherArticles">其它文章</div>
      <div class="phoneViceTitle">快速浏览您想知道的信息</div>
      <div
        class="phoneOtherOneNew"
        v-for="(item, index) in otherData"
        :key="index"
        @click="showOtherNews(item)"
      >
        <!-- <div></div> -->
        <div class="allTopImgBlock">
          <img
            :src="global.AssetsURL + item.url"
            class="phoneImg"
            v-if="!item.height"
          />
          <img
            :src="global.AssetsURL + item.url"
            class="phoneImg1"
            v-if="item.height"
          />
        </div>
        <div class="phoneOthersInfo">
          <p class="phoneOtherTitle">{{ item.content }}</p>
          <p class="phoneOtherInfoDate"><span>日期</span>{{ item.time }}</p>
        </div>
      </div>
    </div>
    <Footer style="margin-top:120px" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Marketing from "./Marketing.vue";
// import { setInterval } from "timers";
export default {
  name: "NewsInfo",
  components: {
    Header,
    Footer,
    Marketing,
  },
  // props: {
  //   data: Array,
  //   index: Number
  // },
  data() {
    return {
      showData: null,
      showIndex: Number,
      data: null,
      otherData: null,
      otherIndex: Number,
      imgIsShow: false,
      dataOne: null,
      titleInfo: false,
      isPhone: false,
    };
  },
  methods: {
    trnasChangeFn(index) {
      if (index) {
        if (this.showIndex > 0) this.showIndex--;
      } else {
        if (this.showIndex < this.data.length - 1) this.showIndex++;
      }
      this.showData = this.data[this.showIndex];
      // this.scrollFn();
    },
    menuSelectFn(index) {
      this.showIndex = index;
      this.showData = this.data[this.showIndex];
    },
    imageLoad() {
      this.imgIsShow = true;
    },
    gotoHomeFn() {
      this.$router.back();
      // this.$router.push({
      //   name: "Home",
      // });
    },
    showOtherNews(item) {
      // this.showData = this.otherData[index];
      console.log(item);
      this.titleInfo = true;
      // let title = document.querySelector(".title");
      // title.style.bottom = "-2.56rem";
      (this.otherIndex = this.data.findIndex((val) => {
        return val.id === item.id;
      })),
        this.$router.push({
          name: "NewsInfo",
          query: {
            data: JSON.stringify(this.data),
            index: this.otherIndex,
          },
        });
    },
    // startInfo() {
    //   let title = document.querySelector(".title");
    //   title.style.bottom = 0;
    // },
  },
  computed: {},
  created() {
    if (this.global.isPhone) {
      this.isPhone = true;
    }
    this.global.titleIndex = 10;
    let data = this.$route.query;
    this.showIndex = data.index;
    this.data = JSON.parse(data.data);
    this.dataOne = JSON.parse(data.data);
    this.showData = this.data[this.showIndex];
    this.otherData = this.dataOne.splice(this.showIndex + 1, 4);
    for (let i = 0; i < 4; i++) {
      if (this.otherData.length == 4) return;
      this.otherData.push(this.data[i]);
    }
  },
  mounted() {
    // this.scrollFn();
    // this.startInfo();
    // setInterval(() => {
    //   if (this.titleInfo) {
    //     let title = document.querySelector(".title2");
    //     title.style.bottom = 0;
    //     this.titleInfo = false;
    //   }
    // }, 100);
  },
  destroyed() {},
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.index != undefined) {
          let data = this.$route.query;
          this.showIndex = data.index;
          this.data = JSON.parse(data.data);
          this.showData = this.data[data.index];
          this.dataOne = JSON.parse(data.data);
          this.otherData = this.dataOne.splice(this.showIndex + 1, 4);
          for (let i = 0; i < 4; i++) {
            if (this.otherData.length == 4) return;
            this.otherData.push(this.data[i]);
          }
        }
      },
    },
    showIndex(newData) {
      console.log(newData);
      // this.startInfo();
    },
  },
};
</script>

<style scoped>
.news-info {
  width: 100%;
  position: relative;
}

.info {
  width: 13.5rem;
  height: 2.54rem;
  padding: 0 0.45rem;
  /* background: aqua; */
  margin: 0.5rem auto;
  position: relative;
  background: #f7f7f7;
  overflow: hidden;
  /* overflow: hidden; */
}
/* .info .title {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
} */

.newInfoDate {
  font-size: 0.14rem;
  font-family: _Regular;
  color: #777a81;
  margin-top: 0.42rem;
  /* margin-left: 0.35rem; */
}
.newInfoDate span {
  color: #0073e6;
  padding-right: 0.1rem;
}
.newsInfo {
  position: relative;
  width: 7.52rem;
  margin: 0 auto;
}
.newsInfo img {
  width: 100%;
  margin: 0.3rem 0;
}
.leftMenu {
  position: relative;
  width: 2.92rem;
  flex-shrink: 0;
  overflow: hidden;
  font-family: "Regular";
}

.leftMenuTitle {
  /* margin-top: 0.67rem; */
  margin-top: 0.73rem;
  width: 100%;
  /* font-size: 16px; */
  font-size: 0.16rem;
  line-height: 0.21rem;
  color: #666666;
  padding-bottom: 0.11rem;
  border-bottom: 1px solid #aaaaaa;
}

.leftMenuBlock {
  position: relative;
  width: 100%;
  height: 5.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: -moz-scrollbars-none;
}

.leftMenuBlock::-webkit-scrollbar {
  width: 0 !important;
}

.leftMenuBlock {
  -ms-overflow-style: none;
}

.timePoint {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  top: 50%;
  transform: translateY(-30%);
}

.leftMenuItem {
  position: relative;
  margin-top: 0.3rem;
  cursor: pointer;
}

.leftMenuItemTime {
  position: relative;
  font-size: 14px;
  color: #333333;
}

.leftMenuItemTxt {
  position: relative;
  font-size: 16px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0.05rem;
}

.newsData {
  position: relative;
  margin-left: 1.2rem;
}

.newsDataTitle {
  width: 100%;
  position: relative;
  font-size: 0.48rem;
  margin-top: 0.15rem;
  /* margin-top: 0.25rem;
  margin-left: 0.35rem; */
  color: #333333;
  font-family: "Bold";
  font-weight: 700;
  text-align: justify;
}
.newsDataTitle2 {
  width: 95%;
  font-size: 0.48rem;
  margin-left: 0.35rem;
  color: #32373e;
  font-family: "Regular";
  line-height: 0.6rem;
  margin-top: 0.1rem;
}
.newsDataTime {
  position: relative;
  /* font-size: 16px; */
  font-size: 0.16rem;
  line-height: 0.21rem;
  color: #666666;
  padding-bottom: 8px;
  border-bottom: 1px solid #aaaaaa;
  font-family: "Regular";
}

.newsData img {
  position: relative;
  width: 100%;
}

.newsDataTxt {
  position: relative;
  font-size: 16px;
  color: #666666;
  line-height: 32px;
  font-family: "Regular";
  text-align: justify;
}
.allNewsBtnArea {
  position: relative;
  margin: 0 auto;
  margin-top: 0.64rem;
  height: 0.48rem;
  display: flex;
  justify-content: space-around;
  user-select: none;
  font-family: "Regular";
}

.leftBtn,
.rightBtn {
  position: relative;
  height: calc(0.48rem - 2px);
  width: calc(0.48rem - 2px);
  margin: 0 0.18rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #333333;
  font-size: 0.4rem;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 0.08rem 0.16rem;
}

.leftBtn {
  background-image: url("../assets/left.png");
  background-position: 50% 50%;
}

.leftBtn:hover {
  background-image: url("../assets/left_hover.png");
  border: 1px solid #005dff;
}

.leftBtn:active {
  background-image: url("../assets/icon_left_click.png");
  background-size: 100% 100%;
  background-position: 0% 0%;
  border: 1px solid rgba(0, 0, 0, 0);
}

.rightBtn {
  background-image: url("../assets/right.png");
  background-position: 50% 50%;
}

.rightBtn:hover {
  background-image: url("../assets/right_hover.png");
  border: 1px solid #005dff;
}

.rightBtn:active {
  background-image: url("../assets/icon_right_click.png");
  background-size: 100% 100%;
  background-position: 0% 0%;
  border: 1px solid rgba(0, 0, 0, 0);
}

.leftBtn div {
  position: absolute;
  font-size: 16px;
  white-space: nowrap;
  left: 140%;
}

.rightBtn div {
  position: absolute;
  font-size: 16px;
  white-space: nowrap;
  left: -240%;
}

.backBtn {
  position: absolute;
  top: 0;
  left: -1.35rem;
  color: #333333;
  font-size: 0.16rem;
  margin-top: 0.37rem;
  margin-bottom: 0.78rem;
  cursor: pointer;
}

.left-arrow {
  width: 0.05rem;
  height: 0.1rem;
}
.otherNewsInfo {
  width: 14.4rem;
  margin: 0 auto;
  margin-top: 1.4rem;
}
.otherArticles {
  position: relative;
  font-size: 0.48rem;
  width: fit-content;
  color: #333333;
  font-family: "Bold";
}
.viceTitle {
  font-size: 0.48rem;
  font-family: "Regular";
  margin-bottom: 0.64rem;
}
.otherNews {
  width: 100%;
  height: 4.92rem;
  display: flex;
  justify-content: space-between;
}
.otherOneNew {
  width: 3.28rem;
  height: 100%;
  border: 1px solid #dfe4ee;
  position: relative;
  cursor: pointer;
}
.otherOneNewImg {
  width: 100%;
  height: 1.9rem;
  overflow: hidden;
}
.otherOneNewImg .img {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.otherOneNewImg .img1 {
  position: relative;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.otherTitle {
  width: calc(100% - 0.4rem);
  padding: 0 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.24rem;
  font-family: "Bold";
  font-weight: 700;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
}
.otherTitleTwo {
  width: calc(100% - 0.4rem);
  padding: 0 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.24rem;
  font-family: "Regular";
  font-weight: 100;
  margin-bottom: 0.2rem;
}
.otherInfoDate {
  font-size: 0.14rem;
  font-family: _Regular;
  color: #777a81;

  position: absolute;
  bottom: 0.2rem;
  left: 0.25rem;
}
.otherInfoDate span {
  color: #0073e6;
  padding-right: 0.1rem;
}
.phoneNews {
  width: calc(100% - 0.36rem);
  padding: 0 0.18rem;
  position: relative;
}
.phoneInfoTitle {
  width: calc(100% - 0.373333rem);
  padding: 0 0.1866667rem;
  background: #f7f7f7;
  overflow: hidden;
  margin-bottom: 0.6rem;
}
.phoneNewInfoDate {
  font-size: 0.13333rem;
  font-family: _Regular;
  color: #777a81;
  margin-top: 0.773333rem;
}
.phoneNewInfoDate span {
  color: #0073e6;
  padding-right: 0.1rem;
}
.phoneNewsDataTitle {
  font-size: 0.28rem;
  font-family: "Bold";
  font-weight: 700;
  margin-top: 0.1077778rem;
  margin-bottom: 0.706667rem;
  text-align: justify;
}
.phoneNewsInfo {
  position: relative;
  width: 100%;
}
.phoneNewsInfo img {
  width: 100%;
  margin: 0.3rem 0;
}
.phoneNewsDataTxt {
  position: relative;
  font-size: 16px;
  color: #666666;
  line-height: 32px;
  font-family: "Regular";
  text-align: justify;
}

.phoneOtherArticles {
  font-size: 0.28rem;
  font-family: "Bold";
  font-weight: 700;
  margin-top: 0.5rem;
}
.phoneViceTitle {
  font-size: 0.28rem;
  font-family: "Regular";
  margin-top: 0.12333rem;
  margin-bottom: 0.30667rem;
}
.phoneOtherOneNew {
  width: 100%;
  margin-top: 0.4rem;
  border: 1px solid #dfe4ee;
}
.allTopImgBlock {
  width: 100%;
  height: 2.03667rem;
  overflow: hidden;
}
.phoneImg {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.phoneImg1 {
  position: relative;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.phoneOthersInfo {
  width: 89%;
  margin: 0 auto;
  margin-bottom: 0.176667rem;
}
.phoneOtherTitle {
  width: 100%;
  font-size: 0.21rem;
  font-family: "Bold";
  font-weight: 700;
  text-align: justify;
  margin-top: 0.19333rem;
  margin-bottom: 0.236667rem;
}
.phoneOtherInfoDate {
  font-size: 0.13333rem;
  font-family: _Regular;
  color: #777a81;
}
.phoneOtherInfoDate span {
  color: #0073e6;
  padding-right: 0.1rem;
}
</style>
