import {
  ServerTypographical
} from "./define";
const MedicalData = {
  // 轮播图待替换
  swiperImgData: [{
      url: "/picture/medical/swiper/s1.jpg",
    },
    {
      url: "/picture/partner/s2.png",
    },
  ],
  siwperContentList: [{
      title: "专科综合教学平台",
      info: "",
      url: "true",
    },
    {
      title: "专科单病种教学内容平台",
      info: "",
      url: "true",
    },
    {
      title: "3D医学打印服务",
      info: "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术...",
      url: "true",
    },
    {
      title: "远程手术/查房<br>直播系统",
      info: "在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现...",
      url: "true",
    },
    {
      title: "全景/高清/立体手术<br>直录播服务",
      info: "5G+临床手术术野融合直播系统，是通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面...",
      url: "true",
    },
    {
      title: "临床与教学科研支持",
      info: "公司基于XR技术在临床科研领域已经和多家医院在专科领域产生了广泛的合作，目前已涵盖心血管、超声、神经外科、泌尿科、骨科脊椎外科、精神科等科室，基于医工结合...",
      url: "true",
    },
  ],
  items: [{
      title: "专科综合教学平台",
      class: ["心血管综合教学平台", "超声综合教学平台", "神经外科综合教学平台"],
      info: [
        "我司将多技术融合于一体，运用虚拟现实、虚拟仿真、医学模拟、3D打印等不同技术打造心血管专科综合教学平台，根据教学目的的不同，有针对性的进行教学产品的研发。平台内容包括：虚拟现实教学系统、8K + 4K全景手术拍摄、虚拟仿真教学系统、心血管医学模拟器、临床病例管理系统等。",
        "超声模拟数据科研教学数据系统，将超声临床病例的数据以三维的形式进行储存、调取、拼合、重现，对于临床科研可以起到数据研究和重现的作用，将数据系统化归类储存建立系统化的超声影像立体数据库，同时支持软硬件平台适配常规电脑和笔记本进行模拟操作训练和远程更新，具有更大的使用推广面和普适效果，让更多基层医师可以得到思维训练。",
        "神经外科是以手术为主要手段，医治中枢神经系统、周围神经系统和植物神经系统疾病的一门临床专科。神经外科专科综合教学平台是针对神经外科教学及临床需求，设计有医学教学内容（VR端及PC端）、临床工作内容（手术直播、MR术中指导）以及临床专科延伸功能（3D打印）。",
      ],
      video: [
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E5%BF%83%E8%A1%80%E7%AE%A1.mp4",
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E8%B6%85%E5%A3%B0.mp4",
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E7%A5%9E%E7%BB%8F%E5%A4%96%E7%A7%91.mp4",
      ],
      data: [{
          name: "心血管综合教学平台",
          icon: [{
              title: "高拟真场景",
              img: "/icon/专科综合教学平台/心血管综合教学平台/SVG/高拟真场景.svg",
            },
            {
              title: "临床病例库",
              img: "/icon/专科综合教学平台/心血管综合教学平台/SVG/临床病例库.svg",
            },
            {
              title: "多技术融合",
              img: "/icon/专科综合教学平台/心血管综合教学平台/SVG/多技术融合.svg",
            },
            {
              title: "体系化管理",
              img: "/icon/专科综合教学平台/心血管综合教学平台/SVG/体系化管理.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "我司将多技术融合于一体，运用虚拟现实、虚拟仿真、医学模拟、3D打印等不同技术打造心血管专科综合教学平台，根据教学目的的不同，有针对性的进行教学产品的研发。平台内容包括：虚拟现实教学系统、8K VR+4K高清全景手术拍摄、虚拟仿真教学系统、心血管医学模拟器、临床病例管理系统等。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "虚拟现实教学系统",
                  txt: "通过虚拟现实在线学习平台对VR教学课程、手术教学视频、病例资源等进行统一管理，通过教学管理平台进行人员、资源、题库、训练记录等方面的管理<br>使用全景8K+4K超高清拍摄模式，采集术中的全手术室场景、主刀特写画面、DSA术中影像、术中B超影像等各种数据信号，经后期处理后上云分类储存，使用者可通过在线学习平台登陆进行在线手术学习<br>VR教学课件模拟临床病理的情况，还原临床介入手术的操作流程，结合临床病理影像，操作者虚拟的操作与真实DSA影像对应匹配，最大限度的还原临床实际操作场景。课件经评分考核系统及临床考试题库与教学管理平台相连通，通过教学管理平台管理记录和统计操作者的操作结果，根据需要随时更新临床考试题库。尽可能满足临床教学需求<br>课件内容包括：桡动脉穿刺、器械准备、冠脉造影、经皮冠状动脉球囊血管成形术、血管内超声、冠状动脉支架植入术等",
                  img: ["/picture/medical/1/1/资源 1.png"],
                },
                {
                  titleB: "虚拟仿真教学系统",
                  txt: "利用3D技术搭建虚拟情景，模拟临床常用心血管介入技术及对应解剖结构。系统采用轻量化的设计，操作者可在移动端及PC端登陆系统，通过点击、拖拽等轻交互的方式利用碎片时间随时随地进行学习体验。课件内容包括：桡动脉穿刺、股动脉穿刺、股静脉穿刺、心包穿刺、冠脉造影、经皮冠脉内血管成形术等",
                  img: [
                    "/picture/medical/1/1/资源 2.png",
                    "/picture/medical/1/1/资源 3.png",
                  ],
                },
                {
                  titleB: "教学管理系统",
                  txt: "建立教学管理体系，通过统一的平台对账号数据、教学资源、教学课件等内容进行体系化管理",
                  img: [
                    "/picture/medical/1/1/资源 4.png",
                    "/picture/medical/1/1/资源 5.png",
                  ],
                },
                {
                  titleB: "综合考评系统",
                  txt: "基于真实血管介入手术流程与标准，建立血管介入手术综合评价体系，通过记录操作者的操作结果进行系统化评分并生成测评报告",
                  img: [
                    "/picture/medical/1/1/资源 6.png",
                    "/picture/medical/1/1/资源 7.png",
                  ],
                },
                {
                  titleB: "心血管医学模拟器",
                  txt: "采用真实临床影像数据，结合三维重建、3D打印等技术制作全3D打印的软体仿真心脏，并根据医学教学、专业学习、临床科研等不同用途进行整体设计开发，满足不同领域、不同用途的模拟器需求",
                  img: [
                    "/picture/medical/1/1/资源 8.png",
                    "/picture/medical/1/1/资源 9.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "高拟真场景",
                  txt: "虚拟现实课件根据临床实景还原手术室环境及临床操作，根据临床操作习惯进行操作还原，操作者可在虚拟场景中重现影像辅助下操作的过程",
                },
                {
                  titleB: "临床病例库",
                  txt: "将大量临床术中的DSA(Digital Subtraction Angiography)、IVUS(intravascular ultrasound imaging)等影像数据归纳总结，建立心血管介入手术临床病例库，方便临床病例整理，为临床科研、专科学习等提供帮助",
                },
                {
                  titleB: "多技术融合",
                  txt: "根据不同临床教学需求及场景，有针对性提供不同技术的解决方案，可根据实际需求灵活搭配",
                },
                {
                  titleB: "体系化管理",
                  txt: "通过统一入口的教学平台对教学行为进行体系化管理",
                },
              ],
            },
          ],
        },
        {
          name: "超声综合教学平台",
          icon: [{
              title: "病例数据库",
              img: "/icon/专科综合教学平台/超声综合教学平台/SVG/病例数据库.svg",
            },
            {
              title: "多人观摩教学",
              img: "/icon/专科综合教学平台/超声综合教学平台/SVG/多人观摩教学.svg",
            },
            {
              title: "分层设计",
              img: "/icon/专科综合教学平台/超声综合教学平台/SVG/分层设计.svg",
            },
            {
              title: "高效率诊断",
              img: "/icon/专科综合教学平台/超声综合教学平台/SVG/高效率诊断.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "超声模拟数据科研教学数据系统，将超声临床病例的数据以三维的形式进行储存、调取、拼合、重现，对于临床科研可以起到数据研究和重现的作用，将数据系统化归类储存建立系统化的超声影像立体数据库，同时支持软硬件平台适配常规电脑和笔记本进行模拟操作训练和远程更新，具有更大的使用推广面和普适效果，让更多基层医师可以得到思维训练",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "超声VR教学系统",
                  txt: "通过虚拟现实在线学习平台对VR教学课程、手术教学视频、病例资源等进行统一管理，通过教学管理平台进行人员、资源、题库、训练记录等方面的管理<br>使用全景8K+4K超高清拍摄模式，采集术中的全手术室场景、主刀特写画面、DSA术中影像、术中B超影像等各种数据信号，经后期处理后上云分类储存，使用者可通过在线学习平台登陆进行在线手术学习<br>VR教学课件模拟临床病理的情况，还原临床介入手术的操作流程，结合临床病理影像，操作者虚拟的操作与真实DSA影像对应匹配，最大限度的还原临床实际操作场景。课件经评分考核系统及临床考试题库与教学管理平台相连通，通过教学管理平台管理记录和统计操作者的操作结果，根据需要随时更新临床考试题库。尽可能满足临床教学需求<br>课件内容包括：桡动脉穿刺、器械准备、冠脉造影、经皮冠状动脉球囊血管成形术、血管内超声、冠状动脉支架植入术等",
                  img: [
                    "/picture/medical/1/2/资源 1.png",
                    "/picture/medical/1/2/资源 2.png",
                  ],
                },
                {
                  titleB: "超声PC端仿真教学数据系统",
                  txt: "利用3D技术搭建虚拟情景，模拟临床常用心血管介入技术及对应解剖结构。系统采用轻量化的设计，操作者可在移动端及PC端登陆系统，通过点击、拖拽等轻交互的方式利用碎片时间随时随地进行学习体验。课件内容包括：桡动脉穿刺、股动脉穿刺、股静脉穿刺、心包穿刺、冠脉造影、经皮冠脉内血管成形术等",
                  img: [
                    "/picture/medical/1/2/资源 3.png",
                    "/picture/medical/1/2/资源 4.png",
                  ],
                },
                {
                  titleB: "成绩查询系统（教师端）",
                  txt: "建立教学管理体系，通过统一的平台对账号数据、教学资源、教学课件等内容进行体系化管理",
                  img: [
                    "/picture/medical/1/2/资源 5.png",
                    "/picture/medical/1/2/资源 6.png",
                  ],
                },
                {
                  titleB: "病例数据库",
                  txt: "基于真实血管介入手术流程与标准，建立血管介入手术综合评价体系，通过记录操作者的操作结果进行系统化评分并生成测评报告",
                  img: [
                    "/picture/medical/1/2/资源 7.png",
                    "/picture/medical/1/2/资源 8.png",
                  ],
                },
                {
                  titleB: "超声考核系统",
                  txt: "采用真实临床影像数据，结合三维重建、3D打印等技术制作全3D打印的软体仿真心脏，并根据医学教学、专业学习、临床科研等不同用途进行整体设计开发，满足不同领域、不同用途的模拟器需求",
                  img: [
                    "/picture/medical/1/2/资源 9.png",
                    "/picture/medical/1/2/资源 10.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                txt: "超生数据库目前收录病例数据超100+。根据不同的培训对象、不同年限轮转规划、不同超声专业的需求，病例有正常和异常，常见病、多发病、疑难病和罕见病，分层设计。运用不同的设备得到不同的检测效果反馈，提高诊断及教学效率，也可用于多人观摩教学及专家会诊，有一定的科研和教学价值。",
              }, ],
            },
          ],
        },
        {
          name: "神经外科综合教学平台",
          icon: [{
              title: "降低风险",
              img: "/icon/专科综合教学平台/神经外科综合教学平台/SVG/降低风险.svg",
            },
            {
              title: "三维成像",
              img: "/icon/专科综合教学平台/神经外科综合教学平台/SVG/三维成像.svg",
            },
            {
              title: "术前模拟",
              img: "/icon/专科综合教学平台/神经外科综合教学平台/SVG/术前模拟.svg",
            },
            {
              title: "提高收益",
              img: "/icon/专科综合教学平台/神经外科综合教学平台/SVG/提高收益.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "神经外科是以手术为主要手段，医治中枢神经系统、周围神经系统和植物神经系统疾病的一门临床专科。<br>神经外科专科综合教学平台是针对神经外科教学及临床需求，设计有医学教学内容（VR端及PC端）、临床工作内容（手术直播、MR术中指导）以及临床专科延伸功能（3D打印）。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "VR教学系统",
                  txt: "1.系统采用三维引擎制作，模拟临床翼点开颅手术操作路径，操作者可在虚拟空间内模拟进行翼点开颅手术步骤：术前准备、麻醉、体位摆放、皮肤切口、肌肉剥离、开颅、硬膜剪开等完整手术流程<br>2.系统按照标准手术流程制作，符合临床医学教学指导标准<br>3.系统分为教学和考核两种模式，可以针对不同场景选择使用<br>4.系统在关键操作步骤有语音、文字提示内容，引导操作者继续模拟操作",
                  img: [
                    "/picture/medical/1/3/资源 2.png",
                    "/picture/medical/1/3/资源 3.png",
                  ],
                },
                {
                  titleB: "PC端虚拟仿真系统",
                  txt: "系统使用3D技术搭建虚拟手术场景，模拟开颅手术。采用轻量化的设计，操作者可在移动端及PC端登陆系统，通过点击、拖拽等轻交互的方式利用碎片时间随时随地进行学习体验课件内容包括：开颅术术前准备、麻醉、体位摆放、皮肤切口、肌肉剥离、开颅、硬膜剪开等内容",
                  img: [
                    "/picture/medical/1/3/资源 4.png",
                    "/picture/medical/1/3/资源 5.png",
                  ],
                },
                {
                  titleB: "手术直播",
                  txt: "系统能够进行高清手术直/录播，可将多通道信息（手术室画面、镜下画面、影像画面、音频等）整合进行全网直播，做到画质高清、延迟低、无噪音。录播系统能够进行手术数据管理，通过后台管理平台对账号数据、手术资源、教学课件等内容进行体系化管理",
                  img: [
                    "/picture/medical/1/3/资源 6.png",
                    "/picture/medical/1/3/资源 7.png",
                  ],
                },
                {
                  titleB: "MR术前规划及术中指导",
                  txt: "1.系统能够将患者的影像数据进行三维重建，医生可以通过PC、VR及移动设备更加立体、直观地看到人体结构，查看器官组织关系<br>2.搭配VR设备可对模型进行直接查看以及拖拽、旋转、放大缩小等简单交互，指定结果的单独显示与隐藏<br>3.PC端配备规划工具，可进行骨瓣定位、直线测量、曲线测量、路径预设、内窥镜模拟等操作，可进行手术部位测量及手术路径规划工作，手术规划方案可导出",
                  img: [
                    "/picture/medical/1/3/资源 8.png",
                    "/picture/medical/1/3/资源 9.png",
                  ],
                },
                {
                  titleB: "3D打印",
                  txt: "可进行软体、硬体及软硬一体3D打印。可根据临床影像学数据三维重建对诸如血管瘤、胶质瘤等常规病理结构进行软硬结合一体打印，构建真实病例模型用于学习、术前规划。硬材质可打印骨骼牙齿等组织，软材质打印血管、皮肤等结构<br>数据管理后台可对数据进行管理，允许多数据来源（本地+PACS系统），可导出手术方案中准确规划的手术路径",
                  img: [
                    "/picture/medical/1/3/资源 10.png",
                    "/picture/medical/1/3/资源 11.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                txt: "神经外科医学图像处理平台能够处理患者的影像数据进行三位成像，借助于PC端工具可以进行术前规划及术前手术模拟，手术模拟路径可导出制定手术方案；借助硬件设备可术中对患者影像数据进行查看，了解手术部位邻近结构，选择最佳手术路径，极大地降低手术风险，提高手术临床收益。",
              }, ],
            },
          ],
        },
      ],
    },
    {
      title: "专科单病种教学内容平台",
      class: ["甲状腺", "乳腺癌"],
      info: [
        "通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验甲状腺次全切术手术流程。软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练。操作时每一步都会有实时反馈，让用户学习效率大大提升。术中还有专业性问答，培训用户的专业性知识和临场应对能力。",
        "乳腺癌在全球女性癌症中的发病率为24.2%，位居女性癌症首位，在我国，乳腺癌的发病率呈逐年上升趋势。手术治疗作为乳腺癌患者首选治疗方案，越来越多术者开始考虑如何在清除肿瘤的基础上，尽量保留乳房的体积以及良好的外型。",
      ],

      video: [
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E7%94%B2%E7%8A%B6%E8%85%BA.mp4",
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E4%B9%B3%E8%85%BA%E7%99%8C.mp4",
      ],
      data: [{
          name: "甲状腺",
          icon: [{
              title: "多模式考核",
              img: "/icon/专科单病种教学内容平台/甲状腺/SVG/多模式考核.svg",
            },
            {
              title: "多终端设备",
              img: "/icon/专科单病种教学内容平台/甲状腺/SVG/多终端设备.svg",
            },
            {
              title: "全流程学习",
              img: "/icon/专科单病种教学内容平台/甲状腺/SVG/全流程学习.svg",
            },
            {
              title: "术前规划",
              img: "/icon/专科单病种教学内容平台/甲状腺/SVG/术前规划.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "甲状腺次全切术模拟软件，让用户在虚拟仿真场景内进行甲状腺次全切术手术仿真模拟。通过模拟真实的手术室环境，在虚拟医生与护士的协助下，全方位沉浸式体验甲状腺次全切术手术流程。软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练。操作时每一步都会有实时反馈，让用户学习效率大大提升。术中还有专业性问答，培训用户的专业性知识与临场应对能力。软件适配多终端（PC/VR/小程序），在不同场景下供用户进行学习。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "便捷操作引导",
                  txt: "用户在VR模式中进行手术模拟操作时，系统会根据用户当前操作步骤，会在操作部位显示出半透明引导模型进行指导，让用户直观地跟随引导进行操作模拟，更清晰明了地进行仿真操作",
                  img: [
                    "/picture/medical/2/1/资源 11.png",
                    "/picture/medical/2/1/资源 12.png",
                  ],
                },
                {
                  titleB: "移动端仿真手术交互",
                  txt: "使用web 3D技术在移动端开发甲状腺次全切术虚拟仿真软件，使得用户能够随时随地使用Pad或者手机进入仿真程序，通过点触和滑动的交互形式进行手术模拟学习",
                  img: [
                    "/picture/medical/2/1/资源 13.png",
                    "/picture/medical/2/1/资源 14.png",
                  ],
                },
                {
                  titleB: "VR多人协同",
                  txt: "VR端软件支持多人协同操作，让多名用户在同一个虚拟仿真手术室中进行协同手术操作模拟，用户之间可以进行互相递器械、协同操作。以此培训用户之间手术协同能力",
                  img: [
                    "/picture/medical/2/1/资源 15.png",
                    "/picture/medical/2/1/资源 16.png",
                  ],
                },
                {
                  titleB: "多教学模式选择",
                  txt: "用户进入程序后，可以自行选择练习模式和考核模式。在练习模式中，系统会有文字和语言提示操作步骤，用户可以根据提示，一步步进行操作训练。在考核模式下，系统不会显示任何提示，并且会根据操作者的操作对错进行评分，在操作结束时给出评分",
                  img: [
                    "/picture/medical/2/1/资源 17.png",
                    "/picture/medical/2/1/资源 18.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "多终端",
                  txt: "软件适配于多终端使用，方便用户在不同场景进行培训练习",
                },
                {
                  titleB: "多模式",
                  txt: "软件针对用户不同需求设置了练习模式和考核模式，在模拟手术完成后会给出数据反馈，提高学习效率",
                },
                {
                  titleB: "全流程学习",
                  txt: "从术前消毒、麻醉、铺巾...到最后缝合。涵盖甲状腺手术全部流程，用户可以对整个手术流程进行全面学习",
                },
                {
                  titleB: "术前规划",
                  txt: "可进行具体病例的术前规划学习和练习",
                },
              ],
            },
          ],
        },
        {
          name: "乳腺癌手术及护理",
          icon: [{
              title: "3D解剖",
              img: "/icon/专科单病种教学内容平台/乳腺癌/SVG/3D解剖.svg",
            },
            {
              title: "术前规划",
              img: "/icon/专科单病种教学内容平台/乳腺癌/SVG/术前规划.svg",
            },
            {
              title: "碎片化学习",
              img: "/icon/专科单病种教学内容平台/乳腺癌/SVG/碎片化学习.svg",
            },
            {
              title: "系统性学习",
              img: "/icon/专科单病种教学内容平台/乳腺癌/SVG/系统性学习.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "乳腺癌在全球女性癌症中的发病率为24.2%，位居女性癌症首位，在我国，乳腺癌的发病率呈逐年上升趋势。手术治疗作为乳腺癌患者首选治疗方案，越来越多术者开始考虑如何在清除肿瘤的基础上，尽量保留乳房的体积以及良好的外型。<br>我司根据临床专家的指导，开发乳腺癌保乳手术教学软件。利用Web 3D 技术在手机移动端开发H5程序，展现乳腺解剖结构展示、以及乳腺癌保乳肿瘤切除手术的操作过程模拟，除了能够帮助术者学习手术操作，还能提升临床对于乳腺健康话题的关注度，为临床医生软性推广教学科普点。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "3D局部解剖",
                  txt: "利用3D技术还原乳房解剖结构及手术对应的肿瘤病理结构，清晰展示乳房皮肤、脂肪、乳腺、深筋膜等的解剖结构，并展示对应肿瘤位置及大小，帮助术者更好理解手术操作",
                  img: [
                    "/picture/medical/2/2/资源 12.png",
                    "/picture/medical/2/2/资源 13.png",
                  ],
                },
                {
                  titleB: "术前规划测量小工具",
                  txt: "使用web 提供一系列小工具，具体包括不同颜色粗细的画笔划线功能、距离测量工具、角度测量工具等帮助术者学习如何进行术前规划及测量",
                  img: [
                    "/picture/medical/2/2/资源 14.png",
                    "/picture/medical/2/2/资源 15.png",
                  ],
                },
                {
                  titleB: "虚拟仿真手术交互",
                  txt: "利用Web 3D 技术在移动端开发乳腺癌保乳手术的具体操作流程，通过点触及滑动等轻交互形式帮助术者利用碎片时间进行手术学习",
                  img: [
                    "/picture/medical/2/2/资源 16.png",
                    "/picture/medical/2/2/资源 17.png",
                  ],
                },
                {
                  titleB: "临床手术实拍",
                  txt: "使用全景及高清摄像机多机位拍摄临床手术，全景摄像机拍摄手术室全景，记录手术中手术室场景、术中配合等情景，高清摄像机拍摄术者视野，记录手术详细操作细节",
                  img: [
                    "/picture/medical/2/2/资源 18.png",
                    "/picture/medical/2/2/资源 19.png",
                  ],
                },
                {
                  titleB: "术后护理VR课件",
                  txt: "通过虚拟现实技术指导患者乳腺癌术后如何进行术后康复自我锻炼",
                  img: [
                    "/picture/medical/2/2/资源 20.png",
                    "/picture/medical/2/2/资源 21.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "3D解剖",
                  txt: "帮助初学者熟悉局部解剖结构及肿瘤位置",
                },
                {
                  titleB: "术前规划",
                  txt: "可进行具体病例的术前规划学习和练习",
                },
                {
                  titleB: "碎片化学习",
                  txt: "在移动端进行开发，方便传播转发，术者可利用碎片化时间随时随地进行学习",
                },
                {
                  titleB: "系统性学习",
                  txt: "提供具体手术的全方面的学习材料，从解剖、病理、术前规划、手术流程、术后管理等各个角度进行教学",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "3D医学打印服务",
      class: ["医学教学模拟器", "临床病理打印"],
      info: [
        "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术。3D打印运用于医疗领域常与三维重建技术相结合，通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印。",
        "近年，医学教学越来越强调贴近临床，统一的批量化模型逐渐无法满足教学需求，于是定制化临床教具应运而生。定制化医学临床教具是指根据医学院或临床科室的教学需求，以疾病及病例为导向，以实际患者的影像数据为基础，利用三维重建技术及3D打印技术定制开发医学临床教具",
      ],
      video: [
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E5%8C%BB%E5%AD%A6%E6%95%99%E5%AD%A6%E6%A8%A1%E6%8B%9F%E5%99%A8.mp4",
        "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E4%B8%B4%E5%BA%8A%E7%97%85%E7%90%86.mp4",
      ],
      data: [{
          name: "医学教学模拟器",
          icon: [{
              title: "定制开发",
              img: "/icon/3D医学打印服务1/医学教学模拟器/SVG/定制开发.svg",
            },
            {
              title: "三维重建",
              img: "/icon/3D医学打印服务1/医学教学模拟器/SVG/三维重建.svg",
            },
            {
              title: "虚实融合",
              img: "/icon/3D医学打印服务1/医学教学模拟器/SVG/虚实融合.svg",
            },
          ],
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术。3D打印运用于医疗领域常与三维重建技术相结合，通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印。常用材料有用于骨骼打印的光敏树脂和用于内脏或血管的类橡胶材料。",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "MR虚实融合血管介入模拟器",
                  txt: "运用混合现实（MR）技术及空间微定位技术，结合高仿真定制人体模型，将介入手术器械的实时操作画面叠加在真实模型上，从而辅助临床医师完成有拟真手感的模拟操作训练。结合三维重建、3D打印等技术，临床医师能够通过MR设备训练各种不同病例的介入模拟操作。操作时能够通过MR设备实时观测介入设备的位置及变化，帮助年轻医师更好理解介入手术的操作原理",
                },
                {
                  titleB: "房间隔穿刺模拟器",
                  txt: "将房间隔穿刺较为重要的左右心房结构提取出来，运用三维建模和3D打印结合，将部分房间隔及卵圆窝的部位制作成可替换的插片，使用者通过替换卵圆窝膜可反复进行穿刺模拟。外置一个仿C臂弧形的摄像头架，可将1-2个小型摄像头固定在弧形架上，通过USB连接电脑，实训监控及投屏的功能。3D打印心脏运用了类橡胶软体材料，使用者在操作时能实现相对更真实的手感",
                  img: [
                    "/picture/medical/3/1/资源 13.png",
                    "/picture/medical/3/1/资源 14.png",
                  ],
                },
                {
                  titleB: "二尖瓣夹闭模拟器",
                  txt: "模拟器由水箱、单向导管输入口，软胶材质3D打印心脏、瓣膜、内窥镜、水泵等几部分组成。通过水泵泵出水流，模拟二尖瓣张开闭合的动态，用预设内窥镜连接到电脑，查看心脏内部瓣膜动态及夹闭操作时的细节",
                  img: [
                    "/picture/medical/3/1/资源 15.png",
                    "/picture/medical/3/1/资源 16.png",
                  ],
                },
                {
                  titleB: "希氏束起搏器模拟器",
                  txt: "模拟器包含软胶3D打印心脏，水箱、射灯三部分组成。使用者可以通过左右锁骨下静脉及下腔静脉三个导管入口进行模拟操作。通过水箱加水及射灯照射的方式强化了软胶心脏透明度，操作者在操作时，可以通过心脏外壁看见导管的大致位置及角度",
                  img: [
                    "/picture/medical/3/1/资源 17.png",
                    "/picture/medical/3/1/资源 18.png",
                  ],
                },
                {
                  titleB: "虚实融合骨折整复模拟器",
                  txt: "通过3D打印技术重建骨折骨骼，打造骨折整复仿真模拟器。结合虚实融合技术，操作者佩戴MR头显设备对骨折模拟器进行整复操作时，骨折端的虚拟影像叠加在模拟器上，操作者能通过MR头显实时观察操作时骨骼的位置变化，更好理解骨折整复操作的原理。系统通过检测骨折断端对合的位置、角度对骨折整复操作进行评分，有效提高操作者的学习效率",
                  img: [
                    "/picture/medical/3/1/资源 19.png",
                    "/picture/medical/3/1/资源 20.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "三维重建",
                  txt: "通过Dicom数据将临床影像数据变成实际可操作的可使用的3D打印模型，常用于临床术前规划、患者教育、临床科研",
                },
                {
                  titleB: "模拟器定制开发",
                  txt: "根据客户实际教学使用情景定制开发3D打印医学模拟器，结合硅胶、亚克力等其他材料，更好利用3D打印模型",
                },
                {
                  titleB: "虚实融合",
                  txt: "与混合现实（MR）或虚拟仿真技术相结合，操作者在使用模拟器进行训练时，能够得到虚拟影像的指引，从而提高学习效率",
                },
              ],
            },
          ],
        },
        // 和school的定制化医学临床教具一模一样
        {
          name: "临床病理打印",
          data: [{
              titleA: "内容及介绍",
              content: [{
                txt: "近年，医学教学越来越强调贴近临床，统一的批量化模型逐渐无法满足教学需求，于是定制化临床教具应运而生。定制化医学临床教具是指根据医学院或临床科室的教学需求，以疾病及病例为导向，以实际患者的影像数据为基础，利用三维重建技术及3D打印技术定制开发医学临床教具",
              }, ],
            },
            {
              titleA: "功能描述",
              content: [{
                  titleB: "病理解剖教学",
                  txt: "教具根据病理影像数据进行重建，可清晰展现病理解剖特征",
                },
                {
                  titleB: "手术操作模拟",
                  txt: "教具根据病理影像重建，可用于手术操作的教学，例如肿瘤手术的切除范围及血管情况，介入手术的入路及操作方法等",
                },
                {
                  titleB: "术前规划及患者教育",
                  txt: "可用于临床科室复杂手术前的术前规划，在术前用完全匹配患者的教具进行模拟操作演练，并可用其对解释手术操作方法",
                  img: [
                    "/picture/school/3/1/资源 13.png",
                    "/picture/school/3/1/资源 14.png",
                    "/picture/school/3/1/资源 15.png",
                  ],
                },
              ],
            },
            {
              titleA: "用途与特色",
              content: [{
                  titleB: "一体化彩色打印",
                  txt: "可在一次打印中完成将不同的打印材料根据解剖结构，运用不同颜色及特性的材料进行区分，并一次性打印成型，可制作复杂解剖结构的临床教具",
                },
                {
                  titleB: "多元化材料",
                  txt: "可根据教学及模拟需要，灵活运用不同的3D打印材料，实现最大化的结构及手感模拟",
                  img: [
                    "/picture/school/3/1/资源 16.png",
                    "/picture/school/3/1/资源 17.png",
                    "/picture/school/3/1/资源 18.png",
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "远程手术/查房直播系统",
      info: "在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。5G+VR探视可以有效避免医生和家属进入病房时遇到交叉感染的情况，增加感染隐患，减少医生和家属探视时所需使用防护用品和衣物，减少不必要资源损耗，减少医生和家属每次穿防护服或防护衣物时间，减少无效时间。",
      // img: ["/picture/medical/4/资源 14.png",
      //     "/picture/medical/4/资源 15.png",
      //     "/picture/medical/4/资源 16.png"],
      video: "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E8%BF%9C%E7%A8%8B%E6%89%8B%E6%9C%AF_%E6%9F%A5%E6%88%BF%E7%9B%B4%E6%92%AD%E7%B3%BB%E7%BB%9F.mp4",
      data: {
        name: "远程手术/查房直播系统",
        data: [{
            titleA: "内容及介绍",
            content: [{
              txt: "3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术。3D打印运用于医疗领域常与三维重建技术相结合，通过三维重建的技术对医学影像Dicom数据进行三维数字化重建，根据不同人体结构使用不同材料进行打印。常用材料有用于骨骼打印的光敏树脂和用于内脏或血管的类橡胶材料。",
              img: ["/picture/medical/4/资源 16.png"],
            }, ],
          },
          {
            titleA: "功能描述",
            content: [{
                titleB: "采用5G+VR技术，支持8k VR+4k超高清视频信号",
              },
              {
                titleB: "可以通过VR头盔，看到全景相机的画面，能够清晰看到患者所处的病房环境与患者本人，犹如身临其境探视患者一般",
              },
              {
                titleB: "系统实时传输现场场景，让探视患者第一时间看到病房内场景",
              },
              {
                titleB: "支持硬件包括：大屏显示设备、手机、VR头盔",
              },
              {
                titleB: "系统包含远程查房系统、直播系统，系统在每个病房内架设4台移动推车，其中3台推车上有一台高视频清晰度的PAD，1台推车上架有1台PAD和1台全景相机，pad与全景相机通过5G网络，进行推流",
              },
            ],
          },
          {
            titleA: "用途与特色",
            content: [{
              txt: "通过5G+VR技术呈现，在保护隐私安全的前提下，为家属开放远程预约和探视服务，尽可能减少医护干扰、交叉感染等可能性。隔离探视的高清直播及双向沟通能力还可以承载远程诊疗场景、医疗操作示范教学、养老探视、母婴探视、医疗教学、会议活动直播、监狱探视、康复训练等场景。同时远程探视还能让家属实时“看”到病房的患者高清动态，平复他们焦虑的内心",
              img: [
                "/picture/medical/4/资源 15.png",
                "/picture/medical/4/资源 14.png",
              ],
            }, ],
          },
        ],
      },
    },
    {
      title: "全景/高清/立体手术直录播服务",
      info: "5G+临床手术术野融合直播系统，是通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面，形成画中画。让观察直播的用户通过手机、VR头盔等多终端身临其境观看手术。全景画面融合医生手术术野画面或者医学影像画面。",
      // img: ["/picture/medical/5/资源 17.png"],
      video: "http://resourcedev.smaxserver.com/assets%2FLCZK%2F%E5%85%A8%E6%99%AF_%E9%AB%98%E6%B8%85_%E7%AB%8B%E4%BD%93%E6%89%8B%E6%9C%AF%E7%9B%B4%E5%BD%95%E6%92%AD%E6%9C%8D%E5%8A%A1.mp4",
      data: {
        name: "全景/高清/立体手术直录播服务",
        icon: [{
            title: "手术过程录制",
            img: "/icon/全景高清立体手术直录播服务/SVG/手术过程录制.svg",
          },
          {
            title: "同步观看",
            img: "/icon/全景高清立体手术直录播服务/SVG/同步观看.svg",
          },
        ],
        data: [{
            titleA: "内容及介绍",
            content: [{
              txt: "5G+临床手术术野融合直播系统，是通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面，形成画中画。让观看直播的用户通过手机、VR头盔等多终端身临其境观看手术。全景画面融合医生手术术野画面或者医学影像画面。",
              img: ["/picture/medical/5/资源 17.png"],
            }, ],
          },
          {
            titleA: "功能描述",
            content: [{
                titleB: "结合5G网络进行直播，支持8k VR+4k超高清视频信号",
              },
              {
                titleB: "运用高清摄像机进行拍摄，画质可达4K以上，体验中能够更加清晰的体会手术细节",
              },
              {
                titleB: "结合VR，体验者能够身临其境观看手术现场。除了全景画面话，还可插入多路医生术野画面或者医学影像画面，形成画中画",
              },
              {
                titleB: "VR头显设备：HTC vive 、Oculus、Pico一体机、华为VR glass眼镜；移动端：PAD、手机；PC电脑",
              },
              {
                titleB: "用户端子系统使用VR设备终端或手机微信小程序等，实时连接到本地流媒体服务器或互联网直播平台，能够身临其境观看手术现场，同时看到医生的手术术野画面或者医学影像画面，及时了解和学习相关细节",
              },
            ],
          },
          {
            titleA: "用途与特色",
            content: [{
              txt: "通过5G+采用在手术室用场景及术野摄像机对手术过程的实时录制，在观摩时进行视频的同步播放或者回放的方式，解决传统手术示教系统的手术室空间限制、观摩学习人数限制、手术医师注意力受到影响、交叉感染等不足。同时在单独视频点播及病例横向展开讲解提供有力的帮助，有助于学生直观深入的理解，可大幅提高教学的效果",
            }, ],
          },
        ],
      },
    },
    {
      title: "临床与教学科研支持",
      info: "公司基于XR技术在临床科研领域已经和多家医院在专科领域产生了广泛的合作，目前已涵盖心血管、超生、神经外科、泌尿科、骨科脊椎外科、精神科等科室，基于医工结合的对于领域，应用于术前规划、疼痛管理、神经治疗、专科医学教学等不同领域。公司致力于使用VR/AR/MR、计算机图形和三维打印技术等，支持临床科研工作，形成专利、软件著作权和内容版权等，同时提供相关技术领域的科研和学术支持。",
      img: ["/picture/medical/6/1.png"],
      data: {
        name: "全景/高清/立体手术直录播服务",
        data: [{
          titleA: "基本介绍",
          content: [{
            txt: "公司基于XR技术在临床科研领域已经和多家医院在专科领域产生了广泛的合作，目前已涵盖心血管、超声、神经外科、泌尿科、骨科脊椎外科、精神科等科室，基于医工结合的对于领域，应用于术前规划、疼痛管理、精神治疗、专科医学教学等不同领域。公司致力于使用VR/AR/MR 、计算机图形学和三维打印技术等，支持临床科研工作，形成专利、软件著作权和内容版权等，同时提供相关技术领域的科研和学术支持。<br>公司在术前规划领域，服务于心血管、神经外科、泌尿科、骨科脊椎外科等，指导医师在术前对于影像数据进行三维重建，虚实融合，指导临床手术治疗和规划，并和三维打印相结合，模拟手术部位组织的质感和病理学器官结构，进行特定病例的术前规划指导和临床支持，产生对应的专科和科研成果。<br>公司在专科医学教学领域，服务于心血管、神经外科、超声科、泌尿科、骨科、消化外科、牙科、养老康复和护理学等，构建基于移动端和三维打印组织模拟，结合VR/AR/MR等终端，针对于专科临床教学建立病例库和多终端教学平台，搜集临床教学对照组数据，进行临床科研和学术支持，产生对应专利和学术成果。<br>公司在疼痛管理、精神治疗领域，针对于强迫症、恐高、焦虑、老年认知障碍、飞行恐惧，和临床疼痛管理，通过视觉和听觉干预，起到诊疗的目的，缓解疼痛和精神疾病的状态，常见应用于PTSD创伤修复、暴露疗法、放松疗法等，产生临床对照组数据和新的精神干预方法，使用VR/AR/MR技术，产生对应的临床和科研成果。",
          }, ],
        }, ],
      },
    },
  ],
  itemList: [{
      title: '专科综合教学平台',
      blocks: [{
        typographical: ServerTypographical.MXFLEX,
        data: [{
          picture: '/picture/medical/b1/1.png',
          prePicture: '/picture/medical/b1/1.jpg',
          showImg: false,
          title: '心血管综合教学平台',
          subtitle: '运用虚拟现实、虚拟仿真、医学模拟、3D打印等不同技术',
          desc: '平台内容包括：虚拟现实教学系统、8K + 4K全景手术拍摄、虚拟仿真教学系统、心血管医学模拟器、临床病例管理系统等',
        }, {
          picture: '/picture/medical/b1/2.png',
          prePicture: '/picture/medical/b1/2.jpg',
          showImg: false,
          title: '超声综合教学平台',
          subtitle: '将超声临床病例以三维形式储存、调取、拼合、重现',
          desc: '平台内容包括：超声VR教学系统、超声PC端仿真教学数据系统、成绩查询系统、病例数据库、超声考核系统等',
        }, {
          picture: '/picture/medical/b1/3.png',
          prePicture: '/picture/medical/b1/3.jpg',
          showImg: false,
          title: '神经外科综合教学平台',
          subtitle: '模拟临床手术路径，提高临床效益',
          desc: '平台内容包括：VR教学系统、PC端虚拟仿真系统、手术直播、MR术前规划及术中指导、3D打印',
        }]
      }],
    },
    {
      title: '专科单病种教学内容平台',
      blocks: [{
        typographical: ServerTypographical.MXFLEX,
        data: [{
          picture: '/picture/medical/b2/4.png',
          prePicture: '/picture/medical/b2/4.jpg',
          showImg: false,
          title: '甲状腺',
          subtitle: '模拟真实手术室环境，全方位沉浸式体验',
          desc: '软件分为培训模式与考核模式，培训模式会进行操作提示，考核模式会进行操作评分，针对不同的用户进行训练',
        }, {
          picture: '/picture/medical/b2/5.png',
          prePicture: '/picture/medical/b2/5.jpg',
          showImg: false,
          title: '乳腺癌手术及护理',
          subtitle: '展现乳房解剖结构，模拟乳腺癌切除手术',
          desc: '利用Web 3D技术在手机端开发H5程序，帮助术者学习手术操作，提升临床对乳腺健康话题的关注度，为临床医生软性推广教学科普',
        }]
      }],
    },
    {
      title: '3D医学打印服务',
      blocks: [{
        typographical: ServerTypographical.MXFLEX,
        data: [{
          picture: '/picture/medical/b3/6.png',
          prePicture: '/picture/medical/b3/6.jpg',
          showImg: false,
          title: '医学教学模拟器',
          subtitle: '根据不同人体结构使用不同材料进行打印',
          desc: '3D打印（3DP）即快速成型技术的一种，又称增材制造，它是一种以数字模型文件为基础，运用粉末状金属或塑料等可粘合材料，通过逐层打印的方式来构造物体的技术',
        }, {
          picture: '/picture/medical/b3/7.png',
          prePicture: '/picture/medical/b3/7.jpg',
          showImg: false,
          title: '临床病理打印',
          subtitle: '定制化临床教具',
          desc: '定制化医学临床教具是指根据医学院或临床科室的教学需求，以疾病及病例为导向，以实际患者的影像数据为基础，利用三维重建技术及3D打印技术定制开发医学临床教具',
        }]
      }],
    },
    {
      title: '',
      phoneTitle: '远程手术/查房直播系统',
      blocks: [{
        typographical: ServerTypographical.SIGFLEX,
        data: [{
          picture: '/picture/medical/b4/8.png',
          prePicture: '/picture/medical/b4/8.jpg',
          mode: 'height-fix',
          showImg: false,
          title: '远程手术/查房直播系统',
          subtitle: '',
          desc: '在疫情防控常态化形势下，医生查房、家属探视成为一道难题，建设远程查房、探视系统成为刚需。非直接接触场景的探视需求或将成为医院的标配。通过5G+VR技术呈现，在保护隐私安全的前提下，开放远程查房探视服务，尽可能减少医护干扰、交叉感染等可能性。5G+VR探视可以有效避免医生和家属进入病房时遇到交叉感染的情况，增加感染隐患，减少医生和家属探视时所需使用防护用品和衣物，减少不必要资源损耗，减少医生和家属每次穿防护服或防护衣物时间，减少无效时间。',
        }]
      }],
    },
    {
      title: '全景/高清/立体手术直录播服务',
      blocks: [{
        typographical: ServerTypographical.VERT,
        data: [{
          picture: '/picture/medical/b5/9.png',
          prePicture: '/picture/medical/b5/9.jpg',
          showImg: false,
          title: '',
          subtitle: '',
          desc: '5G+临床手术术野融合直播系统，是通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面，形成画中画。让观察直播的用户通过手机、VR头盔等多终端身临其境观看手术。全景画面融合医生手术术野画面或者医学影像画面。',
        }]
      }],
    },
    {
      title: '临床与教学科研支持',
      blocks: [{
        typographical: ServerTypographical.VERT,
        data: [{
          picture: '/picture/medical/b6/10.png',
          prePicture: '/picture/medical/b6/10.jpg',
          showImg: false,
          title: '',
          subtitle: '',
          desc: '5G+临床手术术野融合直播系统，是通过5G网络，使用全景相机/高清摄影机，对临床手术进行全景直/录播。同时还能在全景画面内，插入多路医生术野画面或者医学影像画面，形成画中画。让观察直播的用户通过手机、VR头盔等多终端身临其境观看手术。全景画面融合医生手术术野画面或者医学影像画面。',
        }]
      }],
    }
  ],
  swiperList: [{
    url: '/picture/medical/banner1.png',
    preUrl: '/picture/medical/banner1.jpg',
    show: false,
    title: '模拟医学临床专科教学',
    subtitle: ''
  }],
  inPageNavigations: ['专科综合教学平台', '专科单病种教学内容平台', '3D医学打印服务', '远程手术/查房直播系统', '全景/高清/立体手术直录播服务', '临床与教学科研支持']
};

export {
  MedicalData
};