const RobotData = {
  middle: {
    url: "/picture/robot/middleBg.png",
  },
  content: [
    {
      img: "/picture/robot/001.jpg",
      title: "国产血管介入手术模拟器",
      txt:
        "随着模拟医学的不断发展，利用可规模化生产且品质统一可控的模拟器进行术式训练已成为各类血管介入新术式推广的有力辅助工具。并且，随着血管介入手术机器人的飞速发展，结合模拟器的市场推广策略因其起效快、针对性强、覆盖术式广等特点成为同类手术机器人市场推广的方式之一。",
    },
    {
      img: "/picture/robot/002.jpg",
      title: "手术定位导航系统",
      txt:
        "传统介入手术借助于影像技术，在X光等二维影像的引导下进行手术，然而这种方式既要求术者需要有扎实的解剖知识及影像阅读能力，又要求术者有丰富的经验以应付术中突发情况，缩短手术时间。利用三维可视化导航技术，即既可通过缩短手术时间降低辐射对医生的损害，又可减少手术操作对术者经验的依赖，可在极大范围内提升术式的普及程度，惠及更多患者。",
    },
    {
      img: "/picture/robot/003.jpg",
      title: "术前规划",
      txt:
        "三维重建的仿真人体器官：利用3D打印技术的仿真人体器官，可根据真实病例体数据1:1还原，以可视化实物，有效提升术前规划质量。<br><br>辅助临床医生的三维规划：以数字孪生技术还原患者病灶部位及植入器械并赋予其物理属性，通过模拟不同尺寸瓣膜置放体内不同位置的预测结果，帮助医生选择最适合患者的尺寸及植入位置",
    },
    {
      img: "/picture/robot/004.jpg",
      title: "结合人工智能的虚拟医生",
      txt:
        "以人工智能为支撑的虚拟医生能代替真人医生，可实现持续高效且安全的预检分诊等工作，高效完成大量且相似的工作内容，有效减轻医生临床问诊压力",
    },
    {
      img: "/picture/robot/005.jpg",
      title: "结合5G的虚拟手术云平台",
      txt:
        "利用5G网络低延时的特点，远程专家可实时指导现场医生操作，并可通过MR/AR技术，实现无障碍沟通",
    },
  ],
  swiperList: [
    {
      url: "/picture/robot/bannerBg.jpg",
      preUrl: "/picture/robot/bannerBg.jpg",
      title: "开发国产模拟器替代进口产品帮助血管介入术式向各级医院普及",
    },
  ],
};

export { RobotData };
