export const DeviceTrainingData = {
    contents: [{
            picture: '/picture/device/1.jpg',
            title: '医疗器械原理动画',
            desc: '通过3D医学原理建模、高品质贴图与渲染、通过3D动画，打造高品质医疗器械动画视频，展示大型医疗器械设备及精密器械设备。'
        },
        {
            picture: '/picture/device/2.jpg',
            title: '模拟医学培训',
            desc: '通过最新XR（AR/VR/MR）设备，模拟大型设备/精密器械产品操作步骤和设备使用流程。通过多人异地联机，创建在线互动培训室、通过在线语音通讯，快速开展远程器械演示和培训交流，为医疗企业降本增效，提高产品认知与临床操作协同能力。'
        }, {
            picture: '/picture/device/3.jpg',
            title: '在线培训工具',
            desc: '通过最新在线程序，结合AR功能，为高端医疗器械设备，提供在线远程培训工具。局部展示器械设备结构、设备原理介绍、临床模拟展示等功能，为临床医生、科室推广、产品售后等不同环节提供在线培训系统。'
        },
        {
            picture: '/picture/device/4.jpg',
            title: '全景术野融合临床手术直录播平台',
            desc: '通过8K VR全景相机与多机位高清相机融合，将临床手术8K VR全景信号和主刀医师手部局部画面、4K高清多路术野画面、患者生理数据、DSA影像等多路信号采集并合成；医生可以选择VR或全景模式,拥有沉浸式第一人称视角,在线进行现场手术观摩或手术回看，支持不同视角和主画面，为医生提供自主画面视角切换。长期打造高水准、多病理、有深度的临床手术案例，长期为临床医生提供最新器械学习与交流平台。'
        },
        {
            picture: '/picture/device/5.jpg',
            title: '严肃游戏与互动',
            desc: '抓住年轻医生碎片时间，进行兴趣培养，注意力与记忆提升训练。结合医疗器械产品为不同患者选择正确的诊疗方案，包括用药，是否需要手术等不同关卡；医疗器械定制化游戏内容结合时间压力的介入手术游戏，游戏过程中包括使用不同类型器械，操作步骤是否正确；游戏设置榜单和联机模块，提升年轻医师长期使用意愿，完成诊疗记忆训练。'
        }
    ],
    swiperList: [{
        url: '/picture/device/s1.jpg',
        preUrl: '/picture/device/s1.jpg',
        show: false,
        title: '提升培训效率，降低手术风险',
        subtitle: '构建标准，降本增效'
    }]
}