import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Medical from "../views/Medical.vue";
import Digital from "../views/Digital.vue";
import Simulation from "../views/Simulation.vue";
import Industry from "../views/Industry.vue";
import Partner from "../views/Partner.vue";
import Contact from '../views/Contact.vue';

import AboutMore from "../views/AboutMore.vue";

import heart from "../views/Medical/heart.vue";
import fiveSystem from "../views/Medical/fiveSystem.vue";
import AllNews from "../views/News/AllNews.vue";
import NewsInfo from "../components/NewsInfo.vue";
import PhoneNewsList from "../views/News/PhoneNewsList.vue";
import PhoneDataInfo from "../views/PhoneDataInfo.vue";
import Robot from "../views/Robot.vue";
import DeviceTraining from "../views/DeviceTraining.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Medical",
    name: "Medical",
    component: Medical,
  },
  {
    path: "/Digital",
    name: "Digital",
    component: Digital,
  },
  {
    path: "/Simulation",
    name: "Simulation",
    component: Simulation,
  },
  {
    path: "/Industry",
    name: "Industry",
    component: Industry,
  },
  {
    path: "/Partner",
    name: "Partner",
    component: Partner,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/Medical/heart",
    name: "heart",
    component: heart,
  },
  {
    path: "/Medical/fiveSystem",
    name: "fiveSystem",
    component: fiveSystem,
  },
  {
    path: "/Home/AboutMore",
    name: "AboutMore",
    component: AboutMore,
  },
  {
    path: "/Home/AllNews",
    name: "AllNews",
    component: AllNews,
  },
  {
    path: "/Home/NewsInfo",
    name: "NewsInfo",
    component: NewsInfo,
  },
  {
    path: "/Home/PhoneNewsList",
    name: "PhoneNewsList",
    component: PhoneNewsList,
  },
  {
    path: "/Home/PhoneDataInfo",
    name: "PhoneDataInfo",
    component: PhoneDataInfo,
  }, {
    path: "/Robot",
    name: "Robot",
    component: Robot
  }, {
    path: "/DeviceTraining",
    name: "DeviceTraining",
    component: DeviceTraining
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log("router:", to.name, from.name);

    if (to.name == "Home") {
      Vue.prototype.global.userAction(0);
    } else if (to.name == "Robot") {
      Vue.prototype.global.userAction(1);
    } else if (to.name == "DeviceTraining") {
      Vue.prototype.global.userAction(2);
    } else if (to.name == "Digital") {
      Vue.prototype.global.userAction(3);
    } else if (to.name == "Medical") {
      Vue.prototype.global.userAction(4);
    } else if (to.name == "Simulation") {
      Vue.prototype.global.userAction(5);
    } else if (to.name == "Partner") {
      Vue.prototype.global.userAction(6);
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
});

export default router;