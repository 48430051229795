<template>
  <div>
    <Header />
    <!-- PC端 -->
    <div class="allNews" v-if="!isPhone">
      <div class="allNewsBody">
        <div
          class="allNewsData"
          v-for="(item, index) in data"
          :key="index"
          @click="showInfoFn(index)"
        >
          <div class="allNewsLeftImgBlock">
            <img
              :src="global.AssetsURL + item.url"
              class="img"
              v-if="!item.false"
            />
            <img
              :src="global.AssetsURL + item.url"
              class="img1"
              v-if="item.false"
            />
          </div>
          <div class="allNewsRightBlock">
            <div class="newInfoTitle">{{ item.content }}</div>
            <!-- <div class="newInfoTitleTwo">{{ item.contentTwo }}</div> -->
            <div class="newInfoContent" v-html="item.desc"></div>
            <div class="newInfoDate"><span>日期</span>{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="phoneAllNews" v-if="isPhone">
      <div
        class="phoneAllNewsData"
        v-for="(item, index) in data"
        :key="index"
        @click="showInfoFn(index)"
      >
        <div class="allTopImgBlock">
          <img
            :src="global.AssetsURL + item.url"
            alt=""
            class="phoneImg"
            v-if="!item.height"
          />
          <img
            :src="global.AssetsURL + item.url"
            class="phoneImg1"
            v-if="item.height"
          />
          <div></div>
        </div>
        <div class="phoneAllnewInfo">
          <p class="phoneNewInfoTitle">{{ item.content }}</p>
          <p class="phoneNewInfoDate"><span>日期</span>{{ item.time }}</p>
        </div>
      </div>
    </div>
    <Footer style="margin-top:120px" />
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
// import NewsInfoVue from "../../components/NewsInfo.vue";
import { newsData } from "../../../public/data/Allnews";
import { setTimeout } from "timers";

export default {
  name: "AllNews",
  components: {
    Header,
    Footer,
    // NewsInfoVue,
  },
  data() {
    return {
      isPhone: false,
      data: newsData,

      transIndex: 0,
      imgDx: 0,

      showInfo: false,
      showIndex: 0,
    };
  },
  methods: {
    gotoHomeFn() {
      if (this.showInfo) this.showInfo = false;
      else
        this.$router.push({
          name: "Home",
        });
    },
    trnasChangeFn(index) {
      if (index === -1) {
        if (this.transIndex > 0) this.transIndex--;
      } else if (index === -2) {
        if (this.transIndex < Math.ceil(this.data.length / 6) - 1)
          this.transIndex++;
      } else this.transIndex = index - 1;
    },
    transDxChange() {
      this.imgDx = document
        .querySelector(".allNewsData")
        .getBoundingClientRect().width;
    },
    // 进入详情页
    showInfoFn(index) {
      this.showInfo = true;
      this.showIndex = index;
      console.log(this.showIndex);
      this.$router.push({
        name: "NewsInfo",
        query: {
          data: JSON.stringify(this.data),
          index: this.showIndex,
        },
      });
    },
  },
  computed: {
    transDx() {
      let x = -this.transIndex * this.imgDx;
      return {
        transform: `translateX(${x}px)`,
        transition: "1s",
      };
    },
  },
  created() {
    if (this.global.isPhone) {
      this.isPhone = true;
    }
  },
  mounted() {
    // let div = document.querySelector(".allNewsBody");
    // console.log(div.clientWidth);
    setTimeout(() => {
      this.imgDx = document
        .querySelector(".allNewsData")
        .getBoundingClientRect().width;
      window.addEventListener("resize", this.transDxChange);
    }, 50);

    setTimeout(() => {
      let index = this.$route.params?.index;
      if (typeof index === "number") {
        this.showInfoFn(index);
      }
    }, 100);
  },
  destroyed() {
    window.removeEventListener("resize", this.transDxChange);
  },
};
</script>

<style scoped>
.allNews {
  position: relative;
  width: 100%;
}

.allNewsBody {
  position: relative;
  /* width: 75%; */
  width: 14.42rem;
  margin: 0 auto;
  margin-top: 1rem;
  /* background: aqua; */
}

.backBtn {
  color: #333333;
  font-size: 0.16rem;
  margin-top: 0.37rem;
  margin-bottom: 0.78rem;
  cursor: pointer;
}

.left-arrow {
  width: 0.05rem;
  height: 0.1rem;
}

.allNewsData {
  position: relative;
  width: 100%;
  height: 3.22rem;
  overflow: hidden;
  margin-bottom: 0.24rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dfe4ee;
  cursor: pointer;
}

.allNewsDataTrans {
  position: relative;
  width: 100%;
  height: 7.98rem;
  display: flex;
  flex-wrap: nowrap;
}

.allNewsDataBlock {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.allNewsDataItem {
  position: relative;
  width: 3.65rem;
  height: 2.77rem;
  cursor: pointer;
  margin: 0 0.12rem 1rem 0.12rem;
  font-family: "Medium";
}

.allNewsLeftImgBlock {
  height: 100%;
  width: 4.2rem;
  /* background: #005dff; */
  overflow: hidden;
}

.allNewsLeftImgBlock .img {
  position: relative;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.allNewsLeftImgBlock .img1 {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.allNewsRightBlock {
  width: 9.42rem;
  height: 100%;
  position: relative;
}
.newInfoTitle {
  font-size: 0.24rem;
  font-family: _Bold;
  font-weight: 700;
  margin-top: 0.4rem;
  width: 8.83rem;
  /* height: 0.32rem; */
  white-space: wrap;
}
.newInfoTitleTwo {
  font-size: 0.24rem;
  font-family: _Regular;
  margin-top: 0.1rem;
}
.newInfoContent {
  font-size: 0.18rem;
  font-family: _Regular;
  margin-top: 0.3rem;
  width: 8.83rem;
  height: 1rem;
  line-height: 0.32rem;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #777a81;
}
.newInfoDate {
  font-size: 0.14rem;
  font-family: _Regular;
  color: #777a81;
  position: absolute;
  left: 0;
  bottom: 0.2rem;
}
.newInfoDate span {
  color: #0073e6;
  padding-right: 0.1rem;
}
.allNewsDataItemTitle {
  position: relative;
  font-size: 0.16rem;
  color: #666666;
  margin-top: 0.17rem;
  margin-bottom: 0.14rem;
}

.allNewsDataItemContent {
  position: relative;
  font-size: 0.2rem;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.allNewsBtnArea {
  position: relative;
  margin: 0 auto;
  height: 0.48rem;
  display: flex;
  justify-content: center;
  user-select: none;
  font-family: "Regular";
}

.indexBtn {
  position: relative;
  font-size: 0.16rem;
  line-height: 0.48rem;
  color: #333333;
  margin: 0 0.16rem;
  cursor: pointer;
}

.leftBtn,
.rightBtn {
  position: relative;
  height: calc(0.48rem - 2px);
  width: calc(0.48rem - 2px);
  margin: 0 0.18rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #333333;
  font-size: 0.4rem;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  /* background-size: 25% 50%; */
  background-size: 0.08rem 0.16rem;
}

.leftBtn {
  background-image: url("../../assets/left.png");
  background-position: 50% 50%;
}

.leftBtn:hover {
  background-image: url("../../assets/left_hover.png");
  border: 1px solid #005dff;
}

.leftBtn:active {
  background-image: url("../../assets/icon_left_click.png");
  background-size: 100% 100%;
  background-position: 0% 0%;
  border: 1px solid rgba(0, 0, 0, 0);
}

.rightBtn {
  background-image: url("../../assets/right.png");
  background-position: 50% 50%;
}

.rightBtn:hover {
  background-image: url("../../assets/right_hover.png");
  border: 1px solid #005dff;
}

.rightBtn:active {
  background-image: url("../../assets/icon_right_click.png");
  background-size: 100% 100%;
  background-position: 0% 0%;
  border: 1px solid rgba(0, 0, 0, 0);
}

.phoneAllNews {
  width: 90.4%;
  position: relative;
  margin: 0 auto;
}
.phoneAllNewsData {
  width: 100%;
  margin-bottom: 0.4rem;
  border: 1px solid #dfe4ee;
}
.allTopImgBlock {
  width: 100%;
  height: 2.03667rem;
  overflow: hidden;
}
.phoneImg {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.phoneImg1 {
  position: relative;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.phoneAllnewInfo {
  width: 89%;
  margin: 0 auto;
  margin-bottom: 0.176667rem;
}
.phoneNewInfoTitle {
  width: 100%;
  font-size: 0.21rem;
  font-family: "Bold";
  font-weight: 700;
  text-align: justify;
  margin-top: 0.19333rem;
  margin-bottom: 0.236667rem;
}
.phoneNewInfoDate {
  font-size: 0.13333rem;
  font-family: _Regular;
  color: #777a81;
}
.phoneNewInfoDate span {
  color: #0073e6;
  padding-right: 0.1rem;
}
</style>
