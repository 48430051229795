<template>
    <div id="news-list">
        <div class="back" @click="back">
            <img src="../../assets/phone/back-icon.png" class="back-icon">
            <div class="back-text">返回上级</div>
        </div>
        <!--  在这里渲染新闻详情组件-->
        <PhoneNewsInfo v-if="newsInfoVisible" :newsInfo="currentShows[currentNews]"></PhoneNewsInfo>
        <div class="other-news-title" v-if="newsInfoVisible">更多精彩内容</div>

        <div class="news">
            <div class="news-item" @click="showNewsInfo(index)" v-for="(item, index) in currentShows" :key="index">
                <img :src="global.AssetsURL + item.url" class="news-img">
                <div class="news-content">
                    <div class="news-title">{{ item.content }}</div>
                    <div class="news-time">{{ item.time }}</div>
                </div>
            </div>
        </div>
        <div class="more" v-if="this.newsList.length !== this.currentShows.length">
            <div class="more-btn" @click="showMoreNews">
                点击显示更多内容
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue';
import PhoneNewsInfo from '../../components/PhoneNewsInfo.vue';
import { newsData } from '../../../public/data/Allnews';
export default {
    name: 'PhoneNewsList',
    data() {
        return {
            newsList: newsData,
            currentShows: [],
            newsInfoVisible: false,
            currentNews: 0
        }
    },
    components: {
        Footer,
        PhoneNewsInfo,
    },
    methods: {
        back() {
            const i = this.$route.params.index;
            
            if (this.newsInfoVisible && i === undefined) {
                this.newsInfoVisible = false;
                this.currentNews = null;
            } else {
                this.$router.back();
            }
        },
        showMoreNews() {
            for (let i = 8; i < this.newsList.length; i++) {
                this.currentShows.push(this.newsList[i]);
            }
        },
        showNewsInfo(index) {
            this.currentNews = index;
            this.newsInfoVisible = true;
            window.scrollTo(0, 0);
        },
        showNewsInfoCheck(){
            const i = this.$route.params.index;

            if(i !== undefined){
                this.showNewsInfo(i);
            }
        }
    },
    mounted() {
        let c = this.newsList.lnegth >= 8 ? this.newsList.length : 8;

        for (let i = 0; i < c; i++) {
            this.currentShows.push(this.newsList[i]);
        }

        this.showNewsInfoCheck();
    },
    created(){
        this.global.titleIndex = 12;
    }
}
</script>

<style scoped>
#news-list {
    width: 100%;
    padding-top: 0.12rem;
    box-sizing: border-box;
}

.back {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.2rem;
    box-sizing: border-box;
}

.back-icon {
    width: 0.13rem;
    display: block;
    margin-right: 0.09rem;
}

.back-text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #333333;
}

.other-news-title {
    width: 100%;
    padding-left: 0.2rem;
    box-sizing: border-box;
    font-size: 0.24rem;
    font-weight: 700;
    color: #333333;
}

.news {
    width: 100%;
    margin-top: 0.21rem;
    padding: 0 0.2rem;
    box-sizing: border-box;
}

.news-item {
    width: 100%;
    display: flex;
    margin-bottom: 0.16rem;
}

.news-img {
    width: 1.13rem;
    height: 0.76rem;
    border-radius: 0.04rem;
    margin-right: 0.12rem;
    display: block;
}

.news-content {
    width: calc(100% - 1.25rem);
    height: 0.76rem;
    position: relative;
}

.news-title {
    display: -webkit-box;
    width: 100%;
    height: 0.46rem;
    font-size: 0.16rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.24rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-time {
    position: absolute;
    left: 0;
    bottom: 0.02rem;
    font-size: 0.12rem;
    font-weight: 400;
    color: #666;
}

.more {
    width: 100%;
    height: 0.36rem;
    margin: 0.04rem 0 0.36rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-btn {
    width: 1.25rem;
    height: 0.36rem;
    border-radius: 0.08rem;
    text-align: center;
    line-height: 0.36rem;
    font-size: 0.13rem;
    font-weight: 400;
    color: #333333;
    background-color: #F2F5FA;
}
</style>