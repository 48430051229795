<template>
  <div class="dataInfo">
    <div class="backBtn" @click="backFn()">
      <img class="left-arrow" src="../assets/left-arrow.png" />
      返回上级
    </div>
    <div class="infoBody">
      <div class="dataTitle">{{ data.info.name }}</div>
      <div class="dataClass">{{ data.title }}</div>
      <div class="line"></div>

      <div class="dataBlock" v-for="(item, index) in data.info.data" :key="index">
        <div class="titleA" v-if="item.titleA">{{ item.titleA }}</div>
        <div class="dataContentArea" v-for="(item2, index2) in item.content" :key="index2">
          <div class="titleB" v-if="item2.titleB">
            <div class="point"></div>
            {{ item2.titleB }}
          </div>
          <div class="dataTxt" v-if="item2.txt" v-html="item2.txt"></div>
          <div class="imgArea" v-if="item2.img">
            <div class="longImg" v-if="item2.img.length == 1">
              <img :src="global.AssetsURL + item2.img[0]" />
            </div>
            <div class="towImg" v-if="item2.img.length == 2">
              <div class="imgBlock">
                <img :src="global.AssetsURL + item2.img[0]" />
              </div>
              <div class="imgBlock">
                <img :src="global.AssetsURL + item2.img[1]" />
              </div>
            </div>
            <div class="threeImg" v-if="item2.img.length == 3">
              <div class="imgBlock">
                <img :src="global.AssetsURL + item2.img[0]" />
              </div>
              <div class="imgBlock">
                <img :src="global.AssetsURL + item2.img[1]" />
              </div>
              <div class="imgBlock">
                <img :src="global.AssetsURL + item2.img[2]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <div class="connect-us" @click="connectUs()">联系我们</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataInfo",
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {
    // console.log(this.data);
  },
  methods: {
    backFn() {
      this.$emit("clearDataItemInfo");
    },
    connectUs() {
      console.log(`connect us`)
    }
  },
};
</script>

<style scoped>
.dataInfo {
  position: relative;
  width: 75%;
  margin: 0 auto;
  max-width: 1440px;
}

.backBtn {
  color: #333333;
  font-size: 0.16rem;
  margin-top: 0.37rem;
  margin-bottom: 0.78rem;
  cursor: pointer;
}

.left-arrow {
  width: 0.05rem;
  height: 0.1rem;
}

.infoBody {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.dataTitle {
  position: relative;
  font-size: 0.36rem;
  color: #333333;
  font-family: "Bold";
}

.dataClass {
  font-size: 0.16rem;
  color: #666666;
  margin-top: 0.15rem;
  width: 100%;
  padding-bottom: 0.08rem;
  border-bottom: 1px solid #aaaaaa;
  font-family: "Regular";
}

.dataBlock {
  position: relative;
  margin-top: 0.6rem;
}

.titleA {
  position: relative;
  font-size: 18px;
  color: #005dff;
  font-family: "Bold";
}

.dataContentArea {
  position: relative;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

.titleB {
  position: relative;
  font-size: 16px;
  color: #333333;
  font-family: "Medium";
}

.dataTxt {
  margin-top: 0.24rem;
  margin-left: 8px;
  position: relative;
  font-size: 16px;
  color: #666666;
  font-family: "Regular";
}

.imgArea {
  position: relative;
  width: 100%;
  height: 3.26rem;
  margin-top: 0.24rem;
}

.longImg {
  position: relative;
  width: 100%;
  height: 100%;
}

.longImg img {
  position: relative;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.towImg {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.towImg .imgBlock {
  position: relative;
  width: 48%;
  height: 100%;
  overflow: hidden;
}

.towImg img {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.threeImg {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.threeImg .imgBlock {
  position: relative;
  width: 31%;
  height: 100%;
  overflow: hidden;
}

.threeImg img {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.point {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.connect-us {
  width: 1.48rem;
  height: 0.48rem;
  text-align: center;
  line-height: 0.48rem;
  font-size: 0.16rem;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #005DFF;
  border-radius: 6px;
  flex-shrink: 0;
  cursor: pointer;
}
</style>
