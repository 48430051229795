<template>
    <div id="marketing">
        <!-- 定位块 -->
        <div class="phoneCompanyName" style="position: absolute;top: 0;width: 0;height: 0;"></div>

        <img :src="global.isPhone ? require('../assets/phone-marketing-bg.jpg') : require('../assets/marketing.jpg')"
            class="marketing-bg">
        <div class="content" v-if="!global.isPhone">
            <div class="companyName marketing-title">
                元宇宙医学数字营销--服务全球专业的医疗机构与厂商
            </div>
            <div class="marketing-subtitle">用科技关爱生命的温度</div>
            <div class="marketing-video" @click="visitMarketingPage">点击观看视频</div>
        </div>
        <div class="phone-content" v-if="global.isPhone">
            <div class="phone-marketing-title">
                元宇宙医学数字营销--服务全球专业的医疗机构与厂商
            </div>
            <div class="phone-marketing-subtitle">用科技关爱生命的温度</div>
            <div class="phone-marketing-video" @click="visitMarketingPage">点击观看视频</div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        visitMarketingPage() {
            this.$router.push({
                name: 'Digital'
            });
        },
    },
    created() {

    },
    data() {
        return {}
    }
}
</script>

<style scoped>
#marketing {
    position: relative;
    width: 100%;
    margin-top: 2rem;
    overflow: hidden;
}

.marketing-bg {
    position: absolute;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
}

.content {
    position: relative;
    margin: 0 auto;
    width: 14.4rem;
    height: 4rem;
    max-width: 1440px;
    user-select: none;
    z-index: 3;
}

.companyName {
    font-size: 0.48rem;
    font-family: "Bold";
    font-weight: Bold;
    line-height: 0.64rem;
}

.marketing-title {
    position: absolute;
    top: 0.64rem;
    left: 7.54rem;
    color: #333333;
    line-height: .48rem;
    word-break: break-all;
    word-wrap: break-word;
}

.marketing-video {
    width: 2rem;
    height: .6rem;
    background-color: #0073E6;
    text-align: center;
    line-height: .6rem;
    color: #fff;
    font-size: .24rem;
    position: absolute;
    left: 7.54rem;
    top: 2.66rem;
    cursor: pointer;
    font-weight: 100;
}

.marketing-subtitle {
    position: absolute;
    top: 1.6rem;
    left: 7.54rem;
    font-size: .48rem;
    font-weight: 300;
}

.phone-content {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 3.92rem;
    padding: 0.8rem 0.18rem;
    box-sizing: border-box;
}

.phone-marketing-title {
    width: 100%;
    font-weight: bold;
    font-size: 0.28rem;
    line-height: 0.37rem;
    margin-bottom: 0.16rem;
}

.phone-marketing-subtitle {
    width: 100%;
    font-size: 0.28rem;
    margin-bottom: 0.35rem;
}

.phone-marketing-video {
    width: 1.57rem;
    height: 0.4rem;
    text-align: center;
    line-height: 0.4rem;
    background-color: #0073E6;
    color: #fff;
    font-size: 0.16rem;
}
</style>