<template>
  <div class="MedicalALlScreem">
    <div class="pc-medical" v-if="!global.isPhone">
      <Header :styleChangeIndex="headerStyle" @headerListClickFn="scrollMoveFn" />
      <div v-if="!dataItemInfo.title" class="medivalSwiper">
        <!-- <img src="../assets/picture/Medical/banner.png" class="swiperBG" /> -->
        <!-- <img src="../assets/picture/Medical/banner1.png" class="banner"> -->
        <!-- <Swiper :swiperStyle="false" :imgList="data.swiperImgData" :contentList="data.siwperContentList"
          @swiperIndexFn="swiperIndexFn" @checkMoreFn="scrollMoveFn" /> -->
        <Swiper :imgList="data.swiperList" />
      </div>
      <Middle v-if="!dataItemInfo.title" />
      <div class="mid-navigator">
        <div class="pageItemList">
          <div @click="scrollMoveFn(1)">专科综合教学平台</div>
          <div @click="scrollMoveFn(2)">专科单病种教学内容平台</div>
          <div @click="scrollMoveFn(3)">3D医学打印服务</div>
          <div @click="scrollMoveFn(4)">远程手术/查房直播系统</div>
          <div @click="scrollMoveFn(5)">全景/高清/立体手术直录播服务</div>
          <div @click="scrollMoveFn(6)">临床与教学科研支持</div>
        </div>
      </div>

      <DataList v-show="!dataItemInfo.title" :data="data" @showDataItemInfo="showDataItemInfo"
        @showIntentionForm="showIntentionForm" @dataListImageLoad="onDataListImgLoad($event)" />

      <DataInfo v-if="dataItemInfo.title" :data="dataItemInfo" @clearDataItemInfo="clearDataItemInfo" />

      <Footer style="margin-top:120px" @scrollMoveFn="scrollMoveFn" />
    </div>

    <div class="phone-medical" v-if="global.isPhone">
      <div class="phone-medical-body" v-if="!dataItemInfo.title">
        <Header @phoneScrollMoveFn="phoneScrollMoveFn"></Header>
        <div class="phone-banner">
          <Swiper :imgList="data.swiperList" />
        </div>
        <InPageNavigator :data="inPageNavigations" @inPageNavigation="inPageNavigation" />
        <DataList :data="data"></DataList>
      </div>
      <PhoneDataInfo v-if="dataItemInfo.title" :data="dataItemInfo" @clearDataItemInfo="clearDataItemInfo">
      </PhoneDataInfo>
      <Footer @phoneScrollMoveFn="phoneScrollMoveFn" />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Middle from "../components/Middle.vue";
import Footer from "../components/Footer.vue";

import DataList from "../components/DataList.vue";
import DataInfo from "../components/DataInfo.vue";
import PhoneDataInfo from "./PhoneDataInfo.vue";

import { MedicalData } from "../../public/data/Medical";
import Swiper from '../components/Swiper.vue';
import InPageNavigator from "../components/InPageNavigator.vue";

export default {
  name: "MedicalALlScreem",
  components: {
    Header,
    Footer,
    Middle,
    DataList,
    DataInfo,
    PhoneDataInfo,
    Swiper,
    InPageNavigator
  },
  data() {
    return {
      headerStyle: false,
      isPhone: false,
      data: MedicalData,
      dataItemInfo: {
        info: null,
        title: null,
      },
      inPageNavigations: MedicalData.inPageNavigations
    };
  },
  created: function () {
    this.global.titleIndex = 4;

    //如果有跳转index，屏幕滚动
    if (!isNaN(this.$route.params.y)) {
      setTimeout(() => {
        if (this.global.isPhone) {
          const correction = this.$route.params.correction ? this.$route.params.correction : 0;
          this.phoneScrollMoveFn(this.$route.params.y, correction);
        } else {
          this.scrollMoveFn(this.$route.params.y);
        }
      }, 100);
    }
    this.isPhone = this.global.isPhone;
  },
  methods: {
    swiperIndexFn() {
      // console.log(index);
    },
    scrollMoveFn(index) {
      // console.log(index);
      let dy = 0;
      let block = document.getElementsByClassName("dataItemBlock")[index - 1];
      if (index > 0) {
        dy = block.getBoundingClientRect().top;
      }
      let scrollTop = index == 0 ? 0 : document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      window.scrollTo(0, dy + scrollTop - 250);
    },
    showDataItemInfo(data, title) {
      [this.dataItemInfo.info, this.dataItemInfo.title] = [data, title];
    },
    clearDataItemInfo() {
      this.dataItemInfo.info = null;
      this.dataItemInfo.title = null;
    },
    showIntentionForm() {
      this.$emit('showIntentionForm');
    },
    phoneScrollMoveFn(index,correction = 0) {
      const ratio = window.innerWidth / 375;
      const headHeight = 130 * ratio;
      const block = document.getElementsByClassName("phone-data-item")[index];
      const dy = block.getBoundingClientRect().top;
      const scrollTop = document.documentElement.scrollTop; //当前滚动条已经滚动的距离
      let dis = dy + scrollTop - headHeight;
      if (correction != 0) dis += correction;
      window.scrollTo(0, dis);
    },

    onDataListImgLoad(event) {
      const { i, idx, index } = event;
      this.data.itemList[i].blocks[idx].data[index].showImg = true;
    },

    inPageNavigation(event) {
      const { index, correction } = event;

      this.phoneScrollMoveFn(index, correction);
    },
  },
};
</script>

<style scoped>
@import "../common/font/font.css";

.MedicalALlScreem {
  position: relative;
  width: 100%;
}

.medivalSwiper {
  height: 8.62rem;
  width: 100%;
}

.swiperBG {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.banner {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mid-navigator {
  width: 100%;
  margin-top: .41rem;
  margin-bottom: .9rem;
}

.pageItemList {
  white-space: nowrap;
  height: .39rem;
  font-size: .16rem;
  font-family: "regular";
  color: #32373E;
  display: flex;
  background-color: #ffffff;
  width: 14.4rem;
  max-width: 1440px;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  padding-left: .2rem;
  box-sizing: border-box;
}

.pageItemList div {
  margin-right: .4rem;
  cursor: pointer;
}

.pageItemList div:hover {
  color: #005dff;
  box-sizing: border-box;
  border-bottom: .04rem solid #005dff;
}

@media screen and (min-width: 650px) {}

@media screen and (max-width: 649px) {}

.phone-medical {
  width: 100%;
}

.phone-medical-body {
  width: 100%;
}

.phone-banner {
  width: 100%;
}
</style>
