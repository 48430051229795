<template>
  <div style="height: 100%;">

    <div class="pc-swiper" v-if="!global.isPhone">
      <div class="swiper-container">
        <div class="swiper-items">
          <div class="img-container" v-for="(img, index) in imgList" :key="index"
            :style="{ 'transform': `translateX(-${swiperIndex}00%)`, 'transition': swiperIndex == 0 ? '' : 'all 1s linear' }">
            <img :src="global.AssetsURL + img.preUrl" style="z-index: 2;">
          </div>
        </div>
      </div>
      <div class="container">
        <div class="desc" :style="{ 'transform': hideDescription ? 'translateY(100%)' : '' }">
          <div class="title">{{ imgList[descriptionIndex].title }}</div>
          <div class="subtitle">{{ imgList[descriptionIndex].subtitle }}</div>
        </div>
      </div>
    </div>

    <!-- 手机 -->
    <div class="phoneSwiper" v-if="global.isPhone">
      <div class="phone-swiper-container">
        <div class="phone-swiper-item" v-for="(img, index) in imgList" :key="index"
          :style="{ 'transform': `translateX(-${swiperIndex}00%)`, 'transition': swiperIndex == 0 ? '' : 'all 1s linear' }">
          <img :src="global.AssetsURL + img.preUrl" style="z-index: 2;" class="phone-swiper">
        </div>
      </div>
      <div class="phone-swiper-desc">
        <div class="phone-desc-title">{{ imgList[swiperIndex].title }}</div>
        <div class="phone-desc-subtitle">{{ imgList[swiperIndex].subtitle }}</div>
        <div class="contact-button" @click="gotoContactPage()">联系我们</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiper",
  props: {
    imgList: {
      type: Array
    },
    contentList: {
      type: Array
    },
    swiperStyle: Boolean,
    renderBG: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      swiperIndex: 0,
      swiperTimer: "",
      resizeTimer: null,
      imgDx: 0,
      swiperLoadTimer: null,
      swiperTimerIndex: 0,
      hideDescription: false,
      descriptionIndex: 0,
    };
  },
  created: function () {
    if (this.imgList.length == 1) return
    this.swiperTimer = setInterval(() => {
      this.swiperIndexFn();
      this.swiperTimerIndex = 0;
    }, 5000);
    this.swiperLoadTimer = setInterval(() => {
      this.swiperTimerIndex += 1 / 5;
    }, 10);
  },
  methods: {
    swiperIndexFn() {
      this.swiperIndex++;
      if (this.swiperIndex == this.imgList.length - 1) {
        setTimeout(() => {
          this.swiperIndex = 0;
        }, 2500);
      }
    },
    indexClickFn(index) {
      if (this.swiperIndex != index) {
        this.swiperIndex = index;
        window.clearInterval(this.swiperTimer);
        window.clearInterval(this.swiperLoadTimer);
        this.swiperTimerIndex = 0;
        this.swiperTimer = setInterval(() => {
          this.swiperIndexFn();
          this.swiperTimerIndex = 0;
        }, 5000);
        this.swiperLoadTimer = setInterval(() => {
          this.swiperTimerIndex += 1 / 5;
        }, 10);
      }
    },
    contentBtnFn(n) {
      this.$emit("checkMoreFn", n + 1);
    },
    swiperImgSizeChangeFn() {
      this.imgDx =
        document.querySelector(".swiper")?.getBoundingClientRect().width ||
        document.querySelector(".swiperMid")?.getBoundingClientRect().width ||
        document.querySelector(".phoneSwiper")?.getBoundingClientRect().width;
    },

    setSwiperDescVisiblity() {
      if (this.swiperIndex == 0) return
      this.hideDescription = true;
      setTimeout(() => {
        this.descriptionIndex = this.swiperIndex;
        this.hideDescription = false;
      }, 500);
    },

    onSwiperLoad(index) {
      this.imgList[index].show = true;
    },

    gotoContactPage() {
      // this.global.titleIndex = 6;
      this.global.titleIndex = 9;
      this.$router.push({
        name: 'Contact'
      });
    },
  },
  computed: {
    swiperChange() {
      let x = -this.swiperIndex * this.imgDx;

      return {
        transform: `translateX(${x}px)`,
        transition: "1s"
      };
    },
    getSwiperIndexWidth() {
      return `${this.swiperTimerIndex}%`;
    },
  },
  watch: {
    swiperIndex() {
      setTimeout(() => {
        if (!this.global.isPhone) {
          this.setSwiperDescVisiblity();
        }
      }, 1000);
    }
  },
  mounted() {
    setTimeout(() => {
      this.swiperImgSizeChangeFn();
    }, 10);
    window.addEventListener("resize", this.swiperImgSizeChangeFn);
  },
  destroyed() {
    window.removeEventListener("resize", this.swiperImgSizeChangeFn);
    window.clearInterval(this.swiperTimer);
    window.clearInterval(this.swiperLoadTimer);
  }
};
</script>

<style scoped>
.pc-swiper {
  width: 100%;
  height: 8.91rem;
  position: relative;
  overflow: hidden;
}

.swiper-container {
  width: 100%;
  height: 8.62rem;
  position: relative;
  overflow: hidden;
}

.swiper-items {
  height: 100%;
  display: flex;
}

.img-container {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.img-container img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.container {
  width: 14.4rem;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 7.2rem);
  top: 0;
  z-index: 9;
}

.desc {
  width: 9.54rem;
  height: 2.35rem;
  position: absolute;
  left: 0;
  bottom: 0rem;
  background-color: #F7F7F7;
  padding: .36rem .36rem 0 .4rem;
  box-sizing: border-box;
  transition: all 0.3s linear;
  z-index: 9;
}

.title {
  font-size: .48rem;
  font-weight: 600;
  margin-bottom: .1rem;
}

.subtitle {
  width: 100%;
  font-size: .48rem;
  font-weight: 200;
  color: #32373E;
  line-height: .6rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  z-index: 9;
}

.swiper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.swiperTransformBlock {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.swiperItem {
  position: relative;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiperItem img {
  /* position: relative; */
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.imgStyle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.swiperMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right,
      rgba(2, 3, 2, 0.4),
      rgba(0, 0, 0, 0));
}

.swiperIndex {
  position: relative;
  height: 4px;
  width: fit-content;
  bottom: 0.16rem;
  top: 1rem;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  animation: contentInfoMove 1s ease-out;
  cursor: pointer;
}

.indexBlock {
  position: relative;
  height: 4px;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.36);
  margin-right: 8px;
  border-radius: 10px;
  overflow: hidden;
}

.indexWhite {
  position: relative;
  height: 100%;
  background-color: #ffffff;
}

.indexBlueBlock {
  height: 4px;
  width: 80px;
  background-color: #00a2ff;
  transition: 0.5s;
  margin-right: 8px;
}

.midContent {
  position: absolute;
  bottom: 0;
  width: 60%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.contentInfo {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 312px;
  white-space: nowrap;
}

.contentInfoTitle {
  /* font-size: 0.36rem; */
  font-size: 36px;
  font-family: "Bold";
  color: #ffffff;
  animation: contentInfoMove 1s ease-out;
}

@keyframes contentInfoMove {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.contentInfoLine {
  height: 1px;
  width: 0.64rem;
  background-color: rgba(202, 212, 224, 1);
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
}

.contentInfoTxt {
  /* font-size: 0.16rem; */
  font-size: 20px;
  font-family: "Regular";
  color: #cad4e0;
  animation: contentInfoMove 1s ease-out;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.contentBtn {
  width: 1.6rem;
  height: 0.48rem;
  border: 1px #ffffff solid;
  font-size: 0.16rem;
  font-family: "Regular";
  color: #fefefe;
  text-align: center;
  line-height: 0.48rem;
  border-radius: 0.08rem;
  margin-top: 0.47rem;
  animation: contentInfoMove 1s ease-out;
  /* animation-delay: 0.75s; */
}

/* phone css */
.phoneSwiper {
  width: 100%;
}

.phoneImgStyle {
  position: relative;
  width: 100%;
  flex-shrink: 0;
}

.phoneSwiperMask {
  position: absolute;
  width: 100%;
  height: 3.9rem;
  background-color: #09192b;
  opacity: 48%;
}

.phoneSwiperIndex {
  position: absolute;
  height: 0.04rem;
  width: 100%;
  bottom: 0.1rem;
  right: -3.4rem;
  display: flex;
  z-index: 1;
}

.phoneIndexBlock {
  height: 0.04rem;
  width: 0.04rem;
  background-color: #ffffff;
  margin-right: 0.04rem;
}

.phoneIndexBlueBlock {
  height: 0.04rem;
  width: 0.12rem;
  background-color: #00a2ff;
  transition: 0.4s;
  margin-right: 0.04rem;
}

.phoneMidContent {
  position: absolute;
  margin-top: 1.57rem;
  width: 100%;
  height: 0.1rem;
  padding: 0 0.24rem;
  box-sizing: border-box;
  /* text-align: center; */
}

.phoneContentInfo {
  position: relative;
}

.phoneContentInfoTitle {
  font-size: 0.24rem;
  line-height: 0.32rem;
  font-weight: 700;
  font-family: "Regular";
  color: #ffffff;
  animation: contentInfoMove 1s ease-out;
  margin-bottom: 0.12rem;
}

@keyframes contentInfoMove {
  from {
    opacity: 0;
    transform: translateY(0.4rem);
  }

  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.phoneContentInfoLine {
  border: 1px solid #cad4e0;
  width: 1.92rem;
  margin: 0.05rem auto;
}

.phoneContentInfoTxt {
  font-size: 0.12rem;
  font-family: "Regular";
  color: #fff;
  animation: contentInfoMove 1s ease-out;
}

.phoneContentBtn {
  position: relative;
  width: 0.815rem;
  height: 0.3rem;
  border: 1px solid #ffffff;
  margin: 0.16rem auto;
  animation: contentInfoMove 1s ease-out;
  color: #ffffff;
  font-family: "Regular";
  line-height: 0.32rem;
  font-size: 0.12rem;
}

/*  */

.swiperMid {
  position: relative;
  overflow: hidden;
  width: 75%;
  height: 5.5rem;
  margin: 0.25rem auto;
  border-radius: 0.16rem;
  box-shadow: 0px 12px 24px rgba(0, 43, 87, 0.08);
  background-color: rgba(255, 255, 255, 0.8);
}

.swiperMid .swiperTransformBlock {
  position: relative;
  height: 5.18rem;
  width: 100%;
  margin-top: 0.16rem;
}

.swiperMid .imgStyle {
  position: absolute;
  left: 0.16rem;
  transform: translateX(0);
  width: 53%;
  height: 100%;
  border-radius: 0.12rem;
}

.swiperMid .midContent {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0.66rem;
  transform: translateX(0);
  width: 35%;
  height: 100%;
  z-index: 2;
}

.swiperMid .contentInfoTitle {
  color: #333333;
}

.swiperMid .contentInfoTxt {
  color: #666666;
  margin-top: 0.3rem;
}

.swiperMid .contentInfo {
  width: 100%;
}

.contentBtn {
  position: relative;
  height: 0.42rem;
  width: 1.42rem;
  border-radius: 0.1rem;
  border: 1px solid #005dff;
  font-size: 0.16rem;
  color: #005dff;
  cursor: pointer;
  line-height: 0.42rem;
  font-family: "Regular";
}

.swiperMid .indexBlock {
  height: 4px;
  width: 80px;
  background-color: #edeef4;
  margin-right: 8px;
  border-radius: 10px;
  overflow: hidden;
}

.swiperMid .indexWhite {
  position: relative;
  height: 100%;
  background-color: #005dff;
}

.indexBlockShort {
  position: relative;
  height: 4px;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.36);
  margin-right: 8px;
  border-radius: 10px;
  overflow: hidden;
}

.swiperMid .indexBlockShort {
  position: relative;
  height: 4px;
  width: 15px;
  background-color: #edeef4;
  margin-right: 8px;
  border-radius: 10px;
  overflow: hidden;
}

.phone-swiper-container {
  width: 100%;
  height: 2.17rem;
  overflow: hidden;
  display: flex;
}

.phone-swiper-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.phone-swiper-item img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.phone-swiper-desc {
  width: 100%;
  padding: 0.28rem 0.18rem;
  box-sizing: border-box;
  font-size: 0.28rem;
}

.phone-desc-title {
  font-weight: 600;
  width: 100%;
}

.phone-desc-subtitle {
  font-size: 0.24rem;
  width: 100%;
  height: 1.48rem;
  line-height: 0.37rem;
  font-weight: 200;
  margin-bottom: 0.24rem;
}

.contact-button {
  width: 1.57rem;
  height: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  background-color: #0073E6;
  color: #fff;
  font-size: 0.16rem;
}
</style>