<template>
  <div class="PartnerAllScreem">
    <!-- pc -->
    <div class="pc-partner" v-if="!global.isPhone">
      <Header @headerListClickFn="scrollMoveFn" />

      <div class="swiperArea">
        <!-- <Swiper
        :imgList="data.swiperImgData"
        :contentList="data.siwperContentList"
      /> -->
        <img src="../../public/data/picture/partner/s1.png" class="banner" />
      </div>

      <Middle />

      <div class="partnerArea" style="margin-top: .93rem;">
        <div class="title">公司合作伙伴</div>
        <div class="viceTitle">排名不分先后</div>
        <!-- <div class="grid">
          <img
            :src="global.AssetsURL + item.url"
            class="partnerIrem"
            v-for="(item, index) in data.partner"
            :key="index"
          />
        </div> -->
        <div class="grid">
          <div
            v-for="(item, index) in data.partner"
            :key="index"
            :class="{
              item6: index == 2,
              item4: index == 4,
              item5: index == 10,
            }"
          >
            <img :src="global.AssetsURL + item.url" class="partnerIrem" />
          </div>
        </div>
      </div>
      <div class="partnerArea" style="margin-top: 0.74rem;">
        <div class="title">公司客户</div>
        <div class="viceTitle">排名不分先后</div>
        <div class="grid">
          <!-- <img
            :src="global.AssetsURL + item2.url"
            class="partnerIrem"
            v-for="(item2, index2) in item"
            :key="index2"
          /> -->
          <div
            v-for="(item, index) in data.custmer[0]"
            :key="index"
            :class="{
              item5: index == 0,
              item4: index == 8,
              item6: index == 16,
            }"
          >
            <img :src="global.AssetsURL + item.url" class="partnerIrem" />
          </div>
        </div>
        <div class="grid">
          <div
            v-for="(item, index) in data.custmer[1]"
            :key="index"
            :class="{
              item5: index == 0,
            }"
          >
            <img :src="global.AssetsURL + item.url" class="partnerIrem" />
          </div>
        </div>
        <div class="grid">
          <div
            v-for="(item, index) in data.custmer[2]"
            :key="index"
            :class="{
              item5: index == 0 || index == 10,
              item6: index == 5 || index == 15,
            }"
          >
            <img :src="global.AssetsURL + item.url" class="partnerIrem" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
    <!-- 移动 -->
    <div class="phone-partner" v-if="global.isPhone">
      <Header></Header>
      <img
        src="../../public/data/picture/partner/ps1.jpg"
        class="phone-partner-banner"
      />
      <!-- <Middle></Middle> -->

      <div class="phone-partner-area">
        <div class="phone-partner-title">
          <div class="phone-title">公司合作伙伴</div>
          <div class="phone-title2">排名不分先后</div>
        </div>
        <img src="../assets/partner/1.jpg" alt="" />
      </div>
      <div class="phone-partner-area">
        <div class="phone-partner-title">
          <div class="phone-title">公司客户</div>
          <div class="phone-title2">排名不分先后</div>
        </div>
        <img src="../assets/partner/2.jpg" alt="" />
        <img src="../assets/partner/3.jpg" alt="" />
        <img src="../assets/partner/4.jpg" alt="" />
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Middle from "../components/Middle.vue";
import Footer from "../components/Footer.vue";

// import Swiper from "../components/Swiper.vue";

import { PartnerData } from "../../public/data/Partner";

export default {
  name: "Digital",
  components: {
    Header,
    Footer,
    Middle,
    // Swiper,
  },
  data() {
    return {
      data: PartnerData,
    };
  },
  created: function() {
    this.global.titleIndex = 6;
  },
  methods: {
    scrollMoveFn() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    console.log(this.data);
  },
};
</script>

<style scoped>
@import "../common/font/font.css";

.PartnerAllScreem {
  position: relative;
  width: 100%;
  user-select: none;
}

.swiperArea {
  position: relative;
  margin: 0 auto;
  height: 6rem;
  width: 100%;
}

.banner {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  position: relative;
  font-size: 0.36rem;
  width: fit-content;
  color: #333333;
  font-family: "Bold";
}
.viceTitle {
  font-size: 0.36rem;
  font-family: "Regular";
  margin-bottom: 0.64rem;
}
.partnerArea {
  position: relative;
  width: 14.4rem;
  margin: 0 auto;
}

.grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  grid-gap: 0.1rem;
  margin-bottom: 1rem;
}

.grid div {
  /* background-color: aqua; */
  width: 100%;
  height: 2.4rem;
  overflow: hidden;
  background: #f6f7f7;
  transition: all 0.3s linear;
}
.grid div:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
}
.item4 {
  grid-column-start: 2;
  grid-column-end: 4;
}
.item5 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.item6 {
  grid-column-start: 3;
  grid-column-end: 5;
}

.partnerIrem {
  width: 100%;
}

@media screen and (min-width: 650px) {
}

@media screen and (max-width: 649px) {
}

.phone-partner {
  width: 100%;
  padding-top: 0.44rem;
}

.phone-partner-banner {
  display: block;
  width: 100%;
  height: 3.9rem;
}

.phone-partner-area {
  width: 100%;
  padding: 0.2rem 0.18rem 0 0.18rem;
  box-sizing: border-box;
}
.phone-partner-area img {
  width: 100%;
  margin-bottom: 0.6rem;
}
.phone-partner-title {
  width: 100%;
  margin-bottom: 0.26rem;
  font-size: 0.28rem;
}

.phone-left-icon {
  width: 0.06rem;
  height: 0.24rem;
  background: linear-gradient(to bottom, #00a8ff, #005dff);
  margin-right: 0.12rem;
}

.phone-title {
  font-weight: 700;
  color: #000;
  font-family: "Bold";
}
.phone-title2 {
  margin-top: 0.12rem;
  font-family: "Regular";
}
.phone-grid {
  display: grid;
  grid-template-columns: repeat(3, 1.04rem);
  column-gap: 0.12rem;
  row-gap: 0.16rem;
  margin-bottom: 0.36rem;
}

.phone-partner-logo {
  width: 1.04rem;
  height: 0.42rem;
  border-radius: 0.04rem;
}
</style>
