<template>
    <div class="detailPage">
      <div>heart</div>
    </div>
</template>

<script>
export default {
  name: "heart",
  data() {
    return {};
  }
};
</script>

<style scoped>
.detailPage {
  /* position: fixed; */
  /* top: 0; */
  height: 500px;
  width: 1000px;
  background-color: rgb(224, 197, 197);
}
</style>
