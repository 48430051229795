/**用于区分临床专科不同内容块的布局 */
export const ServerTypographical = {
    SIGFLEX: 1, //单块内容的flex布局，图片在左
    MXFLEX: 2, // 传统的list flex
    VERT: 3 // 自上而下，没有flex
}

export const PageBlocks = [{
    name: 'Home',
    root: '关于我们',
    rootName:'Home'
},
{
    name: 'Robot',
    root: '机器人手术模拟器',
    rootName:'Robot'
},
{
    name: 'DeviceTraining',
    root: '高端医疗器械培训',
    rootName:'DeviceTraining'
},
{
    name: 'Digital',
    root: '数字营销',
    rootName:'Digital'
},
{
    name:'AllNews',
    root:'关于我们',
    children:['最新消息'],
    rootName:'Home',
    childrenNames:['AllNews']
},{
    name:'NewsInfo',
    root:'关于我们',
    children:['最新消息'],
    rootName:'Home',
    childrenNames:['AllNews']
},
{
    name:'Medical',
    root:'临床专科',
    rootName:'Medical'
},
{
    name:'Simulation',
    root:'医学院校',
    rootName:'Simulation'
},
{
    name:'Partner',
    root:'合作伙伴',
    rootName:'Partner'
},
{
    name:'Contact',
    root:'联系我们',
    rootName:'Contact'
}]