<template>
  <div class="AboutMore">
    <div class="levelTitleBG"></div>
    <div class="header" v-if="!isPhone">
      <Header :styleChangeIndex="false" :page2Level="true" />
      <div class="levelTitle">
        <p class="homeTags" @click="backHome">首页 </p>
        <p> > 查看更多</p>
      </div>
    </div>
    <div class="phoneHeader" v-if="isPhone">更多案例</div>
    <div class="itemList">
      <div class="item" v-for="(item,index) in lists" :key="index">
        <div class="itemImgBlock">
          <img :src="item.url" class="itemImg">
        </div>
        <div class="itemContentBlock">
          <div class="itemName">{{item.name}}</div>
          <div class="itemContent">{{item.content}}</div>
        </div>
      </div>
    </div>
    <div class="space100"></div>
    <Footer />
  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import Footer from "../components/Footer.vue";

  export default {
    name: "AboutMore",
    components: {
      Header,
      Footer
    },
    data() {
      return {
        lists: [{
            name: "心血管",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/心血管.jpg")
          },
          {
            name: "超声",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/超声.jpg")
          },
          {
            name: "神经外科",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/神经外科.jpg")
          },
          {
            name: "5G+模拟医学临床专科教学系统",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/5G+模拟医学.jpg")
          },
          {
            name: "公共卫生",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/公共卫生.jpg")
          },
          {
            name: "5G+8K临床手术术野融合直播系统",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/5G+8K.jpg")
          },
          {
            name: "MR专家远程指导平台",
            content: "医学插画分成细胞及分子微环境、抽象医学原理、手术解剖和病理学···",
            url: require("../assets/picture/aboutMore/MR远程指导.jpg")
          }
        ],
        isPhone: false
      };
    },
    methods: {
      backHome() {
        this.$router.push(`/`);
      }
    },
    created() {
      this.isPhone = this.global.isPhone
    }
  };
</script>

<style scoped>
  @media screen and (min-width: 650px) {
    .AboutMore {
      position: absolute;
      left: 0;
      width: 100%;
      overflow: hidden;
    }

    .header {
      width: 19.2rem;
      max-width: 1920px;
      margin: 0 auto;
    }

    .levelTitle {
      margin: 0 auto;
      margin-top: 1.37rem;
      width: 13.88rem;
      max-width: 1388px;
      height: 0.8rem;
      background-color: #eff0f5;
      font-family: "Regular";
      color: #2f4257;
      line-height: 0.7rem;
    }

    .levelTitleBG {
      position: absolute;
      margin-top: 1.37rem;
      width: 100%;
      height: 0.8rem;
      background-color: #eff0f5;
      z-index: -1;
    }

    .levelTitle p {
      font-size: 0.18rem;
      display: inline;
    }

    .homeTags:hover {
      cursor: pointer;
    }

    .bigImg {
      width: 19.2rem;
      max-width: 1920px;
      height: 8.8rem;
    }

    .itemList {
      width: 13.88rem;
      max-width: 1388px;
      margin: 0 auto;
      margin-top: 0.6rem;
      display: flex;
      flex-wrap: wrap;
      font-size: 0;
    }

    .item {
      width: 4.34rem;
      margin-right: 0.42rem;
      text-align: center;
      font-family: "Regular";
      margin-bottom: 1.27rem;
    }
    .itemImgBlock{
      width: 4.76rem;
      height: 3.28rem;
    }

    .item:nth-child(3n + 0) {
      margin-right: 0rem;
    }

    .itemImg {
      width: 4.35rem;
      height: 3rem;
    }
    .itemImg:hover{
      height: 3.28rem;
      width: 4.76rem;
      transform: translateY(-0.14rem)
    }
    .itemContentBlock{
      /* position: relative;
      width: 4.35rem;
      margin: 0 auto; */
      width: 4.76rem;
    }
    .itemName {
      font-size: 0.24rem;
      color: #465567;
      width: 4.35rem;
      margin: 0 auto;
    }

    .itemContent {
      font-size: 0.16rem;
      color: #647588;
      width: 4.35rem;
      margin: 0 auto;
      margin-top: 0.1rem;
    }

    .space100 {
      width: 100%;
      height: 0.4rem;
    }
  }

  @media screen and (max-width: 649px) {
    .AboutMore {
      width: 100%;
      text-align: center;
      background: #F8F8FA;
      overflow: hidden;
    }

    .phoneHeader {
      font-size: 0.8rem;
      font-family: "MeDium";
      margin-top: 0.8rem;
      color: #2F4257;
      color: #2F4257;
    }

    .item {
      margin: 0 1rem;
      background-color: #ffffff;
      margin-top: 0.5rem;
      border-radius: 0.6rem 0.6rem 0px 0px;
      overflow: hidden;
    }

    .itemImg {
      width: 100%;
      height: 8.6rem;
      border-radius: 0.6rem 0.6rem 0px 0px;
    }

    .itemName {
      text-align: left;
      margin-left: 1rem;
      font-size: 0.7rem;
      font-family: "MeDium";
      color: #2F4257;
    }

    .itemContent {
      text-align: left;
      margin-left: 1rem;
      font-size: 0.6rem;
      font-family: "Regular";
      color: #647588;
      margin-bottom: 0.5rem;
    }
  }
</style>