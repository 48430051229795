<template>
    <div id="number-wheel" :class="global.isPhone ? 'phone-number-wheel' : 'number-wheel'">
        <div class="numbers">
            <div :class="global.isPhone ? 'phone-number' : 'number'" v-for="(num, index) in data.numbers" :key="index"
                :style="{ 'flex-direction': index % 2 == 0 ? 'column-reverse' : 'column' }">
                <div :class="global.isPhone ? 'phone-num' : 'num'" v-for="(n, i) in 11" :key="i"
                    :style="{ 'transform': `translateY(${index % 2 == 0 ? wheelIndex : -wheelIndex}00%)`, 'transition': `all ${duration / 10}s linear` }">
                    {{ num + 10 - i }}</div>
            </div>
        </div>
        <div class="unit" v-if="data.unit">{{ data.unit }}</div>
        <div>+</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            wheelIndex: 0,
            wheeling: false,
            scrollRecord: 0
        }
    },
    props: {
        data: Object,
        duration: {
            type: Number,
            default: 4
        },
        range: {
            type: Array,
            default: function () {
                return [350, 1800];
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.onWindowScroll.bind(this));
    },
    destroyed() {
        window.removeEventListener('scroll', this.onWindowScroll.bind(this));
    },
    methods: {
        wheel() {
            const interval = (this.duration * 1000) / 10;
            setInterval(() => {
                if (this.wheelIndex == 10) {
                    this.wheeling = false;
                    return
                }
                this.wheeling = true;
                this.wheelIndex++;
            }, interval);
        },
        onWindowScroll() {
            const [start, end] = this.range;
            const top = document.documentElement.scrollTop || document.body.scrollTop;
            // console.log(top);
            this.scrollRecord = top;
            if (this.wheeling) return
            if (!this.wheeling && top >= start && top <= end) return
            if ((top >= start && top >= this.scrollRecord) || (top <= end && top <= this.scrollRecord)) {
                this.wheelIndex = 0;
                this.wheel();
            }
        }
    }
}
</script>

<style scoped>
#number-wheel {
    /* font-size: 1.2rem; */
    display: flex;
    color: #141E8C;
}

.number-wheel {
    font-size: 1.2rem;
}

.phone-number-wheel {
    font-size: 0.4rem;
}

.numbers {
    display: flex;
}

.number {
    width: .72rem;
    height: 1.76rem;
    overflow: hidden;
    display: flex;
}

.phone-number {
    width: 0.24rem;
    height: 0.53rem;
    overflow: hidden;
    display: flex;
}

.num {
    flex-shrink: 0;
    width: .72rem;
    height: 1.76rem;
    overflow: hidden;
}

.phone-num {
    width: 0.24rem;
    height: 0.53rem;
    overflow: hidden;
    flex-shrink: 0;
}
</style>