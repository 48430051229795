const PartnerData = {
  swiperImgData: [
    {
      url: "/picture/partner/s1.jpg",
    },
    {
      url: "/picture/partner/s2.png",
    },
  ],
  siwperContentList: [
    {
      // title: "用科技打造<br/>特色医学教育",
      // info: "专注特色专科医学与模拟医学领域，通过最新前沿技术，用科技打造特色医学教育",
      title: "",
      info: "",
      url: "",
    },
    {
      title: "Hololens<br/>官方合作伙伴",
      info: "微软Hololens混合现实MRPP官方合作伙伴",
      url: "",
    },
  ],
  partner: [
    {
      url: "/picture/partner/1.jpg",
    },
    {
      url: "/picture/partner/2.jpg",
    },
    {
      url: "/picture/partner/3.jpg",
    },

    {
      url: "/picture/partner/4.jpg",
    },
    {
      url: "/picture/partner/5.jpg",
    },

    {
      url: "/picture/partner/6.jpg",
    },
    {
      url: "/picture/partner/7.jpg",
    },
    {
      url: "/picture/partner/8.jpg",
    },
    {
      url: "/picture/partner/9.jpg",
    },

    {
      url: "/picture/partner/10.jpg",
    },
    {
      url: "/picture/partner/11.jpg",
    },
    {
      url: "/picture/partner/12.jpg",
    },

    {
      url: "/picture/partner/13.jpg",
    },
  ],
  custmer: [
    [
      {
        url: "/picture/partner/14.jpg",
      },
      {
        url: "/picture/partner/15.jpg",
      },
      {
        url: "/picture/partner/16.jpg",
      },
      {
        url: "/picture/partner/17.jpg",
      },
      {
        url: "/picture/partner/18.jpg",
      },
      {
        url: "/picture/partner/19.jpg",
      },
      {
        url: "/picture/partner/20.jpg",
      },
      {
        url: "/picture/partner/21.jpg",
      },
      {
        url: "/picture/partner/22.jpg",
      },
      {
        url: "/picture/partner/23.jpg",
      },
      {
        url: "/picture/partner/24.jpg",
      },
      {
        url: "/picture/partner/25.jpg",
      },
      {
        url: "/picture/partner/26.jpg",
      },
      {
        url: "/picture/partner/27.jpg",
      },
      {
        url: "/picture/partner/28.jpg",
      },
      {
        url: "/picture/partner/29.jpg",
      },
      {
        url: "/picture/partner/30.jpg",
      },
    ],
    [
      {
        url: "/picture/partner/31.jpg",
      },
      {
        url: "/picture/partner/32.jpg",
      },
      {
        url: "/picture/partner/33.jpg",
      },
      {
        url: "/picture/partner/34.jpg",
      },
      {
        url: "/picture/partner/35.jpg",
      },
      {
        url: "/picture/partner/36.jpg",
      },
      {
        url: "/picture/partner/37.jpg",
      },
      {
        url: "/picture/partner/38.jpg",
      },
      {
        url: "/picture/partner/39.jpg",
      },
      {
        url: "/picture/partner/40.jpg",
      },
      {
        url: "/picture/partner/41.jpg",
      },
    ],
    [
      {
        url: "/picture/partner/42.jpg",
      },
      {
        url: "/picture/partner/43.jpg",
      },
      {
        url: "/picture/partner/44.jpg",
      },
      {
        url: "/picture/partner/45.jpg",
      },
      {
        url: "/picture/partner/46.jpg",
      },
      {
        url: "/picture/partner/47.jpg",
      },
      {
        url: "/picture/partner/48.jpg",
      },
      {
        url: "/picture/partner/49.jpg",
      },
      {
        url: "/picture/partner/50.jpg",
      },
      {
        url: "/picture/partner/51.jpg",
      },
      {
        url: "/picture/partner/52.jpg",
      },
      {
        url: "/picture/partner/53.jpg",
      },
      {
        url: "/picture/partner/54.jpg",
      },
      {
        url: "/picture/partner/55.jpg",
      },
      {
        url: "/picture/partner/56.jpg",
      },
      {
        url: "/picture/partner/57.jpg",
      },
    ],
  ],
};

export { PartnerData };
