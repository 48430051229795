<template>
    <div id="phone-news-info">
        <div class="news-title">{{ newsInfo.content }}</div>
        <div class="news-time">{{ newsInfo.time }}</div>
        <div class="dividing-line"></div>
        <img :src="global.AssetsURL + newsInfo.url" class="news-img">
        <div class="news-content" v-for="(item, index) in newsInfo.data" :key="index" v-html="item.txt"></div>
    </div>
</template>

<script>
export default {
    name: 'PhoneNewsInfo',
    props: {
        newsInfo: Object
    },
}
</script>

<style scoped>
#phone-news-info {
    width: 100%;
    padding: 0.22rem 0.2rem 0.63rem 0.2rem;
    box-sizing: border-box;
}

.news-title {
    font-size: 0.24rem;
    font-weight: 700;
    color: #333333;
    line-height: 0.32rem;
    margin-bottom: 0.08rem;
}

.news-time {
    font-size: 0.12rem;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0.17rem;
}

.dividing-line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #AAAAAA;
    margin-bottom: 0.36rem;
}

.news-img {
    width: 100%;
    height: 2rem;
    border-radius: 0.08rem;
    display: block;
    margin-bottom: 0.2rem;
}

.news-content {
    font-size: 0.13rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.24rem;
    margin-bottom: 0.24rem;
}
</style>